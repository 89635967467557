import { Cliente } from "./cliente";
import { Empresa } from "./empresa";
import { LineaVehiculo } from "./lineaVehiculo";
import { MarcaVehiculo } from "./marcaVehiculo";
import { OtroDescuento } from "./OtroDescuento";
import { Usuario } from "./usuario";

export class InspeccionVisual {
    proximoServicio: string;
    fugasVisibles: string;
    clutch: string;
    frenos: string;
    amortiguadores: string;
    sistemaDireccion: string;
    sistemaElectrico: string;
    ruidos: string;
    notas: string;
    imagenes: Imagen[] = [];
    trenDelantero: string;
    estadoBateria: string;
    marcaBateria: string;
}

export class DescuentoAvaluo {
    constructor(
        public otroDescuento: OtroDescuento,
        public isSelected: boolean,
        public unidades: number,
        public observaciones: string,
        public urlImage: string
    ) { }
}

export class EstructuraDescuentos {
    public internos: DescuentoAvaluo[] = [];
    public externos: DescuentoAvaluo[] = [];
}

export class OtrasInspecciones {
    public marcaLlantas: string;
    public medidaLlantas: string;
    public estadoLlantas: string;
    public aros: boolean;
    public platos: boolean;
    public tapones: boolean;
    public tarjetaCirculacion: boolean;
    public calcomania: boolean;
}

export class InspeccionGrafica {
    public paragolpesTrasero: ParteVehiculo;
    public luzDelanteraDerecha: ParteVehiculo;
    public luzDelanteraIzquierda: ParteVehiculo;
    public luzTraseraDerecha: ParteVehiculo;
    public luzTraseraIzquierda: ParteVehiculo;
    public panelTrasero: ParteVehiculo;
    public baul: ParteVehiculo;
    public parabrisasTrasero: ParteVehiculo;
    public techo: ParteVehiculo;
    public quemaCocos: ParteVehiculo;
    public parabrisasDelantero: ParteVehiculo;
    public capo: ParteVehiculo;
    public capoIzquierdo: ParteVehiculo;
    public capoDerecho: ParteVehiculo;
    public farosDelanteros: ParteVehiculo;
    public faroDelanteroDerecho: ParteVehiculo;
    public faroDelanteroIzquierdo: ParteVehiculo;
    public paragolpesDelantero: ParteVehiculo;
    public baulIzquierda: ParteVehiculo;
    public llantaTraseraIzquierda: ParteVehiculo;
    public ventanaTraseraIzquierda: ParteVehiculo;
    public puertaTraseraIzquierda: ParteVehiculo;
    public ventanaFrontalIzquierda: ParteVehiculo;
    public puertaFrontalIzquierda: ParteVehiculo;
    public llantaFrontalIzquierda: ParteVehiculo;
    public llantaDelanteraIzquierda: ParteVehiculo;
    public capoIzquierda: ParteVehiculo;
    public puertaDerecha: ParteVehiculo;
    public puertaIzquierda: ParteVehiculo;
    public baulDerecha: ParteVehiculo;
    public llantaTraseraDerecha: ParteVehiculo;
    public ventaTraseraDerecha: ParteVehiculo;
    public puertaTraseraDerecha: ParteVehiculo;
    public ventanaFrontalDerecha: ParteVehiculo;
    public puertaFrontalDerecha: ParteVehiculo;
    public ventanaTraseraDerecha: ParteVehiculo;
    public llantaFrontalDerecha: ParteVehiculo;
    public llantaDelanteraDerecha: ParteVehiculo;
    public capoDerecha: ParteVehiculo;
    public estriboDerecho: ParteVehiculo;
    public estriboIzquierdo: ParteVehiculo;
    public retrovisorDerecho: ParteVehiculo;
    public retrovisorIzquierdo: ParteVehiculo;
    public lateralIzquierdo: ParteVehiculo;
    public lateralDerecho: ParteVehiculo;
    public palanganaIzquierda: ParteVehiculo;
    public palanganaDerecha: ParteVehiculo;
    public palangana: ParteVehiculo;
    public puertaPalangana: ParteVehiculo;
    public imagenes: Imagen[] = [];
}

export class ParteVehiculo {
    public observaciones: string = '';
    public descuento: number = 0;
    public imagenes: Imagen[] = [];
}

export class Danios {
    public paragolpesTrasero: ParteVehiculoDanio[] = [];
    public luzTraseraDerecha: ParteVehiculoDanio[] = [];
    public luzTraseraIzquierda: ParteVehiculoDanio[] = [];
    public panelTrasero: ParteVehiculoDanio[] = [];
    public baul: ParteVehiculoDanio[] = [];
    public parabrisasTrasero: ParteVehiculoDanio[] = [];
    public palangana: ParteVehiculoDanio[] = [];
    public techo: ParteVehiculoDanio[] = [];
    public quemaCocos: ParteVehiculoDanio[] = [];
    public parabrisasDelantero: ParteVehiculoDanio[] = [];
    public capo: ParteVehiculoDanio[] = [];
    public farosDelanteros: ParteVehiculoDanio[] = [];
    public paragolpesDelantero: ParteVehiculoDanio[] = [];
    public baulIzquierda: ParteVehiculoDanio[] = [];
    public llantaTraseraIzquierda: ParteVehiculoDanio[] = [];
    public ventanaTraseraIzquierda: ParteVehiculoDanio[] = [];
    public puertaTraseraIzquierda: ParteVehiculoDanio[] = [];
    public ventanaFrontalIzquierda: ParteVehiculoDanio[] = [];
    public puertaFrontalIzquierda: ParteVehiculoDanio[] = [];
    public llantaFrontalIzquierda: ParteVehiculoDanio[] = [];
    public capoIzquierda: ParteVehiculoDanio[] = [];
    public puertaDerecha: ParteVehiculoDanio[] = [];
    public baulDerecha: ParteVehiculoDanio[] = [];
    public llantaTraseraDerecha: ParteVehiculoDanio[] = [];
    public ventaTraseraDerecha: ParteVehiculoDanio[] = [];
    public puertaTraseraDerecha: ParteVehiculoDanio[] = [];
    public ventanaFrontalDerecha: ParteVehiculoDanio[] = [];
    public puertaFrontalDerecha: ParteVehiculoDanio[] = [];
    public llantaFrontalDerecha: ParteVehiculoDanio[] = [];
    public capoDerecha: ParteVehiculoDanio[] = [];
    public estriboIzquierdo: ParteVehiculoDanio[] = [];
    public estriboDerecho: ParteVehiculoDanio[] = [];
    public retrovisorDerecho: ParteVehiculoDanio[] = [];
    public retrovisorIzquierdo: ParteVehiculoDanio[] = [];
}

export class ParteVehiculoDanio {
    public numero: number = -1;
    public tipoDanio: string = '';
    public fecha: string = '';
    public descripcion: string = '';
    public ContratoAhorrentId: number = -1;
    public UsuarioId: number = -1;
    public nombreUsuario: string = '';
    public nombreParte: string = '';
    public imagenes: Imagen[] = [];
    public ContratoAhorrentCorrelativo: number = null;
}

// interfaz
export interface ParteDaniada {
    nombreParte: string;
    detalleParte: ParteVehiculoDanio[];
}

export class Imagen {
    constructor(
        public url: string,
        public titulo: string,
        public UsuarioId: number,
    ) { }
}


export class Avaluo {
    constructor(
        public id: number,
        public estado: string,
        public tipo: string,
        public fechaSolicitud: string,
        public fechaAvaluo: string,
        public fechaAprobacion: string,
        public fechaConfirmado: string,
        public fechaRechazado: string,
        public fechaRecibido: string,
        public numeroInventario: string,
        public vehiculoRecibido: boolean,
        public vehiculoIngresado: boolean,
        public codigo: string,
        public primerApellido: string,
        public segundoApellido: string,
        public apellidoCasada: string,
        public primerNombre: string,
        public segundoNombre: string,
        public otrosNombres: string,
        public nit: string,
        public color: string,
        public modelo: string,
        public kilometraje: number,
        public precioLista: number,
        public devaluacionEstandar: number,
        public otrosDescuentos: number,
        public precioFinal: number,
        public precioFinalEstimado: number,
        public telefono: string,
        public placa: string,
        public cc: string,
        public numeroMotor: string,
        public transmision: string,
        public es4x4: boolean,
        public vehiculoInteres: string,
        public chasis: string,
        public referido: string,
        public listadoDevoluciones: string,
        public listadoDescuentos: string,
        public inspeccionesVisuales: string,
        public inspeccionGrafica: string,
        public MarcaVehiculoId: number,
        public LineaVehiculoId: number,
        public ClienteId: number,
        public EmpresaId: number,
        public UsuarioVendedorId: number,
        public UsuarioAvaluadorId: number,
        public UsuarioGerenciaId: number,
        public otrasInspecciones: string,
        public descuentoKilometraje: number,
        public utilidad: number,
        public iva: number,
        public descuentoInspeccionGrafica: number,
        public observacionesPrecioAprobado: string,
        public descuentosAdicionales: number,
        public recargosAdicionales: number,
        public descuentoInspeccionMecanicaVisual: number,
        public observacionesDescuento: string,
        public ProductoId: number,

        public es4x2: boolean,
        public AWD: boolean,
        public tipoAsiento: string,
        public colorAsiento: string,
        public aplicarSegundoIVA: boolean,
        public segundoIVA: number,
        public agencia: string,

        public nombreTarjetaCirculacion: string,
        public cambiosLinea: number,
        public descuentoCambioLinea: number,
        
        public correo: string,
        public esMarcaPremium: boolean,
        public carroceria: string,
        public fechaDescartado: string,
        public fechaAprobadoNoEntregado: string,
    ) {

    }

    public marcaVehiculo: MarcaVehiculo;
    public lineaVehiculo: LineaVehiculo;
    public cliente: Cliente;
    public empresa: Empresa;
    public usuarioVendedor: Usuario;
    public usuarioAvaluador: Usuario;
    public usuarioGerencia: Usuario;
    public nombreVendedor: string;
    public nombreAvaluador: string;
    public nombreGerencia: string;
    public estadoCapitalize: string;
    public tipoCapitalize: string;
    public carroceriaObj: any;
    public nombreCarroceria: string;
    public diferenciaEstimado: number;

    public fechaSolicitudFormato: string;
    public fechaAvaluoFormato: string;
    public fechaAprobacionFormato: string;
    public fechaConfirmadoFormato: string;
    public fechaRechazadoFormato: string;
    public fechaRecibidoFormato: string;
    public fechaAprobadoNoEntregadoFormato: string;

    public listadoInspeccionesVisualesJson: InspeccionVisual = new InspeccionVisual();
    public DescuentosJson: EstructuraDescuentos = new EstructuraDescuentos();
    public otrasInspeccionesJson: OtrasInspecciones = new OtrasInspecciones();
    public listadoInspeccionesGraficasJson: InspeccionGrafica = new InspeccionGrafica();

    public diferencia: number;
    public transmisionNombre: string;
    public depreciacion:number;

    public listadoInspeccionesVisualesString() {
        this.inspeccionesVisuales = JSON.stringify(this.listadoInspeccionesVisualesJson);
    }

    public listadoDescuentoAvaluoString() {
        this.listadoDescuentos = JSON.stringify(this.DescuentosJson);
    }

    public listadoOtrasInspeccionesString() {
        this.otrasInspecciones = JSON.stringify(this.otrasInspeccionesJson);
    }

    public listadoInspeccionesGraficasString() {
        this.inspeccionGrafica = JSON.stringify(this.listadoInspeccionesGraficasJson);
    }
}