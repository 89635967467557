export default [
    { "departamento": "Ahuachapán", "nombre": "Ahuachapán", "id": "2101" , "title": "Ciudad Cabecera" , "district": "Ahuachapán" },
    { "departamento": "Ahuachapán", "nombre": "Apaneca", "id": "2102" , "title": "Villa" , "district": "Ahuachapán" },
    { "departamento": "Ahuachapán", "nombre": "Atiquizaya", "id": "2103" , "title": "Ciudad" , "district": "Atiquizaya" },
    { "departamento": "Ahuachapán", "nombre": "Concepción de Ataco", "id": "2106" , "title": "Ciudad" , "district": "Ahuachapán" },
    { "departamento": "Ahuachapán", "nombre": "El Refugio" , "id": "2107", "title": "Pueblo" , "district": "Atiquizaya" },
    { "departamento": "Ahuachapán", "nombre": "Guaymango" , "id": "2108", "title": "Pueblo" , "district": "Ahuachapán" },
    { "departamento": "Ahuachapán", "nombre": "Jujutla" , "id": "2109", "title": "Villa" , "district": "Ahuachapán" },
    { "departamento": "Ahuachapán", "nombre": "San Francisco Menéndez" , "id": "2113", "title": "Pueblo" , "district": "Ahuachapán" },
    { "departamento": "Ahuachapán", "nombre": "San Lorenzo" , "id": "2115", "title": "Pueblo" , "district": "Atiquizaya" },
    { "departamento": "Ahuachapán", "nombre": "San Pedro Puxtla" , "id": "2116", "title": "Villa" , "district": "Ahuachapán" },
    { "departamento": "Ahuachapán", "nombre": "Tacuba" , "id": "2117", "title": "Ciudad" , "district": "Ahuachapán" },
    { "departamento": "Ahuachapán", "nombre": "Turín" , "id": "2118", "title": "Pueblo" , "district": "Atiquizaya" },
    { "departamento": "Cabañas", "nombre": "Sensuntepeque" , "id": "1201", "title": "Ciudad Cabecera" , "district": "Sensuntepeque" },
    { "departamento": "Cabañas", "nombre": "Cinquera" , "id": "1202", "title": "Pueblo" , "district": "Ilobasco" },
    { "departamento": "Cabañas", "nombre": "Dolores" , "id": "1209", "title": "Ciudad" , "district": "Sensuntepeque" },
    { "departamento": "Cabañas", "nombre": "Guacotecti" , "id": "1203", "title": "Villa" , "district": "Sensuntepeque" },
    { "departamento": "Cabañas", "nombre": "Ilobasco" , "id": "1204", "title": "Ciudad" , "district": "Ilobasco" },
    { "departamento": "Cabañas", "nombre": "Jutiapa" , "id": "1206", "title": "Villa" , "district": "Ilobasco" },
    { "departamento": "Cabañas", "nombre": "San Isidro" , "id": "1207", "title": "Ciudad" , "district": "Sensuntepeque" },
    { "departamento": "Cabañas", "nombre": "Tejutepeque" , "id": "1208", "title": "Ciudad" , "district": "Ilobasco" },
    { "departamento": "Cabañas", "nombre": "Victoria" , "id": "1210", "title": "Ciudad" , "district": "Sensuntepeque" },
    { "departamento": "Chalatenango", "nombre": "Chalatenango" , "id": "1301", "title": "Ciudad Cabecera" , "district": "Chalatenango" },
    { "departamento": "Chalatenango", "nombre": "Agua Caliente" , "id": "1302", "title": "Villa" , "district": "Tejutla" },
    { "departamento": "Chalatenango", "nombre": "Arcatao" , "id": "1303", "title": "Ciudad" , "district": "Chalatenango" },
    { "departamento": "Chalatenango", "nombre": "Azacualpa" , "id": "1304", "title": "Pueblo" , "district": "Chalatenango" },
    { "departamento": "Chalatenango", "nombre": "Cancasque" , "id": "1305", "title": "Pueblo" , "district": "Chalatenango" },
    { "departamento": "Chalatenango", "nombre": "Citalá" , "id": "1306", "title": "Villa" , "district": "Tejutla" },
    { "departamento": "Chalatenango", "nombre": "Comalapa" , "id": "1307", "title": "Pueblo" , "district": "Dulce Nombre de María" },
    { "departamento": "Chalatenango", "nombre": "Concepción Quezaltepeque" , "id": "1308", "title": "Villa" , "district": "Chalatenango" },
    { "departamento": "Chalatenango", "nombre": "Dulce Nombre de María" , "id": "1309", "title": "Ciudad" , "district": "Dulce Nombre de María" },
    { "departamento": "Chalatenango", "nombre": "El Carrizal" , "id": "1311", "title": "Pueblo" , "district": "Chalatenango" },
    { "departamento": "Chalatenango", "nombre": "El Paraíso" , "id": "1312", "title": "Pueblo" , "district": "Dulce Nombre de María" },
    { "departamento": "Chalatenango", "nombre": "La Laguna" , "id": "1313", "title": "Pueblo" , "district": "Dulce Nombre de María" },
    { "departamento": "Chalatenango", "nombre": "La Palma" , "id": "1314", "title": "Ciudad" , "district": "Tejutla" },
    { "departamento": "Chalatenango", "nombre": "La Reina" , "id": "1315", "title": "Villa" , "district": "Tejutla" },
    { "departamento": "Chalatenango", "nombre": "Las Flores" , "id": "1316", "title": "Pueblo" , "district": "Chalatenango" },
    { "departamento": "Chalatenango", "nombre": "Las Vueltas" , "id": "1317", "title": "Pueblo" , "district": "Chalatenango" },
    { "departamento": "Chalatenango", "nombre": "Nombre de Jesús" , "id": "1318", "title": "Pueblo" , "district": "Chalatenango" },
    { "departamento": "Chalatenango", "nombre": "Nueva Concepción" , "id": "1319", "title": "Ciudad" , "district": "Tejutla" },
    { "departamento": "Chalatenango", "nombre": "Nueva Trinidad" , "id": "1320", "title": "Pueblo" , "district": "Chalatenango" },
    { "departamento": "Chalatenango", "nombre": "Ojos de Agua" , "id": "1321", "title": "Pueblo" , "district": "Chalatenango" },
    { "departamento": "Chalatenango", "nombre": "Potonico" , "id": "1322", "title": "Pueblo" , "district": "Chalatenango" },
    { "departamento": "Chalatenango", "nombre": "San Antonio de la Cruz" , "id": "1324", "title": "Pueblo" , "district": "Chalatenango" },
    { "departamento": "Chalatenango", "nombre": "San Antonio Los Ranchos" , "id": "1325", "title": "Pueblo" , "district": "Chalatenango" },
    { "departamento": "Chalatenango", "nombre": "San Fernando" , "id": "1326", "title": "Villa" , "district": "Dulce Nombre de María" },
    { "departamento": "Chalatenango", "nombre": "San Francisco Lempa" , "id": "1327", "title": "Villa" , "district": "Chalatenango" },
    { "departamento": "Chalatenango", "nombre": "San Francisco Morazán" , "id": "1328", "title": "Pueblo" , "district": "Dulce Nombre de María" },
    { "departamento": "Chalatenango", "nombre": "San Ignacio" , "id": "1329", "title": "Villa" , "district": "Tejutla" },
    { "departamento": "Chalatenango", "nombre": "San Isidro Labrador" , "id": "1330", "title": "Villa" , "district": "Chalatenango" },
    { "departamento": "Chalatenango", "nombre": "San Luis del Carmen" , "id": "1331", "title": "Pueblo" , "district": "Chalatenango" },
    { "departamento": "Chalatenango", "nombre": "San Miguel de Mercedes" , "id": "1332", "title": "Villa" , "district": "Chalatenango" },
    { "departamento": "Chalatenango", "nombre": "San Rafael" , "id": "1333", "title": "Pueblo" , "district": "Dulce Nombre de María" },
    { "departamento": "Chalatenango", "nombre": "Santa Rita" , "id": "1334", "title": "Pueblo" , "district": "Dulce Nombre de María" },
    { "departamento": "Chalatenango", "nombre": "Tejutla" , "id": "1335", "title": "Ciudad" , "district": "Tejutla" },
    { "departamento": "Cuscatlán", "nombre": "Cojutepeque" , "id": "1401", "title": "Ciudad Cabecera" , "district": "Cojutepeque" },
    { "departamento": "Cuscatlán", "nombre": "Candelaria" , "id": "1302", "title": "Villa" , "district": "Cojutepeque" },
    { "departamento": "Cuscatlán", "nombre": "El Carmen" , "id": "1403", "title": "Pueblo" , "district": "Cojutepeque" },
    { "departamento": "Cuscatlán", "nombre": "El Rosario" , "id": "1404", "title": "Pueblo" , "district": "Cojutepeque" },
    { "departamento": "Cuscatlán", "nombre": "Monte San Juan" , "id": "1405", "title": "Pueblo" , "district": "Cojutepeque" },
    { "departamento": "Cuscatlán", "nombre": "Oratorio de Concepción" , "id": "1406", "title": "Pueblo" , "district": "Suchitoto" },
    { "departamento": "Cuscatlán", "nombre": "San Bartolomé Perulapía" , "id": "1407", "title": "Villa" , "district": "Cojutepeque" },
    { "departamento": "Cuscatlán", "nombre": "San Cristóbal" , "id": "1408", "title": "Villa" , "district": "Cojutepeque" },
    { "departamento": "Cuscatlán", "nombre": "San José Guayabal" , "id": "1409", "title": "Villa" , "district": "Suchitoto" },
    { "departamento": "Cuscatlán", "nombre": "San Pedro Perulapán" , "id": "1410", "title": "Ciudad" , "district": "Cojutepeque" },
    { "departamento": "Cuscatlán", "nombre": "San Rafael Cedros" , "id": "1411", "title": "Ciudad" , "district": "Cojutepeque" },
    { "departamento": "Cuscatlán", "nombre": "San Ramón" , "id": "1412", "title": "Pueblo" , "district": "Cojutepeque" },
    { "departamento": "Cuscatlán", "nombre": "Santa Cruz Analquito" , "id": "1413", "title": "Pueblo" , "district": "Cojutepeque" },
    { "departamento": "Cuscatlán", "nombre": "Santa Cruz Michapa" , "id": "1414", "title": "Pueblo" , "district": "Cojutepeque" },
    { "departamento": "Cuscatlán", "nombre": "Suchitoto" , "id": "1415", "title": "Ciudad" , "district": "Suchitoto" },
    { "departamento": "Cuscatlán", "nombre": "Tenancingo" , "id": "1416", "title": "Villa" , "district": "Cojutepeque" },
    { "departamento": "La Libertad", "nombre": "Santa Tecla" , "id": "1501", "title": "Ciudad Cabecera" , "district": "Santa Tecla" },
    { "departamento": "La Libertad", "nombre": "Antiguo Cuscatlán" , "id": "1502", "title": "Ciudad" , "district": "Santa Tecla" },
    { "departamento": "La Libertad", "nombre": "Chiltiupán" , "id": "1507", "title": "Pueblo" , "district": "Santa Tecla" },
    { "departamento": "La Libertad", "nombre": "Ciudad Arce" , "id": "1504", "title": "Ciudad" , "district": "San Juan Opico" },
    { "departamento": "La Libertad", "nombre": "Colón" , "id": "1512", "title": "Villa" , "district": "Santa Tecla" },
    { "departamento": "La Libertad", "nombre": "Comasagua" , "id": "1506", "title": "Villa" , "district": "Santa Tecla" },
    { "departamento": "La Libertad", "nombre": "Huizúcar" , "id": "1508", "title": "Villa" , "district": "Santa Tecla" },
    { "departamento": "La Libertad", "nombre": "Jayaque" , "id": "1509", "title": "Ciudad" , "district": "Santa Tecla" },
    { "departamento": "La Libertad", "nombre": "Jicalapa" , "id": "1510", "title": "Pueblo" , "district": "Santa Tecla" },
    { "departamento": "La Libertad", "nombre": "La Libertad" , "id": "1511", "title": "Ciudad" , "district": "Santa Tecla" },
    { "departamento": "La Libertad", "nombre": "Nuevo Cuscatlán" , "id": "1513", "title": "Pueblo" , "district": "Santa Tecla" },
    { "departamento": "La Libertad", "nombre": "Quezaltepeque" , "id": "1515", "title": "Ciudad" , "district": "Quezaltepeque" },
    { "departamento": "La Libertad", "nombre": "San Juan Opico" , "id": "1514", "title": "Ciudad" , "district": "San Juan Opico" },
    { "departamento": "La Libertad", "nombre": "Sacacoyo" , "id": "1516", "title": "Pueblo" , "district": "Santa Tecla" },
    { "departamento": "La Libertad", "nombre": "San José Villanueva" , "id": "1517", "title": "Pueblo" , "district": "Santa Tecla" },
    { "departamento": "La Libertad", "nombre": "San Matías" , "id": "1518", "title": "Villa" , "district": "San Juan Opico" },
    { "departamento": "La Libertad", "nombre": "San Pablo Tacachico" , "id": "1519", "title": "Villa" , "district": "Quezaltepeque" },
    { "departamento": "La Libertad", "nombre": "Talnique" , "id": "1521", "title": "Pueblo" , "district": "Santa Tecla" },
    { "departamento": "La Libertad", "nombre": "Tamanique" , "id": "1522", "title": "Pueblo" , "district": "Santa Tecla" },
    { "departamento": "La Libertad", "nombre": "Teotepeque" , "id": "1523", "title": "Villa" , "district": "Santa Tecla" },
    { "departamento": "La Libertad", "nombre": "Tepecoyo" , "id": "1524", "title": "Villa" , "district": "Santa Tecla" },
    { "departamento": "La Libertad", "nombre": "Zaragoza" , "id": "1525", "title": "Ciudad" , "district": "Santa Tecla" },
    { "departamento": "La Paz", "nombre": "Zacatecoluca" , "id": "1601", "title": "Ciudad Cabecera" , "district": "Zacatecoluca" },
    { "departamento": "La Paz", "nombre": "Cuyultitán" , "id": "1603", "title": "Villa" , "district": "Olocuilta" },
    { "departamento": "La Paz", "nombre": "El Rosario" , "id": "1604", "title": "Ciudad" , "district": "San Pedro Masahuat" },
    { "departamento": "La Paz", "nombre": "Jerusalén" , "id": "1605", "title": "Pueblo" , "district": "San Pedro Nonualco" },
    { "departamento": "La Paz", "nombre": "Mercedes La Ceiba" , "id": "1607", "title": "Pueblo" , "district": "San Pedro Nonualco" },
    { "departamento": "La Paz", "nombre": "Olocuilta" , "id": "1608", "title": "Ciudad" , "district": "Olocuilta" },
    { "departamento": "La Paz", "nombre": "Paraíso de Osorio" , "id": "1609", "title": "Pueblo" , "district": "San Pedro Nonualco" },
    { "departamento": "La Paz", "nombre": "San Antonio Masahuat" , "id": "1610", "title": "Pueblo" , "district": "San Pedro Masahuat" },
    { "departamento": "La Paz", "nombre": "San Emigdio" , "id": "1611", "title": "Pueblo" , "district": "San Pedro Nonualco" },
    { "departamento": "La Paz", "nombre": "San Francisco Chinameca" , "id": "1612", "title": "Pueblo" , "district": "Olocuilta" },
    { "departamento": "La Paz", "nombre": "San Pedro Masahuat" , "id": "1618", "title": "Ciudad" , "district": "San Pedro Masahuat" },
    { "departamento": "La Paz", "nombre": "San Juan Nonualco" , "id": "1613", "title": "Ciudad" , "district": "Zacatecoluca" },
    { "departamento": "La Paz", "nombre": "San Juan Talpa" , "id": "1614", "title": "Villa" , "district": "Olocuilta" },
    { "departamento": "La Paz", "nombre": "San Juan Tepezontes" , "id": "1615", "title": "Villa" , "district": "San Pedro Masahuat" },
    { "departamento": "La Paz", "nombre": "San Luis La Herradura" , "id": "1606", "title": "Pueblo" , "district": "Zacatecoluca" },
    { "departamento": "La Paz", "nombre": "San Luis Talpa" , "id": "1616", "title": "Villa" , "district": "Olocuilta" },
    { "departamento": "La Paz", "nombre": "San Miguel Tepezontes" , "id": "1617", "title": "Villa" , "district": "San Pedro Masahuat" },
    { "departamento": "La Paz", "nombre": "San Pedro Nonualco" , "id": "1619", "title": "Ciudad" , "district": "San Pedro Nonualco" },
    { "departamento": "La Paz", "nombre": "San Rafael Obrajuelo" , "id": "1620", "title": "Ciudad" , "district": "Zacatecoluca" },
    { "departamento": "La Paz", "nombre": "Santa María Ostuma" , "id": "1621", "title": "Villa" , "district": "San Pedro Nonualco" },
    { "departamento": "La Paz", "nombre": "Santiago Nonualco" , "id": "1622", "title": "Ciudad" , "district": "Zacatecoluca" },
    { "departamento": "La Paz", "nombre": "Tapalhuaca" , "id": "1623", "title": "Pueblo" , "district": "Olocuilta" },
    { "departamento": "La Unión", "nombre": "La Unión" , "id": "3101", "title": "Ciudad Cabecera" , "district": "La Unión" },
    { "departamento": "La Unión", "nombre": "Anamorós" , "id": "3104", "title": "Ciudad" , "district": "Santa Rosa de Lima" },
    { "departamento": "La Unión", "nombre": "Bolívar" , "id": "3105", "title": "Pueblo" , "district": "La Unión" },
    { "departamento": "La Unión", "nombre": "Concepción de Oriente" , "id": "3106", "title": "Villa" , "district": "Santa Rosa de Lima" },
    { "departamento": "La Unión", "nombre": "Conchagua" , "id": "3107", "title": "Villa" , "district": "La Unión" },
    { "departamento": "La Unión", "nombre": "El Carmen" , "id": "3108", "title": "Villa" , "district": "La Unión" },
    { "departamento": "La Unión", "nombre": "El Sauce" , "id": "3109", "title": "Villa" , "district": "Santa Rosa de Lima" },
    { "departamento": "La Unión", "nombre": "Intipucá" , "id": "3111", "title": "Villa" , "district": "La Unión" },
    { "departamento": "La Unión", "nombre": "Lislique" , "id": "3112", "title": "Villa" , "district": "Santa Rosa de Lima" },
    { "departamento": "La Unión", "nombre": "Meanguera del Golfo" , "id": "3113", "title": "Pueblo" , "district": "La Unión" },
    { "departamento": "La Unión", "nombre": "Nueva Esparta" , "id": "3114", "title": "Ciudad" , "district": "Santa Rosa de Lima" },
    { "departamento": "La Unión", "nombre": "Pasaquina" , "id": "3116", "title": "Ciudad" , "district": "Santa Rosa de Lima" },
    { "departamento": "La Unión", "nombre": "Polorós" , "id": "3117", "title": "Villa" , "district": "Santa Rosa de Lima" },
    { "departamento": "La Unión", "nombre": "San Alejo" , "id": "3119", "title": "Ciudad" , "district": "La Unión" },
    { "departamento": "La Unión", "nombre": "San José" , "id": "3120", "title": "Villa" , "district": "La Unión" },
    { "departamento": "La Unión", "nombre": "Santa Rosa de Lima" , "id": "3121", "title": "Ciudad" , "district": "Santa Rosa de Lima" },
    { "departamento": "La Unión", "nombre": "Yayantique" , "id": "3122", "title": "Pueblo" , "district": "La Unión" },
    { "departamento": "La Unión", "nombre": "Yucuaiquín" , "id": "3123", "title": "Villa" , "district": "La Unión" },
    { "departamento": "Morazán", "nombre": "San Francisco Gotera" , "id": "3201", "title": "Ciudad Cabecera" , "district": "San Francisco" },
    { "departamento": "Morazán", "nombre": "Arambala" , "id": "3202", "title": "Pueblo" , "district": "Jocoaitique" },
    { "departamento": "Morazán", "nombre": "Cacaopera" , "id": "3216", "title": "Ciudad" , "district": "Osicala" },
    { "departamento": "Morazán", "nombre": "Chilanga" , "id": "3203", "title": "Villa" , "district": "San Francisco" },
    { "departamento": "Morazán", "nombre": "Corinto" , "id": "3204", "title": "Ciudad" , "district": "Osicala" },
    { "departamento": "Morazán", "nombre": "Delicias de Concepción" , "id": "3206", "title": "Pueblo" , "district": "Osicala" },
    { "departamento": "Morazán", "nombre": "El Divisadero" , "id": "3207", "title": "Villa" , "district": "San Francisco" },
    { "departamento": "Morazán", "nombre": "El Rosario" , "id": "3208", "title": "Villa" , "district": "Jocoaitique" },
    { "departamento": "Morazán", "nombre": "Gualococti" , "id": "3209", "title": "Pueblo" , "district": "Osicala" },
    { "departamento": "Morazán", "nombre": "Guatajiagua" , "id": "3210", "title": "Ciudad" , "district": "San Francisco" },
    { "departamento": "Morazán", "nombre": "Joateca" , "id": "3211", "title": "Pueblo" , "district": "Jocoaitique" },
    { "departamento": "Morazán", "nombre": "Jocoaitique" , "id": "3212", "title": "Ciudad" , "district": "Jocoaitique" },
    { "departamento": "Morazán", "nombre": "Jocoro" , "id": "3213", "title": "Ciudad" , "district": "San Francisco" },
    { "departamento": "Morazán", "nombre": "Lolotiquillo" , "id": "3214", "title": "Pueblo" , "district": "San Francisco" },
    { "departamento": "Morazán", "nombre": "Meanguera" , "id": "3215", "title": "Ciudad" , "district": "Jocoaitique" },
    { "departamento": "Morazán", "nombre": "Osicala" , "id": "3216", "title": "Ciudad" , "district": "Osicala" },
    { "departamento": "Morazán", "nombre": "Perquín" , "id": "3217", "title": "Pueblo" , "district": "Jocoaitique" },
    { "departamento": "Morazán", "nombre": "San Carlos" , "id": "3218", "title": "Ciudad" , "district": "San Francisco" },
    { "departamento": "Morazán", "nombre": "San Fernando" , "id": "3219", "title": "Pueblo" , "district": "Jocoaitique" },
    { "departamento": "Morazán", "nombre": "San Isidro" , "id": "3220", "title": "Villa" , "district": "Osicala" },
    { "departamento": "Morazán", "nombre": "San Simón" , "id": "3221", "title": "Villa" , "district": "Osicala" },
    { "departamento": "Morazán", "nombre": "Sensembra" , "id": "3222", "title": "Pueblo" , "district": "San Francisco" },
    { "departamento": "Morazán", "nombre": "Sociedad" , "id": "3223", "title": "Ciudad" , "district": "San Francisco" },
    { "departamento": "Morazán", "nombre": "Torola" , "id": "3224", "title": "Pueblo" , "district": "Jocoaitique" },
    { "departamento": "Morazán", "nombre": "Yamabal" , "id": "3225", "title": "Pueblo" , "district": "San Francisco" },
    { "departamento": "Morazán", "nombre": "Yoloaiquín" , "id": "3226", "title": "Pueblo" , "district": "Osicala" },
    { "departamento": "San Miguel", "nombre": "San Miguel" , "id": "3301", "title": "Ciudad Cabecera" , "district": "San Miguel" },
    { "departamento": "San Miguel", "nombre": "Carolina" , "id": "3302", "title": "Villa" , "district": "Sesori" },
    { "departamento": "San Miguel", "nombre": "Chapeltique" , "id": "3305", "title": "Ciudad" , "district": "San Miguel" },
    { "departamento": "San Miguel", "nombre": "Chinameca" , "id": "3306", "title": "Ciudad" , "district": "Chinameca" },
    { "departamento": "San Miguel", "nombre": "Chirilagua" , "id": "3307", "title": "Ciudad" , "district": "San Miguel" },
    { "departamento": "San Miguel", "nombre": "Ciudad Barrios" , "id": "3303", "title": "Ciudad" , "district": "San Miguel" },
    { "departamento": "San Miguel", "nombre": "Comacarán" , "id": "3304", "title": "Villa" , "district": "San Miguel" },
    { "departamento": "San Miguel", "nombre": "El Tránsito" , "id": "3309", "title": "Ciudad" , "district": "Chinameca" },
    { "departamento": "San Miguel", "nombre": "Lolotique" , "id": "3311", "title": "Ciudad" , "district": "Chinameca" },
    { "departamento": "San Miguel", "nombre": "Moncagua" , "id": "3312", "title": "Ciudad" , "district": "San Miguel" },
    { "departamento": "San Miguel", "nombre": "Nueva Guadalupe" , "id": "3313", "title": "Ciudad" , "district": "Chinameca" },
    { "departamento": "San Miguel", "nombre": "Nuevo Edén de San Juan" , "id": "3314", "title": "Pueblo" , "district": "Sesori" },
    { "departamento": "San Miguel", "nombre": "Quelepa" , "id": "3315", "title": "Villa" , "district": "San Miguel" },
    { "departamento": "San Miguel", "nombre": "San Antonio del Mosco" , "id": "3316", "title": "Pueblo" , "district": "Sesori" },
    { "departamento": "San Miguel", "nombre": "San Gerardo" , "id": "3318", "title": "Villa" , "district": "Sesori" },
    { "departamento": "San Miguel", "nombre": "San Jorge" , "id": "3319", "title": "Ciudad" , "district": "Chinameca" },
    { "departamento": "San Miguel", "nombre": "San Luis de la Reina" , "id": "3320", "title": "Villa" , "district": "Sesori" },
    { "departamento": "San Miguel", "nombre": "San Rafael Oriente" , "id": "3322", "title": "Ciudad" , "district": "Chinameca" },
    { "departamento": "San Miguel", "nombre": "Sesori" , "id": "3323", "title": "Ciudad" , "district": "Sesori" },
    { "departamento": "San Miguel", "nombre": "Uluazapa" , "id": "3324", "title": "Villa" , "district": "San Miguel" },
    { "departamento": "San Salvador", "nombre": "San Salvador" , "id": "1101", "title": "Ciudad Cabecera Capital" , "district": "San Salvador" },
    { "departamento": "San Salvador", "nombre": "Aguilares" , "id": "1122", "title": "Ciudad" , "district": "Tonacatepeque" },
    { "departamento": "San Salvador", "nombre": "Apopa" , "id": "1123", "title": "Ciudad" , "district": "Tonacatepeque" },
    { "departamento": "San Salvador", "nombre": "Ayutuxtepeque" , "id": "1121", "title": "Ciudad" , "district": "San Salvador" },
    { "departamento": "San Salvador", "nombre": "Cuscatancingo" , "id": "1119", "title": "Ciudad" , "district": "San Salvador" },
    { "departamento": "San Salvador", "nombre": "Delgado" , "id": "1118", "title": "Ciudad" , "district": "San Salvador" },
    { "departamento": "San Salvador", "nombre": "El Paisnal" , "id": "1124", "title": "Villa" , "district": "Tonacatepeque" },
    { "departamento": "San Salvador", "nombre": "Guazapa" , "id": "1125", "title": "Ciudad" , "district": "Tonacatepeque" },
    { "departamento": "San Salvador", "nombre": "Ilopango" , "id": "1117", "title": "Ciudad" , "district": "Tonacatepeque" },
    { "departamento": "San Salvador", "nombre": "Mejicanos" , "id": "1120", "title": "Ciudad" , "district": "San Salvador" },
    { "departamento": "San Salvador", "nombre": "Nejapa" , "id": "1126", "title": "Ciudad" , "district": "Tonacatepeque" },
    { "departamento": "San Salvador", "nombre": "Panchimalco" , "id": "1127", "title": "Villa" , "district": "Santo Tomás" },
    { "departamento": "San Salvador", "nombre": "Rosario de Mora" , "id": "1128", "title": "Pueblo" , "district": "Santo Tomás" },
    { "departamento": "San Salvador", "nombre": "San Marcos" , "id": "1115", "title": "Ciudad" , "district": "Santo Tomás" },
    { "departamento": "San Salvador", "nombre": "San Martín" , "id": "1129", "title": "Ciudad" , "district": "Tonacatepeque" },
    { "departamento": "San Salvador", "nombre": "Santiago Texacuangos" , "id": "1130", "title": "Villa" , "district": "Santo Tomás" },
    { "departamento": "San Salvador", "nombre": "Santo Tomás" , "id": "1131", "title": "Ciudad" , "district": "Santo Tomás" },
    { "departamento": "San Salvador", "nombre": "Soyapango" , "id": "1116", "title": "Ciudad" , "district": "San Salvador" },
    { "departamento": "San Salvador", "nombre": "Tonacatepeque" , "id": "1132", "title": "Ciudad" , "district": "Tonacatepeque" },
    { "departamento": "San Vicente", "nombre": "San Vicente" , "id": "1701", "title": "Ciudad Cabecera" , "district": "San Vicente" },
    { "departamento": "San Vicente", "nombre": "Apastepeque" , "id": "1702", "title": "Ciudad" , "district": "San Vicente" },
    { "departamento": "San Vicente", "nombre": "Guadalupe" , "id": "1703", "title": "Ciudad" , "district": "San Vicente" },
    { "departamento": "San Vicente", "nombre": "San Cayetano Istepeque" , "id": "1704", "title": "Villa" , "district": "San Vicente" },
    { "departamento": "San Vicente", "nombre": "San Esteban Catarina" , "id": "1705", "title": "Villa" , "district": "San Sebastián" },
    { "departamento": "San Vicente", "nombre": "San Ildefonso" , "id": "1706", "title": "Villa" , "district": "San Sebastián" },
    { "departamento": "San Vicente", "nombre": "San Lorenzo" , "id": "1707", "title": "Villa" , "district": "San Sebastián" },
    { "departamento": "San Vicente", "nombre": "San Sebastián" , "id": "1708", "title": "Ciudad" , "district": "San Sebastián" },
    { "departamento": "San Vicente", "nombre": "Santa Clara" , "id": "1709", "title": "Villa" , "district": "San Sebastián" },
    { "departamento": "San Vicente", "nombre": "Santo Domingo" , "id": "1710", "title": "Ciudad" , "district": "San Sebastián" },
    { "departamento": "San Vicente", "nombre": "Tecoluca" , "id": "1711", "title": "Ciudad" , "district": "San Vicente" },
    { "departamento": "San Vicente", "nombre": "Tepetitán" , "id": "1712", "title": "Villa" , "district": "San Vicente" },
    { "departamento": "San Vicente", "nombre": "Verapaz" , "id": "1713", "title": "Ciudad" , "district": "San Vicente" },
    { "departamento": "Santa Ana", "nombre": "Santa Ana" , "id": "2201", "title": "Ciudad Cabecera" , "district": "Santa Ana" },
    { "departamento": "Santa Ana", "nombre": "Candelaria de la Frontera" , "id": "2203", "title": "Villa" , "district": "Chalchuapa" },
    { "departamento": "Santa Ana", "nombre": "Chalchuapa" , "id": "2205", "title": "Ciudad" , "district": "Chalchuapa" },
    { "departamento": "Santa Ana", "nombre": "Coatepeque" , "id": "2204", "title": "Ciudad" , "district": "Santa Ana" },
    { "departamento": "Santa Ana", "nombre": "El Congo" , "id": "2207", "title": "Villa" , "district": "Santa Ana" },
    { "departamento": "Santa Ana", "nombre": "El Porvenir" , "id": "2208", "title": "Pueblo" , "district": "Chalchuapa" },
    { "departamento": "Santa Ana", "nombre": "Masahuat" , "id": "2210", "title": "Pueblo" , "district": "Metapán" },
    { "departamento": "Santa Ana", "nombre": "Metapán" , "id": "2211", "title": "Ciudad" , "district": "Metapán" },
    { "departamento": "Santa Ana", "nombre": "San Antonio Pajonal" , "id": "2212", "title": "Pueblo" , "district": "Chalchuapa" },
    { "departamento": "Santa Ana", "nombre": "San Sebastián Salitrillo" , "id": "2215", "title": "Pueblo" , "district": "Chalchuapa" },
    { "departamento": "Santa Ana", "nombre": "Santa Rosa Guachipilín" , "id": "2216", "title": "Pueblo" , "district": "Metapán" },
    { "departamento": "Santa Ana", "nombre": "Santiago de la Frontera" , "id": "2217", "title": "Pueblo" , "district": "Metapán" },
    { "departamento": "Santa Ana", "nombre": "Texistepeque" , "id": "2218", "title": "Ciudad" , "district": "Santa Ana" },
    { "departamento": "Sonsonate", "nombre": "Sonsonate" , "id": "2301", "title": "Ciudad Cabecera" , "district": "Sonsonate" },
    { "departamento": "Sonsonate", "nombre": "Acajutla" , "id": "2302", "title": "Ciudad" , "district": "Sonsonate" },
    { "departamento": "Sonsonate", "nombre": "Armenia" , "id": "2303", "title": "Ciudad" , "district": "Izalco" },
    { "departamento": "Sonsonate", "nombre": "Caluco" , "id": "2304", "title": "Pueblo" , "district": "Izalco" },
    { "departamento": "Sonsonate", "nombre": "Cuisnahuat" , "id": "2305", "title": "Pueblo" , "district": "Izalco" },
    { "departamento": "Sonsonate", "nombre": "Izalco" , "id": "2306", "title": "Ciudad" , "district": "Izalco" },
    { "departamento": "Sonsonate", "nombre": "Juayúa" , "id": "2307", "title": "Ciudad" , "district": "Juayúa" },
    { "departamento": "Sonsonate", "nombre": "Nahuizalco" , "id": "2311", "title": "Ciudad" , "district": "Sonsonate" },
    { "departamento": "Sonsonate", "nombre": "Nahulingo" , "id": "2312", "title": "Pueblo" , "district": "Sonsonate" },
    { "departamento": "Sonsonate", "nombre": "Salcoatitán" , "id": "2313", "title": "Pueblo" , "district": "Juayúa" },
    { "departamento": "Sonsonate", "nombre": "San Antonio del Monte" , "id": "2314", "title": "Villa" , "district": "Sonsonate" },
    { "departamento": "Sonsonate", "nombre": "San Julián" , "id": "2316", "title": "Ciudad" , "district": "Izalco" },
    { "departamento": "Sonsonate", "nombre": "Santa Catarina Masahuat" , "id": "2317", "title": "Pueblo" , "district": "Juayúa" },
    { "departamento": "Sonsonate", "nombre": "Santa Isabel Ishuatán" , "id": "2318", "title": "Villa" , "district": "Izalco" },
    { "departamento": "Sonsonate", "nombre": "Santo Domingo de Guzmán" , "id": "2319", "title": "Pueblo" , "district": "Sonsonate" },
    { "departamento": "Sonsonate", "nombre": "Sonzacate" , "id": "2320", "title": "Pueblo" , "district": "Sonsonate" },
    { "departamento": "Usulután", "nombre": "Usulután" , "id": "3401", "title": "Ciudad Cabecera" , "district": "Usulután" },
    { "departamento": "Usulután", "nombre": "Alegría" , "id": "3404", "title": "Ciudad" , "district": "Santiago de María" },
    { "departamento": "Usulután", "nombre": "Berlín" , "id": "3403", "title": "Ciudad" , "district": "Berlín" },
    { "departamento": "Usulután", "nombre": "California" , "id": "3404", "title": "Pueblo" , "district": "Santiago de María" },
    { "departamento": "Usulután", "nombre": "Concepción Batres" , "id": "3405", "title": "Villa" , "district": "Usulután" },
    { "departamento": "Usulután", "nombre": "El Triunfo" , "id": "3406", "title": "Villa" , "district": "Estanzuelas" },
    { "departamento": "Usulután", "nombre": "Ereguayquín" , "id": "3407", "title": "Pueblo" , "district": "Usulután" },
    { "departamento": "Usulután", "nombre": "Estanzuelas" , "id": "3408", "title": "Ciudad" , "district": "Estanzuelas" },
    { "departamento": "Usulután", "nombre": "Jiquilisco" , "id": "3409", "title": "Ciudad" , "district": "Usulután" },
    { "departamento": "Usulután", "nombre": "Jucuapa" , "id": "3410", "title": "Villa" , "district": "Estanzuelas" },
    { "departamento": "Usulután", "nombre": "Jucuarán" , "id": "3411", "title": "Villa" , "district": "Usulután" },
    { "departamento": "Usulután", "nombre": "Mercedes Umaña" , "id": "3412", "title": "Villa" , "district": "Berlín" },
    { "departamento": "Usulután", "nombre": "Nueva Granada" , "id": "3413", "title": "Villa" , "district": "Estanzuelas" },
    { "departamento": "Usulután", "nombre": "Ozatlán" , "id": "3415", "title": "Villa" , "district": "Usulután" },
    { "departamento": "Usulután", "nombre": "Puerto El Triunfo" , "id": "3417", "title": "Pueblo" , "district": "Usulután" },
    { "departamento": "Usulután", "nombre": "San Agustín" , "id": "3418", "title": "Villa" , "district": "Berlín" },
    { "departamento": "Usulután", "nombre": "San Buenaventura" , "id": "3419", "title": "Pueblo" , "district": "Estanzuelas" },
    { "departamento": "Usulután", "nombre": "San Dionisio" , "id": "3420", "title": "Pueblo" , "district": "Usulután" },
    { "departamento": "Usulután", "nombre": "San Francisco Javier" , "id": "3421", "title": "Pueblo" , "district": "Berlín" },
    { "departamento": "Usulután", "nombre": "Santa Elena" , "id": "3422", "title": "Ciudad" , "district": "Usulután" },
    { "departamento": "Usulután", "nombre": "Santa María" , "id": "3423", "title": "Pueblo" , "district": "Usulután" },
    { "departamento": "Usulután", "nombre": "Santiago de María" , "id": "3424", "title": "Ciudad" , "district": "Santiago de María" },
    { "departamento": "Usulután", "nombre": "Tecapán" , "id": "3426" , "title": "Villa" , "district": "Santiago de María" }
]