import { APIUrls } from 'src/app/api/apiUrls';
import { Usuario } from './usuario';
import { Rol } from './rol';
import { Empresa } from './empresa';

export class UsuarioEmpresa {
    constructor(
        public id: number,
        public permisosAdicionales: string,
        public bloqueosAdicionales: string,
        public permisosMembretes:   string,
        public idsUsuariosAprobacionHojas: string,
        public sedesAprobacionDigitalizacion: string,
        public activo: number,
        public isDeleted: string,

        // Relaciones 
        public UsuarioId: number,
        public RolId: number,
        public EmpresaId: number,
    ){
        this.cargarPermisosAdicionales();
        this.cargarBloqueosAdicionales();
        this.cargarMembretesPermitidos();
        this.cargarIdsUsuariosAprobracion();
        this.cargarArrays();
    }

    sedesAprobacionDigitalizacionArray: any[];

    apiURLs = new APIUrls();

    cargarIdsUsuariosAprobracion() {
        if (this.idsUsuariosAprobacionHojas) {
            let stringIds = this.idsUsuariosAprobacionHojas.split('|');
            for (let id of stringIds) {
                this.idsAprobacionSeleccionados.push(+id);
            }
        }
    }

    cargarStringIdsUsuariosAprobracion() {
        this.idsUsuariosAprobacionHojas = "";
        for (let i = 0; i < this.idsAprobacionSeleccionados.length; i++) {
            if (i + 1 < this.idsAprobacionSeleccionados.length) {
                this.idsUsuariosAprobacionHojas += this.idsAprobacionSeleccionados[i] + '|';
            } else {
                this.idsUsuariosAprobacionHojas += this.idsAprobacionSeleccionados[i];
            }
        }
    }

    cargarMembretesPermitidos() {
        if (this.permisosMembretes) {
            let stringsPermisos = this.permisosMembretes.split('|');
            for (let cadenaPermiso of stringsPermisos) {
                this.membretesSeleccionados.push(+cadenaPermiso);
            }
        }
    }

    cargarStringMembretesPermitidos() {
        this.permisosMembretes = "";
        for (let i = 0; i < this.membretesSeleccionados.length; i++) {
            if (i + 1 < this.membretesSeleccionados.length) {
                this.permisosMembretes += this.membretesSeleccionados[i] + '|';
            } else {
                this.permisosMembretes += this.membretesSeleccionados[i];
            }
        }
    }

    cargarPermisosAdicionales() {
        if (this.permisosAdicionales) {
            let stringsPermisos = this.permisosAdicionales.split('|');
            for (let cadenaPermiso of stringsPermisos) {
                this.permisosSeleccionados.push(cadenaPermiso);
            }
        }
    }

    cargarStringPermisosAdicionales() {
        this.permisosAdicionales = "";
        for (let i = 0; i < this.permisosSeleccionados.length; i++) {
            if (i + 1 < this.permisosSeleccionados.length) {
                this.permisosAdicionales += this.permisosSeleccionados[i] + '|';
            } else {
                this.permisosAdicionales += this.permisosSeleccionados[i];
            }
        }
    }

    cargarBloqueosAdicionales() {
        if (this.bloqueosAdicionales) {
            let stringsBloqueos = this.bloqueosAdicionales.split('|');
            for (let cadenaBloqueo of stringsBloqueos) {
                this.bloqueosSeleccionados.push(cadenaBloqueo);
            }
        }
    }

    cargarStringBloqueosAdicionales() {
        this.bloqueosAdicionales = "";
        for (let i = 0; i < this.bloqueosSeleccionados.length; i++) {
            if (i + 1 < this.bloqueosSeleccionados.length) {
                this.bloqueosAdicionales += this.bloqueosSeleccionados[i] + '|';
            } else {
                this.bloqueosAdicionales += this.bloqueosSeleccionados[i];
            }
        }
    }

    cargarArrays() {
        this.sedesAprobacionDigitalizacionArray = this.sedesAprobacionDigitalizacion ? 
            JSON.parse(this.sedesAprobacionDigitalizacion) : [];
    }

    cargarsedesAprobadas() {
        this.sedesAprobacionDigitalizacion = JSON.stringify(this.sedesAprobacionDigitalizacionArray)
    }

    public permisosModel = this.apiURLs.permisosModel;

    permisosSeleccionados = [];
    bloqueosSeleccionados = [];
    membretesSeleccionados: number[] = [];
    idsAprobacionSeleccionados: number[] = [];

    usuario: Usuario;
    rol: Rol;
    empresa: Empresa;
}