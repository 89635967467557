import { ServicioUnidad } from "./servicioUnidad";
import { Usuario } from "./usuario";

export class GastoServicio {
    constructor(
        public id: number,
        public rubro:string ,
        public descripcion:string ,
        public total:number ,
        //Relaciones
        public ServicioUnidadId:number ,
        public Usuario:number ,
    ){}

    usuario: Usuario;
    servicioUnidad: ServicioUnidad;


}