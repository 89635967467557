import { Concesionario } from './concesionario';
import { Kilometraje } from './Kilometraje';
import { LineaVehiculo } from './lineaVehiculo';

export class Version {
    constructor(
        public id: number,
        public nombre: string,
        public descripcion: string,
        public eliminado: boolean,
        public codigosVersionesSOB: string,

        //Relaciones
        public LineaId: number,
        public ConcesionarioId: number
    ){}

    linea: LineaVehiculo;
    concesionario: Concesionario

    nombreConcesionario: string;
    nombreLineaVehiculo: string;

    Kilometrajes: Kilometraje[];

    codigosVersionesSOBArray: string[] = [];

}