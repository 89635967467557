import { Injectable } from '@angular/core';
import { ConnectionService } from '../api/connection.service';
import { APIUrls } from '../api/apiUrls';
import { Usuario } from '../models/usuario';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { JSONConverters } from '../models/JSONConverters';
import { NavigationService } from 'src/@vex/services/navigation.service';
import icHome from '@iconify/icons-fa-solid/home';
import icEmpresas from '@iconify/icons-fa-solid/building';
import icUsers from '@iconify/icons-fa-solid/users-cog';
import icClient from '@iconify/icons-fa-solid/id-badge';
import icProducto from '@iconify/icons-fa-solid/car';
import icAvaluos from '@iconify/icons-fa-solid/clipboard-check';
import icMarcas from '@iconify/icons-fa-solid/car';
import icVenta from '@iconify/icons-fa-solid/file-invoice-dollar';
import icMatrizRiesgo from '@iconify/icons-fa-solid/exclamation-circle';
import icRoles from '@iconify/icons-fa-solid/users';
import icHojaMembretada from '@iconify/icons-fa-solid/file-contract';
import icManuales from '@iconify/icons-fa-solid/book';
import icProspectoLead from '@iconify/icons-fa-solid/tasks';
import icUnidades from '@iconify/icons-fa-solid/car';
import icCampanias from '@iconify/icons-fa-solid/stream';
import icCitas from '@iconify/icons-fa-solid/calendar';
import icBoletas from '@iconify/icons-fa-solid/id-card';
import icPlanesMantenimiento from '@iconify/icons-fa-solid/wrench';
import icTransparenciaPrecios from '@iconify/icons-fa-solid/hand-holding-usd';
import icPDI from '@iconify/icons-fa-solid/clipboard-list';
import icFacturacionSV from '@iconify/icons-fa-solid/receipt';
import icPresupuestosTalleres from '@iconify/icons-fa-solid/tools'
import icSolicitudTraspaso from '@iconify/icons-fa-solid/clipboard-list';
import icDashboard from '@iconify/icons-fa-solid/chart-line';
import icRentas from '@iconify/icons-fa-solid/money-bill';
import icEncuestas from '@iconify/icons-fa-solid/male';
import icConfiguracion from '@iconify/icons-fa-solid/cog';
import icReportes from '@iconify/icons-fa-solid/file';
import icOtros from '@iconify/icons-fa-solid/th';
import { NavigationItem } from 'src/@vex/interfaces/navigation-item.interface';
import { AjusteGeneral } from '../models/ajusteGeneral';

@Injectable()
export class AuthService {
    private apiUrls: APIUrls = new APIUrls();
    public jsonConverters: JSONConverters = new JSONConverters();
    usuario: Usuario;
    ajustes: AjusteGeneral;
    usuarioRegistroValidado: Usuario;
    autenticando: boolean = false;
    recargarReload: Subject<any> = new Subject();
    buscadorReload: Subject<any> = new Subject();
    generarParams: Subject<any> = new Subject();
    perfilReload: Subject<any> = new Subject();
    paramsUpdate: Subject<any> = new Subject();
    limpiarSelectAutocomplete: Subject<any> = new Subject();
    historialBuscador: any = {};

    constructor(
        private router: Router,
        private connection: ConnectionService,
        private navigationService: NavigationService,
    ) {
        var usuarioJSON = JSON.parse(localStorage.getItem('usuario'));
        var ajustesJSON = typeof localStorage.getItem('ajustes') === 'string' ? JSON.parse(localStorage.getItem('ajustes')) : null;
        if (usuarioJSON && usuarioJSON.rol && usuarioJSON.empresa) {
            usuarioJSON.UsuarioEmpresas = [{
                Rol: usuarioJSON.rol,
                Empresa: usuarioJSON.empresa,
            }];
        }
        if (usuarioJSON && usuarioJSON.rol) {
            var RolJSON = null;
            if (usuarioJSON.rol) RolJSON = usuarioJSON.rol;
            usuarioJSON.Rol = RolJSON;
        }
        if (ajustesJSON) {
            this.ajustes = this.jsonConverters.ajusteGeneralDeJSON(ajustesJSON);
        }
        this.usuario = this.jsonConverters.usuarioDeJSON(usuarioJSON);
        this.connection.actualizarUsuario(this.usuario);
    }

    /**
     * Devuelve el id del usuario logueado
     */
    public idUsuarioActual(): number {
        if (this.usuario) return this.usuario.id;
        else return -1;
    }

    public idEmpresaActual(): number {
        if (this.usuario && this.usuario.empresa) return this.usuario.empresa.id;
        else return -1;
    }

    public idConcesionarioActual(): number {
        if (this.usuario && this.usuario.ConcesionarioId) return this.usuario.ConcesionarioId;
        else return null;
    }

    public prefijoEmpresaActual(): string {
        if (this.usuario && this.usuario.empresa) return this.usuario.empresa.prefijo;
        else return null;
    }

    public simboloMonedaPorDefecto(): string {
        if(this.apiUrls.AMBIENTE == 'SV') return '$';
        else if(this.apiUrls.AMBIENTE == 'HN') return 'L';
        else return 'Q';
    }

    /**
     * Redirige a la página de Not Found (404)
     */
    public irANotFound() {
        this.router.navigate(['/404']);
    }

    /**
     * Orquestra el inicio de sesión de usuario
     * @param correo 
     * @param password 
     */
    async login(correo: string, password: string, idFacebook: string, idGoogle: string, extraData: any) {
        try {
            this.autenticando = true;
            this.usuario = null;
            this.usuarioRegistroValidado = null;
            this.ajustes = null;
            this.connection.actualizarUsuario(this.usuario);

            // Obtener el token y usuario
            const tokenRes = await this.getToken(correo, password, idFacebook, idGoogle, extraData);
            if (tokenRes.error) throw Error(tokenRes.error.mensajeError);
            this.usuario = this.jsonConverters.usuarioDeJSON(tokenRes.data.data);
            this.usuario.rol = tokenRes.data.rol;
            this.usuario.relacionEmpresa.rol = tokenRes.data.rol;
            this.ajustes = this.jsonConverters.ajusteGeneralDeJSON(tokenRes.data.ajustes);

            // Asignar empresas de usuario
            var empresasJSON = tokenRes.data.empresas;
            var empresas = [];
            if(empresasJSON) {
                empresasJSON.forEach(element => {
                    empresas.push(this.jsonConverters.empresaDeJSON(element));
                });
            }
            this.usuario.empresas = empresas;

            this.connection.actualizarUsuario(this.usuario);

            // Guardar los datos locales
            localStorage.setItem('token', tokenRes.data.token);
            localStorage.setItem('usuario', JSON.stringify(this.usuario));
            localStorage.setItem('ajustes', JSON.stringify(this.ajustes));

            this.cargarMenus();
            // Navegar a inicio
            this.autenticando = false;
            return { error: false, mensaje: null };
        } catch (error) {
            this.autenticando = false;
            this.logoutLocal();
            return { error: true, mensaje: error };
        }
    }

    private async getToken(email: string, password: string, idFacebook: string, idGoogle: string, extraData: any) {
        try {
            if (idFacebook && idFacebook.trim() != '') {
                email = null;
                password = null;
                idGoogle = null;
            }
            else if (idGoogle && idGoogle.trim() != '') {
                email = null;
                password = null;
                idFacebook = null;
            }
            else if (email && email.trim() != '') {
                idFacebook = null;
                idGoogle = null;
            }

            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/loginWeb';
            var data = { email: email, password: password, idFacebook: idFacebook, idGoogle: idGoogle, extraData: extraData };
            const json = await this.connection.postRequest(url, data, false);
            return {
                error: null,
                data: {
                    token: json.token, mensaje: null,
                    data: json.data, rol: json.rol,
                    empresas: json.empresas, ajustes: json.ajustes
                }
            };
        }
        catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public getAjustesGenerales() {
        return this.ajustes;
    }

    public esAdministrador(): boolean {
        return this.tienePermisoPara(this.apiUrls.permisos.ADMINISTRADOR);
    }

    public esAuditor(): boolean {
        return this.tienePermisoPara(this.apiUrls.permisos.AUDITOR);
    }

    esAgenteCitas() : boolean {
        return (this.tienePermisoPara(this.apiUrls.permisos.AGENTE_CITAS) && !this.tienePermisoPara(this.apiUrls.permisos.APROBACION_CITAS));
    }

    public usuarioActualTienePermisoMembrete(MembreteId: number): boolean {
        if (this.usuario && this.usuario.relacionEmpresa && this.usuario.relacionEmpresa.membretesSeleccionados) {
            return this.usuario.relacionEmpresa.membretesSeleccionados.includes(MembreteId);
        }
        return false;
    }

    /**
     * Elimina los datos de acceso locales y regresa al login
     */
    public logoutLocal(redirigirNoLogueado: boolean = true) {
        this.autenticando = false;
        localStorage.removeItem('token');
        localStorage.removeItem('usuario');
        this.usuario = null;
        this.connection.actualizarUsuario(this.usuario);
        if(redirigirNoLogueado) this.router.navigate(['/login']);
    }

    /**
     * Cierra la sesión
     */
    public async logout() {
        this.logoutLocal();
    }

    public async cambiarLoginEmpresa(EmpresaId: number, redirigirInicio: boolean = true) {
        try {
            this.autenticando = true;
            // Comprobar validez del token
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/cambiarLoginEmpresa';
            var data = { EmpresaId: EmpresaId };
            const res = await this.connection.postRequest(url, data, true);
            if (res.valido) {
                // Asignar el nuevo token actualizado
                localStorage.setItem('token', res.nuevoToken);
                var nuevoUsuario = this.jsonConverters.usuarioDeJSON(res.data);
                this.usuario = nuevoUsuario;
                this.usuario.rol = res.rol;
                this.usuario.relacionEmpresa.rol = res.rol;
                
                // Asignar empresas de usuario
                var empresasJSON = res.empresas;
                var empresas = [];
                if(empresasJSON) {
                    empresasJSON.forEach(element => {
                        empresas.push(this.jsonConverters.empresaDeJSON(element));
                    });
                }
                this.usuario.empresas = empresas;
                
                this.connection.actualizarUsuario(this.usuario);
                localStorage.setItem('usuario', JSON.stringify(nuevoUsuario));
                this.cargarMenus();
                this.autenticando = false;
                if (redirigirInicio) {
                    this.router.navigate(['/']);
                    setTimeout(() => {
                        window.location.reload();
                    }, 50);
                }
                else {
                    setTimeout(() => {
                        window.location.reload();
                    }, 50);
                }
            }
            else throw Error();
        } catch (error) {
            this.autenticando = false;
            this.logoutLocal();
        }
    }

    /**
     * Comprueba si el token local es válido para ingresar
     * @param redirigirInicio 
     */
    public async comprobarAutenticacion(redirigirInicio: boolean, redirigirNoLogueado: boolean = true) {
        try {
            // Obtener token
            var token = localStorage.getItem('token');
            if (!token) throw Error();
            // Comprobar validez del token
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/verificarToken';
            var data = { token: token };
            const res = await this.connection.postRequest(url, data, false);
            if (res.valido) {
                // Asignar el nuevo token actualizado
                localStorage.setItem('token', res.nuevoToken);
                var nuevoUsuario = this.jsonConverters.usuarioDeJSON(res.data);
                this.ajustes = this.jsonConverters.ajusteGeneralDeJSON(res.ajustes);
                this.usuario = nuevoUsuario;
                this.usuario.rol = res.rol;
                this.usuario.relacionEmpresa.rol = res.rol;

                // Asignar empresas de usuario
                var empresasJSON = res.empresas;
                var empresas = [];
                if(empresasJSON) {
                    empresasJSON.forEach(element => {
                        empresas.push(this.jsonConverters.empresaDeJSON(element));
                    });
                }
                this.usuario.empresas = empresas;

                this.connection.actualizarUsuario(this.usuario);
                localStorage.setItem('usuario', JSON.stringify(nuevoUsuario));
                localStorage.setItem('ajustes', JSON.stringify(this.ajustes));
                this.cargarMenus();
                if (redirigirInicio) this.router.navigate(['/']);
            }
            else throw Error();
        } catch (error) {
            this.logoutLocal(redirigirNoLogueado);
        }
    }

    public authGuardLoggedIn(): boolean {
        var missingInfo = false;
        var token = localStorage.getItem('token');
        if (!token || token.trim() == '') missingInfo = true;

        if (!this.usuario || !this.usuario.rol) missingInfo = true;

        if (missingInfo) {
            this.logoutLocal();
            return false;
        }
        return true;
    }


    /**
     * Tokens de auth
     */
    public getAuthToken(): string {
        var token = localStorage.getItem('token');
        return token;
    }

    public maskHora() {
        var horaMask = [/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/];
        return horaMask;
    }

    public maskDPI() {
        var dpiMask = [/[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
        return dpiMask;
    }

    public maskNIT() {
        var mask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /./];
        return mask;
    }

    public optionsMonedaLocal() {
        return { prefix: this.currencySymbol() + ' ', align: 'left' };
    }

    public optionsMoneda(simbolo) {
        return { prefix: simbolo + ' ', align: 'left' };
    }

    public optionsPorcentaje() {
        return { suffix: '%', prefix: '', align: 'left' };
    }

    public optionsMilimetros() {
        return { suffix: ' mm', prefix: '', align: 'left' };
    }

    public optionsNumeroFormato(simbolo) {
        return { suffix: ' ' + simbolo, align: 'left', prefix: '' };
    }

    // public idMonedaPorDefecto(): number {
    //     if(this.usuario && this.usuario.empresa) {
    //         return this.usuario.empresa.MonedaId;
    //     }
    //     else return null;
    // }

    currencySymbol() {
        return this.simboloMonedaPorDefecto();
    }

    maskCreditCard() {
        var mask = [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
        return mask;
    }

    maskCCV() {
        var mask = [/\d/, /\d/, /\d/, /\d/];
        return mask;
    }

    maskExpiracion() {
        var mask = [/\d/, /\d/, '/', /\d/, /\d/];
        return mask;
    }

    maskFecha() {
        var mask = [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
        return mask;
    }

    maskPlaca() {
        var mask = [/[A-Za-z]/, '-', /\d/, /\d/, /\d/, /[ABCDFGHJKLMNPQRSTVWXYZ]/, /[ABCDFGHJKLMNPQRSTVWXYZ]/, /[ABCDFGHJKLMNPQRSTVWXYZ]/,];
        return mask;
    }

    maskHoraRango() {
        var mask = [/\d/, /\d/, ':', /\d/, /\d/, '-', /\d/, /\d/, ':', /\d/, /\d/];
        return mask;
    }

    // * * * Permisos * * *
    tienePermisoPara(permiso: string) {
        if (!this.usuario || !this.usuario.rol) return false;
        return this.usuario.tienePermisoPara(permiso);
    }

    async obtenerRutaFormulario(FormularioId) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.formulariosFEICURL + '/id/' + FormularioId;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.formularioFEICDeJSON(json);
            if (this.tienePermisoPara(this.apiUrls.permisos.VISUALIZAR_FORMULARIOS)) {
                if(registro.isDeleted){
                    return '/ventas/detalle-formulario-feic/' + FormularioId;
                } else {
                    if (this.tienePermisoPara(this.apiUrls.permisos.EDITAR_FORMULARIOS)) {
                        if (registro.cerrado) {
                            if (this.tienePermisoPara(this.apiUrls.permisos.EDITAR_FORMULARIOS_CERRADOS)) return '/ventas/editar-formulario-feic/' + FormularioId;
                            else return '/ventas/detalle-formulario-feic/' + FormularioId;
                        }
                        else return '/ventas/editar-formulario-feic/' + FormularioId;
                    }
                    return '/ventas/detalle-formulario-feic/' + FormularioId;
                }
            }
            return '';
        } catch (error) {
            let mensaje = this.connection.obtenerMensajeError(error.status, null);
            return '';
        }
    }

    // * * * Menú * * *
    cargarMenus() {
        // * Inicio *
        var permisos = this.apiUrls.permisos;

        var items: NavigationItem[] = [{
            type: 'link',
            label: 'Inicio',
            route: '/',
            icon: icHome
        }];

        // * Empresas *
        if (this.tienePermisoPara(permisos.VISUALIZAR_EMPRESAS)) {
            // Menú general
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Empresas',
                icon: icEmpresas,
                children: [],
            };

            // Listado
            menuGeneral.children.push({
                type: 'link',
                label: 'Todas las empresas',
                route: '/empresas/todas'
            });

            // Nuevo
            if (this.tienePermisoPara(permisos.EDITAR_EMPRESAS)) menuGeneral.children.push({
                type: 'link',
                label: 'Agregar nueva empresa',
                route: '/empresas/nuevo'
            });

            if (menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        // * * * Usuarios * * *
        if (this.tienePermisoPara(permisos.VISUALIZAR_USUARIOS)) {
            // Menú general
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Usuarios',
                icon: icUsers,
                children: [],
            };

            // Listado usuarios
            menuGeneral.children.push({
                type: 'link',
                label: 'Listado de usuarios',
                route: '/usuarios/usuarios'
            });

            if (this.tienePermisoPara(permisos.EDITAR_USUARIOS)) {
                // Nuevo usuario
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Agregar nuevo usuario',
                    route: '/usuarios/nuevo'
                });

            }

            if (menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        // * * * Roles * * *
        if (this.tienePermisoPara(permisos.SUPER_ADMIN)) {
            // Menú general
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Roles',
                icon: icRoles,
                children: [],
            };

            // Listado usuarios
            menuGeneral.children.push({
                type: 'link',
                label: 'Listado de roles',
                route: '/roles/todos'
            });

            // Nueva invitación
            menuGeneral.children.push({
                type: 'link',
                label: 'Nuevo rol',
                route: '/roles/nuevo'
            });

            if (menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        // * * * Ventas * * *
        if (this.tienePermisoPara(permisos.VISUALIZAR_VENTAS)) {
            // Menú general
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Ventas',
                icon: icVenta,
                children: [],
            };

            // Listado ventas
            if (this.tienePermisoPara(permisos.AUDITOR)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Listado de ventas',
                    route: '/ventas/todos'
                });
            }

            // Listado ventas
            menuGeneral.children.push({
                type: 'link',
                label: 'Mis ventas',
                route: '/ventas/mis-ventas'
            });

            if (this.tienePermisoPara(permisos.EDITAR_VENTAS)) {
                // Nuevo venta
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Agregar nueva venta',
                    route: '/ventas/nuevo'
                });

                // Listado de formularios
                if (this.tienePermisoPara(permisos.AUDITOR)) {
                    if (this.tienePermisoPara(permisos.VISUALIZAR_FORMULARIOS)) menuGeneral.children.push({
                        type: 'link',
                        label: 'Listado de formularios',
                        route: '/formularios-feic/todos'
                    });
                }

                // Listado de formularios
                if (this.tienePermisoPara(permisos.AUDITOR)) {
                    if (this.tienePermisoPara(permisos.VISUALIZAR_FORMULARIOS)) menuGeneral.children.push({
                        type: 'link',
                        label: 'Formularios anulados',
                        route: '/formularios-feic/eliminados'
                    });
                }

                // Listado de formularios
                if (this.tienePermisoPara(permisos.VISUALIZAR_FORMULARIOS)) menuGeneral.children.push({
                    type: 'link',
                    label: 'Mis formularios',
                    route: '/formularios-feic/mis-formularios'
                });

                // Exportar formularios
                if (this.tienePermisoPara(permisos.EXPORTAR_FORMULARIOS)) menuGeneral.children.push({
                    type: 'link',
                    label: 'Exportar/Calificación FEICs',
                    route: '/formularios-feic/exportar-calificacion'
                });

            }

            if (menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        // * * * Clientes * * *
        if (this.tienePermisoPara(permisos.VISUALIZAR_CLIENTES) || this.tienePermisoPara(permisos.VISUALIZAR_CLIENTES_PROSPECTOS_LEADS)) {
            // Menú general
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Clientes',
                icon: icClient,
                children: [],
            };

            // Listado clientes
            menuGeneral.children.push({
                type: 'link',
                label: 'Listado de clientes',
                route: '/clientes/todos'
            });

            if (this.tienePermisoPara(permisos.EDITAR_CLIENTES) || this.tienePermisoPara(permisos.EDITAR_CLIENTES_PROSPECTOS_LEADS)) {
                // Nuevo cliente
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Agregar nuevo clientes',
                    route: '/clientes/nuevo'
                });

            }

            if (menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        // * * * Productos * * *
        if (this.tienePermisoPara(permisos.VISUALIZAR_PRODUCTOS)) {
            // Menú general
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Productos',
                icon: icProducto,
                children: [],
            };

            // Listado productos
            menuGeneral.children.push({
                type: 'link',
                label: 'Listado de productos FEIC',
                route: '/productos/todos'
            });

            if (this.tienePermisoPara(permisos.EDITAR_PRODUCTOS)) {
                // Nuevo producto
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Agregar nuevo producto FEIC',
                    route: '/productos/nuevo'
                });
            }

            // Listado productos
            menuGeneral.children.push({
                type: 'link',
                label: 'Listado de productos Avalúos',
                route: '/productos/todos-avaluos'
            });

            if (this.tienePermisoPara(permisos.EDITAR_PRODUCTOS)) {
                // Nuevo producto
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Agregar nuevo producto Avalúos',
                    route: '/productos/nuevo-avaluos'
                });
            }

            if (menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        // * * * Matriz de riesgo * * *
        if (this.tienePermisoPara(permisos.MATRIZ_RIESGO)) {
            // Menú general
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Matriz de riesgo',
                icon: icMatrizRiesgo,
                children: [],
            };


            if (this.tienePermisoPara(permisos.MATRIZ_RIESGO)) {

                // Agregar a lista negra
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Agregar a lista negra',
                    route: '/matriz-riesgo/listas-negras/nuevo'
                });

                // Lista negra
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Lista negra',
                    route: '/matriz-riesgo/listas-negras/todas'
                });

                // Carga masiva de lista negra
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Carga masiva lista negra',
                    route: '/matriz-riesgo/listas-negras/carga-masiva'
                });

            }

            if (this.tienePermisoPara(permisos.AJUSTES_GENERALES)) {

                // Agregar a lista negra
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Ajustes generales',
                    route: '/matriz-riesgo/ajuste-general'
                });

            }

            if (menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        if (this.tienePermisoPara(permisos.CONFIGURACION_AVALUOS)) {
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Marcas',
                icon: icMarcas,
                children: [],
            };

            menuGeneral.children.push({
                type: 'link',
                label: 'Listado de marcas',
                route: '/avaluos/vehiculos'
            });

            menuGeneral.children.push({
                type: 'link',
                label: 'Agregar marca',
                route: '/avaluos/vehiculos/nuevo'
            });

            menuGeneral.children.push({
                type: 'link',
                label: 'Líneas de vehículos',
                route: '/avaluos/linea-vehiculo'
            });

            menuGeneral.children.push({
                type: 'link',
                label: 'Agregar Línea de vehículo',
                route: '/avaluos/linea-vehiculo/nuevo'
            });

            if (menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        if (
            this.tienePermisoPara(permisos.CREACION_SOLICITUD_AVALUO) || 
            this.tienePermisoPara(permisos.REALIZACION_AVALUO) || 
            this.tienePermisoPara(permisos.VER_AVALUOS_CONFIRMADOS) || 
            this.tienePermisoPara(permisos.REPORTE_AVALUO) || 
            this.tienePermisoPara(permisos.CONFIRMACION_AVALUO) || 
            this.tienePermisoPara(permisos.CONFIGURACION_AVALUOS) || 
            this.tienePermisoPara(permisos.DASHBOARD_AVALUO) || 
            this.tienePermisoPara(permisos.REPORTE_COACHES) || 
            this.tienePermisoPara(permisos.REPORTE_GERENCIAL_AVALUOS) || 
            this.tienePermisoPara(permisos.CANCELAR_AVALUOS)
        ) {
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Avalúos',
                icon: icAvaluos,
                children: [],
            };

            if (this.tienePermisoPara(permisos.CONFIGURACION_AVALUOS)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Ajustes de avalúos',
                    route: '/avaluos/ajustes/detalle'
                });

                menuGeneral.children.push({
                    type: 'link',
                    label: 'Descuentos',
                    route: '/avaluos/descuento/detalle'
                });
            }

            if (this.tienePermisoPara(permisos.CREACION_SOLICITUD_AVALUO)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Solicitar Avalúo',
                    route: '/avaluos/solicitud/nuevo'
                });
            }

            if (this.tienePermisoPara(permisos.REPORTE_AVALUO)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Reporte de Avalúos',
                    route: '/avaluos/todos'
                });
            }
            
            if(this.tienePermisoPara(permisos.REPORTE_GERENCIAL_AVALUOS)){
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Reporte gerencial',
                    route: '/avaluos/reporte-gerencial'
                });
            }

            if(this.tienePermisoPara(permisos.REPORTE_COACHES)){
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Reporte Coaches',
                    route: '/avaluos/coaches'
                });
            }

            // if (this.tienePermisoPara(permisos.REPORTE_AVALUO) || this.tienePermisoPara(permisos.REALIZACION_AVALUO)) {
            //     menuGeneral.children.push({
            //         type: 'link',
            //         label: 'Listado general',
            //         route: '/avaluos/listado-general'
            //     });
            // }

            if (this.tienePermisoPara(permisos.CREACION_SOLICITUD_AVALUO)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Mis avalúos',
                    route: '/avaluos/mis-avaluos'
                });
            }

            // if (this.tienePermisoPara(permisos.REALIZACION_AVALUO)) {
            //     menuGeneral.children.push({
            //         type: 'link',
            //         label: 'Avalúos pendientes',
            //         route: '/avaluos/pendientes'
            //     });
            // }

            // if (this.tienePermisoPara(permisos.CONFIRMACION_AVALUO)) {
            //     menuGeneral.children.push({
            //         type: 'link',
            //         label: 'Pendientes de confirmación',
            //         route: '/avaluos/pendientes-confirmacion'
            //     });
            // }

            // if (this.tienePermisoPara(permisos.VER_AVALUOS_CONFIRMADOS)) {
            //     menuGeneral.children.push({
            //         type: 'link',
            //         label: 'Avalúos confirmados',
            //         route: '/avaluos/avaluo-entregado'
            //     });
            // }

            if(this.tienePermisoPara(permisos.CONFIRMACION_AVALUO)) {
                // menuGeneral.children.push({
                //     type: 'link',
                //     label: 'Aprobados por cliente',
                //     route: '/avaluos/aprobados-cliente'
                // });
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Rechazados por cliente',
                    route: '/avaluos/rechazados-cliente'
                });
                // menuGeneral.children.push({
                //     type: 'link',
                //     label: 'Recibidos',
                //     route: '/avaluos/recibidos'
                // });
            }

            if (this.tienePermisoPara(permisos.DASHBOARD_AVALUO)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Dashboard General',
                    route: '/avaluos/reporte'
                });
            }

            if (this.tienePermisoPara(permisos.DASHBOARD_COMERCIAL_AVALUO)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Dashboard Comercial',
                    route: '/avaluos/reporte-comercial'
                });
            }

            if (this.tienePermisoPara(permisos.REPORTE_AVALUO)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Historial de avaluos',
                    route: '/avaluos/historial-avaluo'
                });
            }

            if (menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        // * * * Hojas Membretadas  * * *
        let permisoMenuHojasMembretadas = (
            this.tienePermisoPara(permisos.CREACION_MEMBRETE_EMPRESA) ||
            this.tienePermisoPara(permisos.VISUALIZAR_MEBRETES_EMPRESA) ||
            this.tienePermisoPara(permisos.APROBACION_HOJAS_MEMBRETADAS) ||
            this.tienePermisoPara(permisos.CREACION_HOJA_MEMBRETADA) ||
            this.tienePermisoPara(permisos.REPORTE_HOJAS_MEMBRETADAS)
        );

        if (permisoMenuHojasMembretadas) {
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Hojas membretadas',
                icon: icHojaMembretada,
                children: [],
            };

            // * Membretes *
            if (this.tienePermisoPara(permisos.VISUALIZAR_MEBRETES_EMPRESA)) menuGeneral.children.push({
                type: 'link',
                label: 'Listado de membretes',
                route: '/hojas-membretadas/membretes/todos'
            });

            if (this.tienePermisoPara(permisos.CREACION_MEMBRETE_EMPRESA)) menuGeneral.children.push({
                type: 'link',
                label: 'Nuevo membrete',
                route: '/hojas-membretadas/membretes/nuevo'
            });

            // * Hojas membretadas *
            if (this.tienePermisoPara(permisos.CREACION_HOJA_MEMBRETADA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Nueva hoja membretada',
                    route: '/hojas-membretadas/nueva'
                });

                menuGeneral.children.push({
                    type: 'link',
                    label: 'Mis hojas',
                    route: '/hojas-membretadas/mis-hojas'
                });

                menuGeneral.children.push({
                    type: 'link',
                    label: 'Pendientes de aprobación',
                    route: '/hojas-membretadas/pendientes-aprobacion'
                });
            }

            if (this.tienePermisoPara(permisos.REPORTE_HOJAS_MEMBRETADAS)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Todas las hojas',
                    route: '/hojas-membretadas/todas'
                });
            }

            if (this.tienePermisoPara(permisos.REPORTE_HOJAS_MEMBRETADAS)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Reporte',
                    route: '/hojas-membretadas/reporte'
                });
            }

            if (this.tienePermisoPara(permisos.ADMINISTRADOR)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Copiar hojas',
                    route: '/hojas-membretadas/copiar-hojas'
                });
            }

            if (menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        // * * * Manual * * *
        if (this.tienePermisoPara(permisos.CUALQUIERA)) {
            // Menú general
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Manual de usuario',
                icon: icManuales,
                children: [],
            };

            if (this.tienePermisoPara(permisos.SUPER_ADMIN)) {
                // Editor
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Editor de manual',
                    route: '/manual/editor'
                });

            }

            // Tutoriales
            menuGeneral.children.push({
                type: 'link',
                label: 'Manual de usuario',
                route: '/manual/tutorial'
            });

            if (menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        // * * * Ahorrent * * *
        if (this.usuario && this.usuario && this.usuario.empresa && this.usuario.empresa.moduloAhorrent == true) {
            // Menú general
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Ahorrent',
                icon: icUnidades,
                children: [],
            };
            // Submenú Dashboards
            var subMenuDashboards: NavigationItem = {
                type: 'dropdown',
                label: 'Dashboards',
                icon: icDashboard,
                children: [],
            };
            // Submenú Rentas
            var subMenuRentas: NavigationItem = {
                type: 'dropdown',
                label: 'Rentas',
                icon: icRentas,
                children: [],
            };
            // Submenú Unidades
            var subMenuUnidades: NavigationItem = {
                type: 'dropdown',
                label: 'Unidades',
                icon: icUnidades,
                children: [],
            };
            // Submenú Encuestas
            var subMenuListadoEncuestasIndividual: NavigationItem = {
                type: 'dropdown',
                label: 'Listado de Encuestas',
                icon: icEncuestas,
                children: [],
            };
            // Submenú Reportes
            var subMenuReportes: NavigationItem = {
                type: 'dropdown',
                label: 'Reportes',
                icon: icReportes,
                children: [],
            };
            // Submenú Otros
            var subMenuOtros: NavigationItem = {
                type: 'dropdown',
                label: 'Otros',
                icon: icOtros,
                children: [],
            };

            if (
                this.tienePermisoPara(permisos.CONFIGURACION_AHORRENT) ||
                this.tienePermisoPara(permisos.VISUALIZAR_CONF_AHORRENT_ENCUESTA_INDIVIDUAL) ||
                this.tienePermisoPara(permisos.EDITAR_CONF_AHORRENT_ENCUESTA_INDIVIDUAL) ||
                this.tienePermisoPara(permisos.VISUALIZAR_CONF_AHORRENT_ENCUESTA_CORPORATIVA) ||
                this.tienePermisoPara(permisos.EDITAR_CONF_AHORRENT_ENCUESTA_CORPORATIVA) ||
                this.tienePermisoPara(permisos.VISUALIZAR_CONF_AHORRENT_TEXTOS_ENCUESTA) ||
                this.tienePermisoPara(permisos.EDITAR_CONF_AHORRENT_TEXTOS_ENCUESTA) ||
                this.tienePermisoPara(permisos.VISUALIZAR_CONF_AHORRENT_SERVICIOS_KILOMETRAJE) ||
                this.tienePermisoPara(permisos.EDITAR_CONF_AHORRENT_SERVICIOS_KILOMETRAJE) ||
                this.tienePermisoPara(permisos.VISUALIZAR_CONF_AHORRENT_CAMBIO_DOLAR) ||
                this.tienePermisoPara(permisos.EDITAR_CONF_AHORRENT_CAMBIO_DOLAR) ||
                this.tienePermisoPara(permisos.VISUALIZAR_CONF_AHORRENT_PRECIOS_COMBUSTIBLE) ||
                this.tienePermisoPara(permisos.EDITAR_CONF_AHORRENT_PRECIOS_COMBUSTIBLE) ||
                this.tienePermisoPara(permisos.VISUALIZAR_CONF_AHORRENT_PROTECCION_CHOQUES) ||
                this.tienePermisoPara(permisos.EDITAR_CONF_AHORRENT_PROTECCION_CHOQUES) ||
                this.tienePermisoPara(permisos.VISUALIZAR_CONF_AHORRENT_PROTECCION_PORCENTAJES_EFICIENCIA) ||
                this.tienePermisoPara(permisos.EDITAR_CONF_AHORRENT_PROTECCION_PORCENTAJES_EFICIENCIA) ||
                this.tienePermisoPara(permisos.VISUALIZAR_CONF_AHORRENT_PROTECCION_PORCENTAJES_DESCUENTO) ||
                this.tienePermisoPara(permisos.EDITAR_CONF_AHORRENT_PROTECCION_PORCENTAJES_DESCUENTO) ||
                this.tienePermisoPara(permisos.VISUALIZAR_CONF_AHORRENT_PROTECCION_SEGUROS_SERVICIOS) ||
                this.tienePermisoPara(permisos.EDITAR_CONF_AHORRENT_PROTECCION_SEGUROS_SERVICIOS)
            ) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Configuraciones',
                    icon: icConfiguracion,
                    route: '/ahorrent/configuracion'
                });
            }

            // * * * Dashboards * * *

            if(this.tienePermisoPara(permisos.DASHBOARD_AGENTES_AHORRENT)){
                subMenuDashboards.children.push({
                    type: 'link',
                    label: 'Agentes',
                    route: '/ahorrent/dashboard-agentes'
                });
            }
            if(this.tienePermisoPara(permisos.DASHBOARD_ANALISIS_RENTAS_AHORRENT)){
                subMenuDashboards.children.push({
                    type: 'link',
                    label: 'Análisis de Rentas',
                    route: '/ahorrent/dashboard-analisis-rentas'
                });
            }
            if(this.tienePermisoPara(permisos.DASHBOARD_GERENCIAL_AHORRENT)){
                subMenuDashboards.children.push({
                    type: 'link',
                    label: 'Gerencial',
                    route: '/ahorrent/dashboard-gerencial'
                });
            }
            if(this.tienePermisoPara(permisos.DASHBOARD_PILOTOS_AHORRENT)){
                subMenuDashboards.children.push({
                    type: 'link',
                    label: 'Operaciones',
                    route: '/ahorrent/dashboard-pilotos'
                });
            }

            // * * * Listado de Encuestas Individual * * *
            if (this.tienePermisoPara(permisos.LISTADO_ENCUESTAS_INDIVIDUALES_AHORRENT)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Listado encuestas individuales',
                    route: '/ahorrent/encuesta-individual/todos'
                });
            }

            if (this.tienePermisoPara(permisos.LISTADO_ENCUESTAS_CORPORATIVAS_AHORRENT)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Listado encuestas corporativas',
                    route: '/ahorrent/encuesta-corporativa/todos'
                });
            }
            
            if (this.tienePermisoPara(permisos.GRAFICAS_ENCUESTAS_AHORRENT)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Gráficas de encuestas',
                    route: '/ahorrent/graficas-encuestas'
                });
            }

            // * * * Otros * * *

            if(this.tienePermisoPara(permisos.VISUALIZAR_CONTRATOS_AHORRENT)){
                // Listado unidades
                subMenuOtros.children.push({
                    type: 'link',
                    label: 'Calendario de Lavado',
                    route: '/ahorrent/contratos/calendario-lavado'
                });
            }

            if(this.tienePermisoPara(permisos.EDITAR_UNIDADES)){

                subMenuOtros.children.push({
                    type: 'link',
                    label: 'Carga de Datos Depreciación',
                    route: '/ahorrent/unidades/carga-depreciacion'
                });

                subMenuOtros.children.push({
                    type: 'link',
                    label: 'Carga de Datos Unidades',
                    route: '/ahorrent/unidades/carga-unidades'
                });

                subMenuOtros.children.push({
                    type: 'link',
                    label: 'Carga de Imagen Unidades',
                    route: '/ahorrent/unidades/carga-imagen-unidades'
                });

            }

            if (this.tienePermisoPara(permisos.EDITAR_CLIENTES_AHORRENT)){
                // Carga clientes
                subMenuOtros.children.push({
                    type: 'link',
                    label: 'Carga Masiva de Clientes',
                    route: '/ahorrent/clientes/carga'
                });
            }

            if(this.tienePermisoPara(permisos.VISUALIZAR_UNIDADES)){

                subMenuOtros.children.push({
                    type: 'link',
                    label: 'Gráficas de Renta',
                    route: '/ahorrent/graficas-renta'
                });
            }

            // * * * Rentas * * *

            if (this.tienePermisoPara(permisos.EDITAR_RESERVAS_AHORRENT)) {
                // Nuevo reserva
                subMenuRentas.children.push({
                    type: 'link',
                    label: 'Agregar Nueva Reserva',
                    route: '/ahorrent/contratos/nuevo-reserva'
                });
            }

            if (this.tienePermisoPara(permisos.EDITAR_CONTRATOS_AHORRENT)) {
                // Nuevo contrato
                subMenuRentas.children.push({
                    type: 'link',
                    label: 'Agregar Nuevo Contrato',
                    route: '/ahorrent/contratos/nuevo'
                });
            }

            if(this.tienePermisoPara(permisos.VISUALIZAR_CLIENTES_AHORRENT)){
                // Listado unidades
                subMenuRentas.children.push({
                    type: 'link',
                    label: 'Listado de clientes',
                    route: '/ahorrent/clientes/todos'
                });
            }

            if(this.tienePermisoPara(permisos.VISUALIZAR_CONTRATOS_AHORRENT)){
                // Listado contratos
                subMenuRentas.children.push({
                    type: 'link',
                    label: 'Listado de Contratos',
                    route: '/ahorrent/contratos/todos'
                });
            }

            if(this.tienePermisoPara(permisos.VISUALIZAR_RESERVAS_AHORRENT)){
                // Listado reservas
                subMenuRentas.children.push({
                    type: 'link',
                    label: 'Listado de Reservas',
                    route: '/ahorrent/contratos/todos-reservas'
                });
            }

            if(this.tienePermisoPara(permisos.VISUALIZAR_UNIDADES)) {
                subMenuRentas.children.push({
                    type: 'link',
                    label: 'Reporte de Unidades',
                    route: '/ahorrent/unidades/reportes'
                });
            }

            if(this.tienePermisoPara(permisos.TRASLADO_UNIDADES_AHORRENT)){
                subMenuRentas.children.push({
                    type: 'link',
                    label: 'Traslado de Unidades',
                    route: '/ahorrent/unidades/traslado'
                });
            }

            // * * * Reportes * * *

            if (this.tienePermisoPara(permisos.EDITAR_CONTRATOS_AHORRENT)) {
                // Nuevo contrato
                subMenuReportes.children.push({
                    type: 'link',
                    label: 'Reporte de Cambios de Fecha de Entrada',
                    route: '/ahorrent/descargar-cambios-fechas'
                });
            }

            // * * * Unidades * * *

            if(this.tienePermisoPara(permisos.CONFIGURACION_AHORRENT)){

                subMenuUnidades.children.push({
                    type: 'link',
                    label: 'Listado de Líneas',
                    route: '/ahorrent/lineas'
                });
            }

            if(this.tienePermisoPara(permisos.CONFIGURACION_AHORRENT)){

                subMenuUnidades.children.push({
                    type: 'link',
                    label: 'Listado de Marcas',
                    route: '/ahorrent/marcas'
                });
            }

            if(this.tienePermisoPara(permisos.VISUALIZAR_UNIDADES)) {
                // Listado unidades
                subMenuUnidades.children.push({
                    type: 'link',
                    label: 'Listado de Unidades',
                    route: '/ahorrent/unidades/todos'
                });
            }

            if(this.tienePermisoPara(permisos.VISUALIZAR_UNIDADES)) {
                // Listado unidades
                subMenuUnidades.children.push({
                    type: 'link',
                    label: 'Listado de daños',
                    route: '/ahorrent/danios'
                });
            }

            if (subMenuDashboards.children.length > 0) {
                menuGeneral.children.push(subMenuDashboards);
            }

            if (subMenuListadoEncuestasIndividual.children.length > 0) {
                menuGeneral.children.push(subMenuListadoEncuestasIndividual);
            }

            if (subMenuOtros.children.length > 0) {
                menuGeneral.children.push(subMenuOtros);
            }

            if (subMenuRentas.children.length > 0) {
                menuGeneral.children.push(subMenuRentas);
            }

            if (subMenuReportes.children.length > 0) {
                menuGeneral.children.push(subMenuReportes);
            }

            if (subMenuUnidades.children.length > 0) {
                menuGeneral.children.push(subMenuUnidades);
            }

            if (menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        // * * * Campañas Preventivas * * *
        if (this.tienePermisoPara(permisos.CONFIGURACION_CAMPANIAS)) {
            // Menú general
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Campañas Preventivas',
                icon: icCampanias,
                children: [],
            };

            // Nueva campania
            menuGeneral.children.push({
                type: 'link',
                label: 'Agregar nueva campaña',
                route: '/campanias-preventivas/nuevo'
            });

            // Listado campañas preventivas
            menuGeneral.children.push({
                type: 'link',
                label: 'Listado de campañas preventivas',
                route: '/campanias-preventivas/todos'
            });

            menuGeneral.children.push({
                type: 'link',
                label: 'Agregar nuevo vehículo',
                route: '/campanias-preventivas/vehiculos/nuevo'
            });

            menuGeneral.children.push({
                type: 'link',
                label: 'Listado de vehículos',
                route: '/campanias-preventivas/vehiculos/todos'
            });

            // Carga
            menuGeneral.children.push({
                type: 'link',
                label: 'Carga de vehículos',
                route: '/campanias-preventivas/vehiculos/carga'
            });


            if (menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        // * * * Requisiciones * * *
        if (this.tienePermisoPara(permisos.CONFIGURACION_REQUISICIONES) || this.tienePermisoPara(permisos.CREACION_REQUISICIONES)
            || this.tienePermisoPara(permisos.LECTURA_REQUISICIONES) || this.tienePermisoPara(permisos.EDICION_REQUISICIONES)
            || this.tienePermisoPara(permisos.FORZAR_CAMBIO_ESTADO_REQUISICIONES) || this.tienePermisoPara(permisos.LECTURA_REQUISICIONES_CREADAS)
            || this.tienePermisoPara(permisos.LECTURA_REQUISICIONES_PENDIENTES_DE_COTIZACION) || this.tienePermisoPara(permisos.LECTURA_REQUISICIONES_PENDIENTES_DE_VALIDACION) 
            || this.tienePermisoPara(permisos.LECTURA_REQUISICIONES_APROBACION_DE_COTIZACION) || this.tienePermisoPara(permisos.LECTURA_REQUISICIONES_COLOCACION_DE_PEDIDO)
            || this.tienePermisoPara(permisos.LECTURA_REQUISICIONES_ESTATUS_DEL_REQUERIMIENTO) || this.tienePermisoPara(permisos.LECTURA_REQUISICIONES_PEDIDO_COMPLETO_PENDIENTE_DE_DESPACHO)
            || this.tienePermisoPara(permisos.LECTURA_REQUISICIONES_PEDIDO_FINALIZADO) || this.tienePermisoPara(permisos.LECTURA_REQUISICIONES_ANULACION_DE_PEDIDO)
            || this.tienePermisoPara(permisos.AUTORIZACION_REQUISICIONES_TALLER)
        ){
            // Menú general
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Requisiciones',
                icon: icSolicitudTraspaso,
                children: [],
            };

            if (this.tienePermisoPara(permisos.CONFIGURACION_REQUISICIONES)) {
                // Configuracion
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Configuración',
                    route: '/requisiciones/configuracion/'
                });
            }

            if (this.tienePermisoPara(permisos.CREACION_REQUISICIONES)) {
                // Creación
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Nueva Requisición',
                    route: '/requisiciones/nuevo/'
                });

                // Reportes
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Reporte de Requisiciones',
                    route: '/requisiciones/reportes/'
                });
            }

            if (this.tienePermisoPara(permisos.LECTURA_REQUISICIONES)) {
                // Todos
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Todas las Requisiciones',
                    route: '/requisiciones/todos/'
                });
            }

            if (this.tienePermisoPara(permisos.LECTURA_REQUISICIONES_CREADAS)) {
                // Creadas
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Requisiciones Creadas',
                    route: '/requisiciones/creadas/'
                });
            }

            if (this.tienePermisoPara(permisos.LECTURA_REQUISICIONES_PENDIENTES_DE_COTIZACION)) {
                // Pendientes de cotizacion
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Pendientes de Cotización',
                    route: '/requisiciones/pendiente-de-cotizacion/'
                });
            }

            if (this.tienePermisoPara(permisos.LECTURA_REQUISICIONES_PENDIENTES_DE_VALIDACION)) {
                // Pendientes de validacion
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Pendientes de Validación',
                    route: '/requisiciones/pendiente-de-validacion/'
                });
            }

            if (this.tienePermisoPara(permisos.AUTORIZACION_REQUISICIONES_TALLER)) {
                // Anulación de pedido
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Pendiente autorización garantía',
                    route: '/requisiciones/pendiente-autorizacion-garantia/'
                });
            }

            if (this.tienePermisoPara(permisos.LECTURA_REQUISICIONES_APROBACION_DE_COTIZACION)) {
                // Aprobación de cotización
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Aprobaciones de Cotización',
                    route: '/requisiciones/aprobacion-de-cotizacion/'
                });
            }

            if (this.tienePermisoPara(permisos.LECTURA_REQUISICIONES_COLOCACION_DE_PEDIDO)) {
                // Colocación de pedido
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Colocaciones de Pedidos',
                    route: '/requisiciones/colocacion-de-pedido/'
                });
            }

            if (this.tienePermisoPara(permisos.LECTURA_REQUISICIONES_ESTATUS_DEL_REQUERIMIENTO)) {
                // Estatus del requerimiento
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Estatus del Requerimiento',
                    route: '/requisiciones/estatus-del-requerimiento/'
                });
            }

            if (this.tienePermisoPara(permisos.LECTURA_REQUISICIONES_PEDIDO_COMPLETO_PENDIENTE_DE_DESPACHO)) {
                // Pedido completo pendientes de despacho
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Pedidos Completos Pendientes de Despacho',
                    route: '/requisiciones/pedido-completo-pendiente-de-despacho/'
                });
            }

            if (this.tienePermisoPara(permisos.LECTURA_REQUISICIONES_PEDIDO_FINALIZADO)) {
                // Pedido finalizado
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Pedidos Finalizados',
                    route: '/requisiciones/pedido-finalizado/'
                });
            }

            if (this.tienePermisoPara(permisos.LECTURA_REQUISICIONES_ANULACION_DE_PEDIDO)) {
                // Anulación de pedido
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Anulaciones de Pedidos',
                    route: '/requisiciones/anulacion-de-pedido/'
                });
            }

            if (menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        // * * * Citas * * *
        if (
            this.tienePermisoPara(permisos.EDITAR_CONCESIONARIOS) ||
            this.tienePermisoPara(permisos.VISUALIZAR_CITAS) ||
            this.tienePermisoPara(permisos.APROBACION_CITAS) ||
            this.tienePermisoPara(permisos.AGENTE_CITAS)
        ) {
            // Menú general
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Citas',
                icon: icCitas,
                children: [],
            };

            if(this.tienePermisoPara(permisos.EDITAR_CONCESIONARIOS)) {
                // Listado campañas preventivas
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Concesionarios',
                    route: '/citas/concesionarios/todos'
                });
            }

            if(this.tienePermisoPara(permisos.VISUALIZAR_CITAS)) {
                // Calendario de citas
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Calendario',
                    route: '/citas/calendario'
                });

                // Listado de citas
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Listado general',
                    route: '/citas/todas'
                });
            }
            
            if(this.tienePermisoPara(permisos.AGENTE_CITAS)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Reservar cita',
                    route: '/citas/nueva-reservacion-agente'
                });
            }
            
            if(this.tienePermisoPara(permisos.REPORTE_CITAS)) {
                //Reporte
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Reporte citas',
                    route: '/citas/reporte'
                });
            }
            
            if(this.tienePermisoPara(permisos.SUPER_ADMIN)) {
                //versiones SOB
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Versiones SOB API',
                    route: '/citas/versiones-lineas-sob/todos'
                });

                menuGeneral.children.push({
                    type: 'link',
                    label: 'Carga versiones SOB API',
                    route: '/citas/versiones-lineas-sob/carga'
                });

                //Vehículos SOB
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Vehículos SOB API',
                    route: '/citas/vehiculos-sob/todos'
                });

                menuGeneral.children.push({
                    type: 'link',
                    label: 'Carga vehículos SOB API',
                    route: '/citas/vehiculos-sob/carga'
                });
            }

            if (menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        // * * * Transparencia de precios * * *
        if (
            this.tienePermisoPara(permisos.VISUALIZAR_VERSIONES) ||
            this.tienePermisoPara(permisos.EDITAR_VERSIONES)
        ) {
            // Menú general
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Transparencia de precios',
                icon: icTransparenciaPrecios,
                children: [],
            };

            menuGeneral.children.push({
                type: 'link',
                label: 'Líneas de vehículos',
                route: '/avaluos/linea-vehiculo-transparencia'
            });

            if (
                this.tienePermisoPara(permisos.VISUALIZAR_SERVICIOS) ||
                this.tienePermisoPara(permisos.EDITAR_SERVICIOS)
            ) {
                // Listado
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Servicios',
                    route: '/servicios/todos'
                });

                // Nuevo
                if (this.tienePermisoPara(permisos.EDITAR_SERVICIOS)) menuGeneral.children.push({
                    type: 'link',
                    label: 'Agregar nuevo servicio',
                    route: '/servicios/nuevo'
                });
            }

            if (
                this.tienePermisoPara(permisos.VISUALIZAR_REPUESTOS) ||
                this.tienePermisoPara(permisos.EDITAR_REPUESTOS)
            ) {
                // Listado
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Repuestos',
                    route: '/repuestos/todos'
                });

                // Nuevo
                if (this.tienePermisoPara(permisos.EDITAR_REPUESTOS)) menuGeneral.children.push({
                    type: 'link',
                    label: 'Agregar nuevo repuesto',
                    route: '/repuestos/nuevo'
                });
            }

            if(
                this.tienePermisoPara(permisos.VISUALIZAR_VERSIONES) ||
                this.tienePermisoPara(permisos.EDITAR_VERSIONES)
            ) {
                // Listado
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Versiones',
                    route: '/versiones/todos'
                });
    
                // Nuevo
                if (this.tienePermisoPara(permisos.EDITAR_VERSIONES)) menuGeneral.children.push({
                    type: 'link',
                    label: 'Agregar nueva version',
                    route: '/versiones/nuevo'
                });
            }

            if(
                this.tienePermisoPara(permisos.CONFIGURACION_LANDING)
            ) {
                // Listado
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Configuración',
                    route: '/configuraciones-landing/configuracion'
                });

            }

            if (menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        // * * * Planes mantenimiento * * *
        if (true) {
            // Menú general
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Planes de mantenimiento',
                icon: icPlanesMantenimiento,
                children: [],
            };

            if(this.tienePermisoPara(permisos.SUPER_ADMIN)){
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Configuración',
                    route: '/planes-mantenimientos/configuraciones'
                });
            }

            if(this.tienePermisoPara(permisos.VISUALIZAR_CONTRATACIONES)){
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Contrataciones',
                    route: '/planes-mantenimientos/contrataciones/todos'
                });
            }

            if(this.tienePermisoPara(permisos.EDITAR_CONTRATACIONES)){
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Nueva contratación',
                    route: '/planes-mantenimientos/contrataciones/nuevo'
                });
            }

            if(this.tienePermisoPara(permisos.VISUALIZAR_CLIENTES_PLANES_MANTENIMIENTOS)){
            
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Clientes',
                    route: '/planes-mantenimientos/clientes/todos'
                });
            
            }

            if(this.tienePermisoPara(permisos.VISUALIZAR_CLIENTES_PLANES_MANTENIMIENTOS)){
            
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Vehículos',
                    route: '/planes-mantenimientos/vehiculos/todos'
                });
            
            }
            
            if(this.tienePermisoPara(permisos.VISUALIZAR_PLANES_MANTENIMIENTOS)){
            
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Planes',
                    route: '/planes-mantenimientos/planes/todos'
                });
            
            }

            if(this.tienePermisoPara(permisos.VISUALIZAR_PLANES_MANTENIMIENTOS)){
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Reporte',
                    route: '/planes-mantenimientos/reportes'
                });
            }

            if (menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        // * * * Prospectos y Leads * * *
        if (true) {
          // Menú general
          var menuGeneral: NavigationItem = {
              type: 'dropdown',
              label: 'Prospectos y Leads',
              icon: icProspectoLead,
              children: [],
          };

          // cambiar al permiso EDICION_CONFIGURACION_LEAD
          if(this.tienePermisoPara(permisos.EDICION_CONFIGURACION_LEAD)){
              menuGeneral.children.push({
                  type: 'link',
                  label: 'Configuración',
                  route: '/prospectos-leads/configuraciones'
              });
          }
          
          // Ver prospectos
          if (this.tienePermisoPara(permisos.LECTURA_PROSPECTOS_LEADS) || 
                this.tienePermisoPara(permisos.ACCESO_TODOS_PROSPECTOS_LEADS)) {
            menuGeneral.children.push({
                type: 'link',
                label: 'Ver prospectos',
                route: '/prospectos-leads/todos'
            });
          }

          // Ver prospectos para seguimiento-prospectos
          if (this.tienePermisoPara(permisos.SEGUIMIENTOS_PROSPECTOS_LEADS) || 
                this.tienePermisoPara(permisos.SEGUIMIENTOS_PROSPECTOS_LEADS_DE_OTROS_USUARIOS)) {
            menuGeneral.children.push({
                type: 'link',
                label: 'Ver seguimientos prospectos',
                route: '/prospectos-leads/seguimiento-prospectos'
            });
          }

          // crear prospecto
          if (this.tienePermisoPara(permisos.EDICION_PROSPECTOS_LEADS)) {
            menuGeneral.children.push({
                type: 'link',
                label: 'Crear nuevo prospecto',
                route: '/prospectos-leads/nuevo'
            });
          }

          if(this.tienePermisoPara(permisos.REPORTE_PROSPECTOS_LEADS)) {
            //Reporte
            menuGeneral.children.push({
                type: 'link',
                label: 'Dashboard Prospectos y Leads',
                route: '/prospectos-leads/reportes'
            });
        }

        if(this.tienePermisoPara(permisos.REASIGNACION_PROSPECTOS_LEADS)) {
            //Reporte
            menuGeneral.children.push({
                type: 'link',
                label: 'Reasignación de prospectos y leads',
                route: '/prospectos-leads/reasignacion'
            });
        }

          if (menuGeneral.children.length > 0) {
              items.push(menuGeneral);
          }
      }

        // * * * Digitalizacion de boletas * * *
        if (true) {
            // Menú general
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Digitalización de boletas',
                icon: icBoletas,
                children: [],
            };

            if(this.tienePermisoPara(permisos.CALENDARIO_SOLICITUD_PRUEBA_MANEJO)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Calendario Vehículos',
                    route: '/digitalizacion-boleta/calendario-vehiculos'
                });
            }

            if(this.tienePermisoPara(permisos.CREACION_SOLICITUD_PRUEBA_MANEJO)){
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Nueva solicitud',
                    route: '/digitalizacion-boleta/solicitudes/nuevo'
                });
            }

            if(this.tienePermisoPara(permisos.LECTURA_SOLICITUD_PRUEBA_MANEJO)){

                    menuGeneral.children.push({
                        type: 'link',
                        label: 'Solicitudes',
                        route: '/digitalizacion-boleta/solicitudes/todos'
                    });
            }

            if(this.tienePermisoPara(permisos.EDICION_VEHICULOS_SOLICITUDES)){

                menuGeneral.children.push({
                    type: 'link',
                    label: 'Nuevo vehiculo',
                    route: '/digitalizacion-boleta/vehiculos/nuevo'
                });
            }

            if(this.tienePermisoPara(permisos.LECTURA_VEHICULOS_SOLICITUDES)){

                menuGeneral.children.push({
                    type: 'link',
                    label: 'Vehículos',
                    route: '/digitalizacion-boleta/vehiculos/todos'
                });

            }

            if(this.tienePermisoPara(permisos.EDITAR_CLIENTES_BOLETAS)){

                menuGeneral.children.push({
                    type: 'link',
                    label: 'Nuevo cliente',
                    route: '/digitalizacion-boleta/clientes/nuevo'
                });
            }
            
            if(this.tienePermisoPara(permisos.VISUALIZAR_CLIENTES_BOLETAS)){
            
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Clientes',
                    route: '/digitalizacion-boleta/clientes/todos'
                });
            
            }

            if(this.tienePermisoPara(permisos.SUPER_ADMIN)){
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Configuración',
                    route: '/digitalizacion-boleta/configuraciones'
                });
            }

            if(this.tienePermisoPara(permisos.REPORTES_SOLICITUD_PRUEBA_MANEJO)){
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Reportes',
                    route: '/digitalizacion-boleta/reportes'
                });
            }

            if (menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        // * * * PDI * * *
        if (true) {
            // Menú general
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Solicitudes PDI',
                icon: icPDI,
                children: [],
            };

            if (this.tienePermisoPara(permisos.LECTURA_SOLICITUD_VENTA) 
                || this.tienePermisoPara(permisos.LECTURA_INSPECCION_SALIDA_PDI)
                || this.tienePermisoPara(permisos.LECTURA_INSPECCION_ENTRADA_PDI)
            ){
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Solicitudes PDI',
                    route: '/pdi/solicitud/solicitud/todos'
                });
            }
            if (this.tienePermisoPara(permisos.CREACION_SOLICITUD_VENTA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Nueva solicitud PDI',
                    route: '/pdi/solicitud/solicitud/nuevo'
                });
            }
            if (this.tienePermisoPara(permisos.LECTURA_SOLICITUD_VENTA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Cambio de envío a placas',
                    route: '/pdi/cambio-envio-placas'
                });
            }
            if(this.tienePermisoPara(permisos.ENVIO_SOLICITUD_VENTA_KANBOARD)){
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Validación solicitudes Kanboard',
                    route: '/pdi/validacion-solicitudes-kanboard'
                })
            }

            if(this.tienePermisoPara(permisos.LECTURA_SOLICITUD_VENTA)){
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Datos de ventas',
                    route: '/pdi/datos-ventas/solicitud'
                })
                
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Descargar tareas Kanboard',
                    route: '/pdi/descargar-kanboard'
                })

            }

            //Configuraciones
            if (this.tienePermisoPara(permisos.SUPER_ADMIN)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Configuración',
                    route: '/pdi/configuraciones'
                });
            }

            if(this.tienePermisoPara(permisos.REPORTE_PDI)) {
                //Reporte
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Reporte PDI',
                    route: '/pdi/reportes'
                });

                //Reporte inspecciones
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Reporte inspecciones PDI',
                    route: '/pdi/reportes-inspecciones'
                });
            }

            if (menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        // * * * PDI EXHIBICION * * *
        if (this.tienePermisoPara(permisos.EXHIBICIONES_PDI)
            || this.tienePermisoPara(permisos.LECTURA_INSPECCION_SALIDA_PDI)
            || this.tienePermisoPara(permisos.LECTURA_INSPECCION_ENTRADA_PDI)
        ) {
            // Menú general
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Vehículos en Exhibición',
                icon: icPDI,
                children: [],
            };

            if (this.tienePermisoPara(permisos.EXHIBICIONES_PDI)
                || this.tienePermisoPara(permisos.LECTURA_INSPECCION_SALIDA_PDI)
                || this.tienePermisoPara(permisos.LECTURA_INSPECCION_ENTRADA_PDI)
            ) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Exhibiciones PDI',
                    route: '/pdi/solicitud/exhibicion/todos'
                });
            }
            if (this.tienePermisoPara(permisos.NUEVA_EXHIBICION_PDI)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Nueva exhibición PDI',
                    route: '/pdi/solicitud/exhibicion/nuevo'
                });
            }

            if(this.tienePermisoPara(permisos.DATOS_EXHIBICIONES)){
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Datos de exhibición',
                    route: '/pdi/datos-ventas/exhibicion'
                })
            }

            if (menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        // * * * PDI LICITACIONES * * *
        if (this.tienePermisoPara(permisos.LICITACIONES_PDI)
            || this.tienePermisoPara(permisos.LECTURA_INSPECCION_SALIDA_PDI)
            || this.tienePermisoPara(permisos.LECTURA_INSPECCION_ENTRADA_PDI)
        ) {
            // Menú general
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Vehículos en Licitación',
                icon: icPDI,
                children: [],
            };

            if (this.tienePermisoPara(permisos.LICITACIONES_PDI)
                || this.tienePermisoPara(permisos.LECTURA_INSPECCION_SALIDA_PDI)
                || this.tienePermisoPara(permisos.LECTURA_INSPECCION_ENTRADA_PDI)
            ) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Licitaciones PDI',
                    route: '/pdi/solicitud/licitacion/todos'
                });
            }
            if (this.tienePermisoPara(permisos.NUEVA_LICITACION_PDI)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Nueva licitación PDI',
                    route: '/pdi/solicitud/licitacion/nuevo'
                });
            }

            if(this.tienePermisoPara(permisos.DATOS_LICITACIONES)){
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Datos de licitación',
                    route: '/pdi/datos-ventas/licitacion'
                })
            }

            if (menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        //Órdenes taller kanboard
        if(this.tienePermisoPara(permisos.LECTURA_ORDEN_TALLER_KANBOARD) || this.tienePermisoPara(permisos.SUPER_ADMIN)) {

            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Órdenes taller Kanboard',
                icon: icPresupuestosTalleres,
                children: [],
            };

            if(this.tienePermisoPara(permisos.LECTURA_ORDEN_TALLER_KANBOARD)){
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Órdenes de talleres Kanboard',
                    route: '/pdi/orden-taller-kanboard/todos'
                })
            }

            //Configuraciones
            if (this.tienePermisoPara(permisos.SUPER_ADMIN)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Configuración Órdenes Talleres Kanboard',
                    route: '/pdi/configuracion-ordenes-talleres-kanboard'
                });
            }

            if (menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        // * * * Presupuesto * * *
        if (this.usuario && this.usuario && this.usuario.empresa) {
            // Menú general
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Presupuesto talleres',
                icon: icPresupuestosTalleres,
                children: [],
            };
           

            if(this.tienePermisoPara(permisos.VISUALIZAR_CLIENTES_PRESUPUESTO)){
                // Listado unidades
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Listado de clientes',
                    route: '/presupuesto/clientes/todos'
                });
            }
            
            
            if (this.tienePermisoPara(permisos.EDITAR_CLIENTES_PRESUPUESTO)) {
                // Nuevo producto
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Agregar nuevo cliente',
                    route: '/presupuesto/clientes/nuevo'
                });
            }
            
            // Configuracion
            if (this.tienePermisoPara(permisos.CONFIGURACION_PRESUPUESTO)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Configuración',
                    route: '/presupuesto/configuracion'
                });
            }


            // Presupuestos
            if (this.tienePermisoPara(permisos.VISUALIZAR_PRESUPUESTO)) {
                // Listado presupuesto
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Listado de presupuestos',
                    route: '/presupuesto/todos'
                });
            }
            if (this.tienePermisoPara(permisos.EDITAR_PRESUPUESTO)) {
                // Nuevo presupuesto
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Agregar nuevo presupuesto',
                    route: '/presupuesto/nuevo'
                });
            }
         
            if (menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        // * * * Facturación SV * * * 
        // * * * PDI * * *
        if (
            this.tienePermisoPara(permisos.LISTADO_FACTURAS_NUEVOS_USADOS)
        ) {
            // Menú general
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Facturación SV',
                icon: icFacturacionSV,
                children: [],
            };

            if (this.tienePermisoPara(permisos.LISTADO_FACTURAS_NUEVOS_USADOS)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Nuevos y usados',
                    route: '/facturas-sv/nuevos-usados'
                });
            }

            if (this.tienePermisoPara(permisos.LISTADO_FACTURAS_REPUESTOS)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Repuestos',
                    route: '/facturas-sv/repuestos'
                });
            }

            if (this.tienePermisoPara(permisos.LISTADO_FACTURAS_TALLER_SERVICIOS)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Taller y servicios',
                    route: '/facturas-sv/taller-servicios'
                });
            }

            if (this.tienePermisoPara(permisos.LISTADO_FACTURAS_EXPORTACION_VEHICULOS)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Exportación de vehículos',
                    route: '/facturas-sv/exportacion-vehiculos'
                });
            }

            if (this.tienePermisoPara(permisos.LISTADO_FACTURAS_EXPORTACION_REPUESTOS)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Exportación de repuestos',
                    route: '/facturas-sv/exportacion-repuestos'
                });
            }

            if (this.tienePermisoPara(permisos.LISTADO_FACTURAS_AHORRENT)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Ahorrent',
                    route: '/facturas-sv/ahorrent'
                });
            }

            //Configuraciones
            if (this.tienePermisoPara(permisos.SUPER_ADMIN)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Configuración',
                    route: '/facturas-sv/configuraciones'
                });
            }

            if (menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        // * * * SolicitudTraspaso * * *
        if (this.tienePermisoPara(permisos.LECTURA_SOLICITUD_TRASPASO) || this.tienePermisoPara(permisos.EDITAR_SOLICITUD_TRASPASO)
            || this.tienePermisoPara(permisos.CONFIGURACION_TRASPASOS) || this.tienePermisoPara(permisos.AGREGAR_NUEVA_SOLICITUD_TRASPASO)
            || this.tienePermisoPara(permisos.LISTADO_SOLICITUDES_TRASPASOS) || this.tienePermisoPara(permisos.BUZON_DOCUMENTACION_INICIAL)
            || this.tienePermisoPara(permisos.BUZON_ORDEN_COMPRA) || this.tienePermisoPara(permisos.BUZON_DOCUMENTACION_FINAL)
            || this.tienePermisoPara(permisos.BUZON_DOCUMENTACION_COMPLETA) || this.tienePermisoPara(permisos.BUZON_CITA_CLIENTE)) {
            // Menú general
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Solicitud de Traspasos',
                icon: icSolicitudTraspaso,
                children: [],
            };

            if (this.tienePermisoPara(permisos.CONFIGURACION_TRASPASOS)) {
                // Nueva solicitud traspasos
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Configuración',
                    route: '/solicitudTraspaso/configuracion/'
                });
            }

            if (this.tienePermisoPara(permisos.CONFIGURACION_TRASPASOS)) {
                // Nueva solicitud traspasos
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Restricción de Horas de Abogados',
                    route: '/solicitudTraspaso/configuracion/restringirHorasAbogados'
                });
            }

            if (this.tienePermisoPara(permisos.AGREGAR_NUEVA_SOLICITUD_TRASPASO)) {
                // Nueva solicitud traspasos
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Agregar nueva solicitud de traspaso',
                    route: '/solicitudTraspaso/nuevo'
                });
            }

            if(this.tienePermisoPara(permisos.LISTADO_SOLICITUDES_TRASPASOS)){
                // Listado solicitudes traspasos
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Listado de solicitudes de traspasos',
                    route: '/solicitudTraspaso/todos'
                });
            }

            if(this.tienePermisoPara(permisos.BUZON_DOCUMENTACION_INICIAL)){
                // Buzon Documentacion Inicial
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Buzon Documentación Inicial',
                    route: '/solicitudTraspaso/buzon-documentacion-inicial/todos'
                });
            }

            if(this.tienePermisoPara(permisos.BUZON_ORDEN_COMPRA)){
                // Buzon Documentacion Inicial
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Buzon de Orden de Compra Avalúo',
                    route: '/solicitudTraspaso/buzon-orden-compra-avaluo/todos'
                });
            }

            if(this.tienePermisoPara(permisos.BUZON_DOCUMENTACION_FINAL)){
                // Buzon Documentacion Inicial
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Buzon Documentación Final',
                    route: '/solicitudTraspaso/buzon-documentacion-final/todos'
                });
            }

            if(this.tienePermisoPara(permisos.BUZON_DOCUMENTACION_COMPLETA)){
                // Buzon Documentacion Inicial
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Buzon Documentación Completa',
                    route: '/solicitudTraspaso/buzon-documentacion-completa/todos'
                });
            }

            if(this.tienePermisoPara(permisos.BUZON_CITA_CLIENTE)){
                // Buzon Documentacion Inicial
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Buzon Cita Cliente',
                    route: '/solicitudTraspaso/buzon-cita-cliente/todos'
                });
            }

            if(this.tienePermisoPara(permisos.DASHBOARD_SOLICITUD_TRASPASO)){
                // Reporte
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Dashboard Traspasos',
                    route: '/solicitudTraspaso/reportes'
                });
            }

            if(this.tienePermisoPara(permisos.CALENDARIO_SOLICITUD_TRASPASO)){
                // Calendario
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Calendario Traspasos',
                    route: '/solicitudTraspaso/calendario-citas'
                });
            }

            if (menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        // * * * SolicitudTraspaso * * *
        if (this.tienePermisoPara(permisos.CONFIGURACION_BESTUNE) || this.tienePermisoPara(permisos.CATALOGO_BESTUNE)){
            // Menú general
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Catálogo',
                icon: icSolicitudTraspaso,
                children: [],
            };

            if (this.tienePermisoPara(permisos.CONFIGURACION_BESTUNE)) {
                // Nueva solicitud traspasos
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Configuración',
                    route: '/bestune/configuracion/'
                });
            }

            if (this.tienePermisoPara(permisos.CATALOGO_BESTUNE)) {
                // Nueva solicitud traspasos
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Catálogo',
                    route: '/bestune/todos/'
                });
            }

            if (this.tienePermisoPara(permisos.CONFIGURACION_BESTUNE)) {
                // Nueva solicitud traspasos
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Carga masiva repuestos',
                    route: '/bestune/carga-masiva-repuesto/'
                });
            }

            if (this.tienePermisoPara(permisos.CONFIGURACION_BESTUNE)) {
                // Nueva solicitud traspasos
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Carga masiva imágenes',
                    route: '/bestune/carga-masiva-imagenes/'
                });
            }

            if (menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        
        this.navigationService.items = items;
    }

    // * * * Historial de buscador * * *
    agregarHistorialBuscador(rutaCompleta, params) {
        if(rutaCompleta) {
            let partes = rutaCompleta.split('?');
            if(!this.historialBuscador) this.historialBuscador = {};
            this.historialBuscador[partes[0]] = params;
        }
    }

    obtenerParamsRutaHistorial(ruta) {
        if(ruta && this.historialBuscador) {
            return this.historialBuscador[ruta];
        }
    }
}