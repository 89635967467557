import { Repuesto } from "./repuesto";
import { RepuestoVersion } from "./RepuestoVersion";
import { Servicio } from "./servicio";
import { ServicioVersion } from "./ServicioVersion";
import { Version } from "./version";

export interface RepuestoKilometraje {
    id: number; //RepuestoVersion
    RepuestoId: number;
    nombre: string;
    checkbox: boolean;
}

export interface ServicioKilometraje {
    id: number; //ServicioVersion
    ServicioId: number;
    nombre: string;
    checkbox: boolean;
}

export class Kilometraje {
    constructor(
        public id: number,
        public nombre: string,
        public descripcion: number,
        public costo: number,
        public eliminado: boolean,

        //Relaciones
        public VersionId: number,
    ){}

    Version: Version;

    //Auxiliares
    repuestos: RepuestoKilometraje[];
    servicios: ServicioKilometraje[];

    RepuestosVersiones: RepuestoVersion[];
    ServiciosVersiones: ServicioVersion[];
}