import { APIUrls } from "../api/apiUrls";

export class Rol {
    constructor(
        public id: number,
        public nombre: string,
        public tipo: string,
        public descripcion: string,
        public permisos: string,
        public bloqueos: string,
        public mostrar: boolean,
    ){
        this.cargarPermisos();
    }

    apiURLs = new APIUrls();

    cargarPermisos() {
        if (this.permisos) {
            let stringsPermisos = this.permisos.split('|');
            for (let cadenaPermiso of stringsPermisos) {
                this.permisosSeleccionados.push(cadenaPermiso);
            }
        }
    }

    cargarStringPermisos() {
        this.permisos = "";
        for (let i = 0; i < this.permisosSeleccionados.length; i++) {
            if (i + 1 < this.permisosSeleccionados.length) {
                this.permisos += this.permisosSeleccionados[i] + '|';
            } else {
                this.permisos += this.permisosSeleccionados[i];
            }
        }
    }

    public permisosModel = this.apiURLs.permisosModel;

    permisosSeleccionados = [];

    tienePermisoPara(permiso: string) {
        if(!permiso) return true;
        if(this.bloqueos && this.bloqueos.indexOf(permiso) != -1) return false;
        if(this.permisos && this.permisos.indexOf(permiso) != -1) return true;
        return false;
    }
}