import { Empresa } from './empresa';

export class ClientePlanMantenimiento {
    constructor(
        public id: number,
        public nombres: string,        
        public apellidos: string,      
        public nombreCompleto: string, 
        public email: string,          
        public telefono: string,    
        public activo: boolean,         
        public eliminado: boolean,      
        public dpi:string,
        public nit:string,
        //Relaciones
        public EmpresaId: number,
    ){}
    
    Empresa: Empresa;

}