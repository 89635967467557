export class AjusteAvaluo {
    constructor(
        public id: number,
        public porcentajePrimerAnio: number,
        public porcentajeAnioExtra: number,
        public porcentajePrimerAnioVW: number,
        public porcentajeAnioExtraVW: number,
        public iva: number,
        public utilidad: number,
        public kilometrajeAnual: number,
        public porcentajeKilometrajeAnualExtra: number,
        public porcentajeKilometrajeAnualExtraVW: number,
        public cantidadKmAceptadosAnio: number,
        public vobo: string,
        public agencias: string,
        public carrocerias: string,
        public correosAgencia: string,
    ) {
        this.agenciasToArray();
        this.cargarArrays();//Array de correos para abajo
    }

    public agenciasArray: string[] = [];
    public carroceriasArray: any[] = [];
    public correosAgenciaArray: any[] = [];

    public agenciasToArray() {
        if (this.agencias) {
            this.agenciasArray = this.agencias.split(",");
        }
    }

    public agenciasToString() {
        this.agencias = '';
        this.agenciasArray.forEach((value, index) => {
            if (index == this.agenciasArray.length - 1) this.agencias += value;
            else this.agencias += value + ',';
        });
    }

    cargarArrays() {
        this.correosAgenciaArray = this.correosAgencia? JSON.parse(this.correosAgencia) : [];
        this.carroceriasArray = this.carrocerias? JSON.parse(this.carrocerias) : [];
    }

    arraysAString(){
        this.correosAgencia = JSON.stringify(this.correosAgenciaArray);
        this.carrocerias = JSON.stringify(this.carroceriasArray);
    }
}