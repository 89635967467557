import { Empresa } from './empresa';
import { Usuario } from './usuario';
import { Cliente } from './cliente';
import { Venta } from './venta';
import { ContenidoFEIC } from './contenidoFEIC';
import { ListaNegra } from './listaNegra';

export class FormularioFEIC {
    constructor(
        public id:                          number,
        public nombre:                      string,
        public tipo:                        string,
        public contenido:                   string,
        public cerrado:                     boolean,
        public fecha:                       string,
        public total:                       number,
        public impresiones:                 number,
        public actual:                      boolean,
        public observaciones:               string,
        public completado:                  boolean,
        public isDeleted:                   boolean,
        public factorRiesgoProducto:        number,
        public factorRiesgoCliente:         number,
        public factorRiesgoCanal:           number,
        public factorRiesgoUbicacion:       number,
        public factorRiesgoTransaccion:     number,
        public factorRiesgoEstructural:     number,
        public factorRiesgoPromedio:        number,
        public factorRiesgoControl:         number,
        public factorRiesgoResidual:        number,
        public numeroInventario:            string,
        public nombresProductos:            string,
        public motivoEliminarcion:          string,

        //Relaciones
        public ClienteId:           number,
        public VentaId:             number,
        public EmpresaId:           number,
        public ListaNegraId:        number,
        public UsuarioId:           number,
    ){}

    // Objetos
    listasNegras:       ListaNegra[] = [];
    contenidoFEIC:      ContenidoFEIC = new ContenidoFEIC();
    cliente:            Cliente;
    venta:              Venta;
    empresa:            Empresa;
    vendedor:           Usuario;

    // Datos
    nombreFormulario:   string;
    tipoMostrar:        string = '';
    idPrefijo:          string = '';
    nombreVendedor:     string = '';

    // Fechas
    fechaFormato:       string;
    fechaDate:          Date;
    
}