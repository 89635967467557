<div class="row">
    <!-- Nombre -->
    <div class="col">
        <table class="tablaEstilos" *ngIf="preguntasEncuesta.length > 0">
            <thead>
                <tr> 
                    <th>No.</th> 
                    <th>Pregunta</th> 
                    <th>Tipo</th> 
                    <th>Opciones</th> 
                    <th></th> 
                </tr>
            </thead>
            <tbody>
                <!-- Destacar última fila -->
                <tr *ngFor="let element of preguntasEncuesta; let i = index;">
                    <th class="col-xs"> {{i+1}} </th>
                    <!-- Pregunta -->
                    <td class="col-xs">
                        <mat-form-field class="quitarPaddingInput">
                            <mat-label>Pregunta</mat-label>
                            <input
                                matInput
                                class="tableInput" 
                                [(ngModel)]="element.pregunta"
                                [ngModelOptions]="{standalone: true}"
                                [disabled]='modoDetalle || !auth.tienePermisoPara(apiURLs.permisos.EDITAR_CONF_AHORRENT_ENCUESTA_INDIVIDUAL)'
                            >
                        </mat-form-field>
                    </td>
                    <!-- Tipo -->
                    <td class="col-xs">
                        <mat-form-field appearance="fill" class="quitarPaddingInput">
                            <mat-label>Tipo</mat-label>
                            <mat-select [(ngModel)]="element.tipo" name="tipo {{i}}"
                            (selectionChange)="cambiarTipoPregunta(element)" [disabled]="modoDetalle || !auth.tienePermisoPara(apiURLs.permisos.EDITAR_CONF_AHORRENT_ENCUESTA_INDIVIDUAL)"
                            >
                                <mat-option *ngFor="let tp of apiURLs.tipoPregunta" [value]="tp.id">{{tp.nombre}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <!-- Opciones -->
                    <td class="col-xs">
                        <!-- Button para abrir el dialog -->
                        <button mat-raised-button color="primary" *ngIf="element.tipo != apiURLs.tipoPregunta[3].id"
                        (click)="abrirDialogSeleccionesMultiples(element)" 
                        [disabled]="modoDetalle">
                            <mat-icon class="iconoBoton">add</mat-icon>
                            <span>Opciones multiples</span>
                        </button>
                    </td>
                    <!-- Botones -->
                    <td *ngIf="!modoDetalle" class="col-xs" style="text-align: center;">
                        <mat-icon class="iconoBoton" style="cursor:pointer; color: red;" (click)="eliminar(i)" *ngIf="!(modoDetalle || auth.tienePermisoPara(apiURLs.permisos.EDITAR_CONF_AHORRENT_ENCUESTA_INDIVIDUAL))">delete</mat-icon>
                    </td>
                </tr>
            </tbody>
        </table>
        <br />
        <!-- * Botones de pasos * -->
        <div class="row" *ngIf="!modoDetalle">
            <div class="col-md-12" style="text-align: left;">
                <button color="primary" class="margenBoton" mat-raised-button type="button" (click)="agregar()" [disabled]="cargando" *ngIf="auth.tienePermisoPara(apiURLs.permisos.EDITAR_CONF_AHORRENT_ENCUESTA_INDIVIDUAL)">
                    <span>Agregar pregunta</span>
                    <mat-icon class="iconoBoton">add</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>