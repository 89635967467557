export default [
    {
        "id": "16",
        "nombre": "ALTA VERAPAZ"
    },
    {
        "id": "15",
        "nombre": "BAJA VERAPAZ"
    },
    {
        "id": "03",
        "nombre": "CHIMALTENANGO"
    },
    {
        "id": "20",
        "nombre": "CHIQUIMULA"
    },
    {
        "id": "04",
        "nombre": "EL PROGRESO"
    },
    {
        "id": "14",
        "nombre": "EL QUICHÉ"
    },
    {
        "id": "05",
        "nombre": "ESCUINTLA"
    },
    {
        "id": "01",
        "nombre": "GUATEMALA"
    },
    {
        "id": "13",
        "nombre": "HUEHUETENANGO"
    },
    {
        "id": "18",
        "nombre": "IZABAL"
    },
    {
        "id": "21",
        "nombre": "JALAPA"
    },
    {
        "id": "22",
        "nombre": "JUTIAPA"
    },
    {
        "id": "17",
        "nombre": "PETÉN"
    },
    {
        "id": "09",
        "nombre": "QUETZALTENANGO"
    },
    {
        "id": "11",
        "nombre": "RETALHULEU"
    },
    {
        "id": "02",
        "nombre": "SACATEPÉQUEZ"
    },
    {
        "id": "12",
        "nombre": "SAN MARCOS"
    },
    {
        "id": "06",
        "nombre": "SANTA ROSA"
    },
    {
        "id": "07",
        "nombre": "SOLOLÁ"
    },
    {
        "id": "10",
        "nombre": "SUCHITEPÉQUEZ"
    },
    {
        "id": "08",
        "nombre": "TOTONICAPÁN"
    },
    {
        "id": "19",
        "nombre": "ZACAPA"
    }
];