import { MarcaAhorrent } from "./marcaAhorrent";

export class ModeloAhorrent{ 
    public anio: string;
    public precioLista: number;
}

export class LineaAhorrent{
    constructor(
        public id: number,
        public nombre: string,
        public descripcion: string,
        public modelos: string,
        public usarTransparenciaPrecios: boolean,
        public isDeleted: boolean,
        public imagen: string,
    ){}

    public listadoModelos: ModeloAhorrent[] = [];

    
    public listadoModelosString() {
        this.modelos = JSON.stringify(this.listadoModelos);
    }

    public vehiculo: MarcaAhorrent;
}