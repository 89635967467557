import { Empresa } from './empresa';

export class ClientePresupuesto {
    constructor(
        public id: number,
        public nombres: string,        
        public apellidos: string,      
        public nombreCompleto: string, 
        public dpi:string,
        public email: string, 
        public telefono: string,         
        public activo: boolean,         
        public eliminado: boolean,      
        public nit: string,
       
        //Relaciones
        public EmpresaId: number,
    ){}
    
    nombreCompletoNit: string; 
    nombreCompletoNitDireccion: string;
    Empresa: Empresa;
    // Fechas
    fechaNacimientoFormato: string;
    fechaNacimientoDate: Date;

}