export default [
    { "id": "ATLANTIDA", "nombre": "ATLANTIDA" },
    { "id": "COLON", "nombre": "COLON" },
    { "id": "COMAYAGUA", "nombre": "COMAYAGUA" },
    { "id": "COPAN", "nombre": "COPAN" },
    { "id": "CORTES", "nombre": "CORTES" },
    { "id": "CHOLUTECA", "nombre": "CHOLUTECA" },
    { "id": "EL PARAISO", "nombre": "EL PARAISO" },
    { "id": "FRANCISCO MORAZAN", "nombre": "FRANCISCO MORAZAN" },
    { "id": "GRACIAS A DIOS", "nombre": "GRACIAS A DIOS" },
    { "id": "INTIBUCA", "nombre": "INTIBUCA" },
    { "id": "ISLAS DE LA BAHIA", "nombre": "ISLAS DE LA BAHIA" },
    { "id": "LA PAZ", "nombre": "LA PAZ" },
    { "id": "LEMPIRA", "nombre": "LEMPIRA" },
    { "id": "OCOTEPEQUE", "nombre": "OCOTEPEQUE" },
    { "id": "OLANCHO", "nombre": "OLANCHO" },
    { "id": "SANTA BARBARA", "nombre": "SANTA BARBARA" },
    { "id": "VALLE", "nombre": "VALLE" },
    { "id": "YORO", "nombre": "YORO" }
];