<div class="row">
    <div class="col-md-12">
        <h3>{{data.titulo}}</h3>
        <div *ngIf="data.mensaje" [innerHTML]="data.mensaje"></div>
    </div>
</div>
<br/>
<div class="row">
    <div class="col-md-12" style="text-align: right;">
        <button mat-stroked-button (click)="cerrar()" class="margenBoton" style="margin-right: 7px;">
            {{data.cerrarBoton ? data.cerrarBoton : 'Cerrar'}}
        </button>
        <button mat-raised-button color="primary" (click)="confirmar()">
            {{data.okBoton ? data.okBoton : 'Aceptar'}}
        </button>
    </div>
</div>