import * as FileSaver from 'file-saver';
import { Injectable } from '@angular/core';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { JSONConverters } from '../models/JSONConverters';
import { ConfiguracionRequisicion } from '../models/ConfiguracionRequisicion';
import { Requisicion } from '../models/Requisicion';

@Injectable()
export class RequisicionesService { 
    apiUrls: APIUrls = new  APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
    ) { }

    // * * * Configuraciones * * *
    public async obtenerConfiguracion(EmpresaId:number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.configuracionRequisicionURL + `?&EmpresaId=${EmpresaId}`;
            const res = await this.connection.getRequest(url);
            var registro = this.jsonConverters.configuracionRequisicionDeJSON(res.result);
            return { error: null, data: { result: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);   
        }
    }

    public async actualizarConfiguracion(registro) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.configuracionRequisicionURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Configuración actualizada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * Requsiciones * * *
    public async obtenerTodosRequisiciones() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.requisicionesURL + '?';
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                var element = json[i];
                var registro = this.jsonConverters.requisicionDeJSON(element);
                registros.push(registro);
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async obtenerRequisicionPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.requisicionesURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.requisicionDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearRequisicion(registro: Requisicion, estado) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.requisicionesURL;
            const res = await this.connection.postRequest(url, {registro, estado});
            return { error: null, data: { mensaje: 'Requisición creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarRequisicion(registro: Requisicion, estado) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.requisicionesURL;
            const res = await this.connection.putRequest(url, {registro, estado});
            return { error: null, data: { mensaje: res.message, result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarRequisicion(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.requisicionesURL + '/id/' + id;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Requisición eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Forzar Cambio de Estado
    public async forzarCambioEstadoRequisicion(registro: Requisicion) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.requisicionesURL + '/cambioEstado';
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: res.message, result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Regresar a un estado anterior
    public async updateEstadoRequisicion(registro: Requisicion, observaciones: string, estado: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.requisicionesURL + '/regresarEstadoAnterior';
            const res = await this.connection.putRequest(url, {registro, observaciones, estado});
            return { error: null, data: { mensaje: res.message, result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Cancelar Requisicion
    public async cancelarRequisicion(registro: Requisicion) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.requisicionesURL + '/cancelarPedido';
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: res.message, result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Descargar PDF
    async descargarRequisicionPDF(RequisicionId: number, fileName: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.requisicionesURL + `/descargar/${RequisicionId}`;
            var res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var fileName = `${fileName}.pdf`;
            var mediaType = 'application/pdf';
            var blob = new Blob([res], {
                type: mediaType
            });
            FileSaver.saveAs(blob, fileName);

            return {
                error: null,
                data: {
                    mensaje: 'Requisicion descargado con éxito.'
                }
            };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Enviar correos
    async enviarCorreo(correos: string, requisicionId: number) {
        try {
            let url = this.apiUrls.baseURL + this.apiUrls.requisicionesURL + `/enviarCorreos`;

            let enviar = {
                correos: correos,
                RequisicionId: requisicionId
            };

            const res = await this.connection.postRequest(url, enviar);
            return { error: null, data: { result: res, mensaje: 'Correos enviados con éxito' } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Descargar Excel
    public async descargarExcelRequisicion(nombre: string, params:string) {
        try {
            // Construir ruta
            var url = this.apiUrls.baseURL + this.apiUrls.requisicionesURL + '/descargarExcel';
            if(params) url+= '?'+ params;
            var response = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `${nombre}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([ response ], { type: mediaType });
            FileSaver.saveAs(blob, filename);
            return response;
        } catch (error) {
            return null;
        }
    }

    // Reporte de Solicitud de Traspaso
    async obtenerReporte(fechaInicioParam: any, fechaFinParam: any, params = '') {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.requisicionesURL + '/reporte?';
            if (fechaInicioParam) url += '&start=' + fechaInicioParam;
                if (fechaFinParam) url += '&finish=' + fechaFinParam;
            if (params) url += params;
            var json = await this.connection.getRequest(url);
            var registro = json;
            return { error: null, data: { registro: registro.data } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * Detalles de Requisiciones * * *
    // Obtenemos los detalles por RequisicionId
    public async obtenerDetallesRequisicionPorRequisicionId(RequisicionId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.detallesRequisicionURL + `?RequisicionId=${RequisicionId}`;
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                var element = json[i];
                var registro = this.jsonConverters.detalleRequisicionDeJSON(element);
                registros.push(registro);
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }
}