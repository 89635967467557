import * as FileSaver from 'file-saver';
import { Injectable } from '@angular/core';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { JSONConverters } from '../models/JSONConverters';
import { ConfiguracionBestune } from '../models/ConfiguracionBestune';
import { RepuestoBestune } from '../models/RepuestoBestune';
import { AuthService } from '../auth/auth.service';

import * as Papa from 'papaparse';
import * as languageEncoding from 'detect-file-encoding-and-language';

@Injectable()
export class BestuneService { 
    apiUrls: APIUrls = new  APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
        public auth: AuthService,
    ) { 
        //Papa Promise
        Papa.parsePromise = async function (file) {
            let infoFile = function (file: File) {
                return new Promise((resolve, reject) => {
                    languageEncoding(file).then((fileInfo) => resolve(fileInfo));
                });
            } 

            // Obtener resultados del CSV
            let info: any = await infoFile(file);

            return new Promise(function (complete, error) {
                Papa.parse(file, {
                    header: true,
                    skipEmptyLines: true,
                    encoding: info.encoding,
                    complete,
                    error
                });
            });
        };
    }

    // * * * Configuraciones * * *
    public async obtenerConfiguracion(EmpresaId:number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.configuracionBestuneURL + `?&EmpresaId=${EmpresaId}`;
            const res = await this.connection.getRequest(url);
            var registro = this.jsonConverters.configuracionBestuneDeJSON(res.result);
            return { error: null, data: { result: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);   
        }
    }

    public async actualizarConfiguracion(registro) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.configuracionBestuneURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Configuración actualizada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * Carga Masiva * * *
    // * * * * * Generar plantilla * * * * *
    public async generarPlantillaVersiones(nombreArchivo: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.repuestosBestuneURL + '/generacion-plantilla';
            var res = await this.connection.getDownloadRequest(url);
            
            // Descargar archivo
            var filename = `${nombreArchivo}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Plantilla descargada con éxito' } };

        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * * * Lectura del archivo Excel * * * * *
    async lecturaArchivoVersiones(archivo, columnas, modelo ) {
        try {
            // Obtener resultados del Excel
            var resArchivo = await Papa.parsePromise(archivo);
            if(!resArchivo || !resArchivo.data) throw new Error('Ha ocurrido un error al leer los datos del archivo');
            var resNormalizacion = this.libraryService.limpiarColumnasResultadosExcel(resArchivo.data, columnas);
            if(!resNormalizacion) throw new Error('Ha ocurrido un error al preparar los datos del archivo Excel');
            var errores = resNormalizacion.errores;
            var resultados = resNormalizacion.resultados;
            // Preparar resultados según modelo
            var trim = this.libraryService.trimString;

            var EmpresaId = this.auth.idEmpresaActual();

            var resultadosFinales:any[] = [];
            resultados.forEach(element => {
                let usuario = new RepuestoBestune (
                    null,
                    trim(element['num']),
                    trim(element['descripcion de parte']),
                    trim(element['descripcion de parte (español)']),
                    trim(element['codigo de parte']),
                    trim(element['codigo retorno']),
                    parseInt(trim(element['qty'])),
                    trim(element['codigo de imagen']),
                    trim(element['codigo de ensamblaje']),
                    trim(element['codigo de grupo']),
                    modelo ? modelo : '',
                    EmpresaId,
                );

                resultadosFinales.push(usuario);
            })
            
            return {
                error: false,
                errores: errores,
                resultados: resultadosFinales,
                mensaje: 'Carga de archivo exitosa',
            };
        } catch (error) {
            return { error: true, mensaje: error.message };
        }

    }

    async analizarRegistrosRepuestosBestune(registros: RepuestoBestune[], modelo: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.repuestosBestuneURL + '/analisis-registros';
            var data = {
                registros: registros,
                modelo: modelo,
            }
            const res = await this.connection.postRequest(url, data);
            var registrosNuevos = res.registrosNuevos;
            var registrosEditados = res.registrosEditados;
            var registrosAIgnorar = res.registrosIgnorados;
            var errores = res.errores;
            return { error: null, data: {
                mensaje: 'Análisis de importación exitosa.',
                registrosNuevos: registrosNuevos,
                registrosEditados: registrosEditados,
                registrosAIgnorar: registrosAIgnorar,
                errores: errores,
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    subirRegistrosRepuestosBestune(registros: RepuestoBestune[], correo: string, modelo: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.repuestosBestuneURL + '/carga-repuestos';

            var data = {
                registros: registros,
                correo: correo,
                modelo: modelo,
            }
            this.connection.postRequest(url, data);

            return { error: null, data: {
                mensaje: 'Se le notificará cuando los registros hayan sido subidos. Por favor cierre esta ventana.',
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * * * * Archivos * * * * * *
    async guardarArchivoEnServidor(archivo: File, basicAuth: boolean = false) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.repuestosBestuneURL + '/uploadArchivo';
            if(basicAuth) url += 'Abierto';
            const res = await this.connection.uploadFile(url, archivo, !basicAuth);
            if (!res.error) {
                return { error: null, data: { url: res.url } };
            }
            else throw new Error();
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al cargar el archivo.');
        }
    }

    async obtenerImagenesBestune() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.repuestosBestuneURL + '/listar-archivos';
            const res = await this.connection.postRequest(url,
                {
                    dirPath: this.apiUrls.baseBestuneURLGT,
                }
            );

            let imagenes = res.map(imagen => {
                return {
                    nombre: imagen,
                    url: this.apiUrls.baseBestuneURLGT + imagen,
                    seleccionado: false,
                }
            });

            return { error: null, data: imagenes };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las imagenes.');
        }
    }

    async eliminarImagenesBestune(imagenes: string[]) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.repuestosBestuneURL + '/eliminar-archivos';
            const res = await this.connection.postRequest(url, 
                { 
                    dirPath: this.apiUrls.baseBestuneURLGT,
                    fileNames: imagenes 
                }
            );
            return { error: null, data: res };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al eliminar las imagenes.');
        }
    }

    public async actualizarRepuesto(registro: RepuestoBestune) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.repuestosBestuneURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Repuesto actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async obtenerTodosRepuestos() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.repuestosBestuneURL + '?';
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                var element = json[i];
                var registro = this.jsonConverters.repuestoBestuneDeJSON(element);
                registros.push(registro);
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }
}