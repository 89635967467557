<div class="panel-firma">
    <h4>{{titulo}}</h4>
    <mat-radio-group *ngIf="!imagen" [(ngModel)]="tipoFirma" [ngModelOptions]="{standalone: true}">
        <mat-radio-button [value]="'dibujo'" style="margin-left: 16px;">Dibujar firma</mat-radio-button>
        <mat-radio-button [value]="'archivo'" style="margin-left: 16px;">Subir imagen</mat-radio-button>
    </mat-radio-group>
    <!-- Dibujar firma -->
    <ng-container *ngIf="tipoFirma == 'dibujo' && !imagen">
        <signature-pad 
            #signature 
            class="panel-blanco" 
            [options]="signaturePadOptions" 
            (drawStart)="drawStart($event)" 
            (drawEnd)="drawComplete($event)">
        </signature-pad>
        <div class="container containerBotones">
            <button (click)="clearSignature()" *ngIf="!guardado" color="primary" mat-raised-button type="button" class="margenBoton">
                Limpiar
            </button>
            <button (click)="guardarFirmaClick()" *ngIf="!guardado" color="primary" mat-raised-button type="button" class="margenBoton">
                Guardar
            </button>
            <div *ngIf="guardado" style="color: rgb(171, 212, 157); font-style: italic;">Firma guardada</div>
        </div>
    </ng-container>
    <!-- Subir imagen -->
    <ng-container *ngIf="tipoFirma == 'archivo' && !imagen">
        <br/>
        <input id="file-input-archivo" name="file-input-archivo" type="file"
            (change)="seleccionarDocumentoResultado($event)" accept=".png, .jpg, .jpeg"
            style="display: none;">
        <button color="primary" mat-raised-button type="button"
            (click)="activarSeleccionDocumentoResultado()" [disabled]="cargando">
            <span>Subir imagen</span>
            <mat-icon class="iconoBoton">upgrade</mat-icon>
        </button>
        <div *ngIf="guardado" style="color: rgb(171, 212, 157); font-style: italic;">Firma guardada</div>
    </ng-container>
    <div *ngIf="imagen">
        <img [src]="imagen" style="max-width: 80%; height: auto;">
    </div>
    <div class="container containerBotones" *ngIf="imagen">
        <button (click)="reiniciarFirma()" *ngIf="!guardado" color="primary" mat-raised-button type="button" class="margenBoton">
            Limpiar
        </button>
        <div *ngIf="guardado" style="color: rgb(171, 212, 157); font-style: italic;">Firma guardada</div>
    </div>
</div>