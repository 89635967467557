import { Contratacion } from './Contratacion';
import { Unidad } from './unidad';
import { Usuario } from './usuario';

export class DocumentoUnidad {
    constructor(
        public id: number,
        public descripcion: string,
        public fechaSubida: string,
        public url: string,
        //Relaciones
        public UsuarioId: number,
        public UnidadId: number,
    ){}
    
    Usuario: Usuario;

    Unidad: Unidad;

    fechaSubidaDate: Date
    fechaSubidaFormato: string
}