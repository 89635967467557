import { SolicitudTraspaso } from './solicitudTraspaso';
import { Usuario } from './usuario';

export class HistorialFirmaPromesa {
    constructor(
        public id:                          number,
        public fechaCreacion:               string,
        public fechaFirmaPromesa:           string,
        public horaFirmaPromesa:            string,

        // Relaciones
        public SolicitudTraspasoId:         number,
        public UsuarioId:                   number,
    ){}

    solicitudTraspaso:  SolicitudTraspaso;
    usuario:            Usuario;

    // Fechas
    fechaCreacionFormato:       string;
    fechaCreacionDate:          Date;
    fechaFirmaPromesaFormato:   string;
    fechaFirmaPromesaDate:      Date;
}