import { Empresa } from './empresa';
import { Usuario } from './usuario';
import { FormularioFEIC } from './formularioFEIC';

export class Venta {
    constructor(
        public id:                  number,
        public fecha:               string,
        public total:               number,
        public observaciones:       string,
        public isDeleted:           boolean,
        public numerosInventario:   string,
        public nitsClientes:        string,
        public nombresProductos:    string,
        public VendedorId:          number,
        public EmpresaId:           number,
        public nombre:              string,
        public UsuarioId:           number,
    ){}

    formulariosFEIC:    FormularioFEIC[];
    vendedor:           Usuario;
    empresa:            Empresa;
    nombreVendedor:     string = '';

    // Fechas
    fechaFormato:       string;
    fechaDate:          Date;
}