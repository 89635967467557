import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from './layout/layout.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

@NgModule({
	imports: [
		CommonModule,
		LayoutModule
	],
	exports: [
		LayoutModule
	],
	providers: [{
		provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
		useValue: {
			appearance: 'fill'
		}
	}]
})
export class VexModule {}
