import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SignaturePadComponent } from '@almothafar/angular-signature-pad';
import { UsuariosService } from 'src/app/usuarios/usuarios.service';
import { LibraryService } from '../library.service';

@Component({
  selector: 'vex-panel-firma',
  templateUrl: './panel-firma.component.html',
  styleUrls: ['./panel-firma.component.scss']
})
export class PanelFirmaComponent implements OnInit {

    @Input() titulo: string = 'Firma';
    @Input() width: number = 300;
    @Input() height: number = 150;
    @Input() imagen: string;
    @Output() guardarFirma: EventEmitter<any> = new EventEmitter();
    @Output() subirArchivo: EventEmitter<any> = new EventEmitter();

    @ViewChild('signature')
    public signaturePad: SignaturePadComponent;
    guardado: boolean = false;
    cargando: boolean = false;
    tipoFirma: string = 'dibujo';
    imagenSeleccionada: string = '';

    //SignaturePad
    signaturePadOptions: any = {
        minWidth: 1,
        maxWidth: 1,
        canvasWidth: this.width,
        canvasHeight: this.height,
    };

    constructor(
        private service: UsuariosService,
        private libraryService: LibraryService,
    ) { }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        // this.signaturePad is now available
        this.signaturePad.set('minWidth', 5); // set szimek/signature_pad options at runtime
        this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
    }

    //SignaturePad

    drawComplete(event: MouseEvent | Touch) {
        // will be notified of szimek/signature_pad's onEnd event
    }
    
    drawStart(event: MouseEvent | Touch) {
        // will be notified of szimek/signature_pad's onBegin event
    }

    reiniciarFirma() {
        this.guardado = false;
        this.imagen = null;
    }

    clearSignature() {
        this.signaturePad.clear();
    }

    guardarFirmaClick() {
        this.guardarFirma.emit(this.signaturePad.toDataURL());
        this.guardado = true;
    }

    // * * * * * Imagen * * * * * 
    async crearYSubirDocumento(archivo, rutaArchivo, extension) {
        this.cargando = true;
        if (archivo && rutaArchivo && extension) {
            var res = await this.service.guardarArchivoEnServidorUsuarios(archivo);
            if (!res.error) {
                this.imagenSeleccionada = res.data.url;
            } else {
                this.libraryService.crearNotificacion('Ha ocurrido un error al subir su documento al servidor.', 'danger');
            }
        }
        this.cargando = false;
    }

    async seleccionarDocumentoResultado(event: any) {
        let fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            let file: File = fileList[0];
            var rutaArchivo = file.name;
            var auxExtension = rutaArchivo.split(".");
            var extension = auxExtension[auxExtension.length - 1];
            var archivo = file;
            this.subirArchivo.emit(archivo);
            this.guardado = true;
            // await this.crearYSubirDocumento(archivo, rutaArchivo, extension);
        }
    }

    descargarArchivoImagen() {
        if (this.imagenSeleccionada) {
            window.open(this.imagenSeleccionada, "_blank");
        }
    }
    
    activarSeleccionDocumentoResultado() {
        document.getElementById('file-input-archivo').click();
    }
}
