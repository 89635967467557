import { Injectable } from '@angular/core';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { JSONConverters } from '../models/JSONConverters';
import { VehiculoCampania } from '../models/VehiculoCampania';
import * as FileSaver from 'file-saver';
import * as Papa from 'papaparse';
import { Cita } from '../models/cita';

@Injectable()
export class LaningPageService { 
    apiUrls: APIUrls = new  APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
    ) {
        //Papa Promise
        Papa.parsePromise = function (file) {
            return new Promise(function (complete, error) {
                Papa.parse(file, {
                    header: true,
                    skipEmptyLines: true,
                    complete,
                    error
                });
            });
        };

    }

     // Crear
    public async crearReservacionCampania(registro: Cita) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.reservacionesCampaniasURL + '/public';
            const res = await this.connection.postRequest(url, registro, false);
            return { error: null, data: { mensaje: 'Reservación Campaña creada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * Vehiculos * * *
    async obtenerTodosVehiculoCampania(chasis: string = null, placa: string = null) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.vehiculosCampaniasURL + '/public';
            if(chasis) url += `?&chasis=${chasis}&placa=${placa}`;
            var json = await this.connection.getRequest(url, false);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                var element = json[i];
                var registro = this.jsonConverters.vehiculoCampaniaDeJSON(element);
                registros.push(registro);
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }


}