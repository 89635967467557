import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/auth.service';

export interface DialogData {
    titulo: string,
    mensaje: string,
    payload: any,
    opciones: any[],
    mostrarObservaciones: boolean,
}


@Component({
  selector: 'vex-generic-dialog-confirmacion-opciones',
  templateUrl: './generic-dialog-confirmacion-opciones.component.html'
})
export class GenericDialogConfirmacionOpciones {

    mostrarObservaciones: boolean;
    observaciones: string;

    constructor(
        public dialogRef: MatDialogRef<GenericDialogConfirmacionOpciones>,
        public auth: AuthService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {
        if (this.data.mostrarObservaciones) this.mostrarObservaciones = true;
    }

    cerrar() {
        this.dialogRef.close(null);
    }

    confirmar(id: string) {
        this.dialogRef.close(this.mostrarObservaciones ? { result: id, observaciones: this.observaciones } : id);
    }

}
