import { ClientePresupuesto } from './ClientePresupuesto';
import { Empresa } from './empresa';
import { PuntoPresupuesto } from './PuntoPresupuesto';
import { Usuario } from './usuario';

export class Presupuesto {
    constructor(
        public id:                      number,
        public fecha:                   string,
        public numeroOrdenIntegra:      string,
        public asesor:                  string,
        public numeroPlaca:             string,
        public tipoVehiculo:            string,
        public LineaVehiculo:                   string,
        public anioVehiculo:            string,
        public kilometraje:             string,
        public supervisor:              string,
        public mecanico:                string,
        public sede:                    string,
        public totalManoObra:           number,
        public totalDescuentoManoObra:  number,
        public totalRepuestos:          number,
        public totalDescuentoRepuestos: number,
        public total:                   number,
        public estado:                  string,
        //fechaInicioSeccion1
        public fechaInicioSeccion1:     string,
        public fechaFinSeccion1:        string,
        public fechaEnvioNotificacionSeccion1: string,
        //fechaInicioSeccion2
        public fechaInicioSeccion2:     string,
        public fechaFinSeccion2:        string,
        public fechaEnvioNotificacionSeccion2: string,
        //fechaInicioSeccion3
        public fechaInicioSeccion3:     string,
        public fechaFinSeccion3:        string,
        public fechaEnvioNotificacionSeccion3: string,
        //fechaInicioSeccion4
        public fechaInicioSeccion4:     string,
        public fechaFinSeccion4:        string,
        //fechaInicioSeccion6
        public fechaInicioSeccion6:     string,
        public fechaFinSeccion6:        string,
        //fechaInicioSeccion7
        public fechaInicioSeccion7:     string,
        public fechaFinSeccion7:        string,

        public observaciones:           string,
        public numeroPresupuestoIntegra:string,
        public validarPresupuesto:      boolean,
        public mostrarItemRechazados:   boolean,

        // Relaciones
        public ClientePresupuestoId: number,
        public EmpresaId: number,
        public UsuarioId: number,
    ){}

    Empresa: Empresa;
    ClientePresupuesto: ClientePresupuesto;
    Usuario: Usuario;

    fechaFormato: string;
    fechaDate: Date;

    //Tiempo Secciones
    fechaInicioSeccion1Formato: string;
    fechaFinSeccion1Formato: string;
    fechaEnvioNotificacionSeccion1Formato: string;
    fechaInicioSeccion1Date: Date;
    fechaFinSeccion1Date: Date;
    fechaEnvioNotificacionSeccion1Date: Date;

    fechaInicioSeccion2Formato: string;
    fechaFinSeccion2Formato: string;
    fechaEnvioNotificacionSeccion2Formato: string;
    fechaInicioSeccion2Date: Date;
    fechaFinSeccion2Date: Date;
    fechaEnvioNotificacionSeccion2Date: Date;

    fechaInicioSeccion3Formato: string;
    fechaFinSeccion3Formato: string;
    fechaEnvioNotificacionSeccion3Formato: string;
    fechaInicioSeccion3Date: Date;
    fechaFinSeccion3Date: Date;
    fechaEnvioNotificacionSeccion3Date: Date;

    fechaInicioSeccion4Formato: string;
    fechaFinSeccion4Formato: string;
    fechaInicioSeccion4Date: Date;
    fechaFinSeccion4Date: Date;

    fechaInicioSeccion6Formato: string;
    fechaFinSeccion6Formato: string;
    fechaInicioSeccion6Date: Date;
    fechaFinSeccion6Date: Date;

    fechaInicioSeccion7Formato: string;
    fechaFinSeccion7Formato: string;
    fechaInicioSeccion7Date: Date;
    fechaFinSeccion7Date: Date;

    puntosPresupuesto: PuntoPresupuesto[] = []

    correos: any[] = []

}