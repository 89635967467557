<div class="toolbar w-full px-gutter" fxLayout="row" fxLayoutAlign="start center" vexContainer>
    <button (click)="openSidenav()" [fxHide]="!mobileQuery" mat-icon-button type="button">
        <mat-icon [icIcon]="icMenu"></mat-icon>
    </button>

    <a *ngIf="isVerticalLayout$ | async" [routerLink]="['/']" class="ltr:mr-4 rtl:ml-4 block" fxLayout="row"
        fxLayoutAlign="start center">
        <img class="w-8 select-none" src="assets/img/demo/logo.png">
        <h1 [fxHide]="mobileQuery" class="title ltr:pl-4 rtl:pr-4 m-0 select-none">VEX</h1>
    </a>

    <!-- Add new -->
    <!--
        <button *ngIf="isHorizontalLayout$ | async" [fxHide]="mobileQuery" [matMenuTriggerFor]="addNewMenu" color="primary"
            mat-raised-button type="button">ADD NEW
        </button>
    -->

    <mat-menu #addNewMenu="matMenu" [overlapTrigger]="false" xPosition="after" yPosition="below">
        <button mat-menu-item>
            <mat-icon [icIcon]="icPersonAdd"></mat-icon>
            <span>Add Contact</span>
        </button>

        <button mat-menu-item>
            <mat-icon [icIcon]="icDoneAll"></mat-icon>
            <span>Add Task</span>
        </button>

        <button mat-menu-item>
            <mat-icon [icIcon]="icAssignmentTurnedIn"></mat-icon>
            <span>Add Project</span>
        </button>

        <button [matMenuTriggerFor]="documentMenu" mat-menu-item>
            <mat-icon [icIcon]="icBallot"></mat-icon>
            <span>Add Document</span>
        </button>
    </mat-menu>

    <mat-menu #documentMenu="matMenu">
        <button mat-menu-item>
            <mat-icon [icIcon]="icDescription"></mat-icon>
            <span>Add Quote</span>
        </button>

        <button mat-menu-item>
            <mat-icon [icIcon]="icAssignment"></mat-icon>
            <span>Add Invoice</span>
        </button>

        <button mat-menu-item>
            <mat-icon [icIcon]="icReceipt"></mat-icon>
            <span>Add Receipt</span>
        </button>
    </mat-menu>

    <!-- Mega Menu -->
    <!--
        <div #megaMenuOriginRef class="ltr:ml-2 rtl:mr-2">
            <button *ngIf="isHorizontalLayout$ | async" [fxHide]="mobileQuery" (click)="openMegaMenu(megaMenuOriginRef)"
            color="primary" mat-button type="button">
                MEGA MENU
                    <ic-icon [icon]="icArrowDropDown" class="ltr:-mr-1 rtl:-ml-1" inline="true"></ic-icon>
            </button>
        </div>
    -->


    <div *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async" [fxHide]="mobileQuery" class="px-gutter"
        fxFlex="none" fxLayout="row" fxLayoutAlign="start center">
        <vex-navigation-item *ngFor="let item of navigationItems" [item]="item"></vex-navigation-item>
    </div>

    <span fxFlex></span>

    <div class="-mx-1 flex items-center">
        <!-- Search -->
        <!--
            <div class="px-1">
                <button (click)="openSearch()" mat-icon-button type="button">
                    <mat-icon [icIcon]="icSearch"></mat-icon>
                </button>
            </div>
        -->
        <!--
            <div class="px-1">
                <vex-toolbar-notifications></vex-toolbar-notifications>
            </div>
        -->

        <!-- Quickpanel -->
        <!--
            <div class="px-1">
                <button (click)="openQuickpanel()" mat-icon-button type="button">
                    <mat-icon [icIcon]="icBookmarks"></mat-icon>
                </button>
            </div>
        -->

        <!-- Cambio de empresa -->
        <div class="px-1" *ngIf="auth.usuario && auth.usuario.empresas && auth.usuario.empresas.length > 1">
            <div #originRef [matMenuTriggerFor]="languageMenu" class="flex items-center rounded cursor-pointer relative trans-ease-out select-none py-1 pr-1 pl-3 hover:bg-hover" matRipple>
                <div class="body-1 font-medium leading-snug ltr:mr-3 rtl:ml-3" fxHide.xs>{{ auth.usuario && auth.usuario.empresa ? auth.usuario.empresa.nombre : 'Ingresar' }}</div>
                <div class="rounded-full h-9 w-9 flex items-center justify-center text-primary bg-primary-light">
                    <mat-icon [icIcon]="icTienda"></mat-icon>
                </div>
            </div>
        </div>

        <div class="px-1">
            <vex-toolbar-user></vex-toolbar-user>
        </div>

        <!-- Empresas -->
        <mat-menu #languageMenu="matMenu" overlapTrigger="false" xPosition="before" yPosition="below">
            <button mat-menu-item *ngFor="let empresa of auth.usuario.empresas" (click)="auth.cambiarLoginEmpresa(empresa.id, true)">
                <span>{{empresa.nombre}}</span>
            </button>
        </mat-menu>
    </div>
</div>

<vex-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async" [fxHide]="mobileQuery">
</vex-navigation>