import { Manual } from './../models/manual';
import { Injectable } from '@angular/core';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { JSONConverters } from '../models/JSONConverters';

@Injectable()
export class ManualService { 
    apiUrls: APIUrls = new  APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
    ) {
    }

    // * * * Manual * * * 
    async obtenerManual() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.empresasURL + '/readManual';
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.manualDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async actualizarManual(registro: Manual) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.empresasURL + '/updateManual';
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Manual actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

}