import { ContenidoMatriz } from './contenidoMatriz';

export class AjusteGeneral {
    constructor(
        public id:                          number,
        public ajustesMatrizRiesgo:         string,
        public EmpresaId:                   number,
    ){}

    contenido: ContenidoMatriz = new ContenidoMatriz();
}