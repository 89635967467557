import { LineaAhorrent } from './lineaAhorrent';
import { MarcaAhorrent } from './marcaAhorrent';
import { Unidad } from './unidad';
import { Usuario } from './usuario';

export class LavadoEspecial {
    constructor(
        public id: number,
        public fecha: string,
        public fechaAceptado: string,
        public aprobado: boolean,
        public lavado: boolean,
        public observaciones: string,
        public lavador: string,
        public sucursal: string,
        public color: string,
        public placa: string,
        public tipoVehiculo: string,
        public EmpresaId: number,
        public UsuarioId: number,
        public UnidadId: number,
        public MarcaId: number,
        public LineaId: number,
    ){}
    
    unidad: Unidad;
    usuario: Usuario;
    fechaDate: Date;
    fechaFormato: string;
    fechaAceptadoDate: Date;
    fechaAceptadoFormato: string;
    lavadorJSON: any;
    nombreUnidad: string;

    Marca: MarcaAhorrent;
    Linea: LineaAhorrent;
}