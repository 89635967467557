import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { LibraryService } from 'src/app/library/library.service';
import iconSave from '@iconify/icons-ic/twotone-save';
import iconBack from '@iconify/icons-ic/twotone-arrow-back';
import iconDownload from '@iconify/icons-ic/twotone-cloud-download';
import iconUpload from '@iconify/icons-ic/twotone-file-upload';
import iconDelete from '@iconify/icons-ic/twotone-delete';
import iconClose from '@iconify/icons-ic/twotone-close';
import iconCheck from '@iconify/icons-ic/twotone-check';
import iconPlus from '@iconify/icons-ic/twotone-plus';
import iconEdit from '@iconify/icons-ic/twotone-edit';

export interface DialogData {
    urlDocumentos: string[],
    titulo: string,
}

@Component({
    selector: 'vex-dialog-carrousel-imagenes',
    templateUrl: './dialog-carrousel-imagenes.component.html',
    styleUrls: ['./dialog-carrousel-imagenes.component.scss']
})
export class DialogCarrouselImagenesComponent implements OnInit {
    iconSave = iconSave;
    iconBack = iconBack;
    iconDownload = iconDownload;
    iconUpload = iconUpload;
    iconDelete = iconDelete;
    iconClose = iconClose;
    iconCheck = iconCheck;
    iconPlus = iconPlus;
    iconEdit = iconEdit;
    archivoCargado: boolean = false;
    cargando: boolean = false;
    
    currentImageIndex: number = 0;
    imageUrls: string[];

    constructor(
        public dialogRef: MatDialogRef<DialogCarrouselImagenesComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private libraryService: LibraryService,
        private router: Router,
        public dialog: MatDialog,
        private auth: AuthService
    ) {
        this.imageUrls = data.urlDocumentos;
    }

    ngOnInit(): void {
        // Ajustar estilo
        const dialog = document.getElementById("dialogOverflow");
        let dialogContainer = null;
        if (dialog) dialogContainer = dialog.parentElement;
        if (dialogContainer) dialogContainer = dialogContainer.parentElement;
        if (dialogContainer) dialogContainer = dialogContainer.parentElement;
        if (dialogContainer) dialogContainer.className += ' dialogOverflow';
    }

    cerrar() {
        this.dialogRef.close(null);
    }

    descargar() {
        if (this.imageUrls[this.currentImageIndex]) {
            window.open(this.imageUrls[this.currentImageIndex], "_blank");
        }
    }

    prevImage() {
        this.currentImageIndex = (this.currentImageIndex - 1 + this.imageUrls.length) % this.imageUrls.length;
    }

    nextImage() {
        this.currentImageIndex = (this.currentImageIndex + 1) % this.imageUrls.length;
    }
}