import { Injectable } from '@angular/core';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { JSONConverters } from '../models/JSONConverters';
import { SolicitudPruebaManejo } from '../models/SolicitudPruebaManejo';
import * as FileSaver from 'file-saver';
import { CambioEstadoSolicitud } from '../models/CambioEstadoSolicitud';
import { DocumentoSolicitud } from '../models/DocumentoSolicitud';
import { VehiculoDemo } from '../models/VehiculoDemo';
import { ClienteBoleta } from '../models/ClienteBoleta';


@Injectable()
export class DigitalizacionBoletaService { 
    apiUrls: APIUrls = new  APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
    ) { }

    // * * * SolicitudPruebaManejoes * * * 
    async obtenerTodasSolicitudPruebaManejoes(params: string = '') {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPruebasManejosURL;
            if (params) url += '?' + params;
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                var element = json[i];
                var registro = this.jsonConverters.solicitudPruebaManejoDeJSON(element);
                registros.push(registro);
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerSolicitudPruebaManejoPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPruebasManejosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.solicitudPruebaManejoDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearSolicitudPruebaManejo(registro: SolicitudPruebaManejo) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPruebasManejosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Solicitud creada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarSolicitudPruebaManejo(registro: SolicitudPruebaManejo) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPruebasManejosURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Solicitud actualizada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarSolicitudPruebaManejo(SolicitudPruebaManejoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPruebasManejosURL + '/id/' + SolicitudPruebaManejoId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Solicitud eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    //Configuracion

    public async obtenerConfiguracion(EmpresaId:number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.configuracionDigitalizacionURL+`?&EmpresaId=${EmpresaId}`;
            const res = await this.connection.getRequest(url);
            var registro = this.jsonConverters.configuracionDigitalizacionDeJSON(res.result);
            return { error: null, data: { result: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async actualizarConfiguracion(registro) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.configuracionDigitalizacionURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Configuracion Digitalizacion actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    //* * Cambiar Estado SolicitudPruebaManejo * * * *
    // Crear
    public async crearCambioEstadoSolicitud(registro: CambioEstadoSolicitud) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.cambiosEstadosSolicitudPruebaManejosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Cambio de estado creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    /***  Documentos SolicitudPruebaManejo  ****/
    async obtenerDocumentoSolicitudPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosSolicitudesPruebasManejosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.DocumentoSolicitudDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearDocumentoSolicitud(registro: DocumentoSolicitud) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosSolicitudesPruebasManejosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Documento creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarDocumentoSolicitud(registro: DocumentoSolicitud) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosSolicitudesPruebasManejosURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Documento actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarDocumentoSolicitud(DocumentoSolicitudId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosSolicitudesPruebasManejosURL + '/id/' + DocumentoSolicitudId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Documento eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async descargarBoletaSolicitud(id, fileName){
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPruebasManejosURL + `/descargar/${id}`;
            var res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var Name = `${fileName}.pdf`;
            var mediaType = 'application/pdf';
            var blob = new Blob([res], {
                type: mediaType
            });
            FileSaver.saveAs(blob, Name);

            return {
                error: null,
                data: {
                    mensaje: 'Boleta descargado con éxito.'
                }
            };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async descargarInspeccion(SolicitudId: number, fileName: string, tipo: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPruebasManejosURL + `/inspeccion-${tipo}/${SolicitudId}`;
            var res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var fileName = `${fileName}.pdf`;
            var mediaType = 'application/pdf';
            var blob = new Blob([res], {
                type: mediaType
            });
            FileSaver.saveAs(blob, fileName);

            return {
                error: null,
                data: {
                    mensaje: 'Inspeccion de contrato descarga con éxito.'
                }
            };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * Vehiculos * * *

    async obtenerTodosVehiculoDemo(params = null) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.vehiculosDemoURL;
            if(params) url += '?' + params;
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                var element = json[i];
                var registro = this.jsonConverters.vehiculoDemoDeJSON(element);
                registros.push(registro);
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerVehiculoDemoPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.vehiculosDemoURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.vehiculoDemoDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearVehiculoDemo(registro: VehiculoDemo) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.vehiculosDemoURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Vehiculo de solicitud creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarVehiculoDemo(registro: VehiculoDemo) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.vehiculosDemoURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Vehiculo de solicitud actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarVehiculoDemo(VehiculoDemoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.vehiculosDemoURL + '/id/' + VehiculoDemoId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Vehiculo de solicitud eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async descargarExcelSolicitudes(nombre: string, params:string) {
        try {
            // Construir ruta
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPruebasManejosURL + '/descargar-excel';
            if(params) url+= '?'+ params;
            var response = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `${nombre}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([ response ], { type: mediaType });
            FileSaver.saveAs(blob, filename);
            return response;
        } catch (error) {
            return null;
        }
    }

    // * * * Clientes * * *

    async obtenerTodosClienteBoleta(EmpresaId = null) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.clientesBoletasURL;
            if(EmpresaId) url += '?&EmpresaId=' + EmpresaId;
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                var element = json[i];
                var registro = this.jsonConverters.clienteBoletaDeJSON(element);
                registros.push(registro);
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerClienteBoletaPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.clientesBoletasURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.clienteBoletaDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearClienteBoleta(registro: ClienteBoleta) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.clientesBoletasURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Cliente de solicitud creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarClienteBoleta(registro: ClienteBoleta) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.clientesBoletasURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Cliente de solicitud actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarClienteBoleta(ClienteBoletaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.clientesBoletasURL + '/id/' + ClienteBoletaId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Cliente de solicitud eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async descargarExcelClientes(nombre: string, params:string) {
        try {
            // Construir ruta
            var url = this.apiUrls.baseURL + this.apiUrls.clientesBoletasURL + '/descargar-excel';
            if(params) url+= '?'+ params;
            var response = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `${nombre}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([ response ], { type: mediaType });
            FileSaver.saveAs(blob, filename);
            return response;
        } catch (error) {
            return null;
        }
    }

    public async descargarExcelVehiculos(nombre: string, params:string) {
        try {
            // Construir ruta
            var url = this.apiUrls.baseURL + this.apiUrls.vehiculosDemoURL + '/descargar-excel';
            if(params) url+= '?'+ params;
            var response = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `${nombre}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([ response ], { type: mediaType });
            FileSaver.saveAs(blob, filename);
            return response;
        } catch (error) {
            return null;
        }
    }

    async obtenerReporte(fechaInicioParam: any, fechaFinParam: any, params = '') {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPruebasManejosURL + '/reporte?';
            if (fechaInicioParam) url += '&start=' + fechaInicioParam;
            if (fechaFinParam) url += '&finish=' + fechaFinParam;
            if (params) url += params;
            var json = await this.connection.getRequest(url);
            var registro = json;
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async aprobarSolicitudes(ids: number[], UsuarioId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPruebasManejosURL + '/cambiar-estado-masivo';
            const res = await this.connection.postRequest(url, { ids: ids, estado: 'aprobado', UsuarioId: UsuarioId });
            return { error: null, data: { mensaje: 'Solicitudes aprobadas con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async rechazarSolicitudes(ids: number[], UsuarioId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPruebasManejosURL + '/cambiar-estado-masivo';
            const res = await this.connection.postRequest(url, { ids: ids, estado: 'rechazado', UsuarioId: UsuarioId });
            return { error: null, data: { mensaje: 'Solicitudes aprobadas con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async traslapeFechas(registro: SolicitudPruebaManejo ){
        try {
            let url = this.apiUrls.baseURL + this.apiUrls.solicitudesPruebasManejosURL + '/traslape-horarios';

            var json = await this.connection.postRequest(url,registro);

            let result = this.jsonConverters.solicitudPruebaManejoDeJSON(json);

            return { error: null, data: { registro: result } };

        } catch (error) {
            
        }
    }
    async enviarNotificacionSolicitud(id: number, correos: string[] ) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPruebasManejosURL + '/enviar-solicitud/';
            var json = await this.connection.postRequest(url, { SolicitudId: id, correos: correos });
            return { error: null, data: { } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }
}