import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { GenericDialog } from './library.service';
import { GenericDialogConfirmacion } from './generic-dialog-confirmacion/generic-dialog-confirmacion.component';
import { MatButtonModule } from '@angular/material/button';
import { BusquedaTablaComponent } from './busqueda-tabla/busqueda-tabla.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatSelectAutocompleteComponent } from './mat-select-autocomplete/mat-select-autocomplete.component';
import { MatSelectSearchComponent } from './mat-select-search/mat-select-search.component';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectLocalComponent } from './mat-select-local/mat-select-local.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { GenericDialogConfirmacionOpciones} from './generic-dialog-confirmacion-opciones/generic-dialog-confirmacion-opciones.component';
import { GenericDialogConfirmacionInput } from './generic-dialog-confirmacion-input/generic-dialog-confirmacion-input.component';
import { MatAutocompletePopupComponent } from './mat-autocomplete-popup/mat-autocomplete-popup.component';
import { DialogSugerenciasComponent } from './dialog-sugerencias/dialog-sugerencias.component';
import { PageLayoutModule } from 'src/@vex/components/page-layout/page-layout.module';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { IconModule } from '@visurel/iconify-angular';
import { MatFormCitaComponent } from './mat-cita/mat-cita.component';
import { MatFormClientePlanMantenimientoComponent } from './mat-cliente/mat-cliente.component';
import { MatFormVehiculoPlanMantenimientoComponent } from './mat-vehiculo-planes/mat-vehiculo-planes.component';
import { TextMaskModule } from 'angular2-text-mask';
import { DialogPreviewDownloadDocumentComponent } from './dialog-preview-download-document/dialog-preview-download-document.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PreguntasEncuestaComponent } from './preguntas-encuesta/preguntas-encuesta.component'
import { DialogPreviewDocumentoComponent } from './dialog-preview-documento/dialog-preview-documento.component';
import { PanelFirmaComponent } from './panel-firma/panel-firma.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { MatRadioModule } from '@angular/material/radio';
import { DialogCarrouselImagenesComponent } from './dialog-carrousel-imagenes/dialog-carrousel-imagenes.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgxDocViewerModule,
        MatDialogModule,
        MatButtonModule,
        MatProgressBarModule,
        MatCheckboxModule,
        MatSelectModule,
        MatIconModule,
        MatPaginatorModule,
        MatDatepickerModule,
        MatAutocompleteModule,
        MatChipsModule,
        MatInputModule,
        MatTabsModule,
        NgxDatatableModule,
        PageLayoutModule,
        BreadcrumbsModule,
        IconModule,
        MatFormFieldModule,
        TextMaskModule,
        PdfViewerModule,
        MatRadioModule,
        AngularSignaturePadModule,
    ],
    declarations: [
        GenericDialog,
        GenericDialogConfirmacion,
        BusquedaTablaComponent,
        MatSelectAutocompleteComponent,
        MatSelectSearchComponent,
        MatSelectLocalComponent,
        GenericDialogConfirmacionOpciones,
        GenericDialogConfirmacionInput,
        MatAutocompletePopupComponent,
        DialogSugerenciasComponent,
        MatFormCitaComponent,
        MatFormClientePlanMantenimientoComponent,
        MatFormVehiculoPlanMantenimientoComponent,
        DialogPreviewDownloadDocumentComponent,
        PreguntasEncuestaComponent,
        DialogPreviewDocumentoComponent,
        PanelFirmaComponent,
        DialogCarrouselImagenesComponent
    ],
    entryComponents: [
        GenericDialog,
        GenericDialogConfirmacion,
        GenericDialogConfirmacionOpciones,
        GenericDialogConfirmacionInput,
        DialogSugerenciasComponent,
        DialogPreviewDownloadDocumentComponent,
        DialogPreviewDocumentoComponent,
        PanelFirmaComponent
    ],
    exports: [
        BusquedaTablaComponent,
        MatSelectAutocompleteComponent,
        MatSelectSearchComponent,
        MatSelectLocalComponent,
        MatAutocompletePopupComponent,
        MatFormCitaComponent,
        MatFormClientePlanMantenimientoComponent,
        MatFormVehiculoPlanMantenimientoComponent,
        PreguntasEncuestaComponent,
        DialogPreviewDocumentoComponent,
        PanelFirmaComponent
    ]
})
export class LibraryModule {
}
