export default [
    {
        "departamento": "03",
        "id": "0411",
        "nombre": "ACATENANGO"
    },
    {
        "departamento": "22",
        "id": "2204",
        "nombre": "AGUA BLANCA"
    },
    {
        "departamento": "13",
        "id": "1327",
        "nombre": "AGUACATÁN"
    },
    {
        "departamento": "09",
        "id": "0913",
        "nombre": "ALMOLONGA"
    },
    {
        "departamento": "02",
        "id": "0314",
        "nombre": "ALOTENANGO"
    },
    {
        "departamento": "01",
        "id": "0114",
        "nombre": "AMATITLÁN"
    },
    {
        "departamento": "02",
        "id": "0301",
        "nombre": "ANTIGUA GUATEMALA"
    },
    {
        "departamento": "22",
        "id": "2205",
        "nombre": "ASUNCIÓN MITA"
    },
    {
        "departamento": "22",
        "id": "2207",
        "nombre": "ATESCATEMPA"
    },
    {
        "departamento": "12",
        "id": "1217",
        "nombre": "AYUTLA (TECUN UMAN)"
    },
    {
        "departamento": "06",
        "id": "0602",
        "nombre": "BARBERENA"
    },
    {
        "departamento": "13",
        "id": "1326",
        "nombre": "BARILLAS"
    },
    {
        "departamento": "19",
        "id": "1907",
        "nombre": "CABAÑAS"
    },
    {
        "departamento": "09",
        "id": "0906",
        "nombre": "CABRICÁN"
    },
    {
        "departamento": "16",
        "id": "1612",
        "nombre": "CAHABÓN"
    },
    {
        "departamento": "09",
        "id": "0907",
        "nombre": "CAJOLA"
    },
    {
        "departamento": "20",
        "id": "2005",
        "nombre": "CAMOTÁN"
    },
    {
        "departamento": "14",
        "id": "1418",
        "nombre": "CANILLÁ"
    },
    {
        "departamento": "09",
        "id": "0914",
        "nombre": "CANTEL"
    },
    {
        "departamento": "06",
        "id": "0604",
        "nombre": "CASILLAS"
    },
    {
        "departamento": "12",
        "id": "1216",
        "nombre": "CATARINA"
    },
    {
        "departamento": "16",
        "id": "1614",
        "nombre": "CHAHAL"
    },
    {
        "departamento": "14",
        "id": "1405",
        "nombre": "CHAJUL"
    },
    {
        "departamento": "11",
        "id": "1107",
        "nombre": "CHAMPERICO"
    },
    {
        "departamento": "13",
        "id": "1302",
        "nombre": "CHIANTLA"
    },
    {
        "departamento": "10",
        "id": "1013",
        "nombre": "CHICACAO"
    },
    {
        "departamento": "14",
        "id": "1419",
        "nombre": "CHICAMÁN"
    },
    {
        "departamento": "14",
        "id": "1406",
        "nombre": "CHICHICASTENANGO"
    },
    {
        "departamento": "14",
        "id": "1402",
        "nombre": "CHICHÉ"
    },
    {
        "departamento": "03",
        "id": "0401",
        "nombre": "CHIMALTENANGO"
    },
    {
        "departamento": "01",
        "id": "0106",
        "nombre": "CHINAUTLA"
    },
    {
        "departamento": "14",
        "id": "1403",
        "nombre": "CHINIQUE"
    },
    {
        "departamento": "20",
        "id": "2001",
        "nombre": "CHIQUIMULA"
    },
    {
        "departamento": "06",
        "id": "0608",
        "nombre": "CHIQUIMULILLA"
    },
    {
        "departamento": "16",
        "id": "1613",
        "nombre": "CHISEC"
    },
    {
        "departamento": "01",
        "id": "0112",
        "nombre": "CHUARRANCHO"
    },
    {
        "departamento": "12",
        "id": "1217",
        "nombre": "CIUDAD TECÚN UMÁN"
    },
    {
        "departamento": "02",
        "id": "0312",
        "nombre": "CIUDAD VIEJA"
    },
    {
        "departamento": "09",
        "id": "0920",
        "nombre": "COATEPEQUE"
    },
    {
        "departamento": "16",
        "id": "1601",
        "nombre": "COBÁN"
    },
    {
        "departamento": "09",
        "id": "0917",
        "nombre": "COLOMBA"
    },
    {
        "departamento": "13",
        "id": "1319",
        "nombre": "COLOTENANGO"
    },
    {
        "departamento": "22",
        "id": "2211",
        "nombre": "COMAPA"
    },
    {
        "departamento": "12",
        "id": "1204",
        "nombre": "COMITANCILLO"
    },
    {
        "departamento": "09",
        "id": "0911",
        "nombre": "CONCEPCIÓN CHIQUIRICHAPA"
    },
    {
        "departamento": "13",
        "id": "1322",
        "nombre": "CONCEPCIÓN HUISTA"
    },
    {
        "departamento": "20",
        "id": "2008",
        "nombre": "CONCEPCIÓN LAS MINAS"
    },
    {
        "departamento": "12",
        "id": "1206",
        "nombre": "CONCEPCIÓN TUTUAPA"
    },
    {
        "departamento": "07",
        "id": "0708",
        "nombre": "CONCEPCIÓN"
    },
    {
        "departamento": "22",
        "id": "2213",
        "nombre": "CONGUACO"
    },
    {
        "departamento": "15",
        "id": "1504",
        "nombre": "CUBULCO"
    },
    {
        "departamento": "06",
        "id": "0601",
        "nombre": "CUILAPA"
    },
    {
        "departamento": "13",
        "id": "1304",
        "nombre": "CUILCO"
    },
    {
        "departamento": "14",
        "id": "1410",
        "nombre": "CUNÉN"
    },
    {
        "departamento": "10",
        "id": "1002",
        "nombre": "CUYOTENANGO"
    },
    {
        "departamento": "17",
        "id": "1708",
        "nombre": "DOLORES"
    },
    {
        "departamento": "22",
        "id": "2209",
        "nombre": "EL ADELANTO"
    },
    {
        "departamento": "11",
        "id": "1109",
        "nombre": "EL ASINTAL"
    },
    {
        "departamento": "17",
        "id": "1714",
        "nombre": "EL CHAL"
    },
    {
        "departamento": "15",
        "id": "1506",
        "nombre": "EL CHOL"
    },
    {
        "departamento": "18",
        "id": "1803",
        "nombre": "EL ESTOR"
    },
    {
        "departamento": "04",
        "id": "0205",
        "nombre": "EL JÍCARO"
    },
    {
        "departamento": "09",
        "id": "0919",
        "nombre": "EL PALMAR"
    },
    {
        "departamento": "22",
        "id": "2202",
        "nombre": "EL PROGRESO"
    },
    {
        "departamento": "12",
        "id": "1220",
        "nombre": "EL QUETZAL"
    },
    {
        "departamento": "03",
        "id": "0416",
        "nombre": "EL TEJAR"
    },
    {
        "departamento": "12",
        "id": "1213",
        "nombre": "EL TUMBADOR"
    },
    {
        "departamento": "05",
        "id": "0501",
        "nombre": "ESCUINTLA"
    },
    {
        "departamento": "12",
        "id": "1227",
        "nombre": "ESQUIPULAS PALO GORDO"
    },
    {
        "departamento": "20",
        "id": "2007",
        "nombre": "ESQUIPULAS"
    },
    {
        "departamento": "19",
        "id": "1902",
        "nombre": "ESTANZUELA"
    },
    {
        "departamento": "09",
        "id": "0922",
        "nombre": "FLORES"
    },
    {
        "departamento": "17",
        "id": "1701",
        "nombre": "FLORES"
    },
    {
        "departamento": "01",
        "id": "0113",
        "nombre": "FRAIJANES"
    },
    {
        "departamento": "16",
        "id": "1615",
        "nombre": "FRAY BARTOLOMÉ DE LAS CASAS"
    },
    {
        "departamento": "15",
        "id": "1505",
        "nombre": "GRANADOS"
    },
    {
        "departamento": "19",
        "id": "1904",
        "nombre": "GUALÁN"
    },
    {
        "departamento": "05",
        "id": "0508",
        "nombre": "GUANAGAZAPA"
    },
    {
        "departamento": "04",
        "id": "0201",
        "nombre": "GUASTATOYA"
    },
    {
        "departamento": "01",
        "id": "0101",
        "nombre": "GUATEMALA"
    },
    {
        "departamento": "06",
        "id": "0611",
        "nombre": "GUAZACAPÁN"
    },
    {
        "departamento": "09",
        "id": "0921",
        "nombre": "GÉNOVA COSTA CUCA"
    },
    {
        "departamento": "13",
        "id": "1301",
        "nombre": "HUEHUETENANGO"
    },
    {
        "departamento": "09",
        "id": "0915",
        "nombre": "HUITÁN"
    },
    {
        "departamento": "19",
        "id": "1910",
        "nombre": "HUITÉ"
    },
    {
        "departamento": "20",
        "id": "2011",
        "nombre": "IPALA"
    },
    {
        "departamento": "12",
        "id": "1223",
        "nombre": "IXCHIGUÁN"
    },
    {
        "departamento": "14",
        "id": "1420",
        "nombre": "IXCÁN"
    },
    {
        "departamento": "13",
        "id": "1309",
        "nombre": "IXTAHUACÁN"
    },
    {
        "departamento": "05",
        "id": "0510",
        "nombre": "IZTAPA"
    },
    {
        "departamento": "13",
        "id": "1307",
        "nombre": "JACALTENANGO"
    },
    {
        "departamento": "21",
        "id": "2101",
        "nombre": "JALAPA"
    },
    {
        "departamento": "22",
        "id": "2212",
        "nombre": "JALPATAGUA"
    },
    {
        "departamento": "22",
        "id": "2208",
        "nombre": "JEREZ"
    },
    {
        "departamento": "02",
        "id": "0302",
        "nombre": "JOCOTENANGO"
    },
    {
        "departamento": "20",
        "id": "2004",
        "nombre": "JOCOTÁN"
    },
    {
        "departamento": "14",
        "id": "1412",
        "nombre": "JOYABAJ"
    },
    {
        "departamento": "22",
        "id": "2201",
        "nombre": "JUTIAPA"
    },
    {
        "departamento": "12",
        "id": "1230",
        "nombre": "LA BLANCA"
    },
    {
        "departamento": "05",
        "id": "0503",
        "nombre": "LA DEMOCRACIA"
    },
    {
        "departamento": "13",
        "id": "1312",
        "nombre": "LA DEMOCRACIA"
    },
    {
        "departamento": "09",
        "id": "0923",
        "nombre": "LA ESPERANZA"
    },
    {
        "departamento": "05",
        "id": "0507",
        "nombre": "LA GOMERA"
    },
    {
        "departamento": "13",
        "id": "1311",
        "nombre": "LA LIBERTAD"
    },
    {
        "departamento": "17",
        "id": "1705",
        "nombre": "LA LIBERTAD"
    },
    {
        "departamento": "12",
        "id": "1221",
        "nombre": "LA REFORMA"
    },
    {
        "departamento": "19",
        "id": "1909",
        "nombre": "LA UNIÓN"
    },
    {
        "departamento": "16",
        "id": "1611",
        "nombre": "LANQUÍN"
    },
    {
        "departamento": "17",
        "id": "1713",
        "nombre": "LAS CRUCES"
    },
    {
        "departamento": "18",
        "id": "1802",
        "nombre": "LIVINGSTON"
    },
    {
        "departamento": "18",
        "id": "1805",
        "nombre": "LOS AMATES"
    },
    {
        "departamento": "02",
        "id": "0310",
        "nombre": "MAGDALENA MILPAS ALTAS"
    },
    {
        "departamento": "13",
        "id": "1303",
        "nombre": "MALACATANCITO"
    },
    {
        "departamento": "12",
        "id": "1215",
        "nombre": "MALACATÁN"
    },
    {
        "departamento": "05",
        "id": "0505",
        "nombre": "MASAGUA"
    },
    {
        "departamento": "21",
        "id": "2107",
        "nombre": "MATAQUESCUINTLA"
    },
    {
        "departamento": "10",
        "id": "1001",
        "nombre": "MAZATENANGO"
    },
    {
        "departamento": "17",
        "id": "1711",
        "nombre": "MELCHOR DE MENCOS"
    },
    {
        "departamento": "01",
        "id": "0108",
        "nombre": "MIXCO"
    },
    {
        "departamento": "08",
        "id": "0805",
        "nombre": "MOMOSTENANGO"
    },
    {
        "departamento": "21",
        "id": "2106",
        "nombre": "MONJAS"
    },
    {
        "departamento": "18",
        "id": "1804",
        "nombre": "MORALES"
    },
    {
        "departamento": "04",
        "id": "0202",
        "nombre": "MORAZÁN"
    },
    {
        "departamento": "22",
        "id": "2214",
        "nombre": "MOYUTA"
    },
    {
        "departamento": "07",
        "id": "0705",
        "nombre": "NAHUALÁ"
    },
    {
        "departamento": "14",
        "id": "1413",
        "nombre": "NEBAJ"
    },
    {
        "departamento": "13",
        "id": "1305",
        "nombre": "NENTÓN"
    },
    {
        "departamento": "05",
        "id": "0513",
        "nombre": "NUEVA CONCEPCIÓN"
    },
    {
        "departamento": "06",
        "id": "0614",
        "nombre": "NUEVA SANTA ROSA"
    },
    {
        "departamento": "12",
        "id": "1212",
        "nombre": "NUEVO PROGRESO"
    },
    {
        "departamento": "11",
        "id": "1108",
        "nombre": "NUEVO SAN CARLOS"
    },
    {
        "departamento": "12",
        "id": "1218",
        "nombre": "OCÓS"
    },
    {
        "departamento": "09",
        "id": "0903",
        "nombre": "OLINTEPEQUE"
    },
    {
        "departamento": "20",
        "id": "2006",
        "nombre": "OLOPA"
    },
    {
        "departamento": "06",
        "id": "0606",
        "nombre": "ORATORIO"
    },
    {
        "departamento": "14",
        "id": "1421",
        "nombre": "PACHALUM"
    },
    {
        "departamento": "12",
        "id": "1222",
        "nombre": "PAJAPITA"
    },
    {
        "departamento": "01",
        "id": "0105",
        "nombre": "PALENCIA"
    },
    {
        "departamento": "09",
        "id": "0924",
        "nombre": "PALESTINA DE LOS ALTOS"
    },
    {
        "departamento": "05",
        "id": "0511",
        "nombre": "PALÍN"
    },
    {
        "departamento": "07",
        "id": "0710",
        "nombre": "PANAJACHEL"
    },
    {
        "departamento": "16",
        "id": "1607",
        "nombre": "PANZÓS"
    },
    {
        "departamento": "03",
        "id": "0414",
        "nombre": "PARRAMOS"
    },
    {
        "departamento": "22",
        "id": "2215",
        "nombre": "PASACO"
    },
    {
        "departamento": "02",
        "id": "0303",
        "nombre": "PASTORES"
    },
    {
        "departamento": "10",
        "id": "1014",
        "nombre": "PATULUL"
    },
    {
        "departamento": "03",
        "id": "0409",
        "nombre": "PATZICÍA"
    },
    {
        "departamento": "14",
        "id": "1407",
        "nombre": "PATZITE"
    },
    {
        "departamento": "03",
        "id": "0407",
        "nombre": "PATZÚN"
    },
    {
        "departamento": "13",
        "id": "1333",
        "nombre": "PETATÁN"
    },
    {
        "departamento": "03",
        "id": "0408",
        "nombre": "POCHUTA"
    },
    {
        "departamento": "17",
        "id": "1712",
        "nombre": "POPTÚN"
    },
    {
        "departamento": "10",
        "id": "1019",
        "nombre": "PUEBLO NUEVO"
    },
    {
        "departamento": "06",
        "id": "0613",
        "nombre": "PUEBLO NUEVO VIÑAS"
    },
    {
        "departamento": "18",
        "id": "1801",
        "nombre": "PUERTO BARRIOS"
    },
    {
        "departamento": "05",
        "id": "0509",
        "nombre": "PUERTO SAN JOSÉ"
    },
    {
        "departamento": "15",
        "id": "1508",
        "nombre": "PURULHÁ"
    },
    {
        "departamento": "09",
        "id": "0901",
        "nombre": "QUETZALTENANGO"
    },
    {
        "departamento": "20",
        "id": "2009",
        "nombre": "QUETZALTEPEQUE"
    },
    {
        "departamento": "22",
        "id": "2217",
        "nombre": "QUEZADA"
    },
    {
        "departamento": "15",
        "id": "1503",
        "nombre": "RABINAL"
    },
    {
        "departamento": "16",
        "id": "1617",
        "nombre": "RAXRUHA"
    },
    {
        "departamento": "11",
        "id": "1101",
        "nombre": "RETALHULEU"
    },
    {
        "departamento": "12",
        "id": "1228",
        "nombre": "RÍO BLANCO"
    },
    {
        "departamento": "10",
        "id": "1020",
        "nombre": "RÍO BRAVO"
    },
    {
        "departamento": "19",
        "id": "1903",
        "nombre": "RÍO HONDO"
    },
    {
        "departamento": "14",
        "id": "1416",
        "nombre": "SACAPULAS"
    },
    {
        "departamento": "15",
        "id": "1501",
        "nombre": "SALAMÁ"
    },
    {
        "departamento": "09",
        "id": "0902",
        "nombre": "SALCAJÁ"
    },
    {
        "departamento": "10",
        "id": "1008",
        "nombre": "SAMAYAC"
    },
    {
        "departamento": "04",
        "id": "0203",
        "nombre": "SAN AGUSTÍN ACASAGUASTLÁN"
    },
    {
        "departamento": "03",
        "id": "0413",
        "nombre": "SAN ANDRÉS ITZAPA"
    },
    {
        "departamento": "14",
        "id": "1414",
        "nombre": "SAN ANDRÉS SAJCABAJA"
    },
    {
        "departamento": "07",
        "id": "0709",
        "nombre": "SAN ANDRÉS SEMETABAJ"
    },
    {
        "departamento": "11",
        "id": "1106",
        "nombre": "SAN ANDRÉS VILLA SECA"
    },
    {
        "departamento": "08",
        "id": "0804",
        "nombre": "SAN ANDRÉS XECUL"
    },
    {
        "departamento": "17",
        "id": "1704",
        "nombre": "SAN ANDRÉS"
    },
    {
        "departamento": "02",
        "id": "0315",
        "nombre": "SAN ANTONIO AGUAS CALIENTES"
    },
    {
        "departamento": "13",
        "id": "1324",
        "nombre": "SAN ANTONIO HUISTA"
    },
    {
        "departamento": "14",
        "id": "1408",
        "nombre": "SAN ANTONIO ILOTENANGO"
    },
    {
        "departamento": "04",
        "id": "0208",
        "nombre": "SAN ANTONIO LA PAZ"
    },
    {
        "departamento": "07",
        "id": "0712",
        "nombre": "SAN ANTONIO PALOPÓ"
    },
    {
        "departamento": "12",
        "id": "1203",
        "nombre": "SAN ANTONIO SACATEPÉQUEZ"
    },
    {
        "departamento": "10",
        "id": "1010",
        "nombre": "SAN ANTONIO SUCHITEPÉQUEZ"
    },
    {
        "departamento": "14",
        "id": "1417",
        "nombre": "SAN BARTOLOMÉ JOCOTENANGO"
    },
    {
        "departamento": "02",
        "id": "0307",
        "nombre": "SAN BARTOLOMÉ MILPAS ALTAS"
    },
    {
        "departamento": "08",
        "id": "0808",
        "nombre": "SAN BARTOLO"
    },
    {
        "departamento": "17",
        "id": "1703",
        "nombre": "SAN BENITO"
    },
    {
        "departamento": "10",
        "id": "1004",
        "nombre": "SAN BERNARDINO"
    },
    {
        "departamento": "21",
        "id": "2105",
        "nombre": "SAN CARLOS ALZATATE"
    },
    {
        "departamento": "09",
        "id": "0904",
        "nombre": "SAN CARLOS SIJA"
    },
    {
        "departamento": "04",
        "id": "0204",
        "nombre": "SAN CRISTÓBAL ACASAGUASTLÁN"
    },
    {
        "departamento": "12",
        "id": "1225",
        "nombre": "SAN CRISTÓBAL CUCHO"
    },
    {
        "departamento": "08",
        "id": "0802",
        "nombre": "SAN CRISTÓBAL TOTONICAPÁN"
    },
    {
        "departamento": "16",
        "id": "1603",
        "nombre": "SAN CRISTÓBAL VERAPAZ"
    },
    {
        "departamento": "19",
        "id": "1908",
        "nombre": "SAN DIEGO"
    },
    {
        "departamento": "11",
        "id": "1105",
        "nombre": "SAN FELIPE"
    },
    {
        "departamento": "08",
        "id": "0803",
        "nombre": "SAN FRANCISCO EL ALTO"
    },
    {
        "departamento": "09",
        "id": "0918",
        "nombre": "SAN FRANCISCO LA UNIÓN"
    },
    {
        "departamento": "10",
        "id": "1003",
        "nombre": "SAN FRANCISCO ZAPOTITLÁN"
    },
    {
        "departamento": "17",
        "id": "1706",
        "nombre": "SAN FRANCISCO"
    },
    {
        "departamento": "10",
        "id": "1012",
        "nombre": "SAN GABRIEL"
    },
    {
        "departamento": "13",
        "id": "1329",
        "nombre": "SAN GASPAR IXCHIL"
    },
    {
        "departamento": "20",
        "id": "2010",
        "nombre": "SAN JACINTO"
    },
    {
        "departamento": "15",
        "id": "1507",
        "nombre": "SAN JERÓNIMO"
    },
    {
        "departamento": "19",
        "id": "1911",
        "nombre": "SAN JORGE"
    },
    {
        "departamento": "12",
        "id": "1214",
        "nombre": "SAN JOSE EL RODEO"
    },
    {
        "departamento": "22",
        "id": "2216",
        "nombre": "SAN JOSÉ ACATEMPA"
    },
    {
        "departamento": "07",
        "id": "0702",
        "nombre": "SAN JOSÉ CHACAYÁ"
    },
    {
        "departamento": "01",
        "id": "0104",
        "nombre": "SAN JOSÉ DEL GOLFO"
    },
    {
        "departamento": "10",
        "id": "1005",
        "nombre": "SAN JOSÉ EL ÍDOLO"
    },
    {
        "departamento": "20",
        "id": "2002",
        "nombre": "SAN JOSÉ LA ARADA"
    },
    {
        "departamento": "10",
        "id": "1021",
        "nombre": "SAN JOSÉ LA MÁQUINA"
    },
    {
        "departamento": "12",
        "id": "1224",
        "nombre": "SAN JOSÉ OJETENAM"
    },
    {
        "departamento": "01",
        "id": "0103",
        "nombre": "SAN JOSÉ PINULA"
    },
    {
        "departamento": "03",
        "id": "0402",
        "nombre": "SAN JOSÉ POAQUIL"
    },
    {
        "departamento": "17",
        "id": "1702",
        "nombre": "SAN JOSÉ"
    },
    {
        "departamento": "13",
        "id": "1316",
        "nombre": "SAN JUAN ATITÁN"
    },
    {
        "departamento": "10",
        "id": "1016",
        "nombre": "SAN JUAN BAUTISTA"
    },
    {
        "departamento": "16",
        "id": "1610",
        "nombre": "SAN JUAN CHAMELCO"
    },
    {
        "departamento": "03",
        "id": "0404",
        "nombre": "SAN JUAN COMALAPA"
    },
    {
        "departamento": "14",
        "id": "1411",
        "nombre": "SAN JUAN COTZAL"
    },
    {
        "departamento": "20",
        "id": "2003",
        "nombre": "SAN JUAN ERMITA"
    },
    {
        "departamento": "13",
        "id": "1323",
        "nombre": "SAN JUAN IXCOY"
    },
    {
        "departamento": "07",
        "id": "0717",
        "nombre": "SAN JUAN LA LAGUNA"
    },
    {
        "departamento": "09",
        "id": "0909",
        "nombre": "SAN JUAN OSTUNCALCO"
    },
    {
        "departamento": "01",
        "id": "0110",
        "nombre": "SAN JUAN SACATEPÉQUEZ"
    },
    {
        "departamento": "06",
        "id": "0607",
        "nombre": "SAN JUAN TECUACO"
    },
    {
        "departamento": "10",
        "id": "1007",
        "nombre": "SAN LORENZO"
    },
    {
        "departamento": "12",
        "id": "1229",
        "nombre": "SAN LORENZO"
    },
    {
        "departamento": "02",
        "id": "0308",
        "nombre": "SAN LUCAS SACATEPÉQUEZ"
    },
    {
        "departamento": "07",
        "id": "0713",
        "nombre": "SAN LUCAS TOLIMÁN"
    },
    {
        "departamento": "21",
        "id": "2103",
        "nombre": "SAN LUIS JILOTEPEQUE"
    },
    {
        "departamento": "17",
        "id": "1709",
        "nombre": "SAN LUIS"
    },
    {
        "departamento": "07",
        "id": "0716",
        "nombre": "SAN MARCOS LA LAGUNA"
    },
    {
        "departamento": "12",
        "id": "1201",
        "nombre": "SAN MARCOS"
    },
    {
        "departamento": "03",
        "id": "0403",
        "nombre": "SAN MARTÍN JILOTEPEQUE"
    },
    {
        "departamento": "09",
        "id": "0912",
        "nombre": "SAN MARTÍN SACATEPÉQUEZ"
    },
    {
        "departamento": "11",
        "id": "1104",
        "nombre": "SAN MARTÍN ZAPOTITLÁN"
    },
    {
        "departamento": "13",
        "id": "1318",
        "nombre": "SAN MATEO IXTATÁN"
    },
    {
        "departamento": "09",
        "id": "0910",
        "nombre": "SAN MATEO"
    },
    {
        "departamento": "13",
        "id": "1313",
        "nombre": "SAN MIGUEL ACATÁN"
    },
    {
        "departamento": "21",
        "id": "2104",
        "nombre": "SAN MIGUEL CHAPARRÓN"
    },
    {
        "departamento": "15",
        "id": "1502",
        "nombre": "SAN MIGUEL CHICAJ"
    },
    {
        "departamento": "02",
        "id": "0313",
        "nombre": "SAN MIGUEL DUEÑAS"
    },
    {
        "departamento": "12",
        "id": "1205",
        "nombre": "SAN MIGUEL IXTAHUACÁN"
    },
    {
        "departamento": "10",
        "id": "1011",
        "nombre": "SAN MIGUEL PANÁN"
    },
    {
        "departamento": "01",
        "id": "0117",
        "nombre": "SAN MIGUEL PETAPA"
    },
    {
        "departamento": "09",
        "id": "0908",
        "nombre": "SAN MIGUEL SIGUILA"
    },
    {
        "departamento": "14",
        "id": "1415",
        "nombre": "SAN MIGUEL USPANTÁN"
    },
    {
        "departamento": "10",
        "id": "1009",
        "nombre": "SAN PABLO JOCOPILAS"
    },
    {
        "departamento": "07",
        "id": "0715",
        "nombre": "SAN PABLO LA LAGUNA"
    },
    {
        "departamento": "12",
        "id": "1219",
        "nombre": "SAN PABLO"
    },
    {
        "departamento": "01",
        "id": "0107",
        "nombre": "SAN PEDRO AYAMPUC"
    },
    {
        "departamento": "16",
        "id": "1609",
        "nombre": "SAN PEDRO CARCHÁ"
    },
    {
        "departamento": "14",
        "id": "1409",
        "nombre": "SAN PEDRO JOCOPILAS"
    },
    {
        "departamento": "07",
        "id": "0718",
        "nombre": "SAN PEDRO LA LAGUNA"
    },
    {
        "departamento": "13",
        "id": "1306",
        "nombre": "SAN PEDRO NECTA"
    },
    {
        "departamento": "21",
        "id": "2102",
        "nombre": "SAN PEDRO PINULA"
    },
    {
        "departamento": "01",
        "id": "0109",
        "nombre": "SAN PEDRO SACATEPÉQUEZ"
    },
    {
        "departamento": "12",
        "id": "1202",
        "nombre": "SAN PEDRO SACATEPÉQUEZ"
    },
    {
        "departamento": "03",
        "id": "0412",
        "nombre": "SAN PEDRO YEPOCAPA"
    },
    {
        "departamento": "13",
        "id": "1314",
        "nombre": "SAN RAFAEL LA INDEPENDENCIA"
    },
    {
        "departamento": "06",
        "id": "0605",
        "nombre": "SAN RAFAEL LAS FLORES"
    },
    {
        "departamento": "13",
        "id": "1328",
        "nombre": "SAN RAFAEL PETZAL"
    },
    {
        "departamento": "12",
        "id": "1211",
        "nombre": "SAN RAFAEL PIE DE LA CUESTA"
    },
    {
        "departamento": "01",
        "id": "0111",
        "nombre": "SAN RAYMUNDO"
    },
    {
        "departamento": "13",
        "id": "1325",
        "nombre": "SAN SEBASTIÁN COATÁN"
    },
    {
        "departamento": "13",
        "id": "1320",
        "nombre": "SAN SEBASTIÁN HUEHUETENANGO"
    },
    {
        "departamento": "11",
        "id": "1102",
        "nombre": "SAN SEBASTIÁN"
    },
    {
        "departamento": "05",
        "id": "0512",
        "nombre": "SAN VICENTE PACAYA"
    },
    {
        "departamento": "04",
        "id": "0207",
        "nombre": "SANARATE"
    },
    {
        "departamento": "04",
        "id": "0206",
        "nombre": "SANSARE"
    },
    {
        "departamento": "13",
        "id": "1331",
        "nombre": "SANTA ANA HUISTA"
    },
    {
        "departamento": "17",
        "id": "1707",
        "nombre": "SANTA ANA"
    },
    {
        "departamento": "03",
        "id": "0405",
        "nombre": "SANTA APOLONIA"
    },
    {
        "departamento": "10",
        "id": "1015",
        "nombre": "SANTA BÁRBARA"
    },
    {
        "departamento": "13",
        "id": "1310",
        "nombre": "SANTA BÁRBARA"
    },
    {
        "departamento": "02",
        "id": "0316",
        "nombre": "SANTA CATARINA BARAHONA"
    },
    {
        "departamento": "07",
        "id": "0706",
        "nombre": "SANTA CATARINA IXTAHUACÁN"
    },
    {
        "departamento": "16",
        "id": "1616",
        "nombre": "SANTA CATARINA LA TINTA"
    },
    {
        "departamento": "22",
        "id": "2203",
        "nombre": "SANTA CATARINA MITA"
    },
    {
        "departamento": "07",
        "id": "0711",
        "nombre": "SANTA CATARINA PALOPÓ"
    },
    {
        "departamento": "01",
        "id": "0102",
        "nombre": "SANTA CATARINA PINULA"
    },
    {
        "departamento": "07",
        "id": "0707",
        "nombre": "SANTA CLARA LA LAGUNA"
    },
    {
        "departamento": "03",
        "id": "0410",
        "nombre": "SANTA CRUZ BALANYA"
    },
    {
        "departamento": "14",
        "id": "1401",
        "nombre": "SANTA CRUZ DEL QUICHÉ"
    },
    {
        "departamento": "07",
        "id": "0714",
        "nombre": "SANTA CRUZ LA LAGUNA"
    },
    {
        "departamento": "11",
        "id": "1103",
        "nombre": "SANTA CRUZ MULUÁ"
    },
    {
        "departamento": "06",
        "id": "0612",
        "nombre": "SANTA CRUZ NARANJO"
    },
    {
        "departamento": "16",
        "id": "1602",
        "nombre": "SANTA CRUZ VERAPAZ"
    },
    {
        "departamento": "17",
        "id": "1701",
        "nombre": "SANTA ELENA"
    },
    {
        "departamento": "13",
        "id": "1317",
        "nombre": "SANTA EULALIA"
    },
    {
        "departamento": "05",
        "id": "0502",
        "nombre": "SANTA LUCÍA COTZUMALGUAPA"
    },
    {
        "departamento": "08",
        "id": "0807",
        "nombre": "SANTA LUCÍA LA REFORMA"
    },
    {
        "departamento": "02",
        "id": "0309",
        "nombre": "SANTA LUCÍA MILPAS ALTAS"
    },
    {
        "departamento": "07",
        "id": "0704",
        "nombre": "SANTA LUCÍA UTATLÁN"
    },
    {
        "departamento": "08",
        "id": "0806",
        "nombre": "SANTA MARÍA CHIQUIMULA"
    },
    {
        "departamento": "02",
        "id": "0311",
        "nombre": "SANTA MARÍA DE JESÚS"
    },
    {
        "departamento": "06",
        "id": "0610",
        "nombre": "SANTA MARÍA IXHUATÁN"
    },
    {
        "departamento": "07",
        "id": "0703",
        "nombre": "SANTA MARÍA VISITACIÓN"
    },
    {
        "departamento": "06",
        "id": "0603",
        "nombre": "SANTA ROSA DE LIMA"
    },
    {
        "departamento": "07",
        "id": "0719",
        "nombre": "SANTIAGO ATITLÁN"
    },
    {
        "departamento": "13",
        "id": "1330",
        "nombre": "SANTIAGO CHIMALTENANGO"
    },
    {
        "departamento": "02",
        "id": "0306",
        "nombre": "SANTIAGO SACATEPEQUÉZ"
    },
    {
        "departamento": "10",
        "id": "1006",
        "nombre": "SANTO DOMINGO SUCHITEPEQUEZ"
    },
    {
        "departamento": "02",
        "id": "0305",
        "nombre": "SANTO DOMINGO XENACOJ"
    },
    {
        "departamento": "10",
        "id": "1017",
        "nombre": "SANTO TOMÁS LA UNIÓN"
    },
    {
        "departamento": "17",
        "id": "1710",
        "nombre": "SAYAXCHÉ"
    },
    {
        "departamento": "16",
        "id": "1608",
        "nombre": "SENAHÚ"
    },
    {
        "departamento": "09",
        "id": "0905",
        "nombre": "SIBILIA"
    },
    {
        "departamento": "12",
        "id": "1208",
        "nombre": "SIBINAL"
    },
    {
        "departamento": "12",
        "id": "1226",
        "nombre": "SIPACAPA"
    },
    {
        "departamento": "05",
        "id": "0514",
        "nombre": "SIPACATE"
    },
    {
        "departamento": "05",
        "id": "0504",
        "nombre": "SIQUINALÁ"
    },
    {
        "departamento": "07",
        "id": "0701",
        "nombre": "SOLOLÁ"
    },
    {
        "departamento": "13",
        "id": "1308",
        "nombre": "SOLOMA"
    },
    {
        "departamento": "02",
        "id": "0304",
        "nombre": "SUMPANGO"
    },
    {
        "departamento": "12",
        "id": "1207",
        "nombre": "TACANÁ"
    },
    {
        "departamento": "16",
        "id": "1604",
        "nombre": "TACTIC"
    },
    {
        "departamento": "12",
        "id": "1209",
        "nombre": "TAJUMULCO"
    },
    {
        "departamento": "16",
        "id": "1605",
        "nombre": "TAMAHÚ"
    },
    {
        "departamento": "06",
        "id": "0609",
        "nombre": "TAXISCO"
    },
    {
        "departamento": "03",
        "id": "0406",
        "nombre": "TECPÁN GUATEMALA"
    },
    {
        "departamento": "13",
        "id": "1321",
        "nombre": "TECTITÁN"
    },
    {
        "departamento": "19",
        "id": "1905",
        "nombre": "TECULUTÁN"
    },
    {
        "departamento": "12",
        "id": "1210",
        "nombre": "TEJUTLA"
    },
    {
        "departamento": "05",
        "id": "0506",
        "nombre": "TIQUISATE"
    },
    {
        "departamento": "13",
        "id": "1315",
        "nombre": "TODOS SANTOS CUCHUMATÁN"
    },
    {
        "departamento": "08",
        "id": "0801",
        "nombre": "TOTONICAPÁN"
    },
    {
        "departamento": "16",
        "id": "1606",
        "nombre": "TUCURÚ"
    },
    {
        "departamento": "13",
        "id": "1332",
        "nombre": "UNION CANTINIL"
    },
    {
        "departamento": "19",
        "id": "1906",
        "nombre": "USUMATLÁN"
    },
    {
        "departamento": "01",
        "id": "0116",
        "nombre": "VILLA CANALES"
    },
    {
        "departamento": "01",
        "id": "0115",
        "nombre": "VILLA NUEVA"
    },
    {
        "departamento": "22",
        "id": "2206",
        "nombre": "YUPILTEPEQUE"
    },
    {
        "departamento": "19",
        "id": "1901",
        "nombre": "ZACAPA"
    },
    {
        "departamento": "14",
        "id": "1404",
        "nombre": "ZACUALPA"
    },
    {
        "departamento": "22",
        "id": "2210",
        "nombre": "ZAPOTITLÁN"
    },
    {
        "departamento": "03",
        "id": "0415",
        "nombre": "ZARAGOZA"
    },
    {
        "departamento": "10",
        "id": "1018",
        "nombre": "ZUNILITO"
    },
    {
        "departamento": "09",
        "id": "0916",
        "nombre": "ZUNIL"
    }
]