import { Empresa } from './empresa';

export class OrdenTallerKanboard {
    constructor(
        public id: number,
        public correlativo: string,
        public compania: string,
        public status: string,
        public fechaHoraSistema: string,
        public taller: string,
        public codRecepcion: string,
        public nombreAsesor: string,
        public tipoOrden: string,
        public orden: string,
        public prioridad: number,
        public placa: string,
        public chasis: string,
        public codCliente: string,
        public nombreCliente: string,
        public codSupervisor: string,
        public nombreSupervisor: string,
        public diaRecepcion: number,
        public mesRecepcion: number,
        public anioRecepcion: number,
        public dias: number,
        public codEtapa: string,
        public etapa: string,
        public diaPreparacion: number,
        public mesPreparacion: number,
        public anioPreparacion: number,
        public tipo1: string,
        public tipo2: string,
        public modelo: string,
        public serie: string,
        public linea: string,
        public codMarca: string,
        public nombreMarca: string,
        public colorVehiculo: string,
        public comentarios: string,
        public cono: string,

        // Relaciones
        public EmpresaId: number,
    ){}

    Empresa: Empresa;

    //Fechas
    fechaHoraSistemaFormato: string;
    fechaHoraSistemaDate: Date;

}