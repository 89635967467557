import { Empresa } from './empresa';
import { Usuario } from './usuario';
import { Danios, EstructuraDescuentos, Imagen, InspeccionGrafica } from './avaluo';
import moment from 'moment';
import { Unidad } from './unidad';
import { DanioSolicitudPDI } from './DanioSolicitudPDI';

export class SolicitudPDI {
    constructor(
        public id: number,
        public numeroInventario: string,
        public fechaActual: string,
        public tipoVenta: string,
        public porcentajeEnganche: number,
        public excepcion: boolean,
        public observaciones: string,
        public fechaPedidoVehiculo: string,
        public estado: string,
        public accesoriosFueraAcuerdo: string,
        public enviadoAPlacas: boolean,
        public vendedor: string,
        public inventario: string,
        public chasis: string,
        public color: string,
        public linea: string,
        public cliente: string,
        public ubicacionAlFacturar: string,
        public polarizado: string,
        public cuero: string,
        public tipoNegocio: string,
        public accesorios: string,
        public N_factura: string,
        public fechaFactura: string,
        public fechaCreacion: string,
        public numeroTareaKanboard: string,
        public numeroPlaca: string,
        public observacionesDatoVenta: string,
        public fechaEntregaCliente: string,
        public fechaEntregadoCliente: string,
        public horaEntregadoCliente: string,
        public documentoFactura: string,
        public documentoRecibo: string,
        public documentoAcuerdoVenta: string,
        public fechaLimite: string,
        public tipo: string,
        public numeroFacturaImportacion: string,
        public estadoCodigo: string,
        public categoria: string,
        public modelo: string,
        public numeroPedido: string,
        public serieCodigo: string,
        public fechaEmbarque: string,
        public sucursales: string,
        public compania: string,
        public tipoPase: string,
        public inspeccionSalida: string,
        public inspeccionEntrada: string,
        public calificacionPDI: string,
        //Relaciones
        public UsuarioId: number,
        public EmpresaId: number,
    ) { this.cargarAccesorios(); }

    Usuario: Usuario;
    Empresa: Empresa;
    DaniosSolicitudesPDI: DanioSolicitudPDI[] = [];

    // Fechas
    fechaActualFormato: string;
    fechaActualDate: Date;

    fechaPedidoVehiculoFormato: string;
    fechaPedidoVehiculoDate: Date;

    fechaFacturaFormato: string;
    fechaFacturaDate: Date;

    fechaCreacionFormato: string;
    fechaCreacionDate: Date;

    fechaEntregaClienteFormato: string;
    fechaEntregaClienteDate: Date;

    fechaEntregadoClienteFormato: string;
    fechaEntregadoClienteDate: Date;

    fechaLimiteFormato: string;
    fechaLimiteDate: Date;

    fechaEmbarqueFormato: string;
    fechaEmbarqueDate: Date;

    //Auxiliares
    accesoriosArray: string[] = [];
    observacionesDatoVentaArray: string[] = [];

    // Inspecciones 
    inspeccionSalidaJson: InspeccionEntradaSalida = new InspeccionEntradaSalida();
    inspeccionEntradaJson: InspeccionEntradaSalida = new InspeccionEntradaSalida();

    cargarAccesorios() {
        try {
            this.accesoriosArray = JSON.parse(this.accesorios) || [];
            //this.observacionesDatoVentaArray = JSON.parse(this.observacionesDatoVenta) || [];
            
        } catch (error) {
            console.error('Error al cargar accesorios: '+ error);
        }
    }
}

export class InspeccionVisualAhorrent {
    ruidos: string;
    notas: string;
    imagenes: Imagen[] = [];
    entregadoPor: string;
}

export class OtrasInspeccionesAhorrent {
    public estadoLlantas: string;
    public aros: boolean;
    public platos: boolean;
    public tapones: boolean;
    public tarjetaCirculacion: boolean;
    public calcomania: boolean;
}

export class InspeccionesDetalladasPDI {
    constructor(
        public cinturones: string = null,
        public bocina: string = null,
        public radio: string = null,
        public encendedor: string = null,
        public ceniceros: string = null,
        public espejosRetrovisor: string = null,
        public alfombrasInterior: string = null,
        public placasYDocumentos: string = null,
        public tapiceriaInterior: string = null,
        public taponGasolina: string = null,
        public llantaRepuesto: string = null,
        public tricketHerramienta: string = null,
        public limpiaBrisas: string = null,
        public vidrios: string = null,
        public antenaRadio: string = null,
        public talcosStop: string = null,
        public talcosPidevias: string = null,
        public faroles: string = null,
        public emblemasYLetreros: string = null,
        public taponesRuedas: string = null,
        public candados: string = null,
        public extinguidor: string = null,
        public triangulo: string = null,
        public Viceras: string = null,
        public ManualUsuario: string = null,
        public PerillaPalanca: string = null,
        public Cabeceras: string = null,
        public Controles: string = null,
        public CopiaLlaves: string = null,
        public CinturonesActivo: boolean = false,
        public BocinaActivo: boolean = false,
        public RadioActivo: boolean = false,
        public EncendedorActivo: boolean = false,
        public CeniceroActivo: boolean = false,
        public EspejosActivo: boolean = false,
        public AlfombrasActivo: boolean = false,
        public PlacasDocumentosActivo: boolean = false,
        public TapiceriaActivo: boolean = false,
        public TaponGasolinaActivo: boolean = false,
        public LlantaRepuestoActivo: boolean = false,
        public TricketHerramientaActivo: boolean = false,
        public LimpiaBrisasActivo: boolean = false,
        public VidriosActivo: boolean = false,
        public AntenaRadioActivo: boolean = false,
        public TalcosRuedasActivo: boolean = false,
        public TalcosPideviasActivo: boolean = false,
        public FarolesActivo: boolean = false,
        public EmblemasLetrerosActivo: boolean = false,
        public TaponesRuedasActivo: boolean = false,
        public CandadosActivo: boolean = false,
        public ExtinguidorActivo: boolean = false,
        public TrianguloActivo: boolean = false,
        public VicerasActivo: boolean = false,
        public ManualUsuarioActivo: boolean = false,
        public PerillaPalancaActivo: boolean = false,
        public CabecerasActivo: boolean = false,
        public ControlesActivo: boolean = false,
        public CopiaLlavesActivo: boolean = false,
    ) { }

}

export class InspeccionEntradaSalida {

    constructor(
        public inspeccionGrafica: string = null,
        public listadoDescuentos: string = null,
        public inspeccionesVisuales: string = null,
        public otrasInspecciones: string = null,
        public inspeccionesDetalladas: string = null,
        public descuentoInspeccionMecanicaVisual: number = 0,
        public otrosDescuentos: number = 0,
        public descuentoInspeccionGrafica: number = 0,
        public horaEntrega: string = null,
        public fechaEntrega: string = null,
        public danios: string = null,
        public gasolinaSalida: string = null,
        public gasolinaEntrada: string = null,
        public sucursalSalida: string = null,
        public sucursalEntrada: string = null,
    ) {

        this.fechaEntrega = this.convertirFecha(this.fechaEntrega, 'YYYY-MM-DD', 'YYYY-MM-DD');
        this.fechaEntregaDate = this.convertirFecha(this.fechaEntrega, 'YYYY-MM-DD', 'date');
    }

    public listadoInspeccionesVisualesJson: InspeccionVisualAhorrent = new InspeccionVisualAhorrent();
    public DescuentosJson: EstructuraDescuentos = new EstructuraDescuentos();
    public otrasInspeccionesJson: OtrasInspeccionesAhorrent = new OtrasInspeccionesAhorrent();
    public inspeccionesDetalladasJson: InspeccionesDetalladasPDI = new InspeccionesDetalladasPDI();
    public listadoInspeccionesGraficasJson: InspeccionGrafica = new InspeccionGrafica();
    public listadoDaniosJson: Danios = new Danios();

    fechaEntregaDate: Date;

    private convertirFecha(fecha: any, formatoEntrada: string, formatoSalida: string) {
        var fecha;
        if (formatoEntrada === 'date') fecha = moment(fecha);
        else fecha = moment(fecha, formatoEntrada);

        var res;
        if (formatoSalida === 'date') res = fecha;
        else res = fecha.format(formatoSalida);
        return res;
    }
}