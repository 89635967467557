<div class="container" id="dialogOverflow">
    <h4 mat-dialog-title>{{titulo}}</h4>
    <form #formRegistro="ngForm" *ngIf="registro">
    <mat-dialog-content>
        <div class="container">
            <div class="row">
                <div class="col" *ngIf="configuracion && configuracion.rubrosGastosArray && configuracion.rubrosGastosArray.length != 0">
                    <mat-form-field>
                        <mat-label>Tipo</mat-label>
                        <mat-select
                            [(ngModel)]="registro.rubro" name="rubrosId"
                            [disabled]="modoDetalle" required>
                            <mat-option *ngFor="let tipo of configuracion.rubrosGastosArray" [value]="tipo.nombre">
                                {{tipo.nombre}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <mat-form-field>
                        <mat-label>Descripción</mat-label>
                        <textarea matInput name="descripcion" [(ngModel)]="registro.descripcion"
                            [disabled]="modoDetalle"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <mat-form-field>
                        <mat-label>Total</mat-label>
                        <input matInput currencyMask [options]="{ prefix: (auth.currencySymbol() + ' '), align:'left', precision: 2}" name="total"  [(ngModel)]="registro.total" [disabled]="modoDetalle">
                    </mat-form-field>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <!-- * * * Botones * * * -->
        <ng-container>
            <div class="row">
                <div class="col-md-12" style="text-align: right;">
                    <!-- Guardar -->
                    <button (click)="guardar()" color="primary" mat-raised-button type="button"
                    [disabled]="cargando || !(formRegistro.valid && formularioValido())" class="margenBoton"
                    *ngIf="(auth.tienePermisoPara(apiURLs.permisos.CAMBIOS_ESTADO_UNIDADES_AHORRENT))">
                    Guardar
                    <mat-icon class="iconoBoton">save</mat-icon>
                    </button>
                    <button mat-raised-button color="warn" style="margin: 10px;" (click)="abrirEliminar()"
                    [disabled]="cargando"
                    *ngIf="(auth.tienePermisoPara(apiURLs.permisos.CAMBIOS_ESTADO_UNIDADES_AHORRENT)) && modoEdicion"
                        class="margenBoton">Eliminar
                        <mat-icon class="iconoBoton">close</mat-icon>
                    </button>
                    <button mat-stroked-button style="margin: 10px;" (click)="cerrar()"
                        class="margenBoton">Cerrar</button>
                </div>
            </div>
        </ng-container>
    </mat-dialog-actions>
    </form>
    <mat-progress-bar mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
</div>