import { DetalleRequisicion } from "./DetalleRequisicion";
import { Usuario } from './usuario';

export class Requisicion {
    constructor(
        public id:                              number,
        public estado:                          string,
        public fecha:                           string,
        public fechaAprobacion:                 string,
        public nit:                             string,
        public cliente:                         string,
        public placa:                           string,
        public chasis:                          string,
        public vehiculo:                        string,
        public modelo:                          number,
        public etapaTaller:                     string,
        public canal:                           string,
        public tipoEnvio:                       string,
        public boleta:                          string,
        public pedidoSistema:                   string,
        public pedidoFabrica:                   string,
        public kilometraje:                     number,
        public vehiculoGarantia:                boolean,
        public pendienteCotizacionURL:          string,
        public observaciones:                   string,
        // Relaciones
        public UsuarioId:                       number,

    ){}
    Usuario: Usuario;
    DetallesRequisicion: DetalleRequisicion[];

    fechaFormato: string;
    fechaDate: Date;

    fechaAprobacionFormato: string;
    fechaAprobacionDate: Date;

}