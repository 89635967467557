import { APIUrls } from '../api/apiUrls';
import { Empresa } from './empresa';
import { JSONConverters } from './JSONConverters';

export class ConfiguracionBestune {
    constructor(
        public id: number,
        public modelos: string,
        public marcasVehiculos: string,
        // Relaciones
        public EmpresaId: number,
    ){
        this.cargarArrays();
    }

    jsonConverts: JSONConverters = new JSONConverters();
    modelosArray: any[];
    marcasVehiculosArray: any[];

    Empresa: Empresa;

    cargarArrays() {
        this.modelosArray = this.modelos ? JSON.parse(this.modelos) : [];
        this.marcasVehiculosArray = this.marcasVehiculos ? JSON.parse(this.marcasVehiculos) : [];
    }
}