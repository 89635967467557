import { Presupuesto } from "./presupuesto";
import { PuntoPresupuesto } from "./PuntoPresupuesto";
import { RepuestoItemPuntoPresupuesto } from "./RepuestoItemPuntoPresupuesto";

export class ItemPuntoPresupuesto {
    constructor(
        public id: number,
        public tipo: string,
        public codigo: string,
        public descripcion: string,
        public precio: number,
        public rechazado: boolean,
        public motivoRechazo: string,
        public porcentajeDescuento: number,
        public  presupuestoRecuperado: boolean,
        public  manoObraExterna: boolean,
        public  repuestoExterno: boolean,

        //Relaciones
        public PresupuestoId: number,
        public PuntoPresupuestoId: number,
        public ItemPuntoPresupuestoId: number,
        
    ) { }

    Presupuesto: Presupuesto;
    PuntoPresupuesto: PuntoPresupuesto;
    ItemPuntoPresupuesto: ItemPuntoPresupuesto;

    items: RepuestoItemPuntoPresupuesto[] = []
    color: any

}