import { ClienteAhorrent } from "./clienteAhorrent";
import { LineaVehiculo } from "./lineaVehiculo";
import { MarcaVehiculo } from "./marcaVehiculo";
import { Usuario } from './usuario'

export class SeguimientoCliente {
    libraryService: any;
    constructor(
        public id: number,
        public tipo: string,       
        public fecha: string,     
        public observaciones: string,         
        // Relaciones
        public ClienteId: number,
        public UsuarioId: number,
        public MarcaVehiculoId: number,
        public LineaVehiculoId: number,
    ){ }

    cliente: ClienteAhorrent;
    usuario: Usuario;
    marcaVehiculo: MarcaVehiculo;
    lineaVehiculo: LineaVehiculo;
    fechaDate: Date;
    fechaFormato: string;
    tipoFormato:string;

    nombreMarca: string;
    nombreLinea: string;

}