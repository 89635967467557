import { Empresa } from "./empresa";

export class MembreteEmpresa {
    constructor(
        public id:                          number,
        public nombre:                      string,
        public descripcion:                 string,
        public margenArriba:                number,
        public margenIzquierdo:             number,
        public margenAbajo:                 number,
        public margenDerecho:               number,
        public margenInterno:               number,
        public correlativo:                 number,
        public imagenHeader:                string,
        public imagenFooter:                string,
        public activo:                      boolean,
        public eliminado:                   boolean,
        public bloqueInformacion:           string,
        public bloqueDetalles:              string,
        public bloqueEnvio:                 string,
        public bloqueReferencias:           string,
        public logoPromocional:             string,
        public logoHeader:                  string,
        public nombreCompania:              string,
        public colorTitulo:                 string,

        // Relaciones
        public EmpresaId:                   number,
    ){}

    empresa:    Empresa;
}