import { ServicioUnidad } from "./servicioUnidad";
import { Unidad } from "./unidad";

export class ServicioPendienteUnidad {
    constructor(
        public id:number,
        public nombre: string,
        public observaciones: string,
        public pendiente: boolean,
        public kilometrajeAgregado: number,
        //Relaciones
        public UnidadId: number,
        public ServicioUnidadId: number,
    ){}

    Unidad: Unidad;
    ServicioUnidad : ServicioUnidad;

    //auxiliares
    pendienteCheckbox:boolean;

}