import { Concesionario } from "./concesionario";
import { Usuario } from "./usuario";

export class Cita {
    constructor(
        public id: number,
        public nombres: string,
        public apellidos: string,
        public nombreCompleto: string,
        public correo: string,
        public telefono: string,
        public lineaVehiculo: string,
        public servicio: string,
        public rangoServicio: string,
        public fecha: string,
        public horaInicio: string,      
        public horaFin: string,
        public fechaConfirmacion: string,
        public kilometraje: string,
        public placa: string,
        public observaciones: string,
        public imagenes: string,
        public confirmada: boolean,
        public uuid: string,
        public horaPropuesta: string,
        public fechaPropuesta: Date,
        public nit: string,
        public fechaInicioAtencion: string,
        public fechaFinAtencion: string,
        public citaNoAtendida: boolean,
        public razonNoAtendida: string,
        public observacionesNoAtendida: string,
        public minutosAtencion: number,
        public confirmadaConCliente: boolean,
        // SOB API
        public chasis: string,
        public marca: string,
        public descripcionLinea: string,
        public anioModelo: string,
        public colorExterno: string,
        public colorInterno: string,
        public servicios: string,
        public origen: number,
        public suborigen: number,
        public preferenciaContacto: number,
        //Relaciones   
        public AgenteId: number,  
        public ConcesionarioId: number,     
        public CampaniaPreventivaId: number,
        public VehiculoCampaniaId: number,
    ){
        this.obtenerEstado();
    }

    fechaFormato: string;
    fechaDate: Date;
    fechaConfirmacionFormato: string;
    fechaConfirmacionDate: Date;
    fechaPropuestaFormato: string;
    fechaPropuestaDate: Date;
    horarioCompleto: string;
    fechaInicioAtencionFormato: string;
    fechaInicioAtencionDate: string;
    fechaFinAtencionFormato: string;
    fechaFinAtencionDate: string;
    fechacreatedAtFormato: string;
    fechacreatedAtDate: Date;

    agente: Usuario;
    nombreAgente: string;
    concesionario: Concesionario;
    nombreConcesionario: string;
    ignorarValidacion: boolean;
    imagenesArray: string[] = [];
    nombreTipoServicio: string;
    nombreRangoServicio: string;

    serviciosJSON: any[] = [];
    serviciosFormato: string;
    origenString: string;
    suborigenString: string;
    estadoCita: string;
    estadoCitaColor: string;
    horaCompletaAtencion: string;
    horaInicioAtencion: string;
    horaFinAtencion: string;

    obtenerEstado(){
        this.estadoCita = 
            this.citaNoAtendida ? 
                //Marcada como no atendida
                'No atendida' : 
                ( this.confirmada ? 
                    //Confirmada
                    ( this.fechaInicioAtencion == null ?
                        //Aun no inicia la cita
                        ( this.confirmadaConCliente ? 'Confirmada con cliente' : 'Confirmada' ) : 
                        //Cita iniciada
                        ( this.fechaFinAtencion == null ? 'En proceso' : 'Finalizada' ) ) : 
                    //Son confirmación
                    'Pendiente de confirmar' );

        this.estadoCitaColor = 
        this.citaNoAtendida ? 
            //Marcada como no atendida (rojo)
            '#CE4141' : 
            ( this.confirmada ? 
                //Confirmada
                ( this.fechaInicioAtencion == null ?
                    //Aun no inicia la cita
                    ( this.confirmadaConCliente ? '#238F23' : '#238F23' ) : 
                    //Cita iniciada
                    ( this.fechaFinAtencion == null ? '#CEB241' : '#FFFFFF' ) ) : 
                //Son confirmación
                '#A740AA' );
    }
}

export class HorarioCita {
    constructor(
        public horaInicio: string,
        public horaFin: string,
        public AgenteId: number,
        public ConcesionarioId: number,
    ){}

    agente: Usuario;
    concesionario: Concesionario;
}