export default [
    {
        id: "GTQ",
        nombre: "QUETZAL"
    },
    {
        id: "USD",
        nombre: "DOLAR ESTADOUNIDENSE"
    },
    {
        id: "AFN",
        nombre: "AFGHANI"
    },
    {
        id: "DZD",
        nombre: "ALGERIAN DINAR"
    },
    {
        id: "AZN",
        nombre: "AZERBAIJANIAN MANAT"
    },
    {
        id: "BHD",
        nombre: "BAHRAINI DINAR"
    },
    {
        id: "THB",
        nombre: "BAHT"
    },
    {
        id: "PAB",
        nombre: "BALBOA"
    },
    {
        id: "BMD",
        nombre: "BERMUDIAN DOLLAR"
    },
    {
        id: "VEB",
        nombre: "BOLIVAR"
    },
    {
        id: "VEF",
        nombre: "BOLIVAR VENEZOLANO FUERTE"
    },
    {
        id: "BOB",
        nombre: "BOLIVIANO"
    },
    {
        id: "CVE",
        nombre: "CAPE VERDE ESCUDO"
    },
    {
        id: "GHC",
        nombre: "CEDI"
    },
    {
        id: "XOF",
        nombre: "CFA FRANC BCEAO"
    },
    {
        id: "XAF",
        nombre: "CFA FRANC BEAC"
    },
    {
        id: "XPF",
        nombre: "CFP FRANC"
    },
    {
        id: "CRC",
        nombre: "COLON COSTARRICENSE"
    },
    {
        id: "SVC",
        nombre: "COLON SALVADOREÑO"
    },
    {
        id: "KMF",
        nombre: "COMORO FRANC"
    },
    {
        id: "BAM",
        nombre: "CONVERTIBLE MARKS"
    },
    {
        id: "NIO",
        nombre: "CORDOBA ORO"
    },
    {
        id: "CYP",
        nombre: "CYPRUS POUND"
    },
    {
        id: "CZK",
        nombre: "CZECH KORUNA"
    },
    {
        id: "GMD",
        nombre: "DALASI"
    },
    {
        id: "MKD",
        nombre: "DENAR"
    },
    {
        id: "KWD",
        nombre: "DINAR DE KUWAIT"
    },
    {
        id: "SDD",
        nombre: "DINAR DE SUDAN"
    },
    {
        id: "YUM",
        nombre: "DINAR DE YUGOSLAVIA"
    },
    {
        id: "JOD",
        nombre: "DINAR JORDANO"
    },
    {
        id: "LYD",
        nombre: "DINAR LIBIO"
    },
    {
        id: "CSD",
        nombre: "DINAR SERBIO"
    },
    {
        id: "DJF",
        nombre: "DJIBOUTI FRANC"
    },
    {
        id: "STD",
        nombre: "DOBRA"
    },
    {
        id: "AUD",
        nombre: "DOLAR AUSTRALIANO"
    },
    {
        id: "BZD",
        nombre: "DOLAR BELICEÑO"
    },
    {
        id: "CAD",
        nombre: "DOLAR CANADIENSE"
    },
    {
        id: "BSD",
        nombre: "DOLAR DE BAHAMAS"
    },
    {
        id: "BBD",
        nombre: "DOLAR DE BARBADOS"
    },
    {
        id: "BND",
        nombre: "DOLAR DE BRUNEI"
    },
    {
        id: "FJD",
        nombre: "DOLAR DE FIJI"
    },
    {
        id: "GYD",
        nombre: "DOLAR DE GUYANA"
    },
    {
        id: "HKD",
        nombre: "DOLAR DE HONG KONG"
    },
    {
        id: "KYD",
        nombre: "DOLAR DE LAS ISLAS CAIMAN"
    },
    {
        id: "SBD",
        nombre: "DOLAR DE LAS ISLAS SALOMON"
    },
    {
        id: "NAD",
        nombre: "DOLAR DE NAMIBIA"
    },
    {
        id: "NZD",
        nombre: "DOLAR DE NUEVA ZELANDA"
    },
    {
        id: "TWD",
        nombre: "DOLAR DE NUEVO TAIWAN"
    },
    {
        id: "SGD",
        nombre: "DOLAR DE SINGAPUR"
    },
    {
        id: "TTD",
        nombre: "DOLAR DE TRINIDAD Y TOBAGO"
    },
    {
        id: "ZWD",
        nombre: "DOLAR DE ZIMBAWE"
    },
    {
        id: "XCD",
        nombre: "DOLAR DEL ESTE CARIBEÑO"
    },
    {
        id: "JMD",
        nombre: "DOLAR JAMAIQUINO"
    },
    {
        id: "LRD",
        nombre: "DOLAR LIBERIANO"
    },
    {
        id: "VND",
        nombre: "DONG"
    },
    {
        id: "GRD",
        nombre: "DRACMA"
    },
    {
        id: "AMD",
        nombre: "DRAM DE ARMENIA"
    },
    {
        id: "PTE",
        nombre: "ESCUDO PORTUGUES"
    },
    {
        id: "ETB",
        nombre: "ETHIOPIAN BIRR"
    },
    {
        id: "EUR",
        nombre: "EURO"
    },
    {
        id: "HUF",
        nombre: "FORINT"
    },
    {
        id: "CDF",
        nombre: "FRANC CONGOLAIS"
    },
    {
        id: "BIF",
        nombre: "FRANCO DE BURUNDI"
    },
    {
        id: "GNF",
        nombre: "FRANCO DE GUINEA"
    },
    {
        id: "LUF",
        nombre: "FRANCO DE LUXEMBURGO"
    },
    {
        id: "FRF",
        nombre: "FRANCO FRANCES"
    },
    {
        id: "CHF",
        nombre: "FRANCO SUIZO"
    },
    {
        id: "XFO",
        nombre: "GOLD-FRANC"
    },
    {
        id: "HTG",
        nombre: "GOURDE"
    },
    {
        id: "PYG",
        nombre: "GUARANI"
    },
    {
        id: "ANG",
        nombre: "GUILDER DE ANTILLAS HOLANDESAS"
    },
    {
        id: "AWG",
        nombre: "GUILDER DE ARUBA"
    },
    {
        id: "NLG",
        nombre: "GUILDER DE HOLANDA"
    },
    {
        id: "SRG",
        nombre: "GUILDER DE SURINAME"
    },
    {
        id: "UAH",
        nombre: "HRYVNIA"
    },
    {
        id: "ISK",
        nombre: "ICELAND KRONA"
    },
    {
        id: "IRR",
        nombre: "IRANIAN RIAL"
    },
    {
        id: "IQD",
        nombre: "IRAQI DINAR"
    },
    {
        id: "KES",
        nombre: "KENYAN SHILLING"
    },
    {
        id: "PGK",
        nombre: "KINA"
    },
    {
        id: "LAK",
        nombre: "KIP"
    },
    {
        id: "SEK",
        nombre: "KRONA DE SUECIA"
    },
    {
        id: "DKK",
        nombre: "KRONE DANES"
    },
    {
        id: "NOK",
        nombre: "KRONE NORUEGO"
    },
    {
        id: "EEK",
        nombre: "KROON"
    },
    {
        id: "HRK",
        nombre: "KUNA CROATA"
    },
    {
        id: "ZMK",
        nombre: "KWACHA"
    },
    {
        id: "MWK",
        nombre: "KWACHA"
    },
    {
        id: "AOA",
        nombre: "KWANZA"
    },
    {
        id: "MMK",
        nombre: "KYAT"
    },
    {
        id: "GEL",
        nombre: "LARI"
    },
    {
        id: "LVL",
        nombre: "LATVIAN LATS"
    },
    {
        id: "ALL",
        nombre: "LEK"
    },
    {
        id: "HNL",
        nombre: "LEMPIRA"
    },
    {
        id: "SLL",
        nombre: "LEONE"
    },
    {
        id: "ROL",
        nombre: "LEU"
    },
    {
        id: "BGN",
        nombre: "LEV BULGARO"
    },
    {
        id: "GIP",
        nombre: "LIBRA DE GIBRALTAR"
    },
    {
        id: "FKP",
        nombre: "LIBRA DE LAS ISLAS FALKLAND"
    },
    {
        id: "SHP",
        nombre: "LIBRA DE SANTA ELENA"
    },
    {
        id: "EGP",
        nombre: "LIBRA EGIPCIA"
    },
    {
        id: "GBP",
        nombre: "LIBRA ESTERLINA"
    },
    {
        id: "IEP",
        nombre: "LIBRA IRLANDESA"
    },
    {
        id: "LBP",
        nombre: "LIBRA LIBANESA"
    },
    {
        id: "SYP",
        nombre: "LIBRA SIRIA"
    },
    {
        id: "SZL",
        nombre: "LILANGENI"
    },
    {
        id: "MTL",
        nombre: "LIRA DE MALTA"
    },
    {
        id: "ITL",
        nombre: "LIRA ITALIANA"
    },
    {
        id: "LTL",
        nombre: "LITHUANIAN LITUS"
    },
    {
        id: "LSL",
        nombre: "LOTI"
    },
    {
        id: "MGF",
        nombre: "MALAGASY FRANC"
    },
    {
        id: "MYR",
        nombre: "MALAYSIAN RINGGIT"
    },
    {
        id: "TMM",
        nombre: "MANAT"
    },
    {
        id: "MZM",
        nombre: "METICAL"
    },
    {
        id: "MDL",
        nombre: "MOLDOVAN LEU"
    },
    {
        id: "MAD",
        nombre: "MOROCCAN DIRHAM"
    },
    {
        id: "BOV",
        nombre: "MVDOL"
    },
    {
        id: "NGN",
        nombre: "NAIRA"
    },
    {
        id: "ERN",
        nombre: "NAKFA"
    },
    {
        id: "ILS",
        nombre: "NEW ISRAELI SHEQEL"
    },
    {
        id: "BTN",
        nombre: "NGULTRUM"
    },
    {
        id: "PEN",
        nombre: "NUEVO SOL"
    },
    {
        id: "XAU",
        nombre: "ORO"
    },
    {
        id: "MRO",
        nombre: "OUGUIYA"
    },
    {
        id: "TOP",
        nombre: "PAÑANGA"
    },
    {
        id: "XPD",
        nombre: "PALLADIUM"
    },
    {
        id: "MOP",
        nombre: "PATACA"
    },
    {
        id: "ARS",
        nombre: "PESO ARGENTINO"
    },
    {
        id: "CLP",
        nombre: "PESO CHILENO"
    },
    {
        id: "COP",
        nombre: "PESO COLOMBIANO"
    },
    {
        id: "CUP",
        nombre: "PESO CUBANO"
    },
    {
        id: "GWP",
        nombre: "PESO DE GUINEA-BISSAU"
    },
    {
        id: "DOP",
        nombre: "PESO DOMINICANO"
    },
    {
        id: "PHP",
        nombre: "PESO FILIPINO"
    },
    {
        id: "MXN",
        nombre: "PESO MEXICANO"
    },
    {
        id: "UYU",
        nombre: "PESO URUGUAYO"
    },
    {
        id: "XAG",
        nombre: "PLATA"
    },
    {
        id: "XPT",
        nombre: "PLATINUM"
    },
    {
        id: "BWP",
        nombre: "PULA"
    },
    {
        id: "QAR",
        nombre: "QATARI RIAL"
    },
    {
        id: "ZAR",
        nombre: "RAND"
    },
    {
        id: "BRL",
        nombre: "REAL BRASILEÑO"
    },
    {
        id: "OMR",
        nombre: "RIAL OMANI"
    },
    {
        id: "KHR",
        nombre: "RIEL"
    },
    {
        id: "BYR",
        nombre: "RUBLO BIELORRUSO"
    },
    {
        id: "RUR",
        nombre: "RUBLO RUSO"
    },
    {
        id: "RUB",
        nombre: "RUBLO RUSO"
    },
    {
        id: "MVR",
        nombre: "RUFIYAA"
    },
    {
        id: "INR",
        nombre: "RUPIA DE LA INDIA"
    },
    {
        id: "MUR",
        nombre: "RUPIA DE MAURITIUS"
    },
    {
        id: "NPR",
        nombre: "RUPIA DE NEPAL"
    },
    {
        id: "SCR",
        nombre: "RUPIA DE SEYCHELLES"
    },
    {
        id: "LKR",
        nombre: "RUPIA DE SRI LANKA"
    },
    {
        id: "PKR",
        nombre: "RUPIA PAKISTANI"
    },
    {
        id: "IDR",
        nombre: "RUPIAH"
    },
    {
        id: "RWF",
        nombre: "RWANDA FRANC"
    },
    {
        id: "SAR",
        nombre: "SAUDI RIYAL"
    },
    {
        id: "XDR",
        nombre: "SDR"
    },
    {
        id: "UGX",
        nombre: "SHILLING DE UGANDA"
    },
    {
        id: "SKK",
        nombre: "SLOVAK KORUNA"
    },
    {
        id: "KGS",
        nombre: "SOM"
    },
    {
        id: "SOS",
        nombre: "SOMALI SHILLING"
    },
    {
        id: "TJS",
        nombre: "SOMONI"
    },
    {
        id: "BDT",
        nombre: "TAKA"
    },
    {
        id: "WST",
        nombre: "TALA"
    },
    {
        id: "TZS",
        nombre: "TANZANIAN SHILLING"
    },
    {
        id: "KZT",
        nombre: "TENGE"
    },
    {
        id: "TPE",
        nombre: "TIMOR ESCUDO"
    },
    {
        id: "SIT",
        nombre: "TOLAR"
    },
    {
        id: "MNT",
        nombre: "TUGRIK"
    },
    {
        id: "TND",
        nombre: "TUNISIAN DINAR"
    },
    {
        id: "TRY",
        nombre: "TURKISH LIRA"
    },
    {
        id: "TRL",
        nombre: "TURKISH LIRA"
    },
    {
        id: "AED",
        nombre: "UAE DIRHAM"
    },
    {
        id: "XFU",
        nombre: "UIC-FRANC"
    },
    {
        id: "UZS",
        nombre: "UZBEKISTAN SUM"
    },
    {
        id: "VUV",
        nombre: "VATU"
    },
    {
        id: "KRW",
        nombre: "WON NORCOREANO"
    },
    {
        id: "YER",
        nombre: "YEMENI RIAL"
    },
    {
        id: "JPY",
        nombre: "YEN"
    },
    {
        id: "CNY",
        nombre: "YUAN RENMINBI"
    },
    {
        id: "PLN",
        nombre: "ZLOTY"
    }
];