
export class ListaNegra {
    constructor(
        public id:                  number,
        public nombre:              string,
        public tipo:                string,
        public observaciones:       string,
        public rti:                 boolean,
        public rts:                 boolean,
        public ministerioPublico:   boolean,
        public eliminado: boolean,
        public empresa: string,
    ){}

    porcentaje: number;
    check: boolean;
}