import { Kilometraje } from "./Kilometraje";
import { Servicio } from "./servicio";
import { Version } from "./version";

export class ServicioVersion {
    constructor(
        public id: number,
        public ServicioId: number,
        public VersionId: number,
        public KilometrajeId: number
    ){}

    Servicio: Servicio;
    Version: Version;
    Kilometraje: Kilometraje;

    //Auxiliares
    checkbox: boolean;
}