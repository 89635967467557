export class Concesionario {
    constructor(
        public id: number,
        public nombre: string,          
        public logo: string,
        public descripcion: string,
        public direccion: string,       
        public activo: boolean,    
        public eliminado: boolean,
        public horaInicioAtencion: string,      
        public horaFinAtencion: string,      
        public diasAtencion: string,
        public horariosAtencion: string,
        public fechasFestivas: string,
        public razonesNoAtencion: string,

        public codigoZip: string,
        public numConcesionario: string,
        public razonSocial: string,
        public servicioRapido: boolean,
        public pais: string,
        public uf: string,
        public ciudad: string,
        public latitud: number,
        public longitud: number,
        public ddd: string,
        public email: string,
        public sitio: string,
        public servicioEmail: string,
        public facebookURL: string,
        public telefono: string,
        public fechaFeriado: Date,
        public abreFeriados: boolean,
        public barrio: string,
        public region: string,
    ){}

    diasAtencionArray: string[] = [];
    horariosAtencionJSON: any = {
        lunes: { cerrado: false, horaInicio: '08:00', horaFin: '17:00' },
        martes: { cerrado: false, horaInicio: '08:00', horaFin: '17:00' },
        miercoles: { cerrado: false, horaInicio: '08:00', horaFin: '17:00' },
        jueves: { cerrado: false, horaInicio: '08:00', horaFin: '17:00' },
        viernes: { cerrado: false, horaInicio: '08:00', horaFin: '17:00' },
        sabado: { cerrado: false, horaInicio: '08:00', horaFin: '17:00' },
        domingo: { cerrado: false, horaInicio: '08:00', horaFin: '17:00' },
    };
    fechasFestivasJSON: string[] = [];
    razonesNoAtencionJSON: any[] = [];

    fechaFeriadoDate: Date;
    fechaFeriadoFormato: string;
}