export class PreguntaEncuestaAhorrent {
    constructor(
        public pregunta: string,
        public tipo: string,
        public opciones: string,
    ) {
        this.cargarArrays();
    }

    public tipoFormat: string;
    public opcionesArray: any[];

    cargarArrays() {
        this.opcionesArray = this.opciones ? JSON.parse(this.opciones) : [];
    }

    guardar() {
        this.opciones = JSON.stringify(this.opcionesArray);
    }

}