<div class="row">
    <div class="col-md-12">
        <h3>{{data.titulo}}</h3>
        <div *ngIf="data.mensaje" [innerHTML]="data.mensaje"></div>
    </div>
</div>
<ng-container *ngIf="mostrarObservaciones">
    <br>
    <div class="row">
        <!-- Observaciones -->
        <div class="col">
            <mat-form-field>
                <mat-label>Observaciones</mat-label>
                <textarea matInput name="observacionesSolicitud" [(ngModel)]="observaciones"></textarea>
            </mat-form-field>
        </div>
    </div>
</ng-container>
<br/>
<div class="row">
    <div class="col-md-12" style="text-align: right;">
        <button mat-stroked-button (click)="cerrar()" class="margenBoton">Cerrar</button>
        <button *ngFor="let opcion of data.opciones" class="margenBoton"
            mat-raised-button color="primary"
            (click)="confirmar(opcion.id)"
        >{{ opcion.nombre }}</button>
    </div>
</div>