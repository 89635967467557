import { Empresa } from './empresa';
import { JSONConverters } from './JSONConverters';

export class ConfiguracionFacturaSV {
    constructor(
        public id: number,
        public ultimaActualizacionFacturasSV: string,
        public historialActualizacionesFacturasSV: string,
        //Relaciones
        public EmpresaId: number,
    ){
        this.cargarArrays();
        this.conversiones();
    }

    jsonConverts: JSONConverters = new JSONConverters();

    historialActualizacionesFacturasSVArray: string[];
    ultimaActualizacionFacturasSVFormato: string;

    Empresa: Empresa;

    cargarArrays() {
        this.historialActualizacionesFacturasSVArray = this.historialActualizacionesFacturasSV ? JSON.parse(this.historialActualizacionesFacturasSV) : [];

    }

    cargarHorarios(){
        
    }

    conversiones(){
        
    }

}