import { Empresa } from './empresa';
import { JSONConverters } from './JSONConverters';

export class ConfiguracionOrdenTallerKanboard {
    constructor(
        public id: number,
        public sucursales: string,
        public coloresTareas: string,
        //Relaciones
        public EmpresaId: number,
    ){
        this.cargarArrays();
    }

    jsonConverts: JSONConverters = new JSONConverters();

    sucursalesArray: any[];
    coloresTareasArray: any[];
    

    Empresa: Empresa;

    cargarArrays() {
        this.sucursalesArray = this.sucursales ? JSON.parse(this.sucursales) : [];
        this.coloresTareasArray = this.coloresTareas ? JSON.parse(this.coloresTareas) : [];
    }

    guardarArrays() {
        this.sucursales = JSON.stringify(this.sucursalesArray);
        this.coloresTareas = JSON.stringify(this.coloresTareasArray);
    }

}
