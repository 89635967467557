import { MarcaVehiculo } from "./marcaVehiculo";

export class ModeloVehiculo{ 
    public anio: string;
    public precioLista: number;
}

export class LineaVehiculo{
    constructor(
        public id: number,
        public nombre: string,
        public descripcion: string,
        public modelos: string,
        public usarTransparenciaPrecios: boolean,
        public isDeleted: boolean,
        public imagen: string,
        public noMostrarLandingPage: boolean,
    ){}

    public listadoModelos: ModeloVehiculo[] = [];

    
    public listadoModelosString() {
        this.modelos = JSON.stringify(this.listadoModelos);
    }

    public vehiculo: MarcaVehiculo;
}