<mat-form-field [class.selectBuscador]="modoBuscador" (click)="inputBuscador.focus()">
    <mat-label>{{placeholder}}</mat-label>
    <mat-select [(ngModel)]="registro[campoRegistro]" (ngModelChange)='accion()' name="registro{{(name ? name : null)}}" id="{{(name ? name : null)}}"
        [disabled]="disabled" [required]="obligatorio" (openedChange)="openedChange($event)" [multiple]="multiple">
        <mat-form-field class="buscador-fijo">
            <input #inputBuscador matInput [(ngModel)]="texto" (keyup)="applyFilter($event.target.value)" 
            name='buscador' placeholder="Buscar" (keydown)="$event.stopPropagation()">
        </mat-form-field>
        <mat-option [value]="'N/A'" *ngIf="valorNulo">N/A</mat-option>
        <mat-option [value]="-1" *ngIf="valorNuloNumerico">N/A</mat-option>
        <ng-container *ngFor="let control of controles">
            <mat-option (click)="clickCall()" *ngIf="control.mostrar && control.registro && campoRegistros && campoVisibleRegistros && control.registro[campoRegistros] != undefined &&  control.registro[campoVisibleRegistros] != undefined" [value]="control.registro[campoRegistros]">{{control.registro[campoVisibleRegistros]}}</mat-option>
        </ng-container>
    </mat-select>
</mat-form-field>