<div class="row">
    <div class="col-md-12">
        <h3>{{data.titulo}}</h3>
        <p *ngIf="data.texto" [innerHTML]="data.texto"></p>
    </div>
</div>
<br/>
<div class="row">
    <div class="col-md-12" style="text-align: right;">
        <button mat-raised-button color="primary" [mat-dialog-close]="data.titulo">OK</button>
    </div>
</div>