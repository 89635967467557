import { Injectable } from '@angular/core';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { JSONConverters } from '../models/JSONConverters';
import { CampaniaPreventiva } from '../models/CampaniaPreventiva';
import { VehiculoCampania } from '../models/VehiculoCampania';
import * as FileSaver from 'file-saver';
import * as Papa from 'papaparse';
import { ReservacionCampania } from '../models/ReservacionCampania';
import { Cita } from '../models/cita';

@Injectable()
export class CampaniaPreventivaService { 
    apiUrls: APIUrls = new  APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
    ) {
        //Papa Promise
        Papa.parsePromise = function (file) {
            return new Promise(function (complete, error) {
                Papa.parse(file, {
                    header: true,
                    skipEmptyLines: true,
                    complete,
                    error
                });
            });
        };

     }

    // * * * CampaniaPreventiva * * * 
    async obtenerTodosCampaniaPreventiva() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.campaniasPreventivasURL;
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                var element = json[i];
                var registro = this.jsonConverters.campaniaPreventivaDeJSON(element);
                registros.push(registro);
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerCampaniaPreventivaPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.campaniasPreventivasURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.campaniaPreventivaDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearCampaniaPreventiva(registro: CampaniaPreventiva) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.campaniasPreventivasURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Campaña Preventiva creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarCampaniaPreventiva(registro: CampaniaPreventiva) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.campaniasPreventivasURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Campaña Preventiva actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarCampaniaPreventiva(CampaniaPreventivaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.campaniasPreventivasURL + '/id/' + CampaniaPreventivaId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Campaña Preventiva eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * Vehiculos * * *

    async obtenerTodosVehiculoCampania() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.vehiculosCampaniasURL;
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                var element = json[i];
                var registro = this.jsonConverters.vehiculoCampaniaDeJSON(element);
                registros.push(registro);
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }
    
    async obtenerVehiculoCampaniaPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.vehiculosCampaniasURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.vehiculoCampaniaDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }
    
    // Crear
    public async crearVehiculoCampania(registro: VehiculoCampania) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.vehiculosCampaniasURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Campaña Preventiva creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarVehiculoCampania(registro: VehiculoCampania) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.vehiculosCampaniasURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Campaña Preventiva actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Eliminar
    public async eliminarVehiculoCampania(VehiculoCampaniaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.vehiculosCampaniasURL + '/id/' + VehiculoCampaniaId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Campaña Preventiva eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * Reservaciones * * * *
    async obtenerTodosReservacionCampania() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.reservacionesCampaniasURL;
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                var element = json[i];
                var registro = this.jsonConverters.citaDeJSON(element);
                registros.push(registro);
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerReservacionCampaniaPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.reservacionesCampaniasURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.citaDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearReservacionCampania(registro: Cita) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.reservacionesCampaniasURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Reservación Campaña creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarReservacionCampania(registro: Cita) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.reservacionesCampaniasURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Reservación Campaña actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarReservacionCampania(ReservacionCampaniaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.reservacionesCampaniasURL + '/id/' + ReservacionCampaniaId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Reservación Campaña eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    //// Carga Vehiculos
    // * * * * * Generar plantilla * * * * *
    public async generarPlantilla(nombreArchivo: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.vehiculosCampaniasURL + '/generacion-plantilla';
            var res = await this.connection.getDownloadRequest(url);
            
            // Descargar archivo
            var filename = `${nombreArchivo}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Plantilla descargada con éxito' } };

        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * * * Lectura del archivo Excel * * * * *
    async lecturaArchivoVehiculos(archivo, campanias:CampaniaPreventiva[], columnasUtilizadas:string[],columnasT:string[] ) {
        try {
             // Obtener resultados del Excel
             var resArchivo = await Papa.parsePromise(archivo);
             if(!resArchivo || !resArchivo.data) throw new Error('Ha ocurrido un error al leer los datos del archivo');

            var resNormalizacion = this.libraryService.limpiarColumnasResultadosExcel(resArchivo.data, columnasT);
            if(!resNormalizacion) throw new Error('Ha ocurrido un error al preparar los datos del archivo Excel');
            var errores = resNormalizacion.errores;
            var resultados = resNormalizacion.resultados;
            // Preparar resultados según modelo
            var trim = this.libraryService.trimString;

            var resultadosFinales:any[] = [];
            resultados.forEach(element => {
                let aplicado = element['aplicado']?(element['aplicado']=='Si'||element['aplicado']=='1'?true:false):false;
                var vehiculoFinal = new VehiculoCampania(null,element['chasis'],element['tipo'],element['marca'],
                element['modelo'],element['numero del motor'],element['color'],element['placa'],element['vehiculo de cliente'],
                element['correo'],element['telefono'],aplicado, null);

                // Campania
                if(campanias && element['codigo de campaña']) {
                    let campaniaRes = campanias.find(campania => campania.codigo == element['codigo de campaña']);

                    if(campaniaRes) {
                        vehiculoFinal.CampaniaPreventiva = campaniaRes;
                        vehiculoFinal.CampaniaPreventivaId = campaniaRes.id;
                        vehiculoFinal.codigoCampania = campaniaRes.codigo;
                    }
                }

                resultadosFinales.push(vehiculoFinal);

            })

            return {
                error: false,
                errores: errores,
                resultados: resultadosFinales,
                mensaje: 'Lectura de archivo realizada con éxito.',
            };
        } catch (error) {
            return { error: true, mensaje: error.message };
        }

    }

    async analizarRegistrosVehiculos(registros: VehiculoCampania[]) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.vehiculosCampaniasURL + '/analisis-registros-vehiculos';
            var data = {
                registros: registros,
            }
            const res = await this.connection.postRequest(url, data);
            var registrosNuevos = res.registrosNuevos;
            var registrosEditados = res.registrosEditados;
            var registrosAIgnorar = res.registrosIgnorados;
            var errores = res.errores;
            return { error: null, data: {
                mensaje: 'Importación analizada con éxito',
                registrosNuevos: registrosNuevos,
                registrosEditados: registrosEditados,
                registrosAIgnorar: registrosAIgnorar,
                errores: errores,
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async subirRegistrosVehiculos(registros: VehiculoCampania[]) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.vehiculosCampaniasURL + '/carga-vehiculos';
            var data = {
                registros: registros,
            }
            const res = await this.connection.postRequest(url, data);

            return { error: null, data: {
                mensaje: 'Carga Masiva realizada con éxito',
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

}