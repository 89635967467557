import { ContratoAhorrent } from './ContratoAhorrent';
import { Unidad } from './unidad';
import { ClienteAhorrent } from './clienteAhorrent';
export class EncuestaClienteAhorrent{
    constructor(
        public id: number,
        public codigo: string,
        public fechaEnviado: string,
        public fechaLlenado: string,
        public respuestas: string,
        public tipoLlenado: string,
        public estado: string,

        // Relaciones
        public ClienteAhorrentId: number,
        public ContratoAhorrentId: number,
    ){
        this.cargarArrays();
    }

    emailCliente

    //Fechas
    fechaEnviadoFormato: string;
    fechaEnviadoDate: Date;

    fechaLlenadoFormato: string;
    fechaLlenadoDate: Date;

    ClienteAhorrent: ClienteAhorrent;
    ContratoAhorrent: ContratoAhorrent;

    public respuestasArray: any[];

    cargarArrays() {
        this.respuestasArray = this.respuestas? JSON.parse(this.respuestas) : [];
    }

    guardar() {
        this.respuestas = JSON.stringify(this.respuestasArray);
    }

}