import { ContratoAhorrent } from "./ContratoAhorrent";
import { Usuario } from "./usuario";

export class CambioEstadoContratoAhorrent {
    constructor(
        public id:number,
        public fecha: string,
        public estadoAnterior: string,
        public estadoPosterior: string,
        public observaciones: string,

        //Relaciones
        public UsuarioId: number,
        public ContratoAhorrentId: number,
    ){}

    fechaFormato:string;
    fechaDate:Date;
    Usuario: Usuario;
    ContratoAhorrent: ContratoAhorrent;

}