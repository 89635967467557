import { Empresa } from './empresa';

export class ConfiguracionLead {
    constructor(
        public id: number,
        public canalesIngresos: string,
        public canalesWeb: string,
        public estatusCita: string,
        public opcionesPago: string,
        public institucionesFinancieras: string,
        public motivosDesistimientos: string,
        public tiposSeguimientos: string,
        public unidades: string,
        //Relaciones
        public EmpresaId: number,
    ){
      this.cargarArrays()
    }

    canalesIngresosArray: any[];
    canalesWebArray: any[];
    estatusCitaArray: any[];
    opcionesPagoArray: any[];
    institucionesFinancierasArray: any[];
    motivosDesistimientosArray: any[];
    tiposSeguimientosArray: any[];
    unidadesArray: any[];

    Empresa: Empresa;

    cargarArrays() {
        
        this.canalesIngresosArray = this.canalesIngresos ? JSON.parse(this.canalesIngresos) : [];
        this.canalesWebArray = this.canalesWeb ? JSON.parse(this.canalesWeb) : [];
        this.estatusCitaArray = this.estatusCita ? JSON.parse(this.estatusCita) : [];
        this.opcionesPagoArray = this.opcionesPago ? JSON.parse(this.opcionesPago) : [];
        this.institucionesFinancierasArray = this.institucionesFinancieras ? JSON.parse(this.institucionesFinancieras) : [];
        this.motivosDesistimientosArray = this.motivosDesistimientos ? JSON.parse(this.motivosDesistimientos) : [];
        this.tiposSeguimientosArray = this.tiposSeguimientos ? JSON.parse(this.tiposSeguimientos) : [];
        this.unidadesArray = this.unidades ? JSON.parse(this.unidades) : [];

    }

}