import { SolicitudPDI } from "./solicitudPDI";
import { Usuario } from "./usuario";

export class CambioEstadoSolicitudPDI {
    constructor(
        public id: number,
        public fecha: string,
        public estadoAnterior: string,
        public estadoPosterior: string,

        // Relaciones
        public SolicitudPDIId: number,
        public UsuarioId: number,
    ) { }

    Usuario: Usuario;
    SolicitudPDI: SolicitudPDI;

    //Fechas
    fechaFormato: string;
    fechaDate: Date;

    estadoAnteriorFormat: string;
    estadoPosteriorFormat: string;
}