import { Empresa } from './empresa';
import { FormularioFEIC } from './formularioFEIC';

export class Cliente {
    constructor(
        public id: number,
        public codigo: string,
        public primerApellido: string,
        public segundoApellido: string,
        public apellidoCasada: string,
        public primerNombre: string,
        public segundoNombre: string,
        public otrosNombres: string,
        public otros: string,
        public fechaNacimiento: string,
        public paisNacimiento: string,
        public departamentoNacimiento: string,
        public municipioNacimiento: string,
        public condicionMigratoria: number,
        public otraCondicionMigratoria: string,
        public edad: number,
        public nacionalidad: string,
        public otraNacionalidad: string,
        public sexo: number,
        public estadoCivil: number,
        public profesion: string,
        public documentoIdentificacion: number,
        public numeroIdentificacion: string,
        public emisionPasaporte: string,
        public nit: string,
        public telefono1: string,
        public telefono2: string,
        public correoElectronico: string,
        public direccionResidencia: string,
        public paisResidencia: string,
        public departamentoResidencia: string,
        public municipioResidencia: string,
        public isDeleted: boolean,
        public nombreCompleto: string,

        //Relaciones
        public EmpresaId: number,
    ){}
    
    empresa: Empresa;
    nombreCompletoNit: string;
    nombreCompletoNitDireccion: string;

    formulariosFEIC: FormularioFEIC[];

    // Fechas
    fechaNacimientoFormato: string;
    fechaNacimientoDate: Date;
}