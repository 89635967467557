import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { fadeInUp400ms } from '../../@vex/animations/fade-in-up.animation';
import * as tz from 'moment-timezone';
import { AuthService } from '../auth/auth.service';
import { EmpresasService } from '../empresas/empresas.service';
import { LibraryService } from '../library/library.service';
import { Title } from '@angular/platform-browser';
import { APIUrls } from '../api/apiUrls';
import { JSONConverters } from '../models/JSONConverters';
import { FormularioFEIC } from '../models/formularioFEIC';
import { Chart } from 'angular-highcharts';
import { AvaluosService } from '../avaluos/avaluos.service';

@Component({
    selector: 'app-inicio',
    templateUrl: './inicio.component.html',
    styleUrls: ['./inicio.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        fadeInUp400ms
    ]
})
export class InicioComponent implements OnInit {
    @ViewChild('cellNormal', { static: true }) cellNormal: TemplateRef<any>;
    @ViewChild('cellBotones', { static: true }) cellBotones: TemplateRef<any>;
    @ViewChild('cellMoneda', { static: true }) cellMoneda: TemplateRef<any>;
    @ViewChild('cellCompletado', { static: true }) cellCompletado: TemplateRef<any>;

    apiUrls = new APIUrls();
    cargando: boolean = false;
    mostrar: boolean = false;

    formularios:                    FormularioFEIC[];
    numeroFormularios:              number = 0;
    numeroFormulariosRiesgo:        number = 0;
    numeroformulariosCompletados:   number = 0;
    numeroformulariosIncompletos:   number = 0;
    jsonConverters = new JSONConverters();
    apiURLs = new APIUrls();

    rangosFechas = this.apiURLs.rangosFechas;
    rangoFecha: string = this.rangosFechas[0].id;
    fechaInicioDate: Date;
    fechaInicioParam: string;
    fechaFinDate: Date;
    fechaFinParam: string;

    // Formularios
    columnasFormularios = [
        { prop: 'idPrefijo', name: 'ID', sortable: true, cellTemplate: null },
        { prop: 'numeroInventario', name: 'No. Inventario', sortable: true, cellTemplate: null },
        { prop: 'cliente.nit', name: 'Nit cliente', sortable: true, cellTemplate: null },
        { prop: 'cliente.nombreCompleto', name: 'Cliente', sortable: true, cellTemplate: null },
        { prop: 'VentaId', name: 'Venta', sortable: true, cellTemplate: null },
        { prop: 'venta.fechaFormato', name: 'Fecha de venta', sortable: true, cellTemplate: null },
        { prop: 'tipoMostrar', name: 'Tipo', sortable: true, cellTemplate: null },
        { prop: 'completado', name: 'Completado', sortable: true, cellTemplate: null },
    ];

    columnasAvaluo = [
        { prop: 'id', name: 'ID', sortable: true, cellTemplate: null },
        { prop: 'fechaSolicitudFormato', name: 'Fecha de solicitud', sortable: true, cellTemplate: null },
        { prop: 'tipoCapitalize', name: 'Tipo de avalúo', sortable: true, cellTemplate: null },
        { prop: 'estadoCapitalize', name: 'Estado', sortable: true, cellTemplate: null },
        { prop: 'marcaVehiculo.nombre', name: 'Marca del vehículo', sortable: true, cellTemplate: null },
        { prop: 'lineaVehiculo.nombre', name: 'Línea del vehículo', sortable: true, cellTemplate: null },
        { prop: 'modelo', name: 'Modelo del vehículo', sortable: true, cellTemplate: null },
        { prop: 'cliente.nombreCompleto', name: 'Cliente', sortable: true, cellTemplate: null },
    ];

    columnasCitas =  [
        { prop: 'id', name: 'Código de cita', sortable: true, cellTemplate: null },
        { prop: 'nombreCompleto', name: 'Nombre', sortable: true, cellTemplate: null },
        { prop: 'nombreAgente', name: 'Asesor', sortable: true, cellTemplate: null },
        { prop: 'nombreConcesionario', name: 'Concesionario', sortable: true, cellTemplate: null },
        { prop: 'fechaFormato', name: 'Fecha', sortable: true, cellTemplate: null },
        { prop: 'horarioCompleto', name: 'Horario', sortable: true, cellTemplate: null },
        { prop: 'telefono', name: 'Teléfono', sortable: true, cellTemplate: null },
        { prop: 'correo', name: 'Correo', sortable: true, cellTemplate: null },
    ];

    columnasRecordatorios =  [
        { prop: 'id', name: 'Código de cita', sortable: true, cellTemplate: null },
        { prop: 'nombreCompleto', name: 'Nombre', sortable: true, cellTemplate: null },
        { prop: 'nombreConcesionario', name: 'Concesionario', sortable: true, cellTemplate: null },
        { prop: 'fechaFormato', name: 'Fecha', sortable: true, cellTemplate: null },
        { prop: 'horarioCompleto', name: 'Horario', sortable: true, cellTemplate: null },
        { prop: 'telefono', name: 'Teléfono', sortable: true, cellTemplate: null },
        { prop: 'correo', name: 'Correo', sortable: true, cellTemplate: null },
    ];

    // Campos para buscador
    campos = [
    ];

    filtros            = [];
    urlBusqueda        = this.apiUrls.baseURL + this.apiUrls.formulariosFEICURL + '/search';
    urlBusquedaAvaluos = this.apiUrls.baseURL + this.apiUrls.avaluoURL + '/search';
    urlBusquedaCitas   = this.apiURLs.baseURL + this.apiURLs.citasURL + '/search';
    urlBusquedaRecordatorios   = this.apiURLs.baseURL + this.apiURLs.citasURL + '/recordatorio';

    chart: Chart;
    chart2: Chart;
    chart3: Chart;
    Data: any;

    constructor(
        private router: Router,
        public auth: AuthService,
        private cd: ChangeDetectorRef,
        private empresasService: EmpresasService,
        private libraryService: LibraryService,
        private route: ActivatedRoute,
        private titleService: Title,
        private service: AvaluosService,
    ) { }

    ngOnInit() {
        this.titleService.setTitle('Inicio' + this.apiUrls.nombreSitioTitle);
        // Verifica con queryParams
        this.route.queryParams.subscribe((params: Params) => {
            this.inicializarDatos(params);
        });
    }

    async inicializarDatos(params: Params) {
        this.obtenerRuta(params);
        this.agregarTemplates();
        await this.actualizarFechas();
        await this.obtenerDataInicio();
        //await this.obtenerData();
    }

    async obtenerRuta(params: Params) {
    }

    actualizarOtrosCampos(data) {
    }

    async obtenerDataInicio() {
        this.cargando = true;
        var res = await this.empresasService.obtenerDataInicio(this.auth.idUsuarioActual(), this.fechaInicioParam, this.fechaFinParam);
        if (!res.error) {
            var data                              = res.data;
                this.numeroFormularios            = data.numeroFormularios;
                this.numeroFormulariosRiesgo      = data.numeroFormulariosRiesgo;
                this.numeroformulariosCompletados = data.numeroformulariosCompletados;
                this.numeroformulariosIncompletos = data.numeroformulariosIncompletos;
        }

        this.cargando = false;
        this.cd.detectChanges();
    }

    // * * * Formularios * * *
    calcularParamsEspecialesFormularios(): string {
        var paramsEspeciales  = '';
        paramsEspeciales += '&porFecha=true'
        paramsEspeciales += '&porEmpresa=true'
        paramsEspeciales += '&UsuarioId=' + this.auth.idUsuarioActual();
        return paramsEspeciales;
    }

    calcularParamsEspecialesCitas(): string {
        var paramsEspeciales  = '';
        paramsEspeciales += '&AgenteId=' + this.auth.idUsuarioActual();
        return paramsEspeciales;
    }

    calcularParamsEspecialesRecordatorios(tabla: number): string {
        var paramsEspeciales  = '';
        paramsEspeciales += '&AgenteId=' + this.auth.idUsuarioActual();
        paramsEspeciales += '&Tabla=' + tabla;
        return paramsEspeciales;
    }

    actualizarFechaInicio($event) {
        this.fechaInicioParam = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
    }

    actualizarFechaFin($event) {
        this.fechaFinParam = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
    }

    async actualizarFechas(obtenerDatos = true) {
        if (this.rangoFecha != 'personalizado') {
            var fechas                = this.libraryService.calcularFechas(this.rangoFecha);
                this.fechaInicioDate  = fechas.startDate;
                this.fechaInicioParam = this.libraryService.convertirFecha(fechas.startDate, 'date', 'YYYY-MM-DD');
                this.fechaFinDate     = fechas.finishDate;
                this.fechaFinParam    = this.libraryService.convertirFecha(fechas.finishDate, 'date', 'YYYY-MM-DD');
        }
    }

    agregarTemplates() {
        this.columnasFormularios.forEach(columna => {
            columna.cellTemplate = this.cellNormal;
        });
        this.columnasFormularios[7].cellTemplate = this.cellCompletado;
    }

    async obtenerData() {
        this.cargando = true;
        if(this.auth.tienePermisoPara(this.apiUrls.permisos.REPORTE_AVALUO)) {
            var res = await this.service.obtenerReporte(this.fechaInicioParam, this.fechaFinParam, null);
            if (!res.error) {
                this.Data = res.data.registro.data;
                this.generarGrafica1(this.Data.value1);
                this.generarGrafica2(this.Data.value2);
                this.generarGrafica3(this.Data.value3);
            }
        }
        this.cargando = false;
    }

    generarGrafica1(values) {
        if(values) {
            this.chart = new Chart({
                chart: {
                    type: 'pie'
                },
                title: {
                    text: 'Avalúos realizados por tipo'
                },
                credits: {
                    enabled: false
                },
                series: [
                    {
                        name: 'Cantidad de avalúos',
                        type: 'pie',
                        data: [
                            ['Compra venta', values.CountCompraVenta],
                            ['Compra directa por nuevo', values.CountCompraDirecta],
                            ['Compra directa usados', values.CountCompraDirectaUsados],
                            ['Consignación', values.CountConsignacion]
                        ]
                    },
                ]
            });
        }
    }

    generarGrafica2(values) {
        if(values) {
            let data = [];
            for (const val of values) {
                data.push([val.usuario, val.conteo])
            }
            this.chart2 = new Chart({
                chart: {
                    type: 'pie'
                },
                title: {
                    text: 'Avalúos realizados por usuario'
                },
                credits: {
                    enabled: false
                },
                series: [
                    {
                        name: 'Cantidad de avalúos realizados',
                        type: 'pie',
                        data: data
                    },
                ]
            });
        }
    }

    generarGrafica3(values) {
        if(values) {
            let data = [];
            for (const val of values) {
                data.push([val.marca, val.conteo])
            }
            this.chart3 = new Chart({
                chart: {
                    type: 'pie'
                },
                title: {
                    text: 'Avalúos realizados por marca'
                },
                credits: {
                    enabled: false
                },
                series: [
                    {
                        name: 'Avalúos realizados',
                        type: 'pie',
                        data: data
                    },
                ]
            });
        }
    }

    calcularParamsEspecialesAvaluo() {
        var params = '&DESC=true';

        //params += '&UsuarioVendedorId=' + this.auth.idUsuarioActual();

        return params;

    }

}