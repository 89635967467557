import { ProspectoLead } from "./ProspectoLead";
import { SolicitudPDI } from "./solicitudPDI";
import { Usuario } from "./usuario";

export class SeguimientoProspectoLead {
  constructor(
    public id: number,
    public fechaCreacion: string,
    public fechaAnterior: string,
    public fechaNueva: string,
    public tipoSeguimiento: string,
    public observaciones: string,

    // Relaciones
    public UsuarioId: number,
    public ProspectoLeadId: number,
  ) { }

  Usuario: Usuario;
  ProspectoLead: ProspectoLead;

  //Fechas
  fechaCreacionFormato: string;
  fechaCreacionDate: Date;

  fechaAnteriorFormato: string;
  fechaAnteriorDate: Date;

  fechaNuevaFormato: string;
  fechaNuevaDate: Date;
  horaNueva: string;

}