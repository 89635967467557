import { Empresa } from './empresa';
import { HojaMembretada } from './hojaMembretada';
import { Usuario } from './usuario';

export class HistorialHojaMembretada {
    constructor(
        public id:                          number,
        public fecha:                       string,
        public tipo:                        string,
        public observaciones:               string,

        // Relaciones
        public HojaMembretadaId:            number,
        public UsuarioId:                   number,
        public EmpresaId:                   number,
    ){}

    hojaMembretada:     HojaMembretada;
    usuario:            Usuario;
    empresa:            Empresa;
    
    // Fechas
    fechaFormato:       string;
    fechaDate:          Date;

    tipoFormato:        string;
}