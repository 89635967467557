import { ContratoAhorrent } from './ContratoAhorrent';
import { DocumentoCliente } from './documentoCliente';
import { Empresa } from './empresa';
import { SeguimientoCliente } from './seguimientoCliente1';

export class ClienteAhorrent {
    constructor(
        public id: number,
        public nombres: string,        
        public apellidos: string,      
        public nombreCompleto: string, 
        public email: string,          
        public telefono: string,       
        public telefono2: string,      
        public activo: boolean,         
        public eliminado: boolean,      
        public imagenPerfil: string, 
        public dpi:string,
        public nit:string,
        public direccion:string,
        public nombreEmpresa: string,
        public asesorCliente: string,
        public clienteFrecuente: boolean,
        public tipoCliente: string,
        public categoria: string,
        public fechaNacimiento: string,
        public nacionalidad: string,
        //Relaciones
        public EmpresaId: number,
    ){}
    
    nombreCompletoNit: string; 
    nombreCompletoNitDireccion: string;
    empresa: Empresa;

    documentosClientes: DocumentoCliente[] = [];
    seguimientosClientes: SeguimientoCliente[] = [];

    // Fechas
    fechaNacimientoFormato: string;
    fechaNacimientoDate: Date;

    ContratosAhorrent: ContratoAhorrent[] = [];

}