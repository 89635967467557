<div class="row">
    <div class="col-md-12">
        <h3>{{data.titulo}}</h3>
        <div *ngIf="data.mensaje" [innerHTML]="data.mensaje"></div>
    </div>
</div>
<br>
<div class="row">
    <div class="col-md-12">
        <mat-form-field>
            <mat-label>{{labelTexto ? labelTexto : 'Observaciones'}}</mat-label>
            <textarea matInput [(ngModel)]="texto"
                name="obsInput"></textarea>
        </mat-form-field>
    </div>
</div>
<br/>
<div class="row">
    <div class="col-md-12" style="text-align: right;">
        <button mat-stroked-button (click)="cerrar()" class="margenBoton">{{ data.textoCerrar ? data.textoCerrar : 'Cerrar' }}</button>
        <button mat-raised-button color="primary" class="margenBoton" [disabled]="!texto" (click)="confirmar()">{{ data.textoAceptar ? data.textoAceptar  : 'Aceptar' }}</button>
    </div>
</div>