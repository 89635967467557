import { ProspectoLead } from "./ProspectoLead";
import { SolicitudPDI } from "./solicitudPDI";
import { Usuario } from "./usuario";

export class CambioEstadoProspectoLead {
    constructor(
        public id: number,
        public estadoAnterior: string,
        public estadoPosterior: string,
        public observaciones: string,
        public fecha: string,

        // Relaciones
        public UsuarioId: number,
        public ProspectoLeadId: number,
    ) { }

    Usuario: Usuario;
    ProspectoLead: ProspectoLead;

    //Fechas
    fechaFormato: string;
    fechaDate: Date;

    estadoAnteriorFormat: string;
    estadoPosteriorFormat: string;

    //Detalle de estado para prospecto
    detalleEstado: string;
    detalleInstitucionFinanciera: string; // Si se selecciona Financiado, aca se guarda la institucion financiera
}