export default [
    {
        "nombre": "Guatemala",
        "id": "GT"
    },
    {
        "nombre": "El Salvador",
        "id": "SV"
    },
    {
        "nombre": "Afganistán",
        "id": "AF"
    },
    {
        "nombre": "Albania",
        "id": "AL"
    },
    {
        "nombre": "Alemania",
        "id": "DE"
    },
    {
        "nombre": "Andorra",
        "id": "AD"
    },
    {
        "nombre": "Angola",
        "id": "AO"
    },
    {
        "nombre": "Anguila",
        "id": "AI"
    },
    {
        "nombre": "Antártida",
        "id": "AQ"
    },
    {
        "nombre": "Antigua y Barbuda",
        "id": "AG"
    },
    {
        "nombre": "Arabia Saudita",
        "id": "SA"
    },
    {
        "nombre": "Argelia",
        "id": "DZ"
    },
    {
        "nombre": "Argentina",
        "id": "AR"
    },
    {
        "nombre": "Armenia",
        "id": "AM"
    },
    {
        "nombre": "Aruba",
        "id": "AW"
    },
    {
        "nombre": "Australia",
        "id": "AU"
    },
    {
        "nombre": "Austria",
        "id": "AT"
    },
    {
        "nombre": "Azerbaiyán",
        "id": "AZ"
    },
    {
        "nombre": "Bélgica",
        "id": "BE"
    },
    {
        "nombre": "Bahamas",
        "id": "BS"
    },
    {
        "nombre": "Bahrein",
        "id": "BH"
    },
    {
        "nombre": "Bangladesh",
        "id": "BD"
    },
    {
        "nombre": "Barbados",
        "id": "BB"
    },
    {
        "nombre": "Belice",
        "id": "BZ"
    },
    {
        "nombre": "Benín",
        "id": "BJ"
    },
    {
        "nombre": "Bhután",
        "id": "BT"
    },
    {
        "nombre": "Bielorrusia",
        "id": "BY"
    },
    {
        "nombre": "Birmania",
        "id": "MM"
    },
    {
        "nombre": "Bolivia",
        "id": "BO"
    },
    {
        "nombre": "Bosnia y Herzegovina",
        "id": "BA"
    },
    {
        "nombre": "Botsuana",
        "id": "BW"
    },
    {
        "nombre": "Brasil",
        "id": "BR"
    },
    {
        "nombre": "Brunéi",
        "id": "BN"
    },
    {
        "nombre": "Bulgaria",
        "id": "BG"
    },
    {
        "nombre": "Burkina Faso",
        "id": "BF"
    },
    {
        "nombre": "Burundi",
        "id": "BI"
    },
    {
        "nombre": "Cabo Verde",
        "id": "CV"
    },
    {
        "nombre": "Camboya",
        "id": "KH"
    },
    {
        "nombre": "Camerún",
        "id": "CM"
    },
    {
        "nombre": "Canadá",
        "id": "CA"
    },
    {
        "nombre": "Chad",
        "id": "TD"
    },
    {
        "nombre": "Chile",
        "id": "CL"
    },
    {
        "nombre": "China",
        "id": "CN"
    },
    {
        "nombre": "Chipre",
        "id": "CY"
    },
    {
        "nombre": "Ciudad del Vaticano",
        "id": "VA"
    },
    {
        "nombre": "Colombia",
        "id": "CO"
    },
    {
        "nombre": "Comoras",
        "id": "KM"
    },
    {
        "nombre": "República del Congo",
        "id": "CG"
    },
    {
        "nombre": "República Democrática del Congo",
        "id": "CD"
    },
    {
        "nombre": "Corea del Norte",
        "id": "KP"
    },
    {
        "nombre": "Corea del Sur",
        "id": "KR"
    },
    {
        "nombre": "Costa de Marfil",
        "id": "CI"
    },
    {
        "nombre": "Costa Rica",
        "id": "CR"
    },
    {
        "nombre": "Croacia",
        "id": "HR"
    },
    {
        "nombre": "Cuba",
        "id": "CU"
    },
    {
        "nombre": "Curazao",
        "id": "CW"
    },
    {
        "nombre": "Dinamarca",
        "id": "DK"
    },
    {
        "nombre": "Dominica",
        "id": "DM"
    },
    {
        "nombre": "Ecuador",
        "id": "EC"
    },
    {
        "nombre": "Egipto",
        "id": "EG"
    },
    {
        "nombre": "Emiratos Árabes Unidos",
        "id": "AE"
    },
    {
        "nombre": "Eritrea",
        "id": "ER"
    },
    {
        "nombre": "Eslovaquia",
        "id": "SK"
    },
    {
        "nombre": "Eslovenia",
        "id": "SI"
    },
    {
        "nombre": "España",
        "id": "ES"
    },
    {
        "nombre": "Estados Unidos de América",
        "id": "US"
    },
    {
        "nombre": "Estonia",
        "id": "EE"
    },
    {
        "nombre": "Etiopía",
        "id": "ET"
    },
    {
        "nombre": "Filipinas",
        "id": "PH"
    },
    {
        "nombre": "Finlandia",
        "id": "FI"
    },
    {
        "nombre": "Fiyi",
        "id": "FJ"
    },
    {
        "nombre": "Francia",
        "id": "FR"
    },
    {
        "nombre": "Gabón",
        "id": "GA"
    },
    {
        "nombre": "Gambia",
        "id": "GM"
    },
    {
        "nombre": "Georgia",
        "id": "GE"
    },
    {
        "nombre": "Ghana",
        "id": "GH"
    },
    {
        "nombre": "Gibraltar",
        "id": "GI"
    },
    {
        "nombre": "Granada",
        "id": "GD"
    },
    {
        "nombre": "Grecia",
        "id": "GR"
    },
    {
        "nombre": "Groenlandia",
        "id": "GL"
    },
    {
        "nombre": "Guadalupe",
        "id": "GP"
    },
    {
        "nombre": "Guam",
        "id": "GU"
    },
    {
        "nombre": "Guayana Francesa",
        "id": "GF"
    },
    {
        "nombre": "Guernsey",
        "id": "GG"
    },
    {
        "nombre": "Guinea",
        "id": "GN"
    },
    {
        "nombre": "Guinea Ecuatorial",
        "id": "GQ"
    },
    {
        "nombre": "Guinea-Bissau",
        "id": "GW"
    },
    {
        "nombre": "Guyana",
        "id": "GY"
    },
    {
        "nombre": "Haití",
        "id": "HT"
    },
    {
        "nombre": "Honduras",
        "id": "HN"
    },
    {
        "nombre": "Hong kong",
        "id": "HK"
    },
    {
        "nombre": "Hungría",
        "id": "HU"
    },
    {
        "nombre": "India",
        "id": "IN"
    },
    {
        "nombre": "Indonesia",
        "id": "ID"
    },
    {
        "nombre": "Irán",
        "id": "IR"
    },
    {
        "nombre": "Irak",
        "id": "IQ"
    },
    {
        "nombre": "Irlanda",
        "id": "IE"
    },
    {
        "nombre": "Isla Bouvet",
        "id": "BV"
    },
    {
        "nombre": "Isla de Man",
        "id": "IM"
    },
    {
        "nombre": "Isla de Navidad",
        "id": "CX"
    },
    {
        "nombre": "Isla Norfolk",
        "id": "NF"
    },
    {
        "nombre": "Islandia",
        "id": "IS"
    },
    {
        "nombre": "Islas Bermudas",
        "id": "BM"
    },
    {
        "nombre": "Islas Caimán",
        "id": "KY"
    },
    {
        "nombre": "Islas Cocos (Keeling)",
        "id": "CC"
    },
    {
        "nombre": "Islas Cook",
        "id": "CK"
    },
    {
        "nombre": "Islas de Åland",
        "id": "AX"
    },
    {
        "nombre": "Islas Feroe",
        "id": "FO"
    },
    {
        "nombre": "Islas Georgias del Sur y Sandwich del Sur",
        "id": "GS"
    },
    {
        "nombre": "Islas Heard y McDonald",
        "id": "HM"
    },
    {
        "nombre": "Islas Maldivas",
        "id": "MV"
    },
    {
        "nombre": "Islas Malvinas",
        "id": "FK"
    },
    {
        "nombre": "Islas Marianas del Norte",
        "id": "MP"
    },
    {
        "nombre": "Islas Marshall",
        "id": "MH"
    },
    {
        "nombre": "Islas Pitcairn",
        "id": "PN"
    },
    {
        "nombre": "Islas Salomón",
        "id": "SB"
    },
    {
        "nombre": "Islas Turcas y Caicos",
        "id": "TC"
    },
    {
        "nombre": "Islas Ultramarinas Menores de Estados Unidos",
        "id": "UM"
    },
    {
        "nombre": "Islas Vírgenes Británicas",
        "id": "VG"
    },
    {
        "nombre": "Islas Vírgenes de los Estados Unidos",
        "id": "VI"
    },
    {
        "nombre": "Israel",
        "id": "IL"
    },
    {
        "nombre": "Italia",
        "id": "IT"
    },
    {
        "nombre": "Jamaica",
        "id": "JM"
    },
    {
        "nombre": "Japón",
        "id": "JP"
    },
    {
        "nombre": "Jersey",
        "id": "JE"
    },
    {
        "nombre": "Jordania",
        "id": "JO"
    },
    {
        "nombre": "Kazajistán",
        "id": "KZ"
    },
    {
        "nombre": "Kenia",
        "id": "KE"
    },
    {
        "nombre": "Kirguistán",
        "id": "KG"
    },
    {
        "nombre": "Kiribati",
        "id": "KI"
    },
    {
        "nombre": "Kuwait",
        "id": "KW"
    },
    {
        "nombre": "Líbano",
        "id": "LB"
    },
    {
        "nombre": "Laos",
        "id": "LA"
    },
    {
        "nombre": "Lesoto",
        "id": "LS"
    },
    {
        "nombre": "Letonia",
        "id": "LV"
    },
    {
        "nombre": "Liberia",
        "id": "LR"
    },
    {
        "nombre": "Libia",
        "id": "LY"
    },
    {
        "nombre": "Liechtenstein",
        "id": "LI"
    },
    {
        "nombre": "Lituania",
        "id": "LT"
    },
    {
        "nombre": "Luxemburgo",
        "id": "LU"
    },
    {
        "nombre": "México",
        "id": "MX"
    },
    {
        "nombre": "Mónaco",
        "id": "MC"
    },
    {
        "nombre": "Macao",
        "id": "MO"
    },
    {
        "nombre": "Macedônia",
        "id": "MK"
    },
    {
        "nombre": "Madagascar",
        "id": "MG"
    },
    {
        "nombre": "Malasia",
        "id": "MY"
    },
    {
        "nombre": "Malawi",
        "id": "MW"
    },
    {
        "nombre": "Mali",
        "id": "ML"
    },
    {
        "nombre": "Malta",
        "id": "MT"
    },
    {
        "nombre": "Marruecos",
        "id": "MA"
    },
    {
        "nombre": "Martinica",
        "id": "MQ"
    },
    {
        "nombre": "Mauricio",
        "id": "MU"
    },
    {
        "nombre": "Mauritania",
        "id": "MR"
    },
    {
        "nombre": "Mayotte",
        "id": "YT"
    },
    {
        "nombre": "México",
        "id": "MX"
    },
    {
        "nombre": "Micronesia",
        "id": "FM"
    },
    {
        "nombre": "Moldavia",
        "id": "MD"
    },
    {
        "nombre": "Mongolia",
        "id": "MN"
    },
    {
        "nombre": "Montenegro",
        "id": "ME"
    },
    {
        "nombre": "Montserrat",
        "id": "MS"
    },
    {
        "nombre": "Mozambique",
        "id": "MZ"
    },
    {
        "nombre": "Namibia",
        "id": "NA"
    },
    {
        "nombre": "Nauru",
        "id": "NR"
    },
    {
        "nombre": "Nepal",
        "id": "NP"
    },
    {
        "nombre": "Nicaragua",
        "id": "NI"
    },
    {
        "nombre": "Niger",
        "id": "NE"
    },
    {
        "nombre": "Nigeria",
        "id": "NG"
    },
    {
        "nombre": "Niue",
        "id": "NU"
    },
    {
        "nombre": "Noruega",
        "id": "NO"
    },
    {
        "nombre": "Nueva Caledonia",
        "id": "NC"
    },
    {
        "nombre": "Nueva Zelanda",
        "id": "NZ"
    },
    {
        "nombre": "Omán",
        "id": "OM"
    },
    {
        "nombre": "Países Bajos",
        "id": "NL"
    },
    {
        "nombre": "Pakistán",
        "id": "PK"
    },
    {
        "nombre": "Palau",
        "id": "PW"
    },
    {
        "nombre": "Palestina",
        "id": "PS"
    },
    {
        "nombre": "Panamá",
        "id": "PA"
    },
    {
        "nombre": "Papúa Nueva Guinea",
        "id": "PG"
    },
    {
        "nombre": "Paraguay",
        "id": "PY"
    },
    {
        "nombre": "Perú",
        "id": "PE"
    },
    {
        "nombre": "Polinesia Francesa",
        "id": "PF"
    },
    {
        "nombre": "Polonia",
        "id": "PL"
    },
    {
        "nombre": "Portugal",
        "id": "PT"
    },
    {
        "nombre": "Puerto Rico",
        "id": "PR"
    },
    {
        "nombre": "Qatar",
        "id": "QA"
    },
    {
        "nombre": "Reino Unido",
        "id": "GB"
    },
    {
        "nombre": "República Centroafricana",
        "id": "CF"
    },
    {
        "nombre": "República Checa",
        "id": "CZ"
    },
    {
        "nombre": "República Dominicana",
        "id": "DO"
    },
    {
        "nombre": "República de Sudán del Sur",
        "id": "SS"
    },
    {
        "nombre": "Reunión",
        "id": "RE"
    },
    {
        "nombre": "Ruanda",
        "id": "RW"
    },
    {
        "nombre": "Rumanía",
        "id": "RO"
    },
    {
        "nombre": "Rusia",
        "id": "RU"
    },
    {
        "nombre": "Sahara Occidental",
        "id": "EH"
    },
    {
        "nombre": "Samoa",
        "id": "WS"
    },
    {
        "nombre": "Samoa Americana",
        "id": "AS"
    },
    {
        "nombre": "San Bartolomé",
        "id": "BL"
    },
    {
        "nombre": "San Cristóbal y Nieves",
        "id": "KN"
    },
    {
        "nombre": "San Marino",
        "id": "SM"
    },
    {
        "nombre": "San Martín (Francia)",
        "id": "MF"
    },
    {
        "nombre": "San Pedro y Miquelón",
        "id": "PM"
    },
    {
        "nombre": "San Vicente y las Granadinas",
        "id": "VC"
    },
    {
        "nombre": "Santa Elena",
        "id": "SH"
    },
    {
        "nombre": "Santa Lucía",
        "id": "LC"
    },
    {
        "nombre": "Santo Tomé y Príncipe",
        "id": "ST"
    },
    {
        "nombre": "Senegal",
        "id": "SN"
    },
    {
        "nombre": "Serbia",
        "id": "RS"
    },
    {
        "nombre": "Seychelles",
        "id": "SC"
    },
    {
        "nombre": "Sierra Leona",
        "id": "SL"
    },
    {
        "nombre": "Singapur",
        "id": "SG"
    },
    {
        "nombre": "Sint Maarten",
        "id": "SX"
    },
    {
        "nombre": "Siria",
        "id": "SY"
    },
    {
        "nombre": "Somalia",
        "id": "SO"
    },
    {
        "nombre": "Sri lanka",
        "id": "LK"
    },
    {
        "nombre": "Sudáfrica",
        "id": "ZA"
    },
    {
        "nombre": "Sudán",
        "id": "SD"
    },
    {
        "nombre": "Suecia",
        "id": "SE"
    },
    {
        "nombre": "Suiza",
        "id": "CH"
    },
    {
        "nombre": "Surinám",
        "id": "SR"
    },
    {
        "nombre": "Svalbard y Jan Mayen",
        "id": "SJ"
    },
    {
        "nombre": "Swazilandia",
        "id": "SZ"
    },
    {
        "nombre": "Tayikistán",
        "id": "TJ"
    },
    {
        "nombre": "Tailandia",
        "id": "TH"
    },
    {
        "nombre": "Taiwán",
        "id": "TW"
    },
    {
        "nombre": "Tanzania",
        "id": "TZ"
    },
    {
        "nombre": "Territorio Británico del Océano Índico",
        "id": "IO"
    },
    {
        "nombre": "Territorios Australes y Antárticas Franceses",
        "id": "TF"
    },
    {
        "nombre": "Timor Oriental",
        "id": "TL"
    },
    {
        "nombre": "Togo",
        "id": "TG"
    },
    {
        "nombre": "Tokelau",
        "id": "TK"
    },
    {
        "nombre": "Tonga",
        "id": "TO"
    },
    {
        "nombre": "Trinidad y Tobago",
        "id": "TT"
    },
    {
        "nombre": "Tunez",
        "id": "TN"
    },
    {
        "nombre": "Turkmenistán",
        "id": "TM"
    },
    {
        "nombre": "Turquía",
        "id": "TR"
    },
    {
        "nombre": "Tuvalu",
        "id": "TV"
    },
    {
        "nombre": "Ucrania",
        "id": "UA"
    },
    {
        "nombre": "Uganda",
        "id": "UG"
    },
    {
        "nombre": "Uruguay",
        "id": "UY"
    },
    {
        "nombre": "Uzbekistán",
        "id": "UZ"
    },
    {
        "nombre": "Vanuatu",
        "id": "VU"
    },
    {
        "nombre": "Venezuela",
        "id": "VE"
    },
    {
        "nombre": "Vietnam",
        "id": "VN"
    },
    {
        "nombre": "Wallis y Futuna",
        "id": "WF"
    },
    {
        "nombre": "Yemen",
        "id": "YE"
    },
    {
        "nombre": "Yibuti",
        "id": "DJ"
    },
    {
        "nombre": "Zambia",
        "id": "ZM"
    },
    {
        "nombre": "Zimbabue",
        "id": "ZW"
    }
]