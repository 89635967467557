import { Unidad } from "./unidad";
import { Usuario } from "./usuario";

export class CambioUbicacionUnidad {
    constructor(
        public id:number,
        public fecha: string,

        public ubicacionActualAnterior: string,
        public ubicacionActualNuevo: string,
        public ubicacionAnterior: string,
        public ubicacionNuevo: string,

        //Relaciones
        public UsuarioId: number,
        public UnidadId: number,
    ){}

    fechaFormato:string;
    fechaDate:Date;
    Usuario: Usuario;
    Unidad: Unidad;

}