import { VehiculoCampania } from "./VehiculoCampania";

export class CampaniaPreventiva {
    constructor(
        public id: number,
        public nombre: string,
        public codigo: string,
        public fechaInicio: string,
        public fechaFin: string,
        public imagen:  string,
        public textoPromocional: string,
        public descripcion: string,
    ){}

    fechaFormatoInicio: string;
    fechaDateInicio: Date;
    fechaFormatoFin: string;
    fechaDateFin: Date;

    VehiculosCampanias: VehiculoCampania[] = [];

}