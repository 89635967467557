import { Injectable } from '@angular/core';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { HojaMembretada } from '../models/hojaMembretada';
import { JSONConverters } from '../models/JSONConverters';
import { MembreteEmpresa } from '../models/membreteEmpresa';
import { Producto } from '../models/producto';
import * as FileSaver from 'file-saver';

@Injectable()
export class HojasMembretadasService { 
    apiUrls: APIUrls = new  APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
    ) { }

    // * * * MembreteEmpresas * * * 
    async obtenerMembreteEmpresaPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.membretesEmpresasURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.membreteEmpresaDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearMembreteEmpresa(registro: MembreteEmpresa) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.membretesEmpresasURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Membrete creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public copiarHojasDeUsuario(UsuarioIdOrigen: number, UsuarioIdDestino: number, idsHojas: number[]) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.hojasMembretadasURL + '/copiarHojasDeUsuario';
            this.connection.postRequest(url, {
                UsuarioIdOrigen: UsuarioIdOrigen,
                UsuarioIdDestino: UsuarioIdDestino,
                idsHojas: idsHojas
            });
            return { error: null, data: { mensaje: 'La copia se realizará en segundo plano y se le enviará un correo al finalizar.' } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarMembreteEmpresa(registro: MembreteEmpresa) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.membretesEmpresasURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Membrete actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarMembreteEmpresa(MembreteEmpresaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.membretesEmpresasURL + '/id/' + MembreteEmpresaId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Membrete eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Desactivar
    public async desactivarMembrete(MembreteEmpresaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.membretesEmpresasURL + '/desactivar';
            const res = await this.connection.postRequest(url, { MembreteEmpresaId: MembreteEmpresaId });
            return { error: null, data: { mensaje: 'Membrete desactivado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Activar
    public async activarMembrete(MembreteEmpresaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.membretesEmpresasURL + '/activar';
            const res = await this.connection.postRequest(url, { MembreteEmpresaId: MembreteEmpresaId });
            return { error: null, data: { mensaje: 'Membrete activado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * * * * Archivos * * * * * *
    async guardarArchivoEnServidorMembretesEmpresas(archivo: File){
        try {
            var url = this.apiUrls.uploadURL + this.apiUrls.usuariosURL + '/uploadArchivo';
            const res = await this.connection.uploadFile(url, archivo, true);
            if(!res.error) {
                return { error: null, data: { url: res.url } };
            }
            else throw new Error();
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al cargar el archivo.');
        }
    }

    // * * * Hojas membretadas * * * 
    async obtenerHojaMembretadaPorId(id: number, redirectEmpresa: boolean = false) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.hojasMembretadasURL + '/id/' + id;
            if(redirectEmpresa) url += '?redirectEmpresa=1';
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.hojaMembretadaDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearHojaMembretada(registro: HojaMembretada) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.hojasMembretadasURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Hoja membretada creada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Crear
    public async crearHojaMembretadaDuplicada(registro: HojaMembretada) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.hojasMembretadasURL + '?modoDuplicada=true';
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Hoja membretada duplicada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarHojaMembretada(registro: HojaMembretada) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.hojasMembretadasURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Hoja membretada actualizada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarHojaMembretada(HojaMembretadaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.hojasMembretadasURL + '/id/' + HojaMembretadaId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Hoja membretada eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async obtenerTodosLosMembretes(){
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.membretesEmpresasURL;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.membreteEmpresaDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las marcas de los vehiculos.');
        }
    }

    public async obtenerTodasHojasUsuario(UsuarioId){
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.hojasMembretadasURL + '/usuario/' + UsuarioId;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.hojaMembretadaDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las marcas de los vehiculos.');
        }
    }

    public async cambiarDeEstado(estado: string, tipo: string, observaciones: string, HojaMembretadaId: number, observacionesSolicitud: string = null, UsuarioSolicitudId = null){
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.hojasMembretadasURL + '/actualizarEstado';
            const res = await this.connection.postRequest(url, { estado : estado, id : HojaMembretadaId, tipo: tipo, observaciones: observaciones, observacionesSolicitud: observacionesSolicitud, UsuarioSolicitudId: UsuarioSolicitudId });
            return { error: null, data: { mensaje: 'Cambia de estado del avalúo con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async cambiarDeEstadoMultiple(estado: string, tipo: string, observaciones: string, ids: number[], UsuarioSolicitudId = null){
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.hojasMembretadasURL + '/actualizarEstadoMultiple';
            const res = await this.connection.postRequest(url, { estado : estado, ids : ids, tipo: tipo, observaciones: observaciones, UsuarioSolicitudId: UsuarioSolicitudId });
            return { error: null, data: { mensaje: 'Cambio de solicitudes con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // PDF
    async descargarMuestaHojaMembretada(HojaMembretadaId: number, esZip: boolean = false) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.hojasMembretadasURL + '/pdfMuestra?HojaMembretadaId=' + HojaMembretadaId;
            var res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var fileName    = esZip ? `Hojas membretadas de muestra.zip` : `Hoja membretada de muestra.pdf`;
            var mediaType   = esZip ? 'application/zip' : 'application/pdf';
            var blob = new Blob([res], {
                type: mediaType
            });
            FileSaver.saveAs(blob, fileName);

            return {
                error: null,
                data: {
                    mensaje: esZip ? 'Hojas membretadas de muestra descargadas con éxito.' : 'Hoja membretada de muestra descargada con éxito.'
                }
            };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async visualizarDocumentoPDF(HojaMembretadaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.hojasMembretadasURL + '/pdfMuestra?HojaMembretadaId=' + HojaMembretadaId;
            var res = await this.connection.getDownloadRequest(url);
            let pdfSrc: any = null;
  
            // Descargar archivo
            var mediaType = 'application/pdf';
            var blob = new Blob([res], {
                type: mediaType
            });
  
            return {
                  error: null,
                  data: {
                      blob: blob,
                  },
                  mensaje: 'documento',
            };
        } catch (error) {
          return { error: true, mensaje: error.error.message, result: null };
        }
      }

    async descargarFinalHojaMembretada(HojaMembretadaId: number, esZip: boolean = false) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.hojasMembretadasURL + '/pdfFinal?HojaMembretadaId=' + HojaMembretadaId;
            var res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var fileName    = esZip ? `Hojas membretadas.zip` : `Hoja membretada.pdf`;
            var mediaType   = esZip ? 'application/zip' : 'application/pdf';
            var blob = new Blob([res], {
                type: mediaType
            });
            FileSaver.saveAs(blob, fileName);

            return {
                error: null,
                data: {
                    mensaje: esZip ? 'Hojas membretadas descargadas con éxito.' : 'Hoja membretada descargada con éxito.'
                }
            };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async descargarPruebaMembrete(MembreteEmpresaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.hojasMembretadasURL + '/pdfMembrete?MembreteEmpresaId=' + MembreteEmpresaId;
            var res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var fileName    = `Membrete.pdf`;
            var mediaType   = 'application/pdf';
            var blob = new Blob([res], {
                type: mediaType
            });
            FileSaver.saveAs(blob, fileName);

            return {
                error: null,
                data: {
                    mensaje: 'Prueba de membrete descargada con éxito.'
                }
            };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async reporteHojasMembretadas(){
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.hojasMembretadasURL + '/reporte';
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.reporteHMDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

}