import { Component, OnInit, Input } from '@angular/core';
import { PreguntaEncuestaAhorrent } from '../../models/PreguntaEncuestaAhorrent';
import { APIUrls } from 'src/app/api/apiUrls';
import { AuthService } from '../../auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogSeleccionMultipleComponent } from '../../ahorrent/dialog-seleccion-multiple/dialog-seleccion-multiple.component';

@Component({
    selector: 'vex-preguntas-encuesta',
    templateUrl: './preguntas-encuesta.component.html',
    styleUrls: ['./preguntas-encuesta.component.scss']
})
export class PreguntasEncuestaComponent implements OnInit {

    @Input() preguntasEncuesta: PreguntaEncuestaAhorrent[] = [];

    @Input() modoCreacion: boolean = false;
    @Input() modoEdicion: boolean = false;
    @Input() modoDetalle: boolean = false;
    @Input() cargando: boolean = false;

    
    apiURLs: APIUrls = new APIUrls();

    constructor(
        public auth: AuthService,
        public dialog: MatDialog
        ) { }

    ngOnInit(): void {
    }

    
    // Configuraciones
    cambiarTipoPregunta(preguntaEncuesta: PreguntaEncuestaAhorrent){
        if (preguntaEncuesta.tipo == this.apiURLs.tipoPregunta[3].id) { // Si es de texto abierto, no tiene opciones
            preguntaEncuesta.opcionesArray = null;
        }else{
            preguntaEncuesta.opcionesArray = [];
        }
    }

    eliminar(index){
        this.preguntasEncuesta.splice(index, 1);
    }

    agregar() {
        if (this.preguntasEncuesta) {
            let nuevo = new PreguntaEncuestaAhorrent(null, null, null);
            this.preguntasEncuesta.push(nuevo);
        }
    }

    abrirDialogSeleccionesMultiples(preguntaEncuesta: PreguntaEncuestaAhorrent = null){
        try {
            const dialogRef = this.dialog.open(DialogSeleccionMultipleComponent, {
                data: {
                    registro: preguntaEncuesta ? preguntaEncuesta : null,
                    modoCreacion: false,
                    modoDetalle: this.modoDetalle,
                    modoEdicion: preguntaEncuesta ? true: false,
                }
                });
        
                dialogRef.afterClosed().subscribe(result => {
                if (result == 'confirmar') {
                    this.auth.buscadorReload.next();
                    this.ngOnInit();
                }
                
            });
        } catch (error) {
            
        }

    }

}
