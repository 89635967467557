<div class="container" id="dialogOverflow">
    <h3>{{data.titulo}}</h3>
    <br>
    <div class="row">
        <!-- Carrusel de Imágenes -->
        <div class="col" style="text-align: center;">
            <button (click)="prevImage()" class="carousel-nav">&#10094;</button>
            <img [src]="imageUrls[currentImageIndex]" class="carousel-image" alt="Imagen de previsualización">
            <button (click)="nextImage()" class="carousel-nav">&#10095;</button>
        </div>
    </div>
    <br/>
    <div class="row">
        <div class="col" style="text-align: right;">
            <button (click)="cerrar()" color="secundary" mat-raised-button type="button" class="margenBoton">
                <ic-icon [icon]="iconClose" inline="true" class="iconBoton"></ic-icon>
                <span>Cerrar</span>
            </button>
            <button (click)="descargar()" color="primary" mat-raised-button type="button" class="margenBoton">
                <ic-icon [icon]="iconDownload" inline="true" class="iconBoton"></ic-icon>
                <span>Descargar</span>
            </button>
        </div>
    </div>
</div>