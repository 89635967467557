<form #formRegistroCliente="ngForm">
    <div class="row">
        <!-- numero chasis -->
        <div class="col">
            <mat-form-field>
                <mat-label>Número de chasis</mat-label>
                <input matInput name="chasisSolicitud" [(ngModel)]="registro.chasis" [disabled]="modoDetalle" required>
            </mat-form-field>
        </div>
        <!-- tipo -->
        <div class="col">
            <mat-form-field>
                <mat-label>Tipo de vehículo</mat-label>
                <mat-select [(ngModel)]="registro.tipo" name="tipoVehiculo" [disabled]="modoDetalle" required>
                    <mat-option *ngFor="let tipo of apiURLs.tiposVehiculo" [value]="tipo.id">
                        {{tipo.nombre}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>         
    </div>
    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Linea</mat-label>
                <input matInput name="linea" [(ngModel)]="registro.linea" [disabled]="modoDetalle" required>
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field>
                <mat-label>Marca</mat-label>
                <input matInput name="marca" [(ngModel)]="registro.marca" [disabled]="modoDetalle" required>
            </mat-form-field>
        </div>
        <!-- marca -->
        <!--<div class="col" *ngIf="configuracion && configuracion.marcasArray">
            <mat-form-field>
                <mat-label>Marca</mat-label>
                <mat-select
                    [(ngModel)]="registro.marca" name="marcaSolicitud"
                    [disabled]="modoDetalle" required>
                    <mat-option *ngFor="let marca of configuracion.marcasArray" [value]="marca.nombre">
                        {{marca.nombre}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>-->
        <!-- modelo -->
        <div class="col">
            <mat-form-field>
                <mat-label>Modelo</mat-label>
                <input matInput name="modeloSolicitud" [(ngModel)]="registro.modelo" [disabled]="modoDetalle" required>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <!-- numeroMotor -->
        <div class="col">
            <mat-form-field>
                <mat-label>Número de motor</mat-label>
                <input matInput name="numeroMotorSolicitud" [(ngModel)]="registro.numeroMotor" [disabled]="modoDetalle" required>
            </mat-form-field>
        </div>
        <!-- color -->
        <div class="col">
            <mat-form-field>
                <mat-label>Color</mat-label>
                <input matInput name="colorSolicitud" [(ngModel)]="registro.color" [disabled]="modoDetalle" required>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <!-- placa -->
        <div class="col">
            <mat-form-field>
                <mat-label>Placa</mat-label>
                <input matInput name="placa" required
                [(ngModel)]="registro.placa" [textMask]="{mask: auth.maskPlaca()}" [disabled]="modoDetalle">
            </mat-form-field>
        </div>
        <!-- kilometraje -->
        <div class="col">
            <mat-form-field>
                <mat-label>Kilometraje</mat-label>
                <input matInput name="kilometraje" required
                [(ngModel)]="registro.kilometraje" [disabled]="modoDetalle" type="number">
            </mat-form-field>
        </div>
    </div>
</form>