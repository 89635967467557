

export class Accion {
    constructor(
        public numero:          number = null,
        public nombre:          string = null,
        public html:            string = null,
        public permisos:        string[] = [],
    ){}
}

export class Tutorial {
    constructor(
        public numero:          number = null,
        public nombre:          string = null,
        public acciones:        Accion[] = [],
    ){}
}

export class Manual {
    constructor(
        public id:              number,
        public contenido:       string,
    ){}

    contenidoModel: Tutorial[] = [];
}