import { ContratoAhorrent } from "./ContratoAhorrent";
import { ClienteAhorrent } from "./clienteAhorrent";
import { Unidad } from "./unidad";
import { Usuario } from "./usuario";

export class ReclamoRentaClienteAhorrent {
    constructor(
        public id:number,
        public observaciones: string,
        public fecha: string,

        //Relaciones
        public UsuarioId: number,
        public ClienteAhorrentId: number,
        public ContratoAhorrentId: number,
        public UnidadId: number,
    ){}

    fechaFormato:string;
    fechaDate:Date;
    Usuario: Usuario;
    ClienteAhorrent: ClienteAhorrent;
    ContratoAhorrent: ContratoAhorrent;
    Unidad:Unidad;

}