<ng-container *ngIf="recordsForm">
    <form [formGroup]='recordsForm'>
        <mat-form-field>
            <mat-label>{{placeholder}}</mat-label>
            <mat-chip-list #chipList>
                <mat-chip
                    *ngFor="let seleccionado of seleccionados; let i = index;"
                    [selectable]="true"
                    [removable]="!disabled"
                    (removed)="eliminarSeleccionado(i)"
                >
                    {{ seleccionado ? seleccionado[titleProp] : null }}
                    <mat-icon matChipRemove *ngIf="!disabled">clear</mat-icon>
                </mat-chip>
                <input
                    *ngIf="!(seleccionados.length > 0 && !multiSelect)"
                    [placeholder]="searchPlaceholder"
                    formControlName="query"
                    [(ngModel)]="query"
                    (keydown)="_handleKeydown($event)"
                    [matAutocomplete]="auto"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="true"
                    [disabled]="disabled"
                >
                <mat-progress-bar *ngIf="cargando" mode="indeterminate" color="primary"></mat-progress-bar>
            </mat-chip-list>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="agregarOpcion($event)">
                <mat-option *ngFor="let record of recordsQuery" [value]="record[idProp]" [disabled]="disabledRecords && !record.disponible">
                    {{record ? (!record.disponible ? 'NO DISPONIBLE - ' : '') : ''}} {{ record ? record[titleProp] : null}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </form>
</ng-container>