import { Injectable } from '@angular/core';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { JSONConverters } from '../models/JSONConverters';
import { Empresa } from '../models/empresa';

@Injectable()
export class EmpresasService { 
    apiUrls: APIUrls = new  APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
    ) { }

    // * * * Empresas * * * 
    async obtenerEmpresaPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.empresasURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.empresaDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearEmpresa(registro: Empresa) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.empresasURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarEmpresa(registro: Empresa) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.empresasURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarEmpresa(EmpresaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.empresasURL + '/id/' + EmpresaId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Empresa eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Todas
    async obtenerTodas() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.empresasURL;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.empresaDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las empresas.');
        }
    }

    async obtenerUsuariosConPermisosAprobacion() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.empresasURL + '/usuariosPermisosAprobacion';
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.usuarioDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los usuarios con permisos de aprobación.');
        }
    }

    // Desactivar
    public async desactivarEmpresa(EmpresaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.empresasURL + '/desactivar';
            const res = await this.connection.postRequest(url, { EmpresaId: EmpresaId });
            return { error: null, data: { mensaje: 'Empresa desactivada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Activar
    public async activarEmpresa(EmpresaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.empresasURL + '/activar';
            const res = await this.connection.postRequest(url, { EmpresaId: EmpresaId });
            return { error: null, data: { mensaje: 'Empresa activada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * * * * Archivos * * * * * *
    async guardarArchivoEnServidorEmpresas(archivo: File){
        try {//
            var url = this.apiUrls.uploadURL + this.apiUrls.usuariosURL + '/uploadArchivo';
            const res = await this.connection.uploadFile(url, archivo, true);
            if(!res.error) {
                return { error: null, data: { url: res.url } };
            }
            else throw new Error();
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al cargar el archivo.');
        }
    }

    async obtenerDataInicio(idUsuario: number, fechaInicioParam: any, fechaFinParam: any) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.empresasURL + '/resumenInicio?';
            if(fechaInicioParam) url += '&start=' + fechaInicioParam;
            if(fechaFinParam) url += '&finish=' + fechaFinParam;
            url += '&porRol=' + idUsuario;

            var json = await this.connection.getRequest(url);
            var data = {
                numeroFormularios:              json.data.numeroFormularios,
                numeroFormulariosRiesgo:        json.data.numeroFormulariosRiesgo,
                numeroformulariosCompletados:   json.data.numeroformulariosCompletados,
                numeroformulariosIncompletos:   json.data.numeroformulariosIncompletos,
            };
            
            return { error: null, data: data };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener el resumen de inicio.');
        }
    }
}