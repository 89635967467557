import { Empresa } from "./empresa";

export class VehiculoDemo{
    constructor(
        public id: number,
        public chasis: String,
        public tipo: String,
        public marca: String,
        public modelo: String,
        public numeroMotor: String,
        public color: String,
        public placa: String,
        public inactivo: boolean,
        public version: string,
        public umbralKilometraje: number,
        //Relaciones
        public EmpresaId: number,
    ){}

    objetoPlaca: any = {};

    Empresa: Empresa;

    //auxiliares
    nombreVehiculo: string;

}