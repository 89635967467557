import { ContratoAhorrent } from "./ContratoAhorrent";
import { Usuario } from "./usuario";

export class CambioFechaContratoAhorrent {
    constructor(
        public id:number,
        public fechaAnterior: string,
        public horaAnterior: string,
        public fechaNueva: string,
        public horaNueva: string,
        public clienteAviso: boolean,
        public observaciones: string,

        //Relaciones
        public UsuarioId: number,
        public ContratoAhorrentId: number,
    ){}

    fechaAnteriorFormato:string;
    fechaAnteriorDate:Date;
    fechaNuevaFormato:string;
    fechaNuevaDate:Date;
    nombreUsuario: string;
    clienteAvisoFormato: string;
    Usuario: Usuario;
    ContratoAhorrent: ContratoAhorrent;

}