export default [
    { "id": "Ahuachapán", "nombre": "Ahuachapán"},
    { "id": "Cabañas", "nombre": "Cabañas"},
    { "id": "Chalatenango", "nombre": "Chalatenango"},
    { "id": "Cuscatlán", "nombre": "Cuscatlán"},
    { "id": "La Libertad", "nombre": "La Libertad"},
    { "id": "La Paz", "nombre": "La Paz"},
    { "id": "La Unión", "nombre": "La Unión"},
    { "id": "Morazán", "nombre": "Morazán"},
    { "id": "San Miguel", "nombre": "San Miguel"},
    { "id": "San Salvador", "nombre": "San Salvador"},
    { "id": "San Vicente", "nombre": "San Vicente"},
    { "id": "Santa Ana", "nombre": "Santa Ana"},
    { "id": "Sonsonate", "nombre": "Sonsonate"},
    { "id": "Usulután", "nombre": "Usulután"}
];