import { ItemPuntoPresupuesto } from "./ItemPuntoPresupuesto";
import { Presupuesto } from "./presupuesto";
import { PuntoPresupuesto } from "./PuntoPresupuesto";
import { Usuario } from "./usuario";

export class HistoricoItemPresupuesto {
    constructor(
        public id: number,
        public fecha: string,
        public tipo: string,
        public accion: string,
        //Relaciones
        //PrespuestoId
        public PresupuestoId: number,
        public PuntoPresupuestoId: number,
        public ItemPuntoPresupuestoId: number,
        public UsuarioId: number,
    ) { }

    fechaFormato: string;
    fechaDate: Date;

    //Relaciones
    Presupuesto: Presupuesto;
    PuntoPresupuesto: PuntoPresupuesto;
    ItemPuntoPresupuesto: ItemPuntoPresupuesto;
    Usuario: Usuario

    

}