import { APIUrls } from '../api/apiUrls';
import { Empresa } from './empresa';
import { JSONConverters } from './JSONConverters';

export class RepuestoBestune {
    constructor(
        public id: number,
        public numeroRepuesto: number,
        public nombreIngles: string,
        public nombreEspaniol: string,
        public codigo: string,
        public codigoRetorno: string,
        public cantidadPiezas: number,
        public codigoImagen: string,
        public codigoEnsamblaje: string,
        public codigoGrupo: string,
        public modelo: string,

        // Relaciones
        public EmpresaId: number,
    ){ }

    jsonConverts: JSONConverters = new JSONConverters();

    Empresa: Empresa;

}