export class ConfiguracionLanding {
    constructor(
        public id: number,
        public disclaimerPrecios: string,
    ){this.cargarArrays()}

    cargarArrays() {
        
        if (this.disclaimerPrecios) {
            this.disclaimerPrecios =  this.disclaimerPrecios;
        }

    }

}