import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import iconClose from '@iconify/icons-ic/twotone-close';
import { LibraryService } from 'src/app/library/library.service';
import { APIUrls } from 'src/app/api/apiUrls';
import { ClientePlanMantenimiento } from 'src/app/models/ClientePlanMantenimiento';


@Component({
  selector: 'mat-form-cliente',
  templateUrl: './mat-cliente.component.html',
  styleUrls: ['./mat-cliente.component.scss']
})
export class MatFormClientePlanMantenimientoComponent implements OnInit {

    @Input() registro: ClientePlanMantenimiento;
    @Input() openModal: Function;
    @Input() modoDetalle: boolean = false;


    @Output() changeCliente: EventEmitter<any> = new EventEmitter();

    apiURLs: APIUrls = new APIUrls();

    //banderas
    telefonoValido: boolean = false;
    dpiValido: boolean = false;
    emailValido: boolean = false;

    constructor(
        public libraryService: LibraryService,
        private changeDetector: ChangeDetectorRef,
        private service: LibraryService,
    ){}

    ngOnInit() { 
        if (!this.registro) throw new TypeError("The input 'registro' is required"); 
        this.iniciarDatos();
    }

    async iniciarDatos(){
        this.formularioValido();
    }

    change(){
        let valido = this.formularioValido();
        this.changeCliente.emit({registro: this.registro, valido: valido })
    }

    formularioValido(){
        if(!this.registro) return false;
        if(!this.registro.nombres) return false;
        if(!this.registro.apellidos) return false;
        if(!this.registro.nit) return false;
        if(!this.registro.email) return false;
        this.validarDPI();
        this.validarTelefono();
        this.validarCorreo();
        if(!(this.dpiValido && this.telefonoValido && this.emailValido)) return false
        return true;
    }

    validarDPI(){
        if(!this.registro) return;
        let cadenaLength = this.registro.dpi.length;
        this.dpiValido = cadenaLength == 13;
    }

    validarTelefono(){
        if(!this.registro) return;
        let cadenaLength = this.registro.telefono.length;
        this.telefonoValido = cadenaLength == 8;
    }

    validarCorreo(){
        if(!this.registro) return;
        this.emailValido = this.libraryService.validateEmail(this.registro.email);
    }

}
