import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import iconClose from '@iconify/icons-ic/twotone-close';
import { LibraryService } from 'src/app/library/library.service';
import { APIUrls } from 'src/app/api/apiUrls';
import { VehiculoPlanMantenimiento } from 'src/app/models/vehiculoPlanMantenimiento';
import { AuthService } from 'src/app/auth/auth.service';


@Component({
  selector: 'mat-form-vehiculo-planes',
  templateUrl: './mat-vehiculo-planes.component.html',
  styleUrls: ['./mat-vehiculo-planes.component.scss']
})
export class MatFormVehiculoPlanMantenimientoComponent implements OnInit {

    @Input() registro: VehiculoPlanMantenimiento;
    @Input() openModal: Function;
    @Input() modoDetalle: boolean = false;


    @Output() changeRegistro: EventEmitter<any> = new EventEmitter();

    apiURLs: APIUrls = new APIUrls();

    constructor(
        public libraryService: LibraryService,
        private changeDetector: ChangeDetectorRef,
        private service: LibraryService,
        public auth: AuthService,
    ){}

    ngOnInit() { 
        if (!this.registro) throw new TypeError("The input 'registro' is required"); 
        this.iniciarDatos();
    }

    async iniciarDatos(){
        
    }

    change(){
        this.changeRegistro.emit(this.registro)
    }

}
