import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { LibraryService } from '../library.service';
import iconClose from '@iconify/icons-ic/twotone-close';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

interface Control{
    mostrar: boolean,
    registro: any,
}

@Component({
  selector: 'mat-select-local',
  templateUrl: './mat-select-local.component.html',
  styleUrls: ['./mat-select-local.component.scss']
})
export class MatSelectLocalComponent implements OnInit {
    /*
        Descripción de inputs:

        - placeholder:              Texto que mostrara el componente.
        - disabled:                 Deshabilita o habilita el componente.
        - name:                     Nombre único del componente.
        - registro:                 Registro en el cual se estará trabajando(ngModel).
        - campoRegistro:            Campo al cual se asignara el valor seleccionado en las opciones del mat-select.
        - registros:                Registros de los cuales se obtendrán los valores asignables.
        - campoRegistros:           Campo que sera asignado a cada mat-option, en concreto sera el valor asignable([value]="control.registro[campoRegistros]").
        - campoVisibleRegistros:    Campo que mostrara cada mat-option.
    */

    @Input() placeholder: string;
    @Input() disabled: boolean;
    @Input() name: string;
    @Input() registro: any;
    @Input() campoRegistro: string;
    @Input() registros: any[] = [];
    @Input() campoRegistros: string;
    @Input() campoVisibleRegistros: string;
    @Input() modelChange: Function;
    @Input() click: Function;
    @Input() obligatorio: boolean = false;
    @Input() modoBuscador: boolean = false;
    @Input() valorNulo: boolean = false;
    @Input() valorNuloNumerico: boolean = false;
    @Input() multiple: boolean = false;

    @Output() selectionChange: EventEmitter<any> = new EventEmitter();
    separatorKeysCodes: number[] = [ENTER, COMMA];
    iconClose = iconClose;
    texto: string;

    // Data:
    cargando: boolean = false;
    controles: Control[] = [];
    private searchSub$ = new Subject<string>();

    constructor(
        public libraryService: LibraryService,
        private changeDetector: ChangeDetectorRef,
        private service: LibraryService,
    ){}

    accion(){
        if(this.modelChange){
            this.modelChange();
        }
        this.selectionChange.emit(this.registro[this.campoRegistro])
    }

    clickCall(){
        if(this.click){
            this.click();
        }
        if(!this.multiple) {
            this.texto = null;
            this.resetData();
        }
    }

    ngOnInit() { 
        if (!this.registro)                 throw new TypeError("The input 'registro' is required");
        if (!this.name)                     throw new TypeError("The input 'name' is required");
        if (!this.campoRegistro)            throw new TypeError("The input 'campoRegistro' is required");
        if (!this.campoRegistros)           throw new TypeError("The input 'campoRegistros' is required");  
        if (!this.campoVisibleRegistros)    throw new TypeError("The input 'campoVisibleRegistros' is required");  
        this.construirData();
        this.searchSub$.pipe(
            debounceTime(400),
            distinctUntilChanged()
        ).subscribe((filterValue: string) => {
            this.filtroControl(filterValue);
        });
    }

    applyFilter(filterValue: string) {
        this.searchSub$.next(filterValue)
    }

    filtroControl(value) { 
        this.controles = this.search(value);
    }

    search(value: string) { 
        let filter = value.toLowerCase();
        this.resetData();
        let controles: Control[] = this.controles;
        if(filter && filter.localeCompare('') != 0){
            this.filtrarControlPorBusqueda(controles, filter);
        }
        return controles;
    }

    filtrarControlPorBusqueda(controles: Control[], palabra: string){
        if(this.campoRegistro && this.campoRegistros){
            for(let control of controles){
                if(control.registro && control.registro[this.campoVisibleRegistros] != undefined){
                    if(this.registro){
                        let campo: string = control.registro[this.campoVisibleRegistros];
                        if(campo.toLocaleLowerCase().includes(palabra)){
                            control.mostrar = true;
                        } else {
                            control.mostrar = false;
                        }
                    } else {
                        control.mostrar = false;
                    }
                } else {
                    control.mostrar = false;
                }

                if(this.multiple && !control.mostrar) {
                    for(let el of this.registro[this.campoRegistro]) {
                        if(el == control.registro[this.campoRegistros]) {
                            control.mostrar = true;
                        }
                    }
                }
            }
        } 
    }

    resetData(){
        if(this.controles && this.controles.length > 0){
            for(let control of this.controles){
                control.mostrar = true;
            }
        }
    }

    construirData(){
        if(this.registros && this.registros.length > 0){
            this.controles = [];
            for(let registro of this.registros){
                this.controles.push({ registro: registro, mostrar: true });
            }
        }
    }

    openedChange(opened: boolean) {
        if(!opened) {
            this.texto = null;
            this.resetData();
        }
    }

}
