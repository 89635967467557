<div class="container">
    <mat-progress-bar *ngIf="cargando" mode="indeterminate" color="primary"></mat-progress-bar>
    <div class="row">
        <div class="col">
            <!-- Bienvenida -->
            <div class="card" style="background-color: rgb(0,63,192);" fxLayout="row" fxLayout.xs="column">
                <div class="p-6 text-primary-contrast" fxFlex="auto">
                    <h2 class="headline m-0" style="font-weight: bold;">Bienvenido de vuelta, {{
                        auth.usuario.nombreCompleto }}</h2>
                </div>
            </div>
        </div>
    </div>
    <br />
    <div class="row align-items-center">
        <!-- Rango de fechas -->
        <div class="col">
            <mat-form-field>
                <mat-label>Rango de fechas</mat-label>
                <mat-select [(ngModel)]="rangoFecha" name="rangoFecha" (selectionChange)="actualizarFechas()">
                    <mat-option *ngFor="let rango of rangosFechas" [value]="rango.id">{{rango.nombre}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <!-- Inicio -->
        <div class="col">
            <mat-form-field class="anchoCompleto">
                <mat-label>Del</mat-label>
                <input matInput [matDatepicker]="pickerInicio" placeholder="Del"
                    (dateChange)="actualizarFechaInicio($event)" [(ngModel)]="fechaInicioDate">
                <mat-datepicker-toggle matSuffix [for]="pickerInicio"></mat-datepicker-toggle>
                <mat-datepicker #pickerInicio></mat-datepicker>
            </mat-form-field>
        </div>
        <!-- Fin -->
        <div class="col">
            <mat-form-field class="anchoCompleto">
                <mat-label>Al</mat-label>
                <input matInput [matDatepicker]="pickerFin" placeholder="Al" (dateChange)="actualizarFechaFin($event)"
                    [(ngModel)]="fechaFinDate">
                <mat-datepicker-toggle matSuffix [for]="pickerFin"></mat-datepicker-toggle>
                <mat-datepicker #pickerFin></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col">
            <!-- Buscar -->
            <button mat-raised-button color="primary" (click)="obtenerDataInicio()"
                style="width: 100%; margin-bottom: 1.34375em;">
                <mat-icon class="iconoBoton">update</mat-icon>
                <span>Actualizar</span>
            </button>
        </div>
    </div>
    <div class="row" *ngIf="auth.tienePermisoPara(apiURLs.permisos.VISUALIZAR_FORMULARIOS)">
        <!-- Total facturado -->
        <div class="col">
            <div class="card p-6 relative hover:shadow-16 trans-shadow" fxLayout="column" fxLayoutAlign="start center"
                style="margin-bottom: 10px;">
                <div class="rounded-full w-12 h-12 flex items-center justify-center text-accent bg-accent-light">
                    <mat-icon>description</mat-icon>
                </div>
                <h2 class="fuenteInicioWidget font-bold">{{ numeroFormularios }}</h2>
                <h3 class="fuenteInicioWidgetSub font-medium text-secondary m-0 font-card-reporte">Formularios del mes
                </h3>
            </div>
        </div>
        <!-- Gastos del mes -->
        <div class="col">
            <div class="card p-6 relative hover:shadow-16 trans-shadow" fxLayout="column" fxLayoutAlign="start center"
                style="margin-bottom: 10px;">
                <div class="rounded-full w-12 h-12 flex items-center justify-center text-accent bg-accent-light">
                    <mat-icon>priority_high</mat-icon>
                </div>
                <h2 class="fuenteInicioWidget font-bold">{{ numeroFormulariosRiesgo }}</h2>
                <h3 class="fuenteInicioWidgetSub font-medium text-secondary m-0 font-card-reporte">Formularios en riesgo
                </h3>
            </div>
        </div>
        <!-- Total de recibos -->
        <div class="col">
            <div class="card p-6 relative hover:shadow-16 trans-shadow" fxLayout="column" fxLayoutAlign="start center"
                style="margin-bottom: 10px;">
                <div class="rounded-full w-12 h-12 flex items-center justify-center text-accent bg-accent-light">
                    <mat-icon>check</mat-icon>
                </div>
                <h2 class="fuenteInicioWidget font-bold">{{ numeroformulariosCompletados }}</h2>
                <h3 class="fuenteInicioWidgetSub font-medium text-secondary m-0 font-card-reporte">Formularios
                    completados</h3>
            </div>
        </div>
        <!-- Ventas del mes -->
        <div class="col">
            <div class="card p-6 relative hover:shadow-16 trans-shadow" fxLayout="column" fxLayoutAlign="start center"
                style="margin-bottom: 10px;">
                <div class="rounded-full w-12 h-12 flex items-center justify-center text-accent bg-accent-light">
                    <mat-icon>cancel</mat-icon>
                </div>
                <h2 class="fuenteInicioWidget font-bold">{{ (numeroformulariosIncompletos) }}</h2>
                <h3 class="fuenteInicioWidgetSub font-medium text-secondary m-0 font-card-reporte">Formularios
                    incompletos</h3>
            </div>
        </div>
    </div>
    <!-- Últimos formularios -->
    <div class="row" style="margin-bottom: 10px;" *ngIf="auth.tienePermisoPara(apiUrls.permisos.VISUALIZAR_FORMULARIOS)">
        <div class="col">
            <div class="card w-full" fxLayout="column">
                <div class="border-b py-3 px-6" fxLayout="row" fxLayoutAlign="start center">
                    <h2 class="m-0 title" fxFlex="auto"><b>Mis formularios</b></h2>
                </div>
                <!-- Tabla -->
                <app-busqueda-tabla #tablaBuscadorFormularios [ignorarRuta]="true" [esconderBusqueda]="true" [campos]="campos"
                    [columnas]="columnasFormularios" [filtros]="filtros" [url]="urlBusqueda"
                    [parser]="jsonConverters.formularioFEICDeJSON" [parserService]="jsonConverters"
                    [paramsEspeciales]="calcularParamsEspecialesFormularios()" [limite]="10"
                    [routerClick]="'/ventas/detalle-formulario-feic/'" (actualizarData)="actualizarOtrosCampos($event)"
                    [verificarRutaFormulario]="true" [forzarResponsive]="true" [ignorarAlertas]="true">
                </app-busqueda-tabla>
            </div>
        </div>
    </div>
    <!-- Avalúos 
    <div class="row" style="margin-bottom: 10px;" *ngIf="auth.tienePermisoPara(apiUrls.permisos.REPORTE_AVALUO)">
        <div class="col">
            <div class="card w-full" fxLayout="column">
                <div class="border-b py-3 px-6" fxLayout="row" fxLayoutAlign="start center">
                    <h2 class="m-0 title" fxFlex="auto"><b>Últimos avalúos</b></h2>
                </div>
                <!-- Tabla --
                <app-busqueda-tabla #tablaBuscadorAvaluos [ignorarRuta]="true" [esconderBusqueda]="true" [campos]="campos"
                    [columnas]="columnasAvaluo" [filtros]="filtros" [url]="urlBusquedaAvaluos"
                    [parser]="jsonConverters.avaluoDeJSON" [parserService]="jsonConverters"
                    [paramsEspeciales]="calcularParamsEspecialesAvaluo()" [limite]="10"
                    (actualizarData)="actualizarOtrosCampos($event)" [verificarRutaFormulario]="true" [forzarResponsive]="true" [ignorarAlertas]="true">
                </app-busqueda-tabla>
            </div>
        </div>
    </div>
    -->
    <!-- Mis citas --
    <div class="row" style="margin-bottom: 10px;" *ngIf="auth.tienePermisoPara(apiUrls.permisos.AGENTE_CITAS)">
        <div class="col">
            <div class="card w-full" fxLayout="column">
                <div class="border-b py-3 px-6" fxLayout="row" fxLayoutAlign="start center">
                    <h2 class="m-0 title" fxFlex="auto"><b>Mis citas</b></h2>
                </div>
                <!-- Tabla --
                <app-busqueda-tabla #tablaBuscadorCitas [ignorarRuta]="true" [esconderBusqueda]="true" [campos]="campos"
                    [columnas]="columnasCitas" [filtros]="filtros" [url]="urlBusquedaCitas"
                    [parser]="jsonConverters.citaDeJSON" [parserService]="jsonConverters"
                    [paramsEspeciales]="calcularParamsEspecialesCitas()" [limite]="10"
                    [routerClick]="'/citas/detalle/'" (actualizarData)="actualizarOtrosCampos($event)"
                    [forzarResponsive]="true" [ignorarAlertas]="true">
                </app-busqueda-tabla>
            </div>
        </div>
    </div>
    -->
    <!-- Recordatorio de citas --
    <div class="row" style="margin-bottom: 10px;" *ngIf="auth.tienePermisoPara(apiUrls.permisos.AGENTE_CITAS)">
        <div class="col">
            <div class="card overflow-auto w-full" fxLayout="column">
                <div class="border-b py-3 px-6" fxLayout="row" fxLayoutAlign="start center">
                    <h2 class="m-0 title" fxFlex="auto"><b>Citas pendientes de confirmar</b></h2>
                </div>
                <!-- Tabla 1--
                <app-busqueda-tabla #tablaBuscadorRecordatorioPendiente [ignorarRuta]="true" 
                    [esconderBusqueda]="true" [campos]="campos"
                    [columnas]="columnasRecordatorios" [filtros]="filtros" [url]="urlBusquedaRecordatorios"
                    [parser]="jsonConverters.citaDeJSON" [parserService]="jsonConverters"
                    [paramsEspeciales]="calcularParamsEspecialesRecordatorios(1)" [limite]="10"
                    [routerClick]="'/citas/detalle/'" (actualizarData)="actualizarOtrosCampos($event)"
                    [forzarResponsive]="true" [ignorarAlertas]="true">
                </app-busqueda-tabla>
            </div>
        </div>
    </div>
    -->
    <!--
    <div class="row" style="margin-bottom: 10px;" *ngIf="auth.tienePermisoPara(apiUrls.permisos.AGENTE_CITAS)">
        <div class="col">
            <div class="card overflow-auto w-full" fxLayout="column">
                <div class="border-b py-3 px-6" fxLayout="row" fxLayoutAlign="start center">
                    <h2 class="m-0 title" fxFlex="auto"><b>Citas del día</b></h2>
                </div>
                <!-- Tabla 1--
                <app-busqueda-tabla #tablaBuscadorRecordatorioPendiente [ignorarRuta]="true" 
                    [esconderBusqueda]="true" [campos]="campos"
                    [columnas]="columnasRecordatorios" [filtros]="filtros" [url]="urlBusquedaRecordatorios"
                    [parser]="jsonConverters.citaDeJSON" [parserService]="jsonConverters"
                    [paramsEspeciales]="calcularParamsEspecialesRecordatorios(2)" [limite]="10"
                    [routerClick]="'/citas/detalle/'" (actualizarData)="actualizarOtrosCampos($event)"
                    [forzarResponsive]="true" [ignorarAlertas]="true">
                </app-busqueda-tabla>
            </div>
        </div>
    </div>
    -->
</div>
<!-- * * * Templates * * * -->
<ng-template #cellNormal let-value="value" ngx-datatable-cell-template>{{value}}</ng-template>
<ng-template #cellMoneda let-value="value" ngx-datatable-cell-template>{{value | currency:
    auth.optionsMonedaLocal().prefix}}</ng-template>
<ng-template #cellCompletado let-value="value" ngx-datatable-cell-template>
    <mat-icon class="iconoCompletadoTab" *ngIf="value; else noCompletado">check_circle</mat-icon>
    <ng-template #noCompletado>
        <mat-icon class="iconoErrorTab">error</mat-icon>
    </ng-template>
</ng-template>