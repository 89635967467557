import { Empresa } from './empresa';
import { JSONConverters } from './JSONConverters';

export class ConfiguracionPresupuesto {
    constructor(
        public id: number,
        public asesores: string,
        public tiposVehiculos: string,
        public lineas: string,
        public supervisores: string,
        public mecanicos: string,
        public sedes: string,
        public notificacionesPresupuestos: string,
        public porcentajesDescuentos: string,
        public motivosRechazo: string,
        public notificacionManoObra: string,
        public notificacionRepuestos: string,
        public montoAutorizadoPresupuesto: number,
        public emailsDescargaVisualizacionPresupuesto: string,
        public notificacionesFinalizar: string,
        public porcentajesDescuentosRepuestos: string,
        public notificacionRepuestoExterno: string,
        public EmpresaId: number,

    ) { this.cargarArrays() }

    jsonConverts: JSONConverters = new JSONConverters();


    asesoresArray: any[] = []
    tiposVehiculosArray: any[] = []
    lineasArray: any[] = []
    supervisoresArray: any[]= []
    mecanicosArray: any[]= []
    sedesArray: any[]= []
    notificacionesPresupuestosArray: any[]= []
    porcentajesDescuentosArray: any[]= []
    motivosRechazoArray: any[]= []
    notificacionManoObraArray: any[]= []
    notificacionRepuestosArray: any[]= []
    emailsDescargaVisualizacionPresupuestoArray: any[] = []
    notificacionesFinalizarArray: any[] = []
    porcentajesDescuentosRepuestosArray: any[] = []
    notificacionRepuestoExternoArray: any[] = []

    Empresa: Empresa;


    cargarArrays() {
        this.asesoresArray = this.asesores ? JSON.parse(this.asesores) : [];
        this.tiposVehiculosArray = this.tiposVehiculos ? JSON.parse(this.tiposVehiculos) : [];
        this.lineasArray = this.lineas ? JSON.parse(this.lineas) : [];
        this.supervisoresArray = this.supervisores ? JSON.parse(this.supervisores) : [];
        this.mecanicosArray = this.mecanicos ? JSON.parse(this.mecanicos) : [];
        this.sedesArray = this.sedes ? JSON.parse(this.sedes) : [];
        this.notificacionesPresupuestosArray = this.notificacionesPresupuestos ? JSON.parse(this.notificacionesPresupuestos) : [];
        this.porcentajesDescuentosArray = this.porcentajesDescuentos ? JSON.parse(this.porcentajesDescuentos) : [];
        this.motivosRechazoArray = this.motivosRechazo ? JSON.parse(this.motivosRechazo) : [];
        this.notificacionManoObraArray = this.notificacionManoObra ? JSON.parse(this.notificacionManoObra) : [];
        this.notificacionRepuestosArray = this.notificacionRepuestos ? JSON.parse(this.notificacionRepuestos) : [];
        this.emailsDescargaVisualizacionPresupuestoArray = this.emailsDescargaVisualizacionPresupuesto ? JSON.parse(this.emailsDescargaVisualizacionPresupuesto) : [];
        this.notificacionesFinalizarArray = this.notificacionesFinalizar ? JSON.parse(this.notificacionesFinalizar) : [];
        this.porcentajesDescuentosRepuestosArray = this.porcentajesDescuentosRepuestos ? JSON.parse(this.porcentajesDescuentosRepuestos) : [];
        this.notificacionRepuestoExternoArray = this.notificacionRepuestoExterno ? JSON.parse(this.notificacionRepuestoExterno) : [];
    }


}