import { ContratoAhorrent } from "./ContratoAhorrent";
import { ClienteAhorrent } from "./clienteAhorrent";
import { Usuario } from "./usuario";

export class ReclamoGeneralClienteAhorrent {
    constructor(
        public id:number,
        public observaciones: string,
        public fecha: string,

        //Relaciones
        public UsuarioId: number,
        public ClienteAhorrentId: number,
    ){}

    fechaFormato:string;
    fechaDate:Date;
    Usuario: Usuario;
    ClienteAhorrent: ClienteAhorrent;

}