<mat-form-field style="width: 100%;">
    <mat-label>{{placeholder}}</mat-label>
    <mat-select placeholder="{{placeholder}}" [(ngModel)]="id" (selectionChange)="itemSelected($event)" [disabled]="disabled">
        <!-- Search box -->
        <div class="searchDiv">
            <div style="width: -webkit-fill-available;">
                <input matInput placeholder="{{searchPlaceholder}}"
                    [(ngModel)]="query"
                    (ngModelChange)="search()"
                    (keydown)="_handleKeydown($event)"
                    (input)="search()"
                >
            </div>
            <div style="float: right;">
                <button class="deleteButton" mat-icon-button (click)="deleteQuery()">
                    <mat-icon>times</mat-icon>
                </button>
            </div>
        </div>
        <!-- Options -->
        <mat-option *ngFor="let record of recordsQuery" [value]="record[idProp]">
            <span *ngIf="!subtitleProp">{{ record[titleProp] }}</span>
            <span *ngIf="subtitleProp">
                <b>{{ record[titleProp] }}</b>
                ({{ record[subtitleProp] }})
            </span>
        </mat-option>
    </mat-select>
</mat-form-field>