import { Injectable, Component, Inject } from '@angular/core';
import { ConnectionService } from '../api/connection.service';
import * as moment from 'moment';
moment.locale('es');
import * as momentTimezone from 'moment-timezone';
import * as ExcelJS from 'exceljs';
import { Observable } from 'rxjs';
import { tap, switchMap } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Danios, ParteVehiculoDanio } from '../models/avaluo';
import { APIUrls } from '../api/apiUrls';
import { ContratoAhorrent } from '../models/ContratoAhorrent';

export interface DialogData {
    titulo: string,
    texto: string,
}

@Injectable()
export class LibraryService {

    private apiURLs: APIUrls = new APIUrls();

    constructor(
        private connection: ConnectionService,
        private dialog: MatDialog,
    ) { }

    public async obtenerRegistros(url: string, parser, campoRecords: string) {
        try {
            if (!campoRecords) campoRecords = 'records';
            console.log(url);
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json[campoRecords].length; i++) {
                const element = json[campoRecords][i];
                registros.push(parser(element));
            }
            return { error: null, data: { registros: registros, total: json.total, originalJSON: json } };
        } catch (error) {
            console.error(error);
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    obtenerRegistrosObservable(url: string, parser, campoRecords: string): Observable<any> {
        try {
            if (!campoRecords) campoRecords = 'records';
            return this.connection.getRequestObservable(url).pipe(
                tap((json: any) => {
                    var registros = [];
                    for (let i = 0; i < json[campoRecords].length; i++) {
                        const element = json[campoRecords][i];
                        registros.push(parser(element));
                    }
                    return { error: null, data: { registros: registros, total: json.total, originalJSON: json } };
                })
            )
        } catch (error) {
            return null;
        }
    }

    /**
     * Convierte una fecha a otro formato
     * @param fecha Fecha a convertir
     * @param formatoEntrada Formato de moment.js, 'date' si viene como objeto Date
     * @param formatoSalida Formato de moment.js
     */
    public convertirFecha(fecha: any, formatoEntrada: string, formatoSalida: string) {
        var fecha;
        if (formatoEntrada === 'date') fecha = moment(fecha);
        else fecha = moment(fecha, formatoEntrada);

        var res;
        if (formatoSalida === 'date') res = fecha;
        else res = fecha.format(formatoSalida);
        return res;
    }

    public convertirFechaATimezone(fecha: any, formatoSalida: string, timezone: string) {
        var fechaConvertida = momentTimezone.tz(fecha, timezone);
        return fechaConvertida.format(formatoSalida);
    }

    primerYUltimoDiaDelMes(fecha) {
        var partes = fecha.split('-');
        var y = parseInt(partes[0]);
        var m = parseInt(partes[1]) - 1;
        var firstDay = this.convertirFecha(new Date(y, m, 1), 'date', 'YYYY-MM-DD');
        var lastDay = this.convertirFecha(new Date(y, m + 1, 0), 'date', 'YYYY-MM-DD');

        return { primerDia: firstDay, ultimoDia: lastDay };
    }

    primerYUltimoDiaDelAno(fecha) {
        var partes = fecha.split('-');
        var firstDay = partes[0] + '-01-01';
        var lastDay = partes[0] + '-12-31';

        return { primerDia: firstDay, ultimoDia: lastDay };
    }

    /**
     * Valida que una hora esté en el formato de 24 horas HH:MM
     * @param hora 
     */
    public validarHora(hora: string): boolean {
        var horaElements = hora.split(':');
        if (parseInt(horaElements[0]) > 23 || parseInt(horaElements[1]) > 59 || horaElements[0].length < 2 || horaElements[1].length < 2 || hora.indexOf('_') != -1) {
            return false;
        }
        return true;
    }

    /**
     * Convierte la fecha a un formato de texto de mejor lectura
     * @param fechaStr 
     * @param formatoEntrada Acepta 'YYYY-MM-DD', 'DD/MM/YYYY'
     * @param incluirDiaSemana 
     */
    convertirFechaATexto(fechaStr: string, formatoEntrada: string, incluirDiaSemana: boolean): string {
        switch (formatoEntrada) {
            case 'YYYY-MM-DD': {
                var elementos = fechaStr.split('-');
                anio = Number.parseInt(elementos[0]);
                mes = Number.parseInt(elementos[1]);
                dia = Number.parseInt(elementos[2]);
                break;
            }
            case 'DD/MM/YYYY': {
                var elementos = fechaStr.split('/');
                anio = Number.parseInt(elementos[2]);
                mes = Number.parseInt(elementos[1]);
                dia = Number.parseInt(elementos[0]);
                break;
            }
            default: {
                return null;
            }
        }
        var dia: number;
        var mes: number;
        var anio: number;
        var fecha = new Date(anio, mes - 1, dia);
        var diaSemana = fecha.getDay();
        var finalString = '';
        if (incluirDiaSemana) {
            switch (diaSemana) {
                case 0: finalString += 'domingo'; break;
                case 1: finalString += 'lunes'; break;
                case 2: finalString += 'martes'; break;
                case 3: finalString += 'miércoles'; break;
                case 4: finalString += 'jueves'; break;
                case 5: finalString += 'viernes'; break;
                case 6: finalString += 'sábado'; break;
            }
            finalString += ' ';
        }
        finalString += dia + ' de ';
        switch (mes) {
            case 1: finalString += 'enero'; break;
            case 2: finalString += 'febrero'; break;
            case 3: finalString += 'marzo'; break;
            case 4: finalString += 'abril'; break;
            case 5: finalString += 'mayo'; break;
            case 6: finalString += 'junio'; break;
            case 7: finalString += 'julio'; break;
            case 8: finalString += 'agosto'; break;
            case 9: finalString += 'septiembre'; break;
            case 10: finalString += 'octubre'; break;
            case 11: finalString += 'noviembre'; break;
            case 12: finalString += 'diciembre'; break;
        }
        finalString += ' de ' + anio;
        return finalString;
    }

    /**
     * Muestra un dialog con un mensaje sencillo.
     * @param titulo Título del dialog
     * @param tipo Tipo de dialog a mostrar
     * @param texto Texto a mostrar como contenido del dialog
     */
    crearNotificacion(titulo, tipo, texto = null) {
        this.dialog.open(GenericDialog, {
            data: {
                titulo: titulo,
                texto: texto,
            }
        });
    }

    indexOf(array, findKey, value) {
        if (array) {
            for (let i = 0; i < array.length; i++) {
                const element = array[i];
                if (element[findKey] == value) {
                    return i;
                }
            }
        }
        return -1;
    }

    validarCampo(campo) {
        if (!campo) return null;
        else {
            if (typeof campo == 'string' && campo.trim() == '') {
                return null;
            }
            else return campo;
        }
    }

    isNormalInteger(str) {
        var n = Math.floor(Number(str));
        return n !== Infinity && String(n) === str && n >= 0;
    }

    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    validateText(text) {
        if (!text) {
            return false;
        }
        if (typeof text === 'string' && text.trim() == '') {
            return false;
        }
        return true;
    }

    validatePositiveNumber(number) {
        if (!number) {
            return false;
        }
        if (isNaN(number)) {
            return false;
        }
        if (number < 0) return false;
        return true;
    }

    validateArrayNotEmpty(array) {
        if (!array) return false;
        if (array.length == 0) return false;
        return true;
    }

    validatePassword(text) {
        if (text == null) {
            return false;
        }
        if (text.trim() == '') {
            return false;
        }
        if (text.length < 6) {
            return false;
        }
        return true;
    }

    numeroMesesParaFrecuencia(frecuenciaPago) {
        switch (frecuenciaPago) {
            case 'mensual': return 1;
            case 'bimestral': return 2;
            case 'trimestral': return 3;
            case 'semestral': return 6;
            case 'anual': return 12;
            default: return 1;
        }
    }

    bubbleSort(inputArr: any[], campo) {
        if (!inputArr) return inputArr;
        let len = inputArr.length;
        let swapped;
        do {
            swapped = false;
            for (let i = 0; i < len - 1; i++) {
                if (inputArr[i][campo] > inputArr[i + 1][campo]) {
                    let tmp = inputArr[i];
                    inputArr[i] = inputArr[i + 1];
                    inputArr[i + 1] = tmp;
                    swapped = true;
                }
            }
        } while (swapped);
        return inputArr;
    };

    bubbleSortDosCampos(inputArr: any[], campo1, campo2) {
        if (!inputArr) return inputArr;
        let len = inputArr.length;
        let swapped;
        do {
            swapped = false;
            for (let i = 0; i < len - 1; i++) {
                if (inputArr[i][campo1][campo2] > inputArr[i + 1][campo1][campo2]) {
                    let tmp = inputArr[i];
                    inputArr[i] = inputArr[i + 1];
                    inputArr[i + 1] = tmp;
                    swapped = true;
                }
            }
        } while (swapped);
        return inputArr;
    };

    async descargarPDFHTMLString(fileName: string, htmlString: string) {
        // Guardar archivo PDF
        var response = await this.connection.downloadPDFFromHTML(htmlString);
        var mediaType = 'application/pdf';
        var blob = new Blob([response], { type: mediaType });
        FileSaver.saveAs(blob, fileName);
    }

    async descargarWordHTMLString(fileName: string, htmlString: string) {
        // Guardar archivo Word
        var arrayUTF8 = this.toUTF8Array(htmlString);
        var byteNumbers = new Uint8Array(arrayUTF8.length);
        for (var i = 0; i < arrayUTF8.length; i++) {
            byteNumbers[i] = arrayUTF8[i];
        }
        var blob = new Blob([byteNumbers], { type: 'text/html;charset=UTF-8;' });
        FileSaver.saveAs(blob, fileName);
    }

    toUTF8Array(str) {
        var utf8 = [];
        for (var i = 0; i < str.length; i++) {
            var charcode = str.charCodeAt(i);
            if (charcode < 0x80) utf8.push(charcode);
            else if (charcode < 0x800) {
                utf8.push(0xc0 | (charcode >> 6),
                    0x80 | (charcode & 0x3f));
            }
            else if (charcode < 0xd800 || charcode >= 0xe000) {
                utf8.push(0xe0 | (charcode >> 12),
                    0x80 | ((charcode >> 6) & 0x3f),
                    0x80 | (charcode & 0x3f));
            }
            // surrogate pair
            else {
                i++;
                charcode = ((charcode & 0x3ff) << 10) | (str.charCodeAt(i) & 0x3ff)
                utf8.push(0xf0 | (charcode >> 18),
                    0x80 | ((charcode >> 12) & 0x3f),
                    0x80 | ((charcode >> 6) & 0x3f),
                    0x80 | (charcode & 0x3f));
            }
        }
        return utf8;
    }

    makeid(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    calcularFechas(mode) {
        var startDate = new Date();
        var finishDate = new Date();
        switch (mode) {
            case 'hoy': {
                startDate = new Date();
                finishDate = new Date();
                break;
            }
            case 'ayer': {
                startDate = new Date();
                startDate.setDate(startDate.getDate() - 1);
                finishDate = new Date();
                finishDate.setDate(finishDate.getDate() - 1);
                break;
            }
            case 'esta-semana': {
                startDate = moment().startOf('week').toDate();
                finishDate = moment().endOf('week').toDate();
                break;
            }
            case 'ultima-semana': {
                finishDate = moment().startOf('week').toDate();
                finishDate.setDate(finishDate.getDate() - 1);
                startDate = moment(finishDate).startOf('week').toDate();
                break;
            }
            case 'mes-actual': {
                startDate = moment().startOf('month').toDate();
                finishDate = moment().endOf('month').toDate();
                break;
            }
            case 'mes-pasado': {
                finishDate = moment().startOf('month').toDate();
                finishDate.setDate(finishDate.getDate() - 1);
                startDate = moment(finishDate).startOf('month').toDate();
                break;
            }
            case 'ano-actual': {
                startDate = moment().startOf('year').toDate();
                finishDate = moment().endOf('year').toDate();
                break;
            }
            case 'ano-pasado': {
                finishDate = moment().startOf('year').toDate();
                finishDate.setDate(finishDate.getDate() - 1);
                startDate = moment(finishDate).startOf('year').toDate();
                break;
            }
        }
        return { startDate: startDate, finishDate: finishDate };
    }
    trimString(texto) {
        if (!texto) { return null };
        return texto.trim();
    }
    eliminarDiacriticosEs(texto) {
        if (!texto) return null;
        return texto
            .normalize('NFD')
            .replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1")
            .normalize();
    }
    normalizarString(texto: string) {
        if (!texto) return null;
        texto = this.eliminarDiacriticosEs(texto);
        texto = texto.toLowerCase().trim();
        return texto;
    }
    limpiarColumnasResultadosExcel(data: any[], columnasUtilizadas: any[]) {
        try {
            //columnas
            var lowerColumnas = [];
            for (let col of columnasUtilizadas) {
                if (col.nombre) lowerColumnas.push(this.normalizarString(col.nombre.toString()))
                else lowerColumnas.push(this.normalizarString(col.toString()))
            }

            var errores = [];
            var resultados = [];
            data.forEach(element => {
                // Se construyen elementos normalizados
                var elementoNormalizado = {};
                for (const [key, value] of Object.entries(element)) {
                    if (key) {
                        // Se normaliza la propiedad
                        var keyString = key.toString();
                        var columnaFinal: string = this.normalizarString(keyString);
                        if (lowerColumnas.indexOf(columnaFinal.toLowerCase()) == -1) {
                            // La columna que viene no corresponde con una de las que se utilizarán
                            if (this.indexOf(errores, 'id', 'columna-' + key,) == -1) {
                                errores.push({ id: 'columna-' + key, mensaje: 'La columna ' + key + ' no corresponde a una columna esperada y será ignorada.' });
                            }
                        }
                        var finalValue = value;
                        if (finalValue && typeof finalValue == 'string') finalValue.trim();
                        elementoNormalizado[columnaFinal] = value;
                    }
                }
                resultados.push(elementoNormalizado);
            });
            return {
                errores: errores,
                resultados: resultados,
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    openModal(link: string) {
        document.getElementById('myModal').style.display = "block";
        (document.getElementById("img01") as HTMLImageElement).src = link;
    }
    closeModal() {
        document.getElementById('myModal').style.display = "none";
    }


    bytesToSize(bytes: number) {
        const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

        if (bytes === 0) {
            return "0 Byte";
        }

        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString());

        return Math.round(bytes / Math.pow(1024, i)) + " " + sizes[i];
    }

    fileToDataUri(field) {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                resolve(reader.result);
            });
            reader.readAsDataURL(field);
        });
    }

    getCanvasBlob(mycanvas, quality) {
        return new Promise(function (resolve, reject) {
            mycanvas.toBlob((blob) => {
                resolve(blob)
            }, "image/jpeg", quality)
        })
    }

    agregarCorrelativosADanios(danios: Danios, contratos: ContratoAhorrent[] = []) {

        const propiedades = Object.keys(danios) as (keyof Danios)[];

        // Recorrer las propiedades
        propiedades.forEach(propiedad => {

            const valor = danios[propiedad];

            if (Array.isArray(valor) && valor.length > 0) {
                // buscar dentro de los contactos, cada uno de los partes daniadas para agregarles el correlativo
                valor.forEach(parte => {
                    let contrato = contratos.find(contrato => contrato.id == parte.ContratoAhorrentId);
                    if (contrato) {
                        parte.ContratoAhorrentCorrelativo = contrato.correlativo;
                    }
                });
            }

        });

    }

    listarDanios(danios: Danios) {
        let partesDaniadas: ParteVehiculoDanio[] = [];
        // Obtener todas las propiedades de la clase
        const propiedades = Object.keys(danios) as (keyof Danios)[];

        // Recorrer las propiedades
        propiedades.forEach(propiedad => {

            // nombre de la propiedad
            var index = this.indexOf(this.apiURLs.inspeccionGrafica, 'id', propiedad);
            let nombrePropiedad = this.apiURLs.inspeccionGrafica[index].nombre;

            const valor = danios[propiedad];

            if (Array.isArray(valor) && valor.length > 0) {
                valor.forEach(parte => {
                    // copia del objeto
                    var copia = Object.assign({}, parte);
                    copia.nombreParte = nombrePropiedad;
                    partesDaniadas.push(copia);
                });
            }

        });

        return partesDaniadas;
    }

    redondearNumero2Decimales(num) {
        if(!num) return 0;
        return Math.round((num + Number.EPSILON) * 100) / 100;
    }

    generarExcel(data: any[], columnas: any[], nombreArchivo: string, colorHeader: string) {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Hoja 1');

            // Añadir headers
            const headers = [];
            columnas.forEach(columna => {
                headers.push(columna);
            });
            worksheet.addRow(headers);

            // Añadir datos
            data.forEach(dato => {
                const row = [];
                columnas.forEach(columna => {
                    row.push(dato);
                });
                worksheet.addRow(row);
            });

            // Estilos
            worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
                row.eachCell((cell, colNumber) => {
                    if (rowNumber == 1) {
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: colorHeader? colorHeader : 'FFFFFF' },
                            
                        };
                        cell.font = {
                            bold: true,
                            color: { argb: 'FFFFFF' },
                            name: 'Arial',
                            size: 12
                        };
                    }
                });
            });

            // Guardar archivo
            workbook.xlsx.writeBuffer().then((buffer) => {
                const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                FileSaver.saveAs(blob, nombreArchivo + '.xlsx');
            });

            this.crearNotificacion('Excel generado exitosamente', 'success', 'Archivo generado correctamente.');
        } catch (error) {
            this.crearNotificacion('Error al generar Excel', 'error', 'Ocurrió un error al generar el archivo Excel.');
            console.log(error);
        }
    }
}

@Component({
    selector: 'generic-dialog',
    templateUrl: 'generic-dialog/generic-dialog.html',
})
export class GenericDialog {
    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) { }
}