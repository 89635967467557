import { APIUrls } from '../api/apiUrls';
import { Empresa } from './empresa';
import { JSONConverters } from './JSONConverters';

export class ConfiguracionDigitalizacionTraspasos {
    constructor(
        public id: number,
        public tipoRecepcionUsado: string,
        public sucursalesVendedor: string,
        public sucursalesTraspasos: string,
        public notificacionesPreOrden: string,
        public notificacionesFacturacion: string,  
        public notificacionesDocumentacionCompleta: string,
        public notificacionesEmisionCheque: string,
        public notificacionesCita: string,
        public abogados: string,
        public prioridades: string,
        public horasAbogados: string,
        public restriccionHorariosAbogados: string,
        // Relaciones
        public EmpresaId: number,
    ){
        this.cargarArrays();
    }

    jsonConverts: JSONConverters = new JSONConverters();
    tipoRecepcionUsadoArray: any[];
    sucursalesVendedorArray: any[];
    sucursalesTraspasosArray: any[];
    notificacionesPreOrdenArray: any[];
    notificacionesFacturacionArray: any[];
    notificacionesDocumentacionCompletaArray: any[];
    notificacionesEmisionChequeArray: any[];
    notificacionesCitaArray: any[];
    abogadosArray: any[];
    prioridadesArray: any[];
    horasAbogadosArray: any[];
    restriccionHorariosAbogadosArray: any[];

    Empresa: Empresa;

    cargarArrays() {
        this.tipoRecepcionUsadoArray = this.tipoRecepcionUsado ? JSON.parse(this.tipoRecepcionUsado) : [];
        this.sucursalesVendedorArray = this.sucursalesVendedor ? JSON.parse(this.sucursalesVendedor) : [];
        this.sucursalesTraspasosArray = this.sucursalesTraspasos ? JSON.parse(this.sucursalesTraspasos) : [];
        this.notificacionesPreOrdenArray = this.notificacionesPreOrden ? JSON.parse(this.notificacionesPreOrden) : [];
        this.notificacionesFacturacionArray = this.notificacionesFacturacion ? JSON.parse(this.notificacionesFacturacion) : [];
        this.notificacionesDocumentacionCompletaArray = this.notificacionesDocumentacionCompleta ? JSON.parse(this.notificacionesDocumentacionCompleta) : [];
        this.notificacionesEmisionChequeArray = this.notificacionesEmisionCheque ? JSON.parse(this.notificacionesEmisionCheque) : [];
        this.notificacionesCitaArray = this.notificacionesCita ? JSON.parse(this.notificacionesCita) : [];
        this.abogadosArray = this.abogados ? JSON.parse(this.abogados) : [];
        this.prioridadesArray = this.prioridades ? JSON.parse(this.prioridades) : [];
        this.horasAbogadosArray = this.horasAbogados ? JSON.parse(this.horasAbogados) : [];
        this.restriccionHorariosAbogadosArray = this.restriccionHorariosAbogados ? JSON.parse(this.restriccionHorariosAbogados) : [];
    }
}