<form #formRegistroCliente="ngForm">
    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Nombres</mat-label>
                <input matInput name="nombresSolicitud" [(ngModel)]="registro.nombres" [disabled]="modoDetalle" 
                (ngModelChange)="change()" required>
            </mat-form-field>
        </div>
        <!-- apellidos -->
        <div class="col">
            <mat-form-field>
                <mat-label>Apellidos</mat-label>
                <input matInput name="apellidosSolicitud" [(ngModel)]="registro.apellidos" [disabled]="modoDetalle"
                (ngModelChange)="change()" required>
            </mat-form-field>
        </div>          
    </div>
    <div class="row">
        <!-- dpi -->
        <div class="col">
            <mat-form-field>
                <mat-label>{{(apiURLs.AMBIENTE == 'GT' ? 'DPI' : (apiURLs.AMBIENTE == 'SV' ? 'DUI' : 'DNI'))}}</mat-label>
                <input matInput name="dpiSolicitud" [(ngModel)]="registro.dpi" [disabled]="modoDetalle"
                (ngModelChange)="change()" required>
            </mat-form-field>
            <label class="text-danger" *ngIf="registro.dpi && !dpiValido">
                El DPI debe contener 13 dígitos.
            </label>
        </div>
        <!-- nit -->
        <div class="col">
            <mat-form-field>
                <mat-label>{{(apiURLs.AMBIENTE == 'GT' ? 'NIT' : (apiURLs.AMBIENTE == 'SV' ? 'NIT' : 'RTN'))}}</mat-label>
                <input matInput name="nitSolicitud" [(ngModel)]="registro.nit" [disabled]="modoDetalle"
                (ngModelChange)="change()" required>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <!-- email -->
        <div class="col">
            <mat-form-field>
                <mat-label>Email</mat-label>
                <input matInput name="emailSolicitud" [(ngModel)]="registro.email" [disabled]="modoDetalle"
                (ngModelChange)="change()" required>
            </mat-form-field>
            <label class="text-danger" *ngIf="registro.email && !emailValido">
                El email es inválido
            </label>
        </div>
        <!-- telefono -->
        <div class="col">
            <mat-form-field>
                <mat-label>Teléfono</mat-label>
                <input matInput name="telefono" [(ngModel)]="registro.telefono" [disabled]="modoDetalle"
                (ngModelChange)="change()" required>
            </mat-form-field>
            <label class="text-danger" *ngIf="registro.telefono && !telefonoValido">
                El teléfono debe contener 8 dígitos
            </label>
        </div>
    </div>
</form>