import { OrdenTallerKanboard } from "./OrdenTallerKanboard";
import { Usuario } from "./usuario";

export class CambioEstadoOrdenTallerKanboard {
    constructor(
        public id: number,
        public estadoAnterior: string,
        public estadoPosterior: string,
        public observaciones: string,

        // Relaciones
        public UsuarioId: number,
        public OrdenTallerKanboardId: number,
    ) { }

    Usuario: Usuario;
    OrdenTallerKanboard: OrdenTallerKanboard;

    estadoAnteriorFormat: string;
    estadoPosteriorFormat: string;

}