import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import iconClose from '@iconify/icons-ic/twotone-close';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { LibraryService } from 'src/app/library/library.service';
import { Cita, HorarioCita } from 'src/app/models/cita';
import { APIUrls } from 'src/app/api/apiUrls';
import { Concesionario } from 'src/app/models/concesionario';
import { Usuario } from 'src/app/models/usuario';
import { CitasService } from 'src/app/citas/citas.service';
import { UsuariosService } from 'src/app/usuarios/usuarios.service';
import { AvaluosService } from 'src/app/avaluos/avaluos.service';
import { LineaVehiculo } from 'src/app/models/lineaVehiculo';

@Component({
  selector: 'mat-form-cita',
  templateUrl: './mat-cita.component.html',
  styleUrls: ['./mat-cita.component.scss']
})
export class MatFormCitaComponent implements OnInit {

    @Input() registro: Cita;
    @Input() concesionarios: Concesionario[];
    @Input() openModal: Function;
    @Input() modoDetalle: boolean = false;
    @Input() tituloBoton: string = 'Reservar cita'
    @Input() cargandoExterior: boolean = false;
    @Output() guardar: EventEmitter<any> = new EventEmitter();


    @Output() selectionChange: EventEmitter<any> = new EventEmitter();
    iconClose = iconClose;
    texto: string;


    // Data:
    cargando: boolean = false;
    apiURLs: APIUrls = new APIUrls();
    agenteSeleccionado: Usuario;
    horarioSeleccionado: HorarioCita;
    mostrarResultadoGuardado: boolean = false;
    minDate: Date = new Date();
    agentes: Usuario[];
    lineas: LineaVehiculo[] = [];

    constructor(
        public libraryService: LibraryService,
        private changeDetector: ChangeDetectorRef,
        private service: LibraryService,
        private citaService: CitasService,
        private usuarioService: UsuariosService,
        public avaluosService: AvaluosService,
    ){}

    ngOnInit() { 
        this.minDate.setDate(this.minDate.getDate() + 1);
        if (!this.registro) throw new TypeError("The input 'registro' is required"); 
        this.iniciarDatos();
    }

    async iniciarDatos(){
        await this.obtenerDatosLineas();
    }

    select(){
        this.guardar.emit(this.registro)
        //this.guardar().emit();
    }

    actualizarFecha($event) {
        this.registro.fecha = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        this.registro.AgenteId = null;
        this.registro.horaInicio = null;
        this.registro.horaFin = null;
        this.agentes = null;
        this.horarioSeleccionado = null;
        this.obtenerHorariosAgentes();
    }
    
    actualizarConcesionario($event) {
        this.registro.AgenteId = null;
        this.registro.horaInicio = null;
        this.registro.horaFin = null;
        this.agentes = null;
        this.horarioSeleccionado = null;
        this.obtenerHorariosAgentes();
    }

    actualizarAgente($event) {
        if($event && $event.value) {
            let AgenteId = $event.value;
            let indexAgente = this.libraryService.indexOf(this.agentes, 'id', AgenteId);
            if(indexAgente != -1) {
                this.registro.horaInicio = null;
                this.registro.horaFin = null;
                this.horarioSeleccionado = null;
                this.agenteSeleccionado = this.agentes[indexAgente];
            }
            else { 
                this.registro.horaInicio = null;
                this.registro.horaFin = null;
                this.horarioSeleccionado = null;
                this.agenteSeleccionado = null;
            }
        }
        else {
            this.registro.horaInicio = null;
            this.registro.horaFin = null;
            this.horarioSeleccionado = null;
            this.agenteSeleccionado = null;
        }
    }

    actualizarHorarioSeleccionado() {
        if(this.horarioSeleccionado) {
            this.registro.horaInicio = this.horarioSeleccionado.horaInicio;
            this.registro.horaFin = this.horarioSeleccionado.horaFin;
        }
        else {
            this.registro.horaInicio = null;
            this.registro.horaFin = null;
        }
    }

    // Obtener concesionarios
    async obtenerHorariosAgentes() {
        this.cargando = true;
        if(this.registro.ConcesionarioId && this.registro.fecha) {
            var res = await this.citaService.obtenerHorariosDisponibles(this.registro.ConcesionarioId, this.registro.fecha);
            if(!res.error) {
                this.agentes = res.data.registros;
            }
            else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    // Imagen

    async seleccionarDocumentoResultado(event: any) {
        let fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            let urls = [];
            for(let x = 0; x < fileList.length; x++){
                let file: File = fileList[x];
                var rutaArchivo = file.name;
                var auxExtension = rutaArchivo.split(".");
                var extension = auxExtension[auxExtension.length - 1];
                var archivo = file;
                let url = await this.crearYSubirDocumento(archivo, rutaArchivo, extension);
                if(url){
                    urls.push(url);
                }
            }
            if(this.registro){
                this.registro.imagenesArray = urls;
            }
        }
        this.cargando = false;
    }

    activarSeleccionDocumentoResultado() {
        document.getElementById('file-input-archivo-multiple').click();
    }

    async crearYSubirDocumento(archivo, rutaArchivo, extension) {
        this.cargando = true;
        if (archivo && rutaArchivo && extension) {
            var res = await this.usuarioService.guardarArchivoEnServidorUsuarios(archivo, true);
            if (!res.error) {
                return res.data.url;
            } else {
                this.libraryService.crearNotificacion('Ha ocurrido un error al subir su documento al servidor.', 'danger');
                return null;
            }
        }
        this.cargando = false;
    }

    async obtenerDatosLineas() {
        const res = await this.avaluosService.ObtenerTodasLasLineasExistentes(false, true);
        if(!res.error) {
            this.lineas = res.data.registros;
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
    }

    formularioValido() {
        if(!this.registro) return false;
        if(!this.registro.ConcesionarioId) return false;
        if(!this.registro.AgenteId) return false;
        if(!this.registro.fecha) return false;
        if(!this.registro.horaInicio) return false;
        if(!this.registro.horaFin) return false;
        if(!this.registro.nombres) return false;
        if(!this.registro.apellidos) return false;
        if(!this.registro.correo) return false;
        if(!this.registro.telefono) return false;

        return true;
    }

}
