export class TransactionFEL {
    constructor(
        public id: number,
        public idFel: string,
        public error: boolean,
        public fecha: string,
        public descripcion: string,
        public alertasSat: string,
        public alertasInFile: string,
        public errores: string,
        public respuestaJson: string,
        public tipo: string,
        public randomNumber: number,
        public dataJSON: string,
        public idRelacionado: number,
        public EmpresaId: number,
    ) {
    }

    fechaDate: Date;
    fechaFormato: string;
}