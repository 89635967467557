import { Empresa } from './empresa';

export class ConfiguracionDigitalizacion {
    constructor(
        public id: number,
        public sedes: string,
        public asesores: string,
        public marcas: string,
        public modelos: string,
        public tiposSolicitudes: string,
        public coloresVehiculos: string,
        public versionesVehiculos: string,
        public correosNuevasSolicitudes: string,
        public kmRutaLarga: number,
        //Relaciones
        public EmpresaId: number,
    ){this.cargarArrays()}

    sedesArray: any[];
    asesoresArray: any[];
    marcasArray: any[];
    modelosArray:any[];
    tiposSolicitudesArray: any[];
    coloresVehiculosArray: any[];
    versionesVehiculosArray: any[];
    correosNuevasSolicitudesArray: any[];

    Empresa: Empresa;

    cargarArrays() {
        
        this.sedesArray = this.sedes? JSON.parse(this.sedes) : [];
        this.asesoresArray = this.asesores ? JSON.parse(this.asesores) : [];
        this.marcasArray = this.marcas ? JSON.parse(this.marcas) : [];
        this.modelosArray = this.modelos ? JSON.parse(this.modelos) : [];
        this.tiposSolicitudesArray = this.tiposSolicitudes ? JSON.parse(this.tiposSolicitudes) : [];
        this.coloresVehiculosArray = this.coloresVehiculos ? JSON.parse(this.coloresVehiculos) : [];
        this.versionesVehiculosArray = this.versionesVehiculos ? JSON.parse(this.versionesVehiculos) : [];
        this.correosNuevasSolicitudesArray = this.correosNuevasSolicitudes ? JSON.parse(this.correosNuevasSolicitudes) : [];
    }

}