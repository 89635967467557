import { ContratoAhorrent } from './ContratoAhorrent';
import { Unidad } from './unidad';
import { Usuario } from './usuario';

export class PendienteUnidad {
    constructor(
        public id: number,
        public descripcion: string,
        public fechaCreacion: string,
        public prioridad: string,
        public estado: string,
        public mecanico: string,
        //Relaciones
        public UsuarioId: number,
        public UnidadId: number,
    ){}
    
    Usuario: Usuario;
    Unidad: Unidad;
    fechaCreacionDate: Date
    fechaCreacionFormato: string;
    fechaProximaReservaFormato: string;
    prioridadFormato: string;
    estadoFormato: string;
    estadoUnidadFormato: string;
    nombreUsuario: string;
    proximoContrato: ContratoAhorrent;
}