<div id="dialogOverflow">
<h2 mat-dialog-title class="mb-4 bold" style="font-weight: bold;">{{data.placeholder.replace("*","")}}</h2>
<mat-dialog-content>
    <vex-page-layout-content vexContainer>
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="container">
                        <!-- Tabla -->
                        <app-busqueda-tabla
                            *ngIf="mostrarTabla"
                            #tablaBuscador
                            [campos]="data.fieldSearch"
                            [columnas]="data.columnas"
                            [url]="data.url"
                            [parser]="data.parser"
                            [parserService]="jsonConverters"
                            [paramsEspeciales]="calcularParamsEspeciales()"
                            [botonNuevo]="false"
                            [ignorarRuta]="true"
                            [soloMostrarIconoBusqueda]="true"
                            (clickAction)="click($event)"
                            [allObjeto]="true"
                            [forzarResponsive]="data.forzarResponsive"
                            [esconderBusqueda]="data.esconderBusqueda"
                            [campoDefault]="data.campoDefault"
                            [valorDefault]="data.valorDefault"
                        ></app-busqueda-tabla>
                    </div>
                </div>
            </div>
        </div>
    </vex-page-layout-content>
</mat-dialog-content>
</div>
<!-- * * * Templates * * * -->
<ng-template #cellNormal let-value="value" ngx-datatable-cell-template>{{value}}</ng-template>