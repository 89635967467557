export class ContenidoMatriz {
    constructor(
        // Factores de producto
        public tiposNegociaciones:          TipoNegociacion     [] = [],
        public marcas:                      Marca               [] = [],
        public rangosModelos:               RangoModelo         [] = [],
        public lineas:                      Linea               [] = [],
        public propositoDestino:            PropositoDestino    [] = [],

        // Factores de cliente
        public rangosEdadIndividual:        RangoEdad           [] = [],        
        public clienteIndividualPEP:        ClientePEP          [] = [],        
        public fuentesIngreso:              FuenteIngreso       [] = [],
        public listaNegraIndividual:        ListaNegra          [] = [],        
        public tercerasPersonasIndividual:  TercerasPersonas    [] = [],            
        public proveedorEstado:             ProveedorEstado     [] = [],
        public contratistaEstado:           ContratistaEstado   [] = [],    
        public partidosPoliticosIndividual: PartidosPoliticos   [] = [],            
        public nacionalidadIndividual_1:    Nacionalidad        [] = [],            
        public nacionalidadIndividual_2:    Nacionalidad        [] = [],            
        public nacionalidadJuridico:        Nacionalidad        [] = [],        
        public listaNegraJuridico:          ListaNegra          [] = [],    
        public anioConstitucion:            AnioConstitucion    [] = [],    
        public tipoDeJuridica:              TipoDeJuridica      [] = [],
        public empresasRelacionadas:        EmpresaRelacionada  [] = [],
        public tercerasPersonasJuridico:    TercerasPersonas    [] = [],       
        public tipoOrganizacion:            TipoOrganizacion    [] = [],    
        public partidosPoliticosJuridico:   PartidosPoliticos   [] = [],            
        public clienteJuridicoPEP:          ClientePEP          [] = [],    
        public rangosEdadJuridico:          RangoEdad           [] = [], 

        // Factores de canal   
        public agencias:                    Agencia             [] = [], 

        // Factores de ubicación   
        public departamentos:               Departamento        [] = [], 

        // Factores de transacción
        public tiposVenta:                  TipoVenta           [] = [],                
        public ubicacionesPago:             UbicacionPago       [] = [],      
        public formasMedioPago:             FormaMedioPago      [] = [],      

        // Factores estructurales
        public estructura:                  Estructura          [] = [],
        public ventasAnuales:               RangoVentasAnuales  [] = [],
        public numeroEmpleados:             RangoNumeroEmpleados[] = [],

        // Factores de mitigación:
        public factoresMitigacion:          FactorMitigacion    [] = [],

        // Formato de declaración
        public htmlDeclaracion:             string                  = null,
        public htmlDeclaracionIndividual:     string                  = null,
    ){}

}

export class Agencia {
    constructor(
        public nombre:              string = null,
        public riesgo:              number = null,
        public idDepartamento:      string = null,
        public id:                  string = null,
        public codigoSucursal:      string = null,
    ){}
}

export class RangoVentasAnuales {
    constructor(
        public min:     number = null,
        public max:     number = null,
        public riesgo:  number = null,
        public nombre:  string = null,
    ){}
}

export class RangoNumeroEmpleados {
    constructor(
        public min:     number = null,
        public max:     number = null,
        public riesgo:  number = null,
        public nombre:  string = null,
    ){}
}

export class Estructura {
    constructor(
        public nombre:  string = null,
        public id:      string = null,
        public valor:  number = null,
    ){}
}

export class TipoVenta {
    constructor(
        public nombre:  string = null,
        public id:      string = null,
        public riesgo:  number = null,
    ){}
}

export class UbicacionPago {
    constructor(
        public nombre:          string      = null,
        public id:              string      = null,
        public riesgo:          number      = null,
    ){}
}

export class FormaMedioPago {
    constructor(
        public nombre:  string = null,
        public id:      string = null,
        public riesgo:  number = null,
    ){}
}


export class Departamento {
    constructor(
        public nombre:  string = null,
        public id:      string = null,
        public riesgo:  number = null,
    ){}
}

export class TipoNegociacion {
    constructor(
        public nombre:  string = null,
        public id:      string = null,
        public riesgo:  number = null,
    ){}
}

export class Marca {
    constructor(
        public nombre:  string = null,
        public id:      string = null,
        public riesgo:  number = null,
    ){}
}

export class RangoModelo {
    constructor(
        public antiguedad:  number = null,
        public id:          string = null,
        public riesgo:      number = null,
    ){}
}

export class Linea {
    constructor(
        public nombre:  string = null,
        public id:      string = null,
        public riesgo:  number = null,
    ){}
}

export class PropositoDestino {
    constructor(
        public nombre:  string = null,
        public id:      string = null,
        public riesgo:  number = null,
    ){}
}

export class RangoEdad {
    constructor(
        public min:     number = null,
        public max:     number = null,
        public riesgo:  number = null,
        public nombre:  string = null,
    ){}
}

export class ClientePEP {
    constructor(
        public nombre:  string = null,
        public riesgo:  number = null,
    ){}
}

export class FuenteIngreso {
    constructor(
        public id:      string = null,
        public riesgo:  number = null,
        public nombre:  string = null,
    ){}
}

export class ListaNegra {
    constructor(
        public riesgo:  number = null,
        public nombre:  string = null,
    ){}
}

export class TercerasPersonas {
    constructor(
        public riesgo:  number = null,
        public nombre:  string = null,
    ){}
}

export class ProveedorEstado {
    constructor(
        public riesgo:  number = null,
        public nombre:  string = null,
    ){}
}

export class ContratistaEstado {
    constructor(
        public riesgo:  number = null,
        public nombre:  string = null,
    ){}
}

export class PartidosPoliticos {
    constructor(
        public riesgo:  number = null,
        public nombre:  string = null,
    ){}
}

export class AnioConstitucion {
    constructor(
        public antiguedad:  number = null,
        public id:          string = null,
        public riesgo:      number = null,
    ){}
}

export class TipoDeJuridica {
    constructor(
        public id:          string = null,
        public riesgo:      number = null,
        public nombre:      string = null,
    ){}
}

export class EmpresaRelacionada {
    constructor(
        public riesgo:  number = null,
        public nombre:  string = null,
    ){}
}

export class TipoOrganizacion {
    constructor(
        public id:          string = null,
        public riesgo:      number = null,
        public nombre:      string = null,
    ){}
}

export class Nacionalidad {
    constructor(
        public id:          string = null,
        public riesgo:      number = null,
        public nombre:      string = null,
    ){}
}

export class FactorMitigacion {
    constructor(
        public nombre:          string = null,
        public efectividad:     number = null,
    ){}
}