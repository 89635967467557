<div id="dialogOverflow">
    <h4 mat-dialog-title>{{titulo}}</h4>
    <form #formRegistro="ngForm" *ngIf="registro">
        <mat-dialog-content>
            <div class="container">
                <div class="row">
                    <div class="col">
                        <table class="tablaEstilos" *ngIf="registro.opcionesArray.length > 0">
                            <thead>
                                <tr> 
                                    <th>No.</th> 
                                    <th>Opción</th> 
                                    <th></th> 
                                </tr>
                            </thead>
                            <tbody>
                                <!-- Destacar última fila -->
                                <tr *ngFor="let element of registro.opcionesArray; let i = index;">
                                    <th class="col-xs"> {{i+1}} </th>
                                    <!-- Pregunta -->
                                    <td>
                                            <input
                                                class="tableInput" 
                                                [(ngModel)]="element.opcion"
                                                [ngModelOptions]="{standalone: true}"
                                                [disabled]='modoDetalle'
                                                required
                                            >
                                    </td>
                                    <!-- Botones -->
                                    <td *ngIf="!modoDetalle" class="col-xs" style="text-align: center;">
                                        <mat-icon class="iconoBoton" style="cursor:pointer; color: red;" (click)="eliminarFila(i)" *ngIf="!modoDetalle">delete</mat-icon>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        
                        <!-- * Botones de pasos * -->
                        <div class="row" *ngIf="!modoDetalle">
                            <div class="col-md-12" style="text-align: left;">
                                <button color="primary" class="margenBoton" mat-raised-button type="button" (click)="agregarFila()" [disabled]="cargando || modoDetalle">
                                    <span>Agregar opción</span>
                                    <mat-icon class="iconoBoton">add</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions>
            <!-- * * * Botones * * * -->
            <ng-container>
                <div class="row">
                    <div class="col-md-12" style="text-align: right;">
                        <!-- Guardar -->
                        <!-- <button (click)="guardar()" color="primary" mat-raised-button type="button"
                            [disabled]="cargando || !formRegistro.valid" class="margenBoton" 
                            *ngIf="modoEdicion && (auth.tienePermisoPara(apiURLs.permisos.EDICION_ENCUESTA))">
                            Guardar
                            <mat-icon class="iconoBoton">save</mat-icon>
                        </button> -->
                        <button mat-stroked-button style="margin: 10px;" (click)="cerrar()"
                            class="margenBoton">Cerrar</button>
                    </div>
                </div>
            </ng-container>
        </mat-dialog-actions>
    </form>
    <mat-progress-bar mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
</div>