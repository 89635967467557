import { Avaluo } from "./avaluo";
import { Usuario } from "./usuario";

export class CambioEstadoAvaluo {
    constructor(
        public id: number,
        public fecha: string,
        public estadoAnterior: string,
        public estadoPosterior: string,
        public descripcion: string,

        // Relaciones
        public CasoId: number,
        public UsuarioId: number,
    ) { }

    usuario: Usuario;
    avaluo: Avaluo;

    //Fechas
    fechaFormato: string;
    fechaDate: Date;

    estadoAnteriorFormat: string;
    estadoPosteriorFormat: string;
}