import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { APIUrls } from 'src/app/api/apiUrls';
import { AuthService } from 'src/app/auth/auth.service';
import { JSONConverters } from 'src/app/models/JSONConverters';
import { BusquedaTablaComponent } from '../busqueda-tabla/busqueda-tabla.component';
import { GenericDialogConfirmacion } from '../generic-dialog-confirmacion/generic-dialog-confirmacion.component';
import { LibraryService } from '../library.service';
import icEdit from '@iconify/icons-ic/twotone-settings';


export interface DialogDataSegurencias {
    columnas: any[],
    fieldSearch:any[],
    url: string,
    placeholder: any,
    parser: any,
    paramsEspeciales: string,
    forzarResponsive: boolean,
    templatesIndexes: any,
    esconderBusqueda: boolean,
    campoDefault: string,
    valorDefault: string,
}

@Component({
  selector: 'vex-dialog-sugerencias',
  templateUrl: './dialog-sugerencias.component.html',
  styleUrls: ['./dialog-sugerencias.component.scss']
})
export class DialogSugerenciasComponent implements OnInit {

    mostrarTabla: boolean = true;

    @ViewChild('cellNormal', { static: true }) cellNormal: TemplateRef<any>;

    apiURLs = new APIUrls();
    jsonConverters = new JSONConverters();
    iconEdit = icEdit;

    constructor(
        private route: ActivatedRoute,
        private titleService: Title,
        public dialogRef: MatDialogRef<GenericDialogConfirmacion>,
        public auth: AuthService,
        @Inject(MAT_DIALOG_DATA) public data: DialogDataSegurencias,
    ) { }

    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.inicializarDatos(params);
        });

        var dialog = document.getElementById("dialogOverflow");
        var dialogContainer = null;
        if (dialog) dialogContainer = dialog.parentElement;
        if (dialogContainer) dialogContainer = dialogContainer.parentElement;
        if (dialogContainer) dialogContainer = dialogContainer.parentElement;
        if (dialogContainer) dialogContainer.className += ' dialogOverflow';
    }

    async inicializarDatos(params: Params){
        await this.agregarFiltros();
        this.agregarTemplates();
        this.mostrarTabla = true;
    }


    agregarTemplates() {
        this.data.columnas.forEach(columna => {
            columna.cellTemplate = this.cellNormal;
        });
        if(this.data.templatesIndexes) {
            for (const el of this.data.templatesIndexes) {
                this.data.columnas[el.index].cellTemplate = el.template;
            }
        }
    }

    async agregarFiltros() {
        
    }

    calcularParamsEspeciales(): string {
        var paramsEspeciales = '';
        paramsEspeciales += (this.data.paramsEspeciales ? this.data.paramsEspeciales : '');
        return paramsEspeciales;
    }

    click($event){
        this.dialogRef.close($event);
    }

}
