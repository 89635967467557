<!-- * * * Buscador * * * -->
<div *ngIf="campoActual && !esconderBusqueda" class="row align-items-center" style="padding-bottom: 0px;">
    <!-- Campos de búsqueda -->
    <div [ngClass]="{'col-md-4': filtros && filtros.length > 0, 'col-md-6': !filtros || filtros.length == 0}">
        <!-- Texto -->
        <ng-container *ngIf="campoActual.tipo === 'texto'">
            <mat-form-field class="anchoCompleto">
                <mat-label>Búsqueda</mat-label>
                <input matInput placeholder="Buscar..." [(ngModel)]="busqueda" name="busqueda"
                    (keyup.enter)="cambioPagina(1)">
            </mat-form-field>
        </ng-container>
        <!-- Rango de cantidades -->
        <ng-container *ngIf="campoActual.tipo === 'rango-cantidades'">
            <div class="row">
                <!-- Mínimo -->
                <div class="col-md-6">
                    <mat-form-field class="anchoCompleto">
                        <input matInput placeholder="Mínimo" [(ngModel)]="min" name="min" type="number"
                            (keyup.enter)="cambioPagina(1)">
                    </mat-form-field>
                </div>
                <!-- Máximo -->
                <div class="col-md-6">
                    <mat-form-field class="anchoCompleto">
                        <input matInput placeholder="Máximo" [(ngModel)]="max" name="max" type="number"
                            (keyup.enter)="cambioPagina(1)">
                    </mat-form-field>
                </div>
            </div>
        </ng-container>
        <!-- Rango de fechas -->
        <ng-container *ngIf="campoActual.tipo === 'rango-fechas'">
            <div class="row">
                <!-- Inicio -->
                <div class="col-md-6">
                    <mat-form-field class="anchoCompleto">
                        <mat-label>Del</mat-label>
                        <input matInput [matDatepicker]="pickerInicio" placeholder="Del"
                            (dateChange)="actualizarFechaInicio($event)">
                        <mat-datepicker-toggle matSuffix [for]="pickerInicio"></mat-datepicker-toggle>
                        <mat-datepicker #pickerInicio disabled="false"></mat-datepicker>
                    </mat-form-field>
                </div>
                <!-- Fin -->
                <div class="col-md-6">
                    <mat-form-field class="anchoCompleto">
                        <mat-label>Al</mat-label>
                        <input matInput [matDatepicker]="pickerFin" placeholder="Al"
                            (dateChange)="actualizarFechaFin($event)">
                        <mat-datepicker-toggle matSuffix [for]="pickerFin"></mat-datepicker-toggle>
                        <mat-datepicker #pickerFin disabled="false"></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </ng-container>
        <!-- Categoría -->
        <ng-container *ngIf="campoActual.tipo === 'categoria'">
            <!-- Con búsqueda -->
            <mat-select-search *ngIf="campoActual.categorias" [placeholder]="campoActual.nombre"
                [searchPlaceholder]="'Buscar'" [idProp]="campoActual.categoriaId"
                [titleProp]="campoActual.categoriaValue" [records]="campoActual.categorias" [id]="categoriaId"
                (idChange)="changing($event)"></mat-select-search>
        </ng-container>
    </div>
    <!-- Seleccionar campo de búsqueda -->
    <div [ngClass]="{'col-md-2': botonNuevo, 'col-md-4': !botonNuevo}">
        <mat-form-field class="anchoCompleto" *ngIf="campos">
            <mat-label>Buscar en</mat-label>
            <mat-select placeholder="Tipo de búsqueda" [(ngModel)]="campoActual"
                (ngModelChange)="inicializarCampoActual()" name="campoActual">
                <mat-option *ngFor="let campo of campos" [value]="campo">
                    {{ campo.nombre }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <!-- Filtros -->
    <div class="col-md-2" *ngIf="filtros && filtros.length > 0">
        <mat-form-field>
            <mat-label>Filtros</mat-label>
            <mat-select [(ngModel)]="camposFiltros" name="filtrosTabla"
                (selectionChange)="actualizarCamposFiltros($event)" multiple>
                <mat-option *ngFor="let filtro of filtros" [value]="filtro.campo">{{filtro.nombre}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <!-- Botones -->
    <div class="col-md-2" style="margin-bottom: 20px;">
        <!-- Buscar -->
        <button mat-raised-button color="primary" (click)="cambioPagina(1)" style="width: 100%;">
            <mat-icon class="iconoBoton">search</mat-icon>
            <span *ngIf="!soloMostrarIconoBusqueda">Buscar</span>
        </button>
    </div>
    <div class="col-md-2" style="margin-bottom: 20px;" *ngIf="botonNuevo && !nuevaAccion">
        <!-- Nuevo -->
        <button mat-raised-button color="accent" routerLink="{{rutaNuevo}}" style="width: 100%;">
            <mat-icon class="iconoBoton">add</mat-icon>
            <span>Nuevo</span>
        </button>
    </div>
    <!-- Boton nuevo con acción -->
    <div class="col-md-2" style="margin-bottom: 20px;" *ngIf="botonNuevo && nuevaAccion">
        <!-- Nuevo -->
        <button mat-raised-button color="accent" style="width: 100%;" (click)="nuevoAccion()">
            <mat-icon class="iconoBoton">add</mat-icon>
            <span>Nuevo</span>
        </button>
    </div>
</div>
<!-- * * * Filtros * * * -->
<div *ngIf="filtros && filtros.length > 0" class="row align-items-center">
    <ng-container *ngFor="let filtro of filtros">
        <div class="col" *ngIf="filtro.mostrar">
            <!-- Número -->
            <ng-container *ngIf="filtro.tipo == 'numero'">
                <mat-form-field>
                    <mat-label>{{filtro.nombre}}</mat-label>
                    <input matInput [(ngModel)]="filtro.valor" type="number"
                        (ngModelChange)="agregarFiltroTexto(filtro)" (keyup.enter)="cambioPagina(1)">
                </mat-form-field>
            </ng-container>
            <!-- Texto -->
            <ng-container *ngIf="filtro.tipo == 'texto'">
                <mat-form-field>
                    <mat-label>{{filtro.nombre}}</mat-label>
                    <input matInput [(ngModel)]="filtro.valor" (ngModelChange)="agregarFiltroTexto(filtro)" (keyup.enter)="cambioPagina(1)">
                </mat-form-field>
            </ng-container>
            <!-- Autocomplete único -->
            <ng-container *ngIf="filtro.tipo == 'autocomplete-unico'">
                <mat-select-autocomplete [placeholder]="filtro.nombre" [searchPlaceholder]="'Buscar'"
                    [idProp]="filtro.idProp" [titleProp]="filtro.titleProp" [fieldSearch]="filtro.fieldSearch"
                    [url]="filtro.urlAutocomplete" [parser]="filtro.parser" [parserService]="filtro.parserService"
                    [defaultRecord]="null" [multiSelect]="false" [seleccionados]="filtro.seleccionados"
                    (selectionChange)="agregarFiltroAutocompleteUnico(filtro, $event)"></mat-select-autocomplete>
            </ng-container>
            <!-- Select único -->
            <ng-container *ngIf="filtro.tipo == 'select-unico'">
                <mat-form-field>
                    <mat-label>{{filtro.nombre}}</mat-label>
                    <mat-select [(ngModel)]="filtro.valor" (selectionChange)="agregarFiltroSelectUnico(filtro, $event)">
                        <mat-option *ngFor="let categoria of filtro.categorias" [value]="filtro.campoId ? categoria[filtro.campoId] : categoria.id">
                            {{filtro.campoNombre ? categoria[filtro.campoNombre] : categoria.nombre}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
            <!-- Select múltiple -->
            <ng-container *ngIf="filtro.tipo == 'select-multiple'">
                <mat-form-field>
                    <mat-label>{{filtro.nombre}}</mat-label>
                    <mat-select [(ngModel)]="filtro.valor"
                        (selectionChange)="agregarFiltroSelectMultiple(filtro, $event)" multiple>
                        <mat-option *ngFor="let categoria of filtro.categorias" [value]="categoria.id">
                            {{categoria.nombre}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
            <!-- Fecha -->
            <ng-container *ngIf="filtro.tipo == 'fecha'">
                <mat-form-field>
                    <mat-label>{{filtro.nombre}}</mat-label>
                    <input matInput [matDatepicker]="pickerFiltro" placeholder="..."
                        [(ngModel)]="filtro.valor"
                        (dateChange)="agregarFiltroFecha(filtro)">
                    <mat-datepicker-toggle matSuffix [for]="pickerFiltro"></mat-datepicker-toggle>
                    <mat-datepicker #pickerFiltro disabled="false"></mat-datepicker>
                </mat-form-field>
            </ng-container>
            <ng-container *ngIf="filtro.tipo == 'rango-fechas'">
                <div class="row">
                    <div class="col-6">
                        <mat-form-field>
                            <mat-label>Del</mat-label>
                            <input matInput [matDatepicker]="pickerFiltroInicio" placeholder="..."
                                (dateChange)="agregarFiltroFechaInicio($event, filtro)">
                            <mat-datepicker-toggle matSuffix [for]="pickerFiltroInicio"></mat-datepicker-toggle>
                            <mat-datepicker #pickerFiltroInicio disabled="false"></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field>
                            <mat-label>Al</mat-label>
                            <input matInput [matDatepicker]="pickerFiltroFin" placeholder="..."
                                (dateChange)="agregarFiltroFechaFin($event, filtro)">
                            <mat-datepicker-toggle matSuffix [for]="pickerFiltroFin"></mat-datepicker-toggle>
                            <mat-datepicker #pickerFiltroFin disabled="false"></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>
<!-- Autocomplete multiple -->
<ng-container *ngIf="filtros && filtros.length > 0">
    <ng-container *ngFor="let filtro of filtros">
        <div class="row align-items-center" *ngIf="filtro.mostrar && filtro.tipo == 'autocomplete-multiple'">
            <div class="col">
                <ng-container>
                    <mat-select-autocomplete [placeholder]="filtro.nombre" [searchPlaceholder]="'Buscar'"
                        [idProp]="filtro.idProp" [titleProp]="filtro.titleProp" [fieldSearch]="filtro.fieldSearch"
                        [url]="filtro.urlAutocomplete" [parser]="filtro.parser" [parserService]="filtro.parserService"
                        [defaultRecord]="null" [multiSelect]="true" [seleccionados]="filtro.seleccionados"
                        (selectionChange)="agregarFiltroAutocompleteMultiple(filtro, $event)"></mat-select-autocomplete>
                </ng-container>
            </div>
        </div>
    </ng-container>
</ng-container>
<!-- * * * Acciones * * * -->
<div class="row align-items-center" *ngIf="acciones && acciones.length > 0 && selected?.length > 0">
    <!-- Seleccionar campo de búsqueda -->
    <div class="col-md-9">
        <mat-form-field class="anchoCompleto" *ngIf="acciones">
            <mat-label>Acciones</mat-label>
            <mat-select placeholder="Acciones" [(ngModel)]="accionActual" name="accionActual">
                <mat-option *ngFor="let accion of acciones" [value]="accion">
                    {{ accion.nombre }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md-3" style="margin-bottom: 20px;">
        <!-- Nuevo -->
        <button mat-raised-button color="primary" (click)="enviarAccion()" style="width: 100%;">
            <mat-icon class="iconoBoton">save</mat-icon>
            <span>Aplicar acción</span>
        </button>
    </div>
</div>
<!-- * * * Tabla de registros * * * -->
<div class="row" *ngIf="!(esconderSiVacio && total == 0)">
    <div class="col-md-12">
        <hr *ngIf="campoActual" style="margin-bottom: 0px;">
        <mat-progress-bar *ngIf="cargando" mode="indeterminate" color="primary"></mat-progress-bar>
        <ngx-datatable *ngIf="!(esconderSiVacio && cargando) && !usarTemplate" class="material striped"
            [columns]="columnas" [rows]="datos" [messages]="{ emptyMessage: 'No se encontraron registros' }"
            [columnMode]="modoResponsive() ? 'standard' : 'force'" [scrollbarH]="modoResponsive()" [headerHeight]="50"
            [rowHeight]="'auto'" [count]="total" [externalSorting]="true" (sort)="onSort($event)"
            (activate)="onActivate($event)" [rowClass]="getRowClass.bind(this)" [selected]="selected"
            [selectionType]="SelectionType.checkbox" (select)="onSelect($event)">
        </ngx-datatable>
        <!-- Templates en caso no use tabla -->
        <div class="row">
            <div class="col" *ngIf="(!datos || datos.length == 0) && !cargando && usarTemplate">
                <h4 style="font-style: italic;">No se encontraron registros</h4>
            </div>
            <div class="col" *ngFor="let dato of datos">
                <ng-container *ngTemplateOutlet="templateRecord; context: { value: dato }"></ng-container>
            </div>
        </div>
        <!-- Footer control de páginas -->
        <div class="row" *ngIf="!setPaginator"></div>
        <mat-paginator *ngIf="setPaginator" [length]="total" [pageSize]="limite" [pageIndex]="paginaActual - 1"
            [pageSizeOptions]="tamanioPagina"
            [hidePageSize]="false" [showFirstLastButtons]="true" (page)="cambioPagina($event)">
        </mat-paginator>
    </div>
</div>