import { Injectable } from '@angular/core';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { JSONConverters } from '../models/JSONConverters';
import { Venta } from '../models/venta';
import { FormularioFEIC } from '../models/formularioFEIC';
import * as FileSaver from 'file-saver';

@Injectable()
export class VentasService { 
    apiUrls: APIUrls = new  APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
    ) { }

    // * * * Ventas * * * 
    async obtenerVentaPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.ventasURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.ventaDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearVenta(registro: Venta) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.ventasURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Venta registrada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarVenta(registro: Venta) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.ventasURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Venta actualizada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarVenta(VentaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.ventasURL + '/id/' + VentaId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Venta eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * Formulario FEIC * * * 
    async obtenerFormularioFEICId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.formulariosFEICURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.formularioFEICDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async descargarJSONFormularioFEICId(id: number, nombreFinal: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.formulariosFEICURL + '/json/' + id + '?nombre=' + nombreFinal;
            var res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var fileName = nombreFinal + '.zip';
            var mediaType = 'octet/stream';
            var blob = new Blob([ res ], { type: mediaType });
            FileSaver.saveAs(blob, fileName);

            return { error: null, data: { mensaje: 'Archivo descargado con éxito.' } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // PDF Formulario jurídico
    async descargarPDFJuridico(FormularioId: number, nombre: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.formulariosFEICURL + '/pdfJuridico?FormularioId=' + FormularioId;
            var res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var fileName = nombre + '.pdf';
            var mediaType = 'application/pdf';
            var blob = new Blob([res], {
                type: mediaType
            });
            FileSaver.saveAs(blob, fileName);

            return {
                error: null,
                data: {
                    mensaje: 'Formulario Jurídico descargado con éxito.'
                }
            };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // PDF Reporte riesgo
    async descargarPDFReporteRiesgo(FormularioId: number, nombre: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.formulariosFEICURL + '/pdfReporteRiesgo?FormularioId=' + FormularioId;
            var res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var fileName = nombre + '.pdf';
            var mediaType = 'application/pdf';
            var blob = new Blob([res], {
                type: mediaType
            });
            FileSaver.saveAs(blob, fileName);

            return {
                error: null,
                data: {
                    mensaje: 'Reporte descargado con éxito.'
                }
            };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async obtenerHTMLStringPDFJuridico(FormularioId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.formulariosFEICURL + '/htmlStringJuridico';
            var data = {
                FormularioId: FormularioId,
            };
            var json = await this.connection.postRequest(url, data);
            var error = json.error;
            var htmlString = json.htmlString;
            var nombre = json.nombre;
            var resPlan = json.plan;
            return {
                error: error,
                htmlString: htmlString,
                nombre: nombre,
            };
        } catch (error) {
            return null;
        }
    }

    // Crear
    public async crearFormularioFEIC(registro: FormularioFEIC) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.formulariosFEICURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Formulario FEIC registrado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarFormularioFEIC(registro: FormularioFEIC) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.formulariosFEICURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Formulario FEIC actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarFormularioFEIC(FormularioFEICId: number, VentaId: number, motivo: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.formulariosFEICURL + `/id/${FormularioFEICId}?VentaId=${VentaId}&motivo=${motivo}`;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Formulario FEIC eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async cambiarEstadoDeFormularioFEIC(registro: FormularioFEIC) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.formulariosFEICURL + '/cambiarEstado';
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Formulario FEIC ' + (!registro.cerrado ? 'cerrado' : 'abierto' ) + ' con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async obtenerTodosClientesRiesgo(
        primerApellido: string,
        segundoApellido: string,
        apellidoCasada: string,
        primerNombre: string,
        segundoNombre: string,
        otrosNombres: string,
        empresa: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.listasNegrasURL + '/obtenerRiesgo';
            let data = {
                primerApellido: primerApellido,
                segundoApellido: segundoApellido,
                apellidoCasada: apellidoCasada,
                primerNombre: primerNombre,
                segundoNombre: segundoNombre,
                otrosNombres: otrosNombres,
                empresa: empresa,
            }
            var json = await this.connection.postRequest(url, data);

            let nDatos = 0;
            if(primerApellido   && primerApellido.length > 2) nDatos++;
            if(segundoApellido  && segundoApellido.length > 2) nDatos++;
            if(apellidoCasada   && apellidoCasada.length > 2) nDatos++;
            if(primerNombre     && primerNombre.length > 2) nDatos++;
            if(segundoNombre    && segundoNombre.length > 2) nDatos++;
            if(otrosNombres     && otrosNombres.length > 2) nDatos++;
            
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                var element = json[i];
                var registro = this.jsonConverters.listaNegraDeJSON(element);
                if(element.nivel != undefined) registro.porcentaje = nDatos > 0 ? (element.nivel / nDatos ) * 100 : 0;
                registros.push(registro);
            }

            return {
                error: null,
                data: {
                    registros: registros
                }
            };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }
}