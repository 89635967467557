import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

export interface DialogData {
    titulo: string,
    pdfSrc: any;
}

@Component({
  selector: 'vex-dialog-preview-download-document',
  templateUrl: './dialog-preview-download-document.component.html',
  styleUrls: ['./dialog-preview-download-document.component.scss']
})
export class DialogPreviewDownloadDocumentComponent implements OnInit {

    archivoCargado:boolean = false;
    cargando: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<DialogPreviewDownloadDocumentComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private router: Router,
        public dialog: MatDialog,
    ) { }

    ngOnInit(): void {
        // Arreglar estilo
        var dialog = document.getElementById("dialogOverflow");
        var dialogContainer = null;
        if(dialog) dialogContainer = dialog.parentElement;
        if(dialogContainer) dialogContainer = dialogContainer.parentElement;
        if(dialogContainer) dialogContainer = dialogContainer.parentElement;
        if(dialogContainer) dialogContainer.className += ' dialogOverflow';
    }

    cerrar() {
        this.dialogRef.close(null);
    }

    //descargar() {
    //    if (this.data.urlDocumento) {
    //        window.open(this.data.urlDocumento, "_blank");
    //    }
    //}

}
