import { Usuario } from "./usuario";
import { Unidad } from "./unidad";
import { ContratoAhorrent } from "./ContratoAhorrent";

export class DanioAhorrent {
    constructor(
        public id: number,
        public tipo: string,
        public parteVehiculo: string,
        public fecha: string,
        public tipoDanio: string,
        public descripcion: string,
        public imagenes: string,

        // Relaciones
        public UnidadId: number,
        public ContratoAhorrentId: number,
        public UsuarioId: number,
    ) { 
        this.imagenes = this.imagenes ? JSON.parse(this.imagenes) : [];
    }

    Usuario: Usuario;
    Unidad: Unidad;
    contrato: ContratoAhorrent;
    imagenesArray: string[];

    //Fechas
    fechaFormato: string;
    fechaDate: Date;
    numeroUnidad: string;
    numeroContrato: number;
    nombreCliente: string;
    nombreParteVehiculo: string;
    nombreUsuario: string;
}