import { Injectable } from '@angular/core';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { JSONConverters } from '../models/JSONConverters';
import { SolicitudPDI } from '../models/solicitudPDI';
import * as FileSaver from 'file-saver';
import { CambioEstadoSolicitudPDI } from '../models/cambioEstadoSolicitudPDI';
import { OrdenTallerKanboard } from '../models/OrdenTallerKanboard';
import { ConfiguracionOrdenTallerKanboard } from '../models/ConfiguracionOrdenTallerKanboard';
import { CambioEstadoOrdenTallerKanboard } from '../models/CambioEstadoOrdenTallerKanboard';
@Injectable()
export class PdiService { 
    apiUrls: APIUrls = new  APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
    ) { }

    //Configuracion
    public async obtenerConfiguracion(EmpresaId:number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.configuracionPDIURL+`?&EmpresaId=${EmpresaId}`;
            const res = await this.connection.getRequest(url);
            var registro = this.jsonConverters.configuracionPDIDeJSON(res.result);
            return { error: null, data: { result: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async actualizarConfiguracion(registro) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.configuracionPDIURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Configuracion PDI actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
   

        // * * * solicitudesPDI * * * 
        async obtenerSolicitudPDIPorId(id: number, actualizarDatos = false) {
            try {
                var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPDIURL + '/id/' + id;
                if(actualizarDatos) {
                    url = url + '?&actualizarDatos=true';
                }
                var json = await this.connection.getRequest(url);
                var registro = this.jsonConverters.solicitudPDIDeJSON(json);
                return { error: null, data: { registro: registro } };
            } catch (error) {
                return this.connection.obtenerMensajeError(error.status, null);
            }
        }
    
        // Crear
        public async crearSolicitudPDI(registro: SolicitudPDI) {
            try {
                var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPDIURL;
                const res = await this.connection.postRequest(url, registro);
                return { error: null, data: { mensaje: 'SolicitudPDI creado con éxito', result: res } };
            } catch (error) {
                return this.connection.obtenerMensajeError(error.status, error.error.message);
            }
        }
        
        // Actualizar
        public async actualizarSolicitudPDI(registro: SolicitudPDI) {
            try {
                var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPDIURL;
                const res = await this.connection.putRequest(url, registro);
                return { error: null, data: { mensaje: 'SolicitudPDI actualizado con éxito', result: res } };
            } catch (error) {
                return this.connection.obtenerMensajeError(error.status, error.error.message);
            }
        }

        public async actualizarAccesorio(accesoriosFueraAcuerdo: string, idSolicitud: number) {
            try {
                var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPDIURL + '/actualizar-accesorio';
                const res = await this.connection.postRequest(url, {accesoriosFueraAcuerdo: accesoriosFueraAcuerdo, idSolicitud: idSolicitud});
                return { error: null, data: { mensaje: 'Accesorio actualizado con éxito', result: res } };
            } catch (error) {
                return this.connection.obtenerMensajeError(error.status, error.error.message);
            }
        }
    
        // Eliminar
        public async eliminarSolicitudPDI(SolicitudPDIId: number) {
            try {
                var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPDIURL + '/id/' + SolicitudPDIId;
                const res = await this.connection.deleteRequest(url);
                return { error: null, data: { mensaje: 'SolicitudPDI eliminado con éxito', result: res } };
            } catch (error) {
                return this.connection.obtenerMensajeError(error.status, null);
            }
        }

        async enviarCorreoSolicitudNueva(SolicitudPDIId: number) {
            try {
                var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPDIURL + '/enviar-correo-solicitud-nueva';
                url+= `?&SolicitudPDIId=${SolicitudPDIId}`;
                var json = await this.connection.getRequest(url);
                return { error: null, data: { mensaje: 'Correo enviado' } };
            } catch (error) {
                return this.connection.obtenerMensajeError(error.status, null);
            }
        }

        async enviarCorreoDocumentosCompletos(SolicitudPDIId: number) {
            try {
                var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPDIURL + '/enviar-correo-documentos-completos';
                url+= `?&SolicitudPDIId=${SolicitudPDIId}`;
                var json = await this.connection.getRequest(url);
                return { error: null, data: { mensaje: 'Correo enviado' } };
            } catch (error) {
                return this.connection.obtenerMensajeError(error.status, null);
            }
        }

            // * * * cambiar de estado el avalúo * * * //
    async cambiarEstado(solicitudPDI: SolicitudPDI, estado: string, UsuarioId: number = null) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPDIURL + '/actualizarEstado';
            const res = await this.connection.postRequest(url, { estado: estado, UsuarioId: UsuarioId, solicitudPDI: solicitudPDI });
            return { error: null, data: { mensaje: 'Cambia de estado del avalúo con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    async actualizarPlacasKanboard(solicitudPDIId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPDIURL + '/actualizar-placas-kanboard';
            const res = await this.connection.putRequest(url, { id: solicitudPDIId });
            return { error: null, data: { mensaje: 'Cambia de estado del avalúo con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    async enviarNotificacionFechaEntrega(solicitudPDIId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPDIURL + '/enviar-notificacion-fecha-entrega/' + solicitudPDIId;
            const res = await this.connection.getRequest(url);
            return { error: null, data: { mensaje: 'Notificación enviada exitosamente', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    
    async enviarCorreoAccesorios(SolicitudPDIId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPDIURL + '/enviar-correo-accesorios-fuera-acuerdo';
            url+= `?&SolicitudPDIId=${SolicitudPDIId}`;
            var json = await this.connection.getRequest(url);
            return { error: null, data: { mensaje: 'Correo enviado' } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async enviarAPlacas(ids: number[], UsuarioId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPDIURL + '/cambiar-envio-placas';
            const res = await this.connection.postRequest(url, { ids: ids, enviadoAPlacas: true, UsuarioId: UsuarioId });
            return { error: null, data: { mensaje: 'Solicitudes aprobadas con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async validarSolicitud() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPDIURL + '/validacion-kanboard';
            var res = await this.connection.getRequest(url);
            return { error: null, data: { registro: res } }; // { solicitudPDI, DatoVenta }
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
            
        }
    }

    async validarDialogAbierto(SolicitudId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPDIURL + '/validacion-dialog-abierto/' + SolicitudId;
            var res = await this.connection.getRequest(url);
            return { error: null, data: { registro: res } }; // { solicitudPDI, DatoVenta }
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
            
        }
    }

    async validarSolicitudPDI(SolicitudPDIId: number, DatoVentaId: number, fechaPedidoVehiculo: string = null) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPDIURL + '/confirmar-validacion-kanboard';
            var res = await this.connection.postRequest(url, {SolicitudPDIId: SolicitudPDIId, DatoVentaId: DatoVentaId, ventaValidada: true, fechaPedidoVehiculo: fechaPedidoVehiculo});
            return { error: null, data: { registro: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
            
        }
    }

    async actualizarVistaValidacion(SolicitudPDI: SolicitudPDI) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPDIURL + '/actualizar-vista-validacion';
            var res = await this.connection.putRequest(url, SolicitudPDI);
            return { error: null, data: { registro: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
            
        }
    }

    public async descargarExcelSolicitudes(nombre: string, params:string) {
        try {
            // Construir ruta
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPDIURL + '/descargar-excel';
            if(params) url+= '?'+ params;
            var response = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `${nombre}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([ response ], { type: mediaType });
            FileSaver.saveAs(blob, filename);
            return response;
        } catch (error) {
            return null;
        }
    }

    public async descargarExcelTareasKanboard(nombre: string, params:string) {
        try {
            // Construir ruta
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPDIURL + '/descargar-excel-kanboard';
            if(params) url+= '?'+ params;
            var response = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `${nombre}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([ response ], { type: mediaType });
            FileSaver.saveAs(blob, filename);
            return response;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    // * * * datos ventas * * * 
    async obtenerDatoVentaPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.datosVentasURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.datoVentaDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerDatoVentaPorInventario(numInventario: string, params = null) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.datosVentasURL + '/numero-inventario/' + numInventario;
            if(params) url+= '?'+ params;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.datoVentaDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async descargarExcelDatosVenta(nombre: string, params:string) {
        try {
            // Construir ruta
            var url = this.apiUrls.baseURL + this.apiUrls.datosVentasURL + '/descargar-excel';
            if(params) url+= '?'+ params;
            var response = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `${nombre}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([ response ], { type: mediaType });
            FileSaver.saveAs(blob, filename);
            return response;
        } catch (error) {
            return null;
        }
    }

    public async actualizarSchecules() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPDIURL + '/actualizar-schedule-reportes';
            const res = await this.connection.getRequest(url);
            return { error: null, data: { mensaje: 'Schedules de reportes actualizados con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // * * * Cambio estado oportunidad * * * 
    async obtenerCambioEstadoSolicitudPDIPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.cambioEstadoSolicitudPDIURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.cambioEstadoSolicitudPDIDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearCambioEstadoSolicitudPDI(registro: CambioEstadoSolicitudPDI) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.cambioEstadoSolicitudPDIURL;
            const res = await this.connection.postRequest(url, { data: registro });
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarCambioEstadoSolicitudPDI(registro: CambioEstadoSolicitudPDI) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.cambioEstadoSolicitudPDIURL;
            const res = await this.connection.putRequest(url, { data: registro });
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarCambioEstadoSolicitudPDI(CambioEstadoSolicitudPDIId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.cambioEstadoSolicitudPDIURL + '/id/' + CambioEstadoSolicitudPDIId;
            const res = await this.connection.putRequest(url,{data:null});
            return { error: null, data: { mensaje: 'CambioEstadoSolicitudPDI eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // CambioEstadoSolicitudPDIes
    public async obtenerTodosCambioEstadoSolicitudPDIes() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.cambioEstadoSolicitudPDIURL;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.cambioEstadoSolicitudPDIDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los oportunidades.');
        }
    }

    async obtenerReporte(fechaInicioParam: any, fechaFinParam: any, params = '') {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPDIURL + '/reporte?';
            if (fechaInicioParam) url += '&start=' + fechaInicioParam;
                if (fechaFinParam) url += '&finish=' + fechaFinParam;
            if (params) url += params;
            var json = await this.connection.getRequest(url);
            var registro = json;
            return { error: null, data: { registro: registro.data } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async selectsReporteInspeccionPDIReq() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPDIURL + '/selects-reporte-inspeccion';
            var json = await this.connection.getRequest(url);
            var registro = json;
            return { error: null, data: { registro: registro.data } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerReporteInspecciones(fechaInicioParam: any, fechaFinParam: any, params = '') {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPDIURL + '/reporte-inspecciones?';
            if (fechaInicioParam) url += '&start=' + fechaInicioParam;
                if (fechaFinParam) url += '&finish=' + fechaFinParam;
            if (params) url += params;
            var json = await this.connection.getRequest(url);
            var registro = json;
            return { error: null, data: { registro: registro.data } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * órdenes talleres kanboard * * *
    async obtenerOrdenTallerKanboardPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.ordenTallerKanboardURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.ordenTallerKanboardDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearOrdenTallerKanboard(registro: OrdenTallerKanboard) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.ordenTallerKanboardURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'OrdenTallerKanboard creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarOrdenTallerKanboard(registro: OrdenTallerKanboard) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.ordenTallerKanboardURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'OrdenTallerKanboard actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarOrdenTallerKanboard(OrdenTallerKanboardId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.ordenTallerKanboardURL + '/id/' + OrdenTallerKanboardId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'OrdenTallerKanboard eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    //Configuracion
    public async obtenerConfiguracionOrdenTallerKanboard(EmpresaId:number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.configuracionOrdenTallerKanboardURL+`?&EmpresaId=${EmpresaId}`;
            const res = await this.connection.getRequest(url);
            var registro = this.jsonConverters.configuracionOrdenTallerKanboardDeJSON(res.result);
            return { error: null, data: { result: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async actualizarConfiguracionOrdenTallerKanboard(registro: ConfiguracionOrdenTallerKanboard) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.configuracionOrdenTallerKanboardURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'ConfiguracionOrdenTallerKanboard actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * Cambio estado OrdenTallerKanboard * * * 
    async obtenerCambioEstadoOrdenTallerKanboardPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.cambioEstadoOrdenTallerKanboardURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.cambioEstadoOrdenTallerKanboardDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Actualizar
    public async actualizarCambioEstadoOrdenTallerKanboard(registro: CambioEstadoOrdenTallerKanboard) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.cambioEstadoOrdenTallerKanboardURL;
            const res = await this.connection.putRequest(url, { data: registro });
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Crear
    public async crearCambioEstadoOrdenTallerKanboard(registro: CambioEstadoOrdenTallerKanboard) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.cambioEstadoOrdenTallerKanboardURL;
            const res = await this.connection.postRequest(url, { data: registro });
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarCambioEstadoOrdenTallerKanboard(CambioEstadoOrdenTallerKanboardId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.cambioEstadoOrdenTallerKanboardURL + '/id/' + CambioEstadoOrdenTallerKanboardId;
            const res = await this.connection.putRequest(url, { data: null });
            return { error: null, data: { mensaje: 'CambioEstadoOrdenTallerKanboard eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async descargarExcelOrdenesTalleresKanboard(nombre: string, params:string) {
        try {
            // Construir ruta
            var url = this.apiUrls.baseURL + this.apiUrls.ordenTallerKanboardURL + '/descargar-excel';
            if(params) url+= '?'+ params;
            var response = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `${nombre}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([ response ], { type: mediaType });
            FileSaver.saveAs(blob, filename);
            return response;
        } catch (error) {
            return null;
        }
    }

    async descargarInspeccionSolicitud(SolicitudPDIId: number, fileName: string, tipo: string, tipoDanio: string = null) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPDIURL + `/inspeccion-${tipo}/${SolicitudPDIId}`;
            if(tipoDanio) url+= `?&tipo=${tipoDanio}`;
            var res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var fileName = `${fileName}.pdf`;
            var mediaType = 'application/pdf';
            var blob = new Blob([res], {
                type: mediaType
            });
            FileSaver.saveAs(blob, fileName);

            return {
                error: null,
                data: {
                    mensaje: 'Inspeccion de solicitud descarga con éxito.'
                }
            };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async enviarCorreo(correos: string, SolicitudPDIId: number, tipo: string, tipoDanio: string = null) {
        try {
            let url = this.apiUrls.baseURL + this.apiUrls.solicitudesPDIURL + `/inspeccion-enviar-${tipo}`;
            if(tipoDanio) url+= `?&tipo=${tipoDanio}`;
            let enviar = {
                correos: correos,
                SolicitudPDIId: SolicitudPDIId
            };

            const res = await this.connection.postRequest(url, enviar);
            return { error: null, data: { mensaje: ('Documento enviado con éxito' ), result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
}