import { Empresa } from "./empresa";
import { Unidad } from "./unidad";
import { Usuario } from "./usuario";

export class ServicioUnidad {
    constructor(
        public id: number,
        public fecha: string,
        public fechaFin: Date,
        public tipo: string,
        public taller: string,
        public descripcion: string,
        public personaEncargada: string,
        public kilometrajeRecibido: number,
        public finalizado: boolean,
        public totalGastado: number,
        public tipoProveedor: string,
        public horaInicio: string,
        public horaFin: string,
        //Relaciones
        public UnidadId: number,
        public UsuarioId: number,
        public EmpresaId: number,
    ){}

    usuario: Usuario;
    empresa: Empresa;
    unidad: Unidad;

    fechaFormato:string;
    fechaDate:Date;

    fechaFinFormato:string;
    fechaFinDate:Date;

    diasEnTaller: number;

    //auxiliares
    estadoAnterior:string;
    nombreServicioPendiente:string;

}