import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { APIService } from './api.service';
import { ConnectionService } from './connection.service';
import { HttpClientModule } from '@angular/common/http';
import { LibraryService } from '../library/library.service';
import { AuthGuardLogueado } from '../auth/auth-guard-logueado';
import { AuthGuardPermisos } from '../auth/auth-guard-permisos';
import { EmpresasService } from '../empresas/empresas.service';
import { UsuariosService } from '../usuarios/usuarios.service';
import { ClientesService } from '../clientes/clientes.service';
import { ProductosService } from '../productos/productos.service';
import { VentasService } from '../ventas/ventas.service';
import { FormulariosFEICService } from '../formularios-feic/formularios-feic.service';
import { MatrizRiesgoService } from '../matriz-riesgo/matriz-riesgo.service';
import { RolesService } from '../roles/roles.service';
import { AvaluosService } from '../avaluos/avaluos.service';
import { HojasMembretadasService } from '../hojas-membretadas/hojas-membretadas.service';
import { ManualService } from '../manual/manual.service';
import { AhorrentService } from '../ahorrent/ahorrent.service';
import { CampaniaPreventivaService } from '../campanias-preventivas/campania-preventiva.service';
import { CitasService } from '../citas/citas.service';
import { FacturasSVService } from '../facturas-sv/facturas-sv.service';
import { ServiciosService } from '../servicios/servicios.service';
import { RepuestosService } from '../repuestos/repuestos.service';
import { VersionesService } from '../versiones/versiones.service';
import { LaningPageService } from '../laning-page/laning-page.service';
import { RequisicionesService } from '../requisiciones/requisiciones.service';
import { DigitalizacionBoletaService } from '../digitalizacion-boleta/digitalizacion-boleta.service';
import { PdiService } from '../pdi/pdi.service';
import { PresupuestoService } from '../presupuestos/presupuestos.service';
import { solicitudTraspasoService } from '../solicitudTraspaso/solicitudTraspaso.service';
import { BestuneService } from '../bestune/bestune.service';
import { ExcelService } from '../excel/excel.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule
    ],
    declarations: [
        
    ],
    exports: [
        
    ],
    providers: [
        APIService,
        ConnectionService,
        LibraryService,
        // Auth guards
        AuthGuardLogueado,
        AuthGuardPermisos,
        // Service para generación de Excels
        ExcelService,
        // Services
        EmpresasService,
        UsuariosService,
        ClientesService,
        ProductosService,
        VentasService,
        FormulariosFEICService,
        MatrizRiesgoService,
        RolesService,
        AvaluosService,
        HojasMembretadasService,
        ManualService,
        AhorrentService,
        CampaniaPreventivaService,
        CitasService,
        RequisicionesService,
        ServiciosService,
        RepuestosService,
        VersionesService,
        LaningPageService,
        DigitalizacionBoletaService,
        PdiService,
        FacturasSVService,
        PresupuestoService,
        solicitudTraspasoService,
        BestuneService,
    ]
})
export class APIModule {
}
