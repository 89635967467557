import { Empresa } from './empresa';

export class PlanDeMantenimiento {
    constructor(
        public id: number,
        public nombre: string,
        public descripcion: string,
        public fechaInicio: string,
        public fechaFin: string,
        public precio: number,
        public activo: boolean,
        //Relaciones
        public EmpresaId: number,
    ){}
    
    Empresa: Empresa;

    fechaInicioDate: Date;
    fechaInicioFormato: string;

    fechaFinDate: Date
    fechaFinFormato: string
}