import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/auth.service';

export interface DialogData {
    titulo: string,
    mensaje: string,
    payload: any,
    textoCerrar: string,
    textoAceptar: string,
    labelTexto?: string,
    texto?: string,
}

@Component({
    selector: 'generic-dialog-confirmacion-input',
    templateUrl: './generic-dialog-confirmacion-input.component.html',
})
export class GenericDialogConfirmacionInput {
    
    texto: string;
    labelTexto: string;

    constructor(
        public dialogRef: MatDialogRef<GenericDialogConfirmacionInput>,
        public auth: AuthService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {
        if(data.labelTexto) this.labelTexto = data.labelTexto;
        if(data.texto) this.texto = data.texto;
    }

    cerrar() {
        this.dialogRef.close({ result: 'cerrar',    texto: this.texto });
    }

    confirmar() {
        this.dialogRef.close({ result: 'confirmar', texto: this.texto });
    }
    
}