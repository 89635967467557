import { MultaAhorrent } from './MultaAhorrent';
import { Danios, EstructuraDescuentos, Imagen, InspeccionGrafica } from './avaluo';
import { ClienteAhorrent } from './clienteAhorrent';
import { ContenidoFormularioAhorrent } from './contenidoFormularioAhorrent';
import { Empresa } from './empresa';
import { Unidad } from './unidad';
import { Usuario } from './usuario';
import * as moment from 'moment';
moment.locale('es');

export class ContratoAhorrent {
    constructor(
        public id: number,
        public estado: string,
        public correlativo: number,
        public correlativoReserva: number,
        public fechaInicio: string,
        public fechaFin: string,
        public eliminado: boolean,
        public observaciones: string,
        public datosContrato: string,
        public inspeccionSalida: string,
        public inspeccionEntrada: string,
        public esReserva: boolean,
        public lavado: boolean,
        public lavador: string,
        public subtotalFinal: number,
        public numeroImagenes: number,
        public tiposVehiculo: string,
        public origenRenta: string,
        public nombreReferido: string,
        public sucursal: string,
        public codigoDescuento: string,
        public montoPorcentajeDescuento: number,
        public UsuarioDescuentoId: number,
        // Relaciones
        public ClienteId: number,
        public EmpresaId: number,
        public UsuarioId: number,
        public UnidadId: number,
    ) { }

    cliente: ClienteAhorrent;
    empresa: Empresa;
    usuario: Usuario;
    unidad: Unidad;
    //Auxiliares
    contrato: ContenidoFormularioAhorrent;
    nombreUnidad: string;
    nombreCliente: string;
    nombreEstado: string;
    lavadorJSON: any;
    multas: MultaAhorrent[] = [];

    // Inspecciones 
    inspeccionSalidaJson: InspeccionEntradaSalida = new InspeccionEntradaSalida();
    inspeccionEntradaJson: InspeccionEntradaSalida = new InspeccionEntradaSalida();

    // Fechas
    fechaInicioFormato: string;
    fechaInicioDate: Date;
    fechaFinFormato: string;
    fechaFinDate: Date;

    fechaEntradaAnteriorAux: string;
    horaEntradaAnteriorAux: string;
    clienteAvisoAux: boolean;
}

export class InspeccionVisualAhorrent {
    ruidos: string;
    notas: string;
    imagenes: Imagen[] = [];
    entregadoPor: string;
}

export class OtrasInspeccionesAhorrent {
    public estadoLlantas: string;
    public aros: boolean;
    public platos: boolean;
    public tapones: boolean;
    public tarjetaCirculacion: boolean;
    public calcomania: boolean;
}

export class InspeccionesDetalladasAhorrent {
    constructor(
        public cinturones: string = null,
        public bocina: string = null,
        public radio: string = null,
        public encendedor: string = null,
        public ceniceros: string = null,
        public espejosRetrovisor: string = null,
        public alfombrasInterior: string = null,
        public placasYDocumentos: string = null,
        public tapiceriaInterior: string = null,
        public taponGasolina: string = null,
        public llantaRepuesto: string = null,
        public tricketHerramienta: string = null,
        public limpiaBrisas: string = null,
        public vidrios: string = null,
        public antenaRadio: string = null,
        public talcosStop: string = null,
        public talcosPidevias: string = null,
        public faroles: string = null,
        public emblemasYLetreros: string = null,
        public taponesRuedas: string = null,
        public candados: string = null,
        public extinguidor: string = null,
        public triangulo: string = null,
        public CinturonesActivo: boolean = false,
        public BocinaActivo: boolean = false,
        public RadioActivo: boolean = false,
        public EncendedorActivo: boolean = false,
        public CeniceroActivo: boolean = false,
        public EspejosActivo: boolean = false,
        public AlfombrasActivo: boolean = false,
        public PlacasDocumentosActivo: boolean = false,
        public TapiceriaActivo: boolean = false,
        public TaponGasolinaActivo: boolean = false,
        public LlantaRepuestoActivo: boolean = false,
        public TricketHerramientaActivo: boolean = false,
        public LimpiaBrisasActivo: boolean = false,
        public VidriosActivo: boolean = false,
        public AntenaRadioActivo: boolean = false,
        public TalcosRuedasActivo: boolean = false,
        public TalcosPideviasActivo: boolean = false,
        public FarolesActivo: boolean = false,
        public EmblemasLetrerosActivo: boolean = false,
        public TaponesRuedasActivo: boolean = false,
        public CandadosActivo: boolean = false,
        public ExtinguidorActivo: boolean = false,
        public TrianguloActivo: boolean = false,
    ) { }

}

export class InspeccionEntradaSalida {

    constructor(
        public inspeccionGrafica: string = null,
        public listadoDescuentos: string = null,
        public inspeccionesVisuales: string = null,
        public otrasInspecciones: string = null,
        public inspeccionesDetalladas: string = null,
        public descuentoInspeccionMecanicaVisual: number = 0,
        public otrosDescuentos: number = 0,
        public descuentoInspeccionGrafica: number = 0,
        public horaEntrega: string = null,
        public fechaEntrega: string = null,
        public danios: string = null,
    ) {

        this.fechaEntrega = this.convertirFecha(this.fechaEntrega, 'YYYY-MM-DD', 'YYYY-MM-DD');
        this.fechaEntregaDate = this.convertirFecha(this.fechaEntrega, 'YYYY-MM-DD', 'date');
    }

    public listadoInspeccionesVisualesJson: InspeccionVisualAhorrent = new InspeccionVisualAhorrent();
    public DescuentosJson: EstructuraDescuentos = new EstructuraDescuentos();
    public otrasInspeccionesJson: OtrasInspeccionesAhorrent = new OtrasInspeccionesAhorrent();
    public inspeccionesDetalladasJson: InspeccionesDetalladasAhorrent = new InspeccionesDetalladasAhorrent();
    public listadoInspeccionesGraficasJson: InspeccionGrafica = new InspeccionGrafica();
    public listadoDaniosJson: Danios = new Danios();

    fechaEntregaDate: Date;

    private convertirFecha(fecha: any, formatoEntrada: string, formatoSalida: string) {
        var fecha;
        if (formatoEntrada === 'date') fecha = moment(fecha);
        else fecha = moment(fecha, formatoEntrada);

        var res;
        if (formatoSalida === 'date') res = fecha;
        else res = fecha.format(formatoSalida);
        return res;
    }
}