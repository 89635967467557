import { Empresa } from './empresa';

export class Producto {
    constructor(
        public id:                      number,
        public tipo:                    string,
        public nombre:                  string,
        public descripcion:             string,
        public moneda:                  string,
        public valorUnitario:           number,
        public identificadorProducto:   string,
        public claseVehiculo:           string,
        public valorMonetario:          number,
        public isDeleted:               boolean,
        public marca:                   string,
        public linea:                   string,
        public modelo:                  string,
        public feic:                    boolean,
        public avaluos:                 boolean,

        // Relaciones
        public EmpresaId: number,
    ){}

    empresa: Empresa;

}