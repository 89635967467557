import { PlanDeMantenimiento } from './PlanDeMantenimiento';

export class ItemPlanDeMantenimiento {
    constructor(
        public id: number,
        public nombre: string,
        public descripcion: string,
        public cantidadUso: string,
        //Relaciones
        public PlanDeMantenimientoId: number,
    ){}
    
    PlanDeMantenimiento: PlanDeMantenimiento;
}