import { Usuario } from "./usuario";

export class ReporteHM {
    constructor(
        public nombreUsuario: string,
        public cantidadHM: number,
        public descargasTotales: number,
        public cantidadSolicitudes: number
    ) { }

}