import { Danios } from './avaluo';
import { CambioEstadoUnidad } from './cambioEstadoUnidad';
import { ContratoAhorrent } from './ContratoAhorrent';
import { Empresa } from './empresa';
import { LineaAhorrent } from './lineaAhorrent';
import { MarcaAhorrent } from './marcaAhorrent';
import { ServicioUnidad } from './servicioUnidad';
import { Usuario } from './usuario';

export class Unidad {
    constructor(
        public id: number,
        public placa: string,          
        public chasis: string,         
        public color: string,       
        public kilometraje: number,    
        public estado: string,         
        public eliminado: boolean,      
        public modelo: string,
        public costoHora: number,
        public costoDia: number,
        public costoDia7a25: number,
        public costoDia25: number,
        public costoSemana: number,
        public costoPorKilometro: number,
        public numero: string,
        public fechaAlta: string,
        public observaciones: string,
        public tipoVehiculo: string,
        public motor: string,
        public fechaEstimadaReparacion: string,
        public propiedad: string,
        public ubicacion: string,
        public numeroInt: number,
        public umbralKilometraje: number,
        public rebajaDeducible: string,
        public montoDeducibleRebajado: number,
        public montoParticipacion: number,
        public tipoCombustible: string,
        public galonaje: number,
        public kilometrajePorDia: number,
        public grupo: string,
        public tipoTransmision: string,
        public nivelGasolina: string,
        public danios: string,
        public ubicacionActual: string,
        
        public numeroPuertas: string,
        public cilindros: string,
        public centimetrosCubicos: string,
        public tonelaje: string,

        public anchoUnidad: string,
        public largoUnidad: string,
        public altoUnidad: string,
        public anchoFurgon: string,
        public largoFurgon: string,
        public altoFurgon: string,
        public fechaAdquisicion: string,
        public precioAdquisicionSinIva: number,
        public mesesDepreciar: number,

        // Relaciones
        public MarcaId: number,
        public LineaId: number,
        public UsuarioId: number,
        public ServicioActualId: number,
        public EmpresaId: number,
    ){}

    marca: MarcaAhorrent;
    linea: LineaAhorrent;
    usuario: Usuario;
    ContratoIdEnCurso: number;
    ContratoAhorrents: ContratoAhorrent[] = [];
    ContratoAhorrentActual: ContratoAhorrent;
    //servicio
    empresa: Empresa;
    nombreUnidadCompleto: string;
    ServicioActual: ServicioUnidad;
    ContratoAhorrentIdActualiza: number;
    nombreTipoVehiculo: string;

    cambiosEstadosUnidades: CambioEstadoUnidad[] = [];
    imagenDefault: any;

    //Fechas
    fechaAltaFormato: string;
    fechaAltaDate: Date;

    //Fechas
    fechaEstimadaReparacionFormato: string;
    fechaEstimadaReparacionDate: Date;

    fechaAdquisicionFormato: string;
    fechaAdquisicionDate: Date;

    public listadoDaniosJson: Danios = new Danios();

}