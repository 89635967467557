import { Injectable } from '@angular/core';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { JSONConverters } from '../models/JSONConverters';
import { ListaNegra } from '../models/listaNegra';
import * as Papa from 'papaparse';
import { AjusteGeneral } from '../models/ajusteGeneral';
import * as FileSaver from 'file-saver';

@Injectable()
export class MatrizRiesgoService { 
    apiUrls: APIUrls = new  APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
    ) {
        //Papa Promise
        Papa.parsePromise = function (file) {
            return new Promise(function (complete, error) {
                Papa.parse(file, {
                    header: true,
                    skipEmptyLines: true,
                    complete,
                    error
                });
            });
        };
    }

    // * * * Listas negras * * * 
    async obtenerListaNegraPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.listasNegrasURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.listaNegraDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearListaNegra(registro: ListaNegra) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.listasNegrasURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro agregado a lista negra con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarListaNegra(registro: ListaNegra) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.listasNegrasURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro de lista negra actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarListaNegra(ListaNegraId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.listasNegrasURL + '/id/' + ListaNegraId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Registro de lista negra eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * Importar registros para lista negra * * *
    async analizarArchivoListaNegra(archivo) {
        try {
            const results = await Papa.parsePromise(archivo);
            var registros: ListaNegra[] = [];
            var errores = [];
            var rowNumberIndex = 2;
            for (let i = 0; i < results.data.length; i++) {
                const element = results.data[i];

                // Crear nuevo registro con sus datos
                var registro = new ListaNegra(
                    -1,
                    this.libraryService.validarCampo(element['Nombre']),
                    this.libraryService.validarCampo(element['Tipo']),
                    this.libraryService.validarCampo(element['Observaciones']),
                    false, 
                    false,
                    false, 
                    false,
                    this.libraryService.validarCampo(element['Empresa']),
                );

                registros.push(registro);
                rowNumberIndex++;
            }
            
            return { errores: errores, registros: registros };
        } catch(error) {
            return null;
        }
    }

    // * * * Productos * * * 
    async obtenerTodosRegistrosListaNegra() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.listasNegrasURL;
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                var element = json[i];
                var registro = this.jsonConverters.listaNegraDeJSON(element);
                registros.push(registro);
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Carga masiva
    public async cargaMasivaListaNegra(registros: ListaNegra[]) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.listasNegrasURL + '/cargaMasiva';
            const res = await this.connection.postRequest(url, registros);
            return { error: null, data: { mensaje: 'Registros agregado a lista negra con éxito.', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * Ajuste general * * * 
    async obtenerAjusteGeneral() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.empresasURL + '/readAjusteGeneral';
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.ajusteGeneralDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async descargaPdfFormatoDeclaracion(nombre: string, id: string, tipo: string, FormularioFEICId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.formulariosFEICURL + `/pdfFormatoDeclaracion?nombre=${nombre ? nombre : ''}&id=${id ? id : ''}&tipo=${tipo}&FormularioFEICId=${FormularioFEICId}`;
            var res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var fileName = 'Formato de declaración.pdf';
            var mediaType = 'application/pdf';
            var blob = new Blob([res], {
                type: mediaType
            });
            FileSaver.saveAs(blob, fileName);

            return {
                error: null,
                data: {
                    mensaje: 'Formulario Jurídico descargado con éxito.'
                }
            };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async descargaPdfContrasenaLlenado(FormularioFEICId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.formulariosFEICURL + `/pdfFormatoContrasenaLlenado?&FormularioFEICId=${FormularioFEICId}`;
            var res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var fileName = 'Contraseña de llenado ' + FormularioFEICId + '.pdf';
            var mediaType = 'application/pdf';
            var blob = new Blob([res], {
                type: mediaType
            });
            FileSaver.saveAs(blob, fileName);

            return {
                error: null,
                data: {
                    mensaje: 'Contraseña descargada con éxito.'
                }
            };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async actualizarAjusteGeneral(registro: AjusteGeneral) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.empresasURL + '/updateAjusteGeneral';
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Ajustes generales actualizados con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

}