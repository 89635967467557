import { Rol } from './rol';
import { Empresa } from './empresa';
import { UsuarioEmpresa } from './usuarioEmpresa';
import { HorarioCita } from './cita';
import { APIUrls } from '../api/apiUrls';

export class Usuario {
    constructor(
        public id: number,
        public nombres: string,
        public apellidos: string,
        public email: string,
        public email2: string,
        public telefono: string,
        public telefono2: string,
        public idGoogle: string,
        public activo: boolean,
        public isDeleted: boolean,
        public atiendeCitas: boolean,
        public horaInicioAtencion: string,      
        public horaFinAtencion: string,      
        public diasAtencion: string,
        public duracionCitas: number,
        public horaInicioAlmuerzo: string,
        public horaFinAlmuerzo: string,
        public inicioVacaciones: Date,
        public finVacaciones: Date,
        public DPI: string,
        public ConcesionarioId: number,
    ){

        this.nombreCompleto = nombres + ' ' + apellidos;
    }

    diasAtencionArray: string[] = [];
    nombreCompleto: string;
    password: string;
    RolId: number;
    rol: Rol;
    EmpresaId: number;
    empresa: Empresa;
    empresas: Empresa[];
    permisosAdicionales: string;
    bloqueosAdicionales: string;
    relacionEmpresa: UsuarioEmpresa;
    horariosDisponibles: HorarioCita[];

    //Fechas
    inicioVacacionesDate: Date;
    inicioVacacionesFormato: string;

    finVacacionesDate: Date;
    finVacacionesFormato: string;

    tienePermisoPara(permiso: string) {
        let apiURLs = new APIUrls();
        if(!permiso) return true;
        if(!this.rol) return false;
        if(this.bloqueosAdicionales && this.bloqueosAdicionales.indexOf(permiso) != -1) return false;
        if(this.permisosAdicionales && this.permisosAdicionales.indexOf(permiso) != -1) return true;
        if(this.rol.permisos) {
            let permisosArray = this.rol.permisos.split('|');
            if(permisosArray.indexOf(permiso) != -1) return true;
        }
        return false;
    }
}
