import { Injectable } from '@angular/core';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { AjusteAvaluo } from '../models/ajusteAvaluo';
import { Avaluo } from '../models/avaluo';
import { JSONConverters } from '../models/JSONConverters';
import { LineaVehiculo } from '../models/lineaVehiculo';
import { MarcaVehiculo } from '../models/marcaVehiculo';
import { OtroDescuento } from '../models/OtroDescuento';
import * as FileSaver from 'file-saver';

@Injectable({
    providedIn: 'root'
})
export class AvaluosService {
    apiUrls: APIUrls = new APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
    ) { }

    async obtenerTodasLasMarcasVehiculo() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.marcaVehiculosURL;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.marcaVehiculoDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las marcas de los vehiculos.');
        }
    }

    async obtenerMarcaVehiculoPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.marcaVehiculosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.marcaVehiculoDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearMarcaVehiculo(registro: MarcaVehiculo) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.marcaVehiculosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Marca de vehiculo creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarMarcaVehiculo(registro: MarcaVehiculo) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.marcaVehiculosURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Marca de vehiculo actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * Linea de vehiculos * * * //
    async obtenerLineaVehiculoPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.lineaVehiculosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.lineaVehiculoDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async ObtenerTodasLasLineas(MarcaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.lineaVehiculosURL;
            url += '?&MarcaVehiculoId=' + MarcaId;
            url += '&usarTransparenciaPrecios=0';
            var json = await this.connection.getRequest(url, false);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.lineaVehiculoDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las líneas.');
        }
    }

    async ObtenerTodasLasLineasExistentes(auth: boolean = true, usarTransparenciaPrecios: boolean, landingPage: boolean = false) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.lineaVehiculosURL + '?';
            if(landingPage) url += '&landingPage=true';
            if(usarTransparenciaPrecios == true) url += '&usarTransparenciaPrecios=1';
            else url += '?&usarTransparenciaPrecios=0';
            var json = await this.connection.getRequest(url,auth);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.lineaVehiculoDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las líneas.');
        }
    }

    // Crear
    public async crearLineaVehiculo(registro: LineaVehiculo) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.lineaVehiculosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Linea de vehiculo creada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarLineaVehiculo(registro: LineaVehiculo) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.lineaVehiculosURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Linea de vehiculo actualizada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async eliminarLineaVehiculo(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.lineaVehiculosURL + '/id/' + id;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Linea eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async eliminarMarcaVehiculo(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.marcaVehiculosURL + '/id/' + id;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Marca eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }


    // * * * Ajustes * * * //
    public async obtenerAjustes() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.ajusteAvaluoURL;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.ajusteAvaluoDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Actualizar
    public async actualizarAjustes(registro: AjusteAvaluo) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.ajusteAvaluoURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Ajuste de avalúo actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * Descuentos * * * //
    async obtenerTodosLosDescuentos() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.otroDescuentoURL;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.otroDescuentoDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los descuentos.');
        }
    }

    async guardarDescuentos(descuentos: OtroDescuento[]) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.otroDescuentoURL;
            const res = await this.connection.postRequest(url, { descuentos: descuentos });
            return { error: null, data: { mensaje: 'descuentos creados con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async crearAvaluo(avaluo: Avaluo, uri: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.avaluoURL + uri;
            const res = await this.connection.postRequest(url, avaluo);
            return { error: null, data: { mensaje: 'Avalúo solicitado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async actualizarAvaluo(avaluo: Avaluo) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.avaluoURL;
            const res = await this.connection.putRequest(url, avaluo);
            return { error: null, data: { mensaje: 'Avaluo actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async eliminarAvaluo(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.avaluoURL + '/id/' + id;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Avalúo eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * Avaluo * * * //
    async obtenerAvaluoId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.avaluoURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.avaluoDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * cambiar de estado el avalúo * * * //
    async cambiarEstado(avaluo: Avaluo, estado: string, UsuarioId: number = null) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.avaluoURL + '/actualizarEstado';
            const res = await this.connection.postRequest(url, { estado: estado, UsuarioId: UsuarioId, avaluo: avaluo });
            return { error: null, data: { mensaje: 'Cambia de estado del avalúo con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async guardarArchivoEnServidorAvaluo(archivo: File) {
        try {
            var url = this.apiUrls.uploadURL + this.apiUrls.usuariosURL + '/uploadArchivo';
            const res = await this.connection.uploadFile(url, archivo, true);
            if (!res.error) {
                return { error: null, data: { url: res.url } };
            }
            else throw new Error();
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al cargar el archivo.');
        }
    }

    async descargarAvaluo(AvaluoId: number, fileName: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.avaluoURL + '/descargarAvaluo/' + AvaluoId;
            var res = await this.connection.getDownloadRequest(url, false);

            // Descargar archivo
            var fileName = `${fileName}.pdf`;
            var mediaType = 'application/pdf';
            var blob = new Blob([res], {
                type: mediaType
            });
            FileSaver.saveAs(blob, fileName);

            // Devolvemos el mensaje de éxito junto al archivo
            return {
                error: null,
                data: {
                    mensaje: 'Avalúo descargado con éxito.'

                }
            };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async descargarAvaluoYSubirAlServidor(AvaluoId: number, fileName: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.avaluoURL + '/descargarAvaluo/' + AvaluoId;
            var res = await this.connection.getDownloadRequest(url, false);
        
            // Descargar archivo
            var fileName = `${fileName}.pdf`;
            var mediaType = 'application/pdf';
            var blob = new Blob([res], {
            type: mediaType
            });
            FileSaver.saveAs(blob, fileName);

            const file = new File([blob], fileName, { type: mediaType });

            // Guardar el archivo en el servidor y obtener el enlace
            const enlaceAvaluo = await this.guardarArchivoEnServidorAvaluo(file);
        
            return {
                error: null,
                data: {
                    mensaje: 'Avalúo descargado con éxito.',
                    url: enlaceAvaluo.data.url
                }
            };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async duplicarAvaluo(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.avaluoURL + '/duplicar'
            var json = await this.connection.postRequest(url, { AvaluoId: id })
            if(json.error) throw Error('Error al duplicar avaluo');
            var registro = this.jsonConverters.avaluoDeJSON(json.result);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerReporte(fechaInicioParam: any, fechaFinParam: any, ProductoParam) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.avaluoURL + '/reporte?';
            if (fechaInicioParam) url += '&start=' + fechaInicioParam;
            if (fechaFinParam) url += '&finish=' + fechaFinParam;
            if (ProductoParam) url += '&ProductoId=' + ProductoParam;
            var json = await this.connection.getRequest(url);
            var registro = json;
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async descargarListadoAvaluosExcel(idsAvaluos: number[], nombre: string, params:string, general:boolean = false, modoCoaches: boolean = false) {
        try {
            // Construir ruta
            var url = this.apiUrls.baseURL + this.apiUrls.avaluoURL
            url+= general? '/listado-general-avaluos-excel?': '/listado-avaluos-excel?';
            if(params) {
                url += params;
            }
            if(modoCoaches) {
                url += '&coaches=true'
            }
            //if(idsAvaluos) url += '&idsAvaluos=' + idsAvaluos.toString();
            var response = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `${nombre}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([response], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: response };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);;
        }
    }

    public async enviarCorreoAvaluo(correos: string, AvaluoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.avaluoURL + '/enviarCorreos?AvaluoId=' + AvaluoId;
            const res = await this.connection.postRequest(url, { correos : correos });
            return { error: null, data: { mensaje: 'Correos enviados con éxito.', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async buscarAvaluo(placa: string, chasis: string){
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.avaluoURL + '/buscarAvaluo?';
            if(placa) url += '&placa=' + placa;
            if(chasis) url += '&chasis=' + chasis;
            var json = await this.connection.getRequest(url);
            if(!json) return { error: null, data: { registro: null } };
            var registro = this.jsonConverters.avaluoDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

}
