import { Injectable } from '@angular/core';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { JSONConverters } from '../models/JSONConverters';
import { Cliente } from '../models/cliente';

@Injectable()
export class ClientesService { 
    apiUrls: APIUrls = new  APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
    ) { }

    // * * * Clientes * * * 
    async obtenerClientePorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.clientesURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.clienteDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearCliente(registro: Cliente) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.clientesURL;
            const res = await this.connection.postRequest(url, registro);
            // Viene false si no se creó el cliente
            if (!res.created) return { error: true, data: { mensaje: 'El NIT ya existe. No se pudo crear el cliente.', result: res } };
            return { error: null, data: { mensaje: 'Cliente creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarCliente(registro: Cliente) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.clientesURL;
            const res = await this.connection.putRequest(url, registro);
            if (res.nitExistente) return { error: true, data: { mensaje: 'El NIT ya existe. No se pudo actualizar el cliente.', result: res } };
            return { error: null, data: { mensaje: 'Cliente actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarCliente(ClienteId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.clientesURL + '/id/' + ClienteId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Cliente eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

}