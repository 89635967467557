import { Version } from "./version";
import { Kilometraje } from "./Kilometraje";
import { Repuesto } from "./repuesto";

export class RepuestoVersion {
    constructor(
        public id: number,
        public RepuestoId: number,
        public VersionId: number,
        public KilometrajeId: number
    ){}

    Repuesto: Repuesto;
    Version: Version;
    Kilometraje: Kilometraje;

    //Auxiliares
    checkbox: boolean;
}