import { ItemPuntoPresupuesto } from "./ItemPuntoPresupuesto";
import { Presupuesto } from "./presupuesto";
import { PuntoPresupuesto } from "./PuntoPresupuesto";

export class RepuestoItemPuntoPresupuesto {
    constructor(
        public id: number,
        public tipo: string,
        public codigo: string,
        public descripcion: string,
        public precio: number,
        public rechazado: boolean,
        public motivoRechazo: string,
        public repuestoImportado: boolean,
        public repuestoBodegaLocal: boolean,
        public bodegaLocalTaller: boolean,
        //Relaciones
        public PresupuestoId: number,
        public PuntoPresupuestoId: number,
        public ItemPuntoPresupuestoId: number,
    ) { }

    Presupuesto: Presupuesto;
    PuntoPresupuesto: PuntoPresupuesto;
    ItemPuntoPresupuesto: ItemPuntoPresupuesto;

}