export default {
    // * * * Factores de producto * * *
    tiposNegociaciones:  [
        { nombre: 'Nuevos', id: 'nuevos', riesgo: 4 },
        { nombre: 'Usados', id: 'usados', riesgo: 2 }
    ],
    marcas:              [
        { nombre: 'Volkswagen',     id: 'volkswagen',   riesgo: 2 },
        { nombre: 'Otras marcas',   id: 'otras',        riesgo: 1 }
    ],
    rangosModelos:       [
        { antiguedad: 3, id: '3-o-más-años',    riesgo: 1 },
        { antiguedad: 2, id: '2-años',          riesgo: 2 },
        { antiguedad: 1, id: '1-años',          riesgo: 3 },
        { antiguedad: 0, id: '0-años',          riesgo: 4 }
    ],
    lineas:              [
        { nombre: 'TOUGAREG',       id: 'tougareg',         riesgo: 4 },
        { nombre: 'CONSTELLATION',  id: 'constellation',    riesgo: 2 },
        { nombre: 'AMAROK',         id: 'amarok',           riesgo: 4 },
        { nombre: 'TIGUAN',         id: 'tiguan',           riesgo: 2 },
        { nombre: 'G TI',           id: 'g-ti',             riesgo: 3 },
        { nombre: 'TRANSPORTER',    id: 'transporter',      riesgo: 2 },
        { nombre: 'BEATTLE',        id: 'beattle',          riesgo: 2 },
        { nombre: 'JETTA',          id: 'jetta',            riesgo: 2 },
        { nombre: 'POLO',           id: 'polo',             riesgo: 1 },
        { nombre: 'SAVEIRO',        id: 'saveiro',          riesgo: 1 },
        { nombre: 'Nuevos',         id: 'nuevos',           riesgo: 4 },
        { nombre: 'T-CROSS',        id: 't-cross',          riesgo: 2 },
        { nombre: 'GOL',            id: 'gol',              riesgo: 1 },
        { nombre: 'FOX',            id: 'fox',              riesgo: 2 },
        { nombre: 'Delivery',       id: 'delivery',         riesgo: 2 },
        { nombre: 'Turing',         id: 'turing',           riesgo: 2 },
        { nombre: 'Crafter',        id: 'crafter',          riesgo: 2 },
        { nombre: 'New carring',    id: 'new-carring',      riesgo: 1 },
        { nombre: 'Virtus',         id: 'virtus',           riesgo: 2 },
        { nombre: 'Vigus',          id: 'vigus',            riesgo: 2 },
    ],
    propositoDestino:              [
        { nombre: 'Particular',     id: 'particular',   riesgo: 2 },
        { nombre: 'Comercial',      id: 'comercial',    riesgo: 2 },
        { nombre: 'Otro',           id: 'otro',         riesgo: 4 },
    ],

    // * * * Factores de cliente * * *

    // * * Individual * *

    // * Generales *
    rangosEdadIndividual:         [
        { min: null,    max: 20,    riesgo: 4 , nombre: '18-20'                 },
        { min: 21,      max: 30,    riesgo: 3 , nombre: '21-30'                 },
        { min: 31,      max: 65,    riesgo: 2 , nombre: '31-65'                 },
        { min: 66,      max: null,  riesgo: 4 , nombre: '66+'                   },
    ],
    clienteIndividualPEP:         [
        { nombre: 'Cliente PEP', riesgo: 4 }
    ],
    fuentesIngreso:     [
        { id: 1, riesgo: 2, nombre: 'Negocio Propio'                            }, 
        { id: 2, riesgo: 2, nombre: 'Relación de dependencia'                   },
        { id: 3, riesgo: 4, nombre: 'Otros'                                     }, 
    ],
    listaNegraIndividual:         [
        { riesgo: 4, nombre: 'Coincidencia con lista negra'                     }, 
    ],
    tercerasPersonasIndividual:   [
        { riesgo: 3, nombre: 'Asociado a mas de un Formulario FEIC'             }, 
    ],
    proveedorEstado:    [
        { riesgo: 4, nombre: 'Cliente es proveedor del estado'                  }, 
    ],
    contratistaEstado:    [
        { riesgo: 3, nombre: 'Cliente es contratista del estado'                }, 
    ],
    partidosPoliticosIndividual:    [
        { riesgo: 4, nombre: 'Cliente pertenece a partidos políticos'           }, 
    ],

    // * Individual - 1 vehículo*
    nacionalidadIndividual_1:   [
        { id:'gt'           , riesgo: 1, nombre: 'Guatemalteco'                 }, 
        { id:'segunda'      , riesgo: 2, nombre: 'Tiene segunda nacionalidad'   }, 
        { id:'extranjero'   , riesgo: 3, nombre: 'Extranjero'                   }, 
    ],

    // * Individual - 2 o mas vehículo*
    nacionalidadIndividual_2:   [
        { id:'gt'           , riesgo: 2, nombre: 'Guatemalteco'                 }, 
        { id:'segunda'      , riesgo: 3, nombre: 'Tiene segunda nacionalidad'   }, 
        { id:'extranjero'   , riesgo: 3, nombre: 'Extranjero'                   }, 
    ],

    // * * Jurídico * *
    nacionalidadJuridico:   [
        { id:'gt'           , riesgo: 1, nombre: 'Guatemalteco'                 },  
        { id:'extranjero'   , riesgo: 3, nombre: 'Extranjero'                   }, 
    ],
    listaNegraJuridico:         [
        { riesgo: 4, nombre: 'Coincidencia con lista negra'                     }, 
    ],
    anioConstitucion:   [
        { antiguedad: 3, id: '3-o-más-años',    riesgo: 1 },
        { antiguedad: 2, id: '2-años',          riesgo: 3 },
        { antiguedad: 1, id: '1-años',          riesgo: 4 },
        { antiguedad: 0, id: '0-años',          riesgo: 4 }
    ],
    tipoDeJuridica:   [
        { id:'organizacion-gubernamental'   , riesgo: 4, nombre: 'Pertenece a organización gubernamental'   }, 
        { id:'fundacion'                    , riesgo: 4, nombre: 'Es fundación'                             }, 
        { id:'organizacion-sin-lucro'       , riesgo: 4, nombre: 'Es una organización sin fines de lucro'   }, 
        { id:'embajada'                     , riesgo: 3, nombre: 'Es una embajada'                          }, 
    ],
    empresasRelacionadas:         [
        { riesgo: 1, nombre: 'Tiene empresas relacionadas'                     }, 
    ],
    tercerasPersonasJuridico:   [
        { riesgo: 3, nombre: 'Asociado a mas de un Formulario FEIC'             }, 
    ],
    tipoOrganizacion:   [
        { id:'anonima'  , riesgo: 4, nombre: 'Sociedad anónima'     }, 
        { id:'otras'    , riesgo: 4, nombre: 'Otras'                }, 
    ],
    partidosPoliticosJuridico:    [
        { riesgo: 4, nombre: 'Cliente pertenece a partidos políticos'           }, 
    ],
    clienteJuridicoPEP:         [
        { nombre: 'Cliente PEP', riesgo: 4 }
    ],
    rangosEdadJuridico:         [
        { min: null,    max: 20,    riesgo: 4 , nombre: '18-20'                 },
        { min: 21,      max: 30,    riesgo: 3 , nombre: '21-30'                 },
        { min: 31,      max: 65,    riesgo: 2 , nombre: '31-65'                 },
        { min: 66,      max: null,  riesgo: 4 , nombre: '66+'                   },
    ],
    // * * * Factores de ubicación * * *
    departamentos:              [
        { nombre: 'Alta Verapaz',   id: '16',   riesgo: 4 },
        { nombre: 'Baja Verapaz',   id: '15',   riesgo: 4 },
        { nombre: 'Chimaltenango',  id: '03',   riesgo: 4 },
        { nombre: 'Chiquimula',     id: '20',   riesgo: 2 },
        { nombre: 'Petén',          id: '17',   riesgo: 4 },
        { nombre: 'El Progreso',    id: '04',   riesgo: 2 },
        { nombre: 'Quiché',         id: '14',   riesgo: 4 },
        { nombre: 'Escuintla',      id: '05',   riesgo: 4 },
        { nombre: 'Huehuetenango',  id: '13',   riesgo: 4 },
        { nombre: 'Izabal',         id: '18',   riesgo: 3 },
        { nombre: 'Jalapa',         id: '21',   riesgo: 3 },
        { nombre: 'Jutiapa',        id: '22',   riesgo: 4 },
        { nombre: 'Quetzaltenango', id: '09',   riesgo: 4 },
        { nombre: 'Retalhuleu',     id: '11',   riesgo: 3 },
        { nombre: 'Sacatepéquez',   id: '02',   riesgo: 4 },
        { nombre: 'San Marcos',     id: '12',   riesgo: 4 },
        { nombre: 'Santa Rosa',     id: '06',   riesgo: 2 },
        { nombre: 'Sololá',         id: '07',   riesgo: 2 },
        { nombre: 'Suchitepéquez',  id: '10',   riesgo: 4 },
        { nombre: 'Totonicapán',    id: '08',   riesgo: 2 },
        { nombre: 'Zacapa',         id: '19',   riesgo: 4 },
        { nombre: 'Guatemala',      id: '01',   riesgo: 2 },
    ],
    // * * * Factores de Transacción * * *
    tiposVenta:              [
        { nombre: 'Contado',               id: 'contado',   riesgo: 3 },
        { nombre: 'Crédito Bancario',      id: 'credito',   riesgo: 1 },
        { nombre: 'Crédito interno',       id: 'propio',    riesgo: 3 },
        { nombre: 'Leasing',               id: 'leasing',   riesgo: 1 },
    ],
    ubicacionesPago:              [
        { nombre: 'CAJAS PROPIAS DE LA EMPRESA',    id: 'cajas-propias',    riesgo: 3 },
        { nombre: 'ENTIDAD BANCARIA',               id: 'entidad-bancaria', riesgo: 1 },
        { nombre: 'CAJAS RURALES/ TIENDAS',         id: 'cajas-rurales',    riesgo: 3 },
        { nombre: 'OTROS',                          id: 'otros',            riesgo: 3 },
    ],
    formasMedioPago:              [
        { nombre: 'Efectivo en cajas',                              id: 'efectivo-caja',                riesgo: 3 },
        { nombre: 'Efectivo en bancos',                             id: 'efectivo-bancos',              riesgo: 3 },
        { nombre: 'Cheques',                                        id: 'cheques',                      riesgo: 2 },
        { nombre: 'Transferencias',                                 id: 'transferencias',               riesgo: 1 },
        { nombre: 'Tarjeta de crédito',                             id: 'tarjetas-credito',             riesgo: 1 },
        { nombre: 'Recepción de vehículo usado marcas propias',     id: 'recepcion-vehiculo-propias',   riesgo: 2 },
        { nombre: 'Recepción de vehículo usado otras marcas',       id: 'recepcion-vehiculo-otras',     riesgo: 3 },
        { nombre: 'Notas de crédito',                               id: 'nota-credito',                 riesgo: 3 },
        { nombre: 'Devoluciones',                                   id: 'devolucion',                   riesgo: 3 },
        { nombre: 'CAJA RURAL',                                     id: 'caja-rural',                   riesgo: 3 },
        { nombre: 'DEPÓSITOS CHEQUES PROPIOS/OTROS BCOS',           id: 'deposito',                     riesgo: 1 },
    ],
    // * * * Factores de canal * * *
    agencias:              [
        { nombre: 'Agencia Central',                riesgo: 2, idDepartamento: '01', id: 'agencia-central'              ,   codigoSucursal: '01'},
        { nombre: 'Agencia Majadas',                riesgo: 2, idDepartamento: '01', id: 'agencia-majadas'              ,   codigoSucursal: '02'},
        { nombre: 'Agencia Condado Concepción',     riesgo: 2, idDepartamento: '01', id: 'agencia-condado-concepcion'   ,   codigoSucursal: '03'},
        { nombre: 'Agencia Xela',                   riesgo: 4, idDepartamento: '09', id: 'agencia-xela'                 ,   codigoSucursal: '04'},
    ],
    // * * * Factores estructurales * * *
    estructura: [
        { nombre: 'Ventas anuales',         id: 'ventas-anuales',       valor: null },
        { nombre: 'Número de empleados',    id: 'numero-empleados',     valor: null },
        { nombre: 'ENR',                    id: 'enr',                  valor: null },
    ],
    ventasAnuales:              [
        { min: null,    max: 190,       riesgo: 1 , nombre: '1 a 190'               },
        { min: 191,     max: 3700,      riesgo: 2 , nombre: '191 a 3700'            },
        { min: 3701,    max: 15420,     riesgo: 3 , nombre: '3701 a 15,420'         },
        { min: 15421,   max: null,      riesgo: 4 , nombre: '15,421+'               },
    ],
    numeroEmpleados:              [
        { min: null,    max: 10,        riesgo: 1 , nombre: '1 a 10'      },
        { min: 11,      max: 80,        riesgo: 2 , nombre: '11 a 80'     },
        { min: 81,      max: 200,       riesgo: 3 , nombre: '81 a 200'    },
        { min: 201,     max: null,      riesgo: 4 , nombre: '201+'        },
    ],
    // * * * Factores estructurales * * *
    factoresMitigacion: [],
}
