import { Component, OnInit, Input, ChangeDetectorRef, HostListener, Output, EventEmitter, TemplateRef } from '@angular/core';
import { LibraryService } from '../library.service';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

// Formato de fechas
import * as _moment from 'moment';
import { AuthService } from '../../auth/auth.service';
import { Subscription } from 'rxjs';
const moment = _moment;
import iconSearch from '@iconify/icons-ic/twotone-search';
import iconAdd from '@iconify/icons-ic/twotone-add';
import iconAccion from '@iconify/icons-fa-solid/check';
import { SelectionType } from '@swimlane/ngx-datatable';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { APIUrls } from 'src/app/api/apiUrls';
import { DialogGastos } from 'src/app/ahorrent/dialog-crud-gastos/dialog-crud-gastos.component';
import { MatDialog } from '@angular/material/dialog';
import { JSONConverters } from 'src/app/models/JSONConverters';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

@Component({
    selector: 'app-busqueda-tabla',
    templateUrl: './busqueda-tabla.component.html',
    styleUrls: ['./busqueda-tabla.component.scss'],
    providers: [
        //{ provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        // { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class BusquedaTablaComponent implements OnInit {
    @Input() url: string;
    @Input() paramsEspeciales: string;
    @Input() campoRegistros: string;
    @Input() campos: any[];
    @Input() columnas: any[];
    @Input() filtros: any[];
    @Input() acciones: any[];
    @Input() parser: Function;
    @Input() parserService: any;
    @Input() UsuarioId: number;
    @Input() estado: string;
    @Input() campoDefault: string;
    @Input() valorDefault: string;
    @Input() fechaInicioInput: string;
    @Input() fechaFinInput: string;
    @Input() ignorarRuta: boolean = false;
    @Input() activarResaltado: boolean = false;
    @Input() debeResaltar: Function;
    @Input() botonNuevo: boolean;
    @Input() rutaNuevo: string;
    @Input() routerClick: string;
    @Input() dialogGastoServicioClick: boolean = false;
    @Input() limite: number = 15;
    @Input() tamanioPagina: number[] = [15,50,100];
    @Input() incluirHoraEnParams: boolean = false;
    @Input() esconderSiVacio: boolean = false;
    @Input() modoSelect: boolean = false;
    @Input() hoverClass: boolean = false;
    @Input() usarTemplate: boolean = false;
    @Input() rangoFechasVisible: boolean = false;
    @Input() setPaginator: boolean = true;
    @Input() esconderBusqueda: boolean = false;
    @Input() verificarRutaFormulario: boolean = false;
    @Input() forzarResponsive: boolean = false;
    @Input() nuevaAccion: boolean = false;
    @Input() soloMostrarIconoBusqueda: boolean = false;
    @Input() allObjeto: boolean = false;
    @Input() ignorarAlertas: boolean = false;
    @Input() templateRecord: TemplateRef<any>;
    @Output() aplicarAccion: EventEmitter<any> = new EventEmitter();
    @Output() actualizarData: EventEmitter<any> = new EventEmitter();
    @Output() actualizarDataParseada: EventEmitter<any> = new EventEmitter();
    @Output() clickAction: EventEmitter<any> = new EventEmitter();
    @Output() selectUpdate: EventEmitter<any> = new EventEmitter();
    @Output() nuevoAction: EventEmitter<any> = new EventEmitter();
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = window.innerWidth;
    }
    campoActual: any;
    categoriaId: number;
    busqueda: string;
    fechaInicio: Date;
    fechaFin: Date;
    fechaInicioParam: string;
    fechaFinParam: string;
    fechaInicioParamDate: Date;
    fechaFinParamDate: Date;
    datos: any[];
    accionActual: any;
    paginaActual: number;
    total: number;
    min: number;
    max: number;
    cargando: boolean = false;
    subscriptions: Subscription;
    innerWidth: any;
    orderBy: string;
    direction: string;
    rowClass: string;
    filtrosCampos = {};
    camposFiltros = [];
    selected: any[] = [];
    SelectionType = SelectionType;
    apiURLs = new APIUrls();
    jsonConverters = new JSONConverters();

    // Íconos
    iconSearch = iconSearch;
    iconAdd = iconAdd;
    iconAccion = iconAccion;

    apiUrls = new APIUrls();

    constructor(
        private service: LibraryService,
        public authService: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private changeDetector: ChangeDetectorRef,
        private libraryService: LibraryService,
        public dialog: MatDialog,
    ) { }

    ngOnInit() {
        this.innerWidth = window.innerWidth;
        // Inicializar búsqueda
        if (this.campos && this.campos.length > 0) {
            this.campoActual = this.campos[0];
        }
        // Valores por default
        if(this.campoDefault) {
            let indexCampo = this.campos.findIndex(campo => campo.campo == this.campoDefault);
            if(indexCampo != -1) this.campoActual = this.campos[indexCampo];
        }
        if(this.valorDefault) {
            this.busqueda = this.valorDefault;
        }

        if (this.acciones && this.acciones.length > 0) {
            this.accionActual = this.acciones[0];
        }
        this.subscriptions = new Subscription();
        this.subscriptions.add(
            this.authService.buscadorReload.subscribe(event => {
                this.reiniciarBusqueda();
            })
        );
        this.subscriptions.add(
            this.authService.generarParams.subscribe(event => {
                var params = this.calcularParams();
                event(params);
            })
        );
        if (this.routerClick) {
            this.rowClass = 'hoverRow';
        }
        
        // Obtener params de historial de navegación
        if(this.router.url) {
            let partes = this.router.url.split('?');
            let params = this.authService.obtenerParamsRutaHistorial(partes[0]);
            if(params) {
                let url = this.router.createUrlTree([], { relativeTo: this.route, queryParams: params}).toString();
                this.location.go(url);
                this.asignarParams(params);
            }
            else {
                this.obtenerParamsDeURL();
            }
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    public reiniciarBusqueda() {
        this.cambioPagina(1);
    }

    async buscar() {
        try {
            this.cargando = true;
            // Calcular params
            var params = this.calcularParams();
    
            // Obtener registros
            var finalURL = this.url + params;
            var res = await this.service.obtenerRegistros(finalURL, this.parser.bind(this.parserService), this.campoRegistros);
    
            if (!res.error) {
                // Actualizar
                this.datos = res.data.registros;
                this.total = res.data.total;
    
                if(res.data.originalJSON.servicios) {
                    for (const unidad of this.datos) {
                        unidad.ServicioActual = this.jsonConverters.servicioUnidadDeJSON(res.data.originalJSON.servicios.find(servicio => servicio.UnidadId == unidad.id));
                    }
                }
    
                this.actualizarData.emit(res.data.originalJSON);
                this.actualizarDataParseada.emit(this.datos);
                this.cargando = false;
            }
            else {
                if(!this.ignorarAlertas) this.service.crearNotificacion(res.error.mensajeError, 'danger');
                this.cargando = false;
            }
        } catch(error) {
            this.cargando = false;
        }
    }

    convertirCaracteresAEscapeCode(texto: string) {
        let textoNuevo = texto;
        textoNuevo = textoNuevo.replace(/#/g, '%23');
        return textoNuevo;
    }

    /**
     * Calcula los params a adjuntar en un URL para hacer una búsqueda
     */
    calcularParams(): string {
        var queryParams: Params = Object.assign({}, this.route.snapshot.queryParams);
        var params = '?';

        // Página y límite
        params += '&page=' + this.paginaActual;
        queryParams['page'] = this.paginaActual;
        params += '&limit=' + this.limite;
        queryParams['limit'] = this.limite;

        if (this.orderBy) params += '&orderBy=' + this.orderBy;
        if (this.direction) params += '&direction=' + this.direction;

        // Campos de búsqueda
        queryParams['field'] = null;
        queryParams['value'] = null;
        queryParams['min'] = null;
        queryParams['max'] = null;
        if (this.campoActual) {
            if (this.campoActual.tipo === 'texto') {
                if (this.busqueda) {
                    if (this.busqueda.trim() != '') {
                        params += '&field=' + this.campoActual.campo;
                        queryParams['field'] = this.campoActual.campo;
                        params += '&value=' + this.convertirCaracteresAEscapeCode(this.busqueda);
                        queryParams['value'] = this.busqueda;
                    }
                }
            }
            else if (this.campoActual.tipo === 'categoria') {
                params += '&field=' + this.campoActual.campo;
                queryParams['field'] = this.campoActual.campo;
                params += '&value=' + this.categoriaId;
                queryParams['value'] = this.categoriaId;
            }
            else if (this.campoActual.tipo === 'rango-cantidades') {
                params += '&field=' + this.campoActual.campo;
                queryParams['field'] = this.campoActual.campo;
                if (this.min && this.min.toString().trim() != '') {
                    params += '&min=' + this.min;
                    queryParams['min'] = this.min;
                }
                if (this.max && this.max.toString().trim() != '') {
                    params += '&max=' + this.max;
                    queryParams['max'] = this.max;
                }
            } else if (this.campoActual.tipo === 'unico') {
                params += '&field=' + this.campoActual.campo;
                queryParams['field'] = this.campoActual.campo;
            }
        }

        // Rango de fechas
        queryParams['start'] = null;
        queryParams['finish'] = null;
        if (this.campoActual) {
            if (this.campoActual.tipo === 'rango-fechas') {
                params += '&field=' + this.campoActual.campo;
                queryParams['field'] = this.campoActual.campo;
                if (this.fechaInicioParam) {
                    if (this.incluirHoraEnParams) {
                        params += '&start=' + this.fechaInicioParam + ' 00:00:00';
                    }
                    else {
                        params += '&start=' + this.fechaInicioParam;
                    }
                    queryParams['start'] = this.fechaInicioParam;
                }
                if (this.fechaFinParam) {
                    if (this.incluirHoraEnParams) {
                        params += '&finish=' + this.fechaFinParam + ' 23:59:59';
                    }
                    else {
                        params += '&finish=' + this.fechaFinParam;
                    }
                    queryParams['finish'] = this.fechaFinParam;
                }
            }
        }

        if (this.fechaInicioInput) {
            queryParams['start'] = this.fechaInicioInput;
            params += '&start=' + this.fechaInicioInput;
        }
        if (this.fechaFinInput) {
            queryParams['finish'] = this.fechaFinInput;
            params += '&finish=' + this.fechaFinInput;
        }

        // Estado
        if (this.estado) params += '&estado=' + this.estado;

        // Usuario
        if (this.UsuarioId) params += '&UsuarioId=' + this.UsuarioId;

        // Filtros
        if (this.filtrosCampos) {
            const keys = Object.keys(this.filtrosCampos);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                var valor = this.filtrosCampos[key];
                if (valor && key) {
                    params += '&' + key + '=' + valor;
                }
            }
        }

        // Parámetros especiales
        if (this.paramsEspeciales) params += this.paramsEspeciales;

        // Actualizar params en URL
        if (!this.ignorarRuta) {
            var url = this.router.createUrlTree([], { relativeTo: this.route, queryParams: queryParams }).toString();
            this.authService.agregarHistorialBuscador(this.router.url, queryParams);
            this.location.go(url);
        }

        this.authService.paramsUpdate.next(params);

        return params;
    }

    /**
     * Obtiene los params de la URL actual, actualiza los params del buscador y busca
     */
    obtenerParamsDeURL() {
        this.route.queryParams.subscribe((params: Params) => {
            this.asignarParams(params);
        });
    }

    asignarParams(params: Params) {
        // Número de página
        if (params['page']) this.paginaActual = parseInt(params['page']);
        if (params['limit']) this.limite = parseInt(params['limit']);
        else this.paginaActual = 1;

        // Campo de búsqueda
        var campo = params['field'];
        if (campo) {
            // Buscar el campo correcto
            for (let i = 0; i < this.campos.length; i++) {
                const element = this.campos[i];
                if (campo === element.campo) {
                    this.campoActual = element;
                    i = this.campos.length;
                }
            }

            // Asignar valor
            switch (this.campoActual.tipo) {
                case 'texto': {
                    this.busqueda = params['value'];
                    break;
                }
                case 'rango-cantidades': {
                    this.min = parseFloat(params['min']);
                    this.max = parseFloat(params['max']);
                    break;
                }
                case 'categoria': {
                    if (this.campoActual.idNoNumerico) this.categoriaId = params['value'];
                    else this.categoriaId = parseInt(params['value']);
                    break;
                }
                case 'rango-fechas': {
                    this.fechaInicioParam = params['start'];
                    this.fechaFinParam = params['finish'];
                    this.fechaInicio = this.service.convertirFecha(this.fechaInicioParam, 'YYYY-MM-DD', 'date');
                    this.fechaFin = this.service.convertirFecha(this.fechaFinParam, 'YYYY-MM-DD', 'date');
                }
            }
        }
        this.buscar();
    }

    changing($event) {
        this.categoriaId = $event;
    }

    /**
     * Cambia de página y busca
     * @param pagina Número de página al cual dirigirse
     */
    cambioPagina(pagina) {
        this.paginaActual = pagina.pageIndex + 1;
        this.limite = pagina.pageSize;
        this.buscar();
    }

    actualizarFechaInicio($event) {
        this.fechaInicioParam = this.service.convertirFecha($event.value, 'date', 'YYYY-MM-DD');
    }

    actualizarFechaFin($event) {
        this.fechaFinParam = this.service.convertirFecha($event.value, 'date', 'YYYY-MM-DD');
        this.cambioPagina(1);
    }

    onSort(event) {
        if (event && event.column) {
            this.orderBy = event.column.prop;
            //this.direction = event.newValue;
            if(this.direction == 'asc') this.direction = 'desc';
            else this.direction = 'asc';
        }
        else {
            this.orderBy = null;
            this.direction = null;
        }
        this.cambioPagina(1);
    }

    getRowClass(row) {
        return {
            'hoverRow': this.routerClick != null || this.hoverClass,
            'filaResaltada': this.activarResaltado && this.debeResaltar(row),
        };
    }

    /**
     * Inicializa el campo actual después de seleccionarlo
     */
    inicializarCampoActual() {
        if (this.campoActual.tipo === 'categoria') {
            if (this.campoActual.categorias && this.campoActual.categorias.length > 0) {
                this.categoriaId = this.campoActual.categorias[0][this.campoActual.categoriaId];
            }
        }

        if (this.rangoFechasVisible) this.fixFechas(this.campoActual.campo);
    }

    fixFechas(campo) {
        switch (campo) {
            case 'mes-actual': {
                var hoy = new Date();
                var hoyTZ = this.service.convertirFechaATimezone(hoy, 'YYYY-MM-DD', this.apiUrls.defaultTimezone);
                var primerUltimoDiaMes = this.service.primerYUltimoDiaDelMes(hoyTZ);
                this.fechaInicioParamDate = this.service.convertirFecha(primerUltimoDiaMes.primerDia + ' 00:00:00', 'date', 'YYYY-MM-DD');
                this.fechaFinParamDate = new Date(this.service.convertirFecha(primerUltimoDiaMes.ultimoDia + ' 23:59:59', 'date', 'YYYY-MM-DD'));
                break;
            }
            case 'mes-anterior': {
                var d = new Date();
                var m = d.getMonth();
                d.setMonth(d.getMonth() - 1);
                if (d.getMonth() == m) d.setDate(0);
                d.setHours(0, 0, 0);
                d.setMilliseconds(0);
                var haceUnMesTZ = this.service.convertirFechaATimezone(d, 'YYYY-MM-DD', this.apiUrls.defaultTimezone);
                var primerUltimoDiaMesAnterior = this.service.primerYUltimoDiaDelMes(haceUnMesTZ);
                this.fechaInicioParamDate = this.service.convertirFecha(primerUltimoDiaMesAnterior.primerDia + ' 00:00:00', 'date', 'YYYY-MM-DD');
                this.fechaFinParamDate = this.service.convertirFecha(primerUltimoDiaMesAnterior.ultimoDia + ' 23:59:59', 'date', 'YYYY-MM-DD');
                break;
            }
            case 'ano-actual': {
                var hoy = new Date();
                var hoyTZ = this.service.convertirFechaATimezone(hoy, 'YYYY-MM-DD', this.apiUrls.defaultTimezone);
                var primerUltimoDiaAno = this.service.primerYUltimoDiaDelAno(hoyTZ);
                this.fechaInicioParamDate = this.service.convertirFecha(primerUltimoDiaAno.primerDia + ' 00:00:00', 'date', 'YYYY-MM-DD');
                this.fechaFinParamDate = this.service.convertirFecha(primerUltimoDiaAno.ultimoDia + ' 23:59:59', 'date', 'YYYY-MM-DD');
                break;
            }
            case 'fecha': {
                this.fechaInicioParamDate = this.service.convertirFecha(this.fechaInicioParamDate + ' 00:00:00', 'date', 'YYYY-MM-DD');
                this.fechaFinParamDate = this.service.convertirFecha(this.fechaFinParamDate + ' 23:59:59', 'date', 'YYYY-MM-DD');
                break;
            }
        }
    }

    modoResponsive() {
        if(this.forzarResponsive) return true;
        if (!this.innerWidth) return false;
        return this.innerWidth <= 768;
    }

    async onActivate($event) {
        if ($event && $event.type == 'click' && !$event.column.checkboxable) {
            if (this.routerClick) {
                var row = $event.row;
                if (this.verificarRutaFormulario) {
                    let ruta = await this.authService.obtenerRutaFormulario(row['id']);
                    this.router.navigate([ruta]);
                }
                else this.router.navigate([this.routerClick + row['id']]);
            }
            else if(this.dialogGastoServicioClick){
                var row = $event.row;
                this.abrirDialogGasto(row);
            }
            else {
                var row = $event.row;
                if(this.allObjeto) this.clickAction.emit(row);
                else this.clickAction.emit(row['id']);
            }
        }
    }

    abrirDialogGasto(gastoServicio){

        const dialogRef = this.dialog.open(DialogGastos, {
            data: {
                modoCreacion: false,
                modoDetalle: false,
                modoEdicion: true,
                registroId: gastoServicio.id,
                ServicioUnidadId: gastoServicio.ServicioUnidadId,
                UsuarioId: -1
            }
            });
    
            dialogRef.afterClosed().subscribe(result => {
            if (result == 'confirmar') {
                this.ngOnInit();
            }
            
        });

    }

    // * * * Filtros * * * 
    actualizarCamposFiltros($event) {
        var values = $event.value;
        for (let i = 0; i < this.filtros.length; i++) {
            if (values.indexOf(this.filtros[i].campo) != -1) {
                this.filtros[i].mostrar = true;
            }
            else {
                this.filtros[i].mostrar = false;
                this.filtros[i].valor = null;
                this.filtrosCampos[this.filtros[i].campo] = null;
            }
        }
    }

    agregarFiltroTexto(filtro) {
        var campo = filtro.campo;
        this.filtrosCampos[campo] = filtro.valor;
    }

    // Agrega el elemento seleccionado a los campos de filtro 
    agregarFiltroAutocompleteUnico(filtro, $event) {
        var campo = filtro.campo;
        if (!$event || $event.length == 0) {
            // Si no seleccionó nada, borrar el campo
            this.filtrosCampos[campo] = null;
        }
        else {
            this.filtrosCampos[campo] = $event[0][filtro.idProp];
        }
    }

    // Agrega el elemento seleccionado a los campos de filtro 
    agregarFiltroAutocompleteMultiple(filtro, $event) {
        var campo = filtro.campo;
        if (!$event || $event.length == 0) {
            // Si no seleccionó nada, borrar el campo
            this.filtrosCampos[campo] = null;
        }
        else {
            this.filtrosCampos[campo] = (($event as []).map(registro => registro[filtro.idProp])).toString();
        }
    }

    // Agrega el elemento seleccionado a los campos de filtro 
    agregarFiltroSelectUnico(filtro, $event) {
        var campo = filtro.campo;
        this.filtrosCampos[campo] = $event.value;
    }

    agregarFiltroSelectMultiple(filtro, $event) {
        var campo = filtro.campo;
        this.filtrosCampos[campo] = $event.value.toString();
    }

    agregarFiltroFecha(filtro) {
        var campo = filtro.campo;
        this.filtrosCampos[campo] = this.libraryService.convertirFecha(filtro.valor._d, 'date', 'YYYY-MM-DD');
    }

    agregarFiltroFechaInicio($event, filtro) {
        this.filtrosCampos['start' + filtro.campo] = this.service.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
    }

    agregarFiltroFechaFin($event, filtro) {
        this.filtrosCampos['finish' + filtro.campo] = this.service.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
    }

    // * * * Acciones * * *
    enviarAccion() {
        if (this.selected && this.selected.length > 0 && this.accionActual) {
            var ids = [];
            for (let i = 0; i < this.selected.length; i++) {
                ids.push(this.selected[i]['id']);
            }
            this.aplicarAccion.emit({ accion: this.accionActual, seleccionados: this.selected, ids: ids });
        }
    }

    // * * * Accion para nuevo * * *
    nuevoAccion(){
        this.nuevoAction.emit();   
    }

    onSelect({ selected }) {
        this.selected.splice(0, this.selected.length);
        this.selected.push(...selected);
        this.selectUpdate.emit(this.selected);
    }
}
