import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { HttpClientModule } from '@angular/common/http';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import { AuthModule } from './auth/auth.module';
import { APIModule } from './api/api.module';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LibraryModule } from './library/library.module';
import { QuillModule } from 'ngx-quill';
import { JoditAngularModule } from 'jodit-angular';
import { ColorPickerModule } from 'ngx-color-picker';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MomentDateModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        JoditAngularModule,
        ColorPickerModule,

        // Vex
        VexModule,
        CustomLayoutModule,
        QuillModule.forRoot(),

        // Módulos
        AuthModule,
        APIModule,
        LibraryModule,

        // Calendar
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
    ],
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'D/M/YYYY',
                },
                display: {
                    dateInput: 'D/M/YYYY',
                    dateA11yLabel: 'D/M/YYYY',
                    monthYearLabel: 'M/YYYY',
                    monthYearA11yLabel: 'M/YYYY',
                },
            }
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
