import { PreguntaEncuestaAhorrent } from "./PreguntaEncuestaAhorrent";

export class EncuestaAhorrent {
    constructor(
        public titulo: string,
        public descripcion: string,
    ) { }

    // PreguntasEncuesta
    PreguntasEncuesta: PreguntaEncuestaAhorrent[] = [];
}