import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import iconClose from '@iconify/icons-ic/twotone-close';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DialogSugerenciasComponent } from '../dialog-sugerencias/dialog-sugerencias.component';


@Component({
  selector: 'mat-autocomplete-popup',
  templateUrl: './mat-autocomplete-popup.component.html',
  styleUrls: ['./mat-autocomplete-popup.component.scss']
})
export class MatAutocompletePopupComponent implements OnInit {

    @Input() placeholder: string;
    @Input() searchPlaceholder: string = 'Buscar';
    @Input() idProp: string = 'id';
    @Input() fieldSearch: any[];
    @Input() titleProp: string = 'title';
    @Input() subtitleProp: string;
    @Input() records: any[];
    @Input() disabled: boolean;
    @Input() seleccionado: any;
    @Input() defaultRecord: any;
    @Input() url: string;
    @Input() paramsEspeciales: string;
    @Input() parser: Function;
    @Input() parserService: any;
    @Input() multiSelect: boolean = true;
    @Input() columnas: any[];
    @Output() selectionChange: EventEmitter<any> = new EventEmitter();
    separatorKeysCodes: number[] = [ENTER, COMMA];
    iconClose = iconClose;

    recordsQuery: any[];
    query: string;
    cargando: boolean = false;
    recordsForm: FormGroup;
    show:boolean = false;

    registro:any="";

    constructor(
        public dialog: MatDialog
    ) { }

    ngOnInit() {
        if(this.seleccionado && this.titleProp) this.registro = this.seleccionado[this.titleProp];
    }

    showPopUp(){
        if(!this.disabled){
            let dialogRef = this.dialog.open(DialogSugerenciasComponent, {
                data: {
                    columnas: this.columnas,
                    fieldSearch: this.fieldSearch,
                    url: this.url,
                    placeholder: this.placeholder,
                    parser: this.parser
                }
            });
            dialogRef.afterClosed().subscribe(async result => {
                if(result) {
                    this.registro = result[this.titleProp];
                    this.selectionChange.emit(result);
                }
            });
        }
    }

}
