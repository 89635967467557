import { MarcaVehiculo } from './marcaVehiculo';
import { LineaVehiculo } from './lineaVehiculo';
import { Usuario } from './usuario';
import { Cliente } from './cliente';
export class ProspectoLead {
  constructor(
      public id: number,
      public canalIngreso: string,
      public canalWeb: string,

      public MarcaVehiculoRequeridoId: number,
      public LineaVehiculoId: number,

      public modeloRequerido: string,
      public placaRequerida: string,
      
      public MarcaVehiculoActualId: number,
      
      public observaciones: string,
      
      public fechaSeguimiento: string,
      public fechaRegistro: string,
      
      public UsuarioAsesorId: number,
      
      public estado: string,

      public unidad: string,

      public estatusCita: string,
      public opcionPago: string,
      public institucionFinanciera: string,
      public motivoDesistimiento: string,

      public incluyeMarcaActual: boolean,
      
      public ClienteId: number,
      public EmpresaId: number,
      public UsuarioCreadorId: number,
  ) { }

  public MarcaRequerida: MarcaVehiculo;
  public LineaRequerida: LineaVehiculo;
  public MarcaActual: MarcaVehiculo;
  public UsuarioAsesor: Usuario;
  public UsuarioCreador: Usuario;
  public Cliente: Cliente;

  fechaSeguimientoFormato:string;
  fechaSeguimientoDate:Date;
  
  fechaRegistroFormato:string;
  fechaRegistroDate:Date;

  estadoFormat:string;

}