import { Injectable } from '@angular/core';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { JSONConverters } from '../models/JSONConverters';
import { Producto } from '../models/producto';

@Injectable()
export class ProductosService { 
    apiUrls: APIUrls = new  APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
    ) { }

    // * * * Productos * * * 
    async obtenerTodosProductos(EmpresaId: number, feic: boolean, avaluos: boolean) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.productosURL + '?';
            if(feic) url += '&feic=1';
            if(avaluos) url += '&avaluos=1';
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                var element = json[i];
                var registro = this.jsonConverters.productoDeJSON(element);
                registros.push(registro);
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerProductoPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.productosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.productoDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearProducto(registro: Producto) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.productosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Producto creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarProducto(registro: Producto) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.productosURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Producto actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarProducto(ProductoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.productosURL + '/id/' + ProductoId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Producto eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

}