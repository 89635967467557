import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CanActivate } from '@angular/router';
// Import our authentication service
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardPermisos implements CanActivate {

    constructor(
        public auth: AuthService,
        private router: Router
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if(!this.auth.authGuardLoggedIn()) return false;
        let data = route.data;
        var tienePermiso = false;
        data.permisos.forEach(permiso => {
            tienePermiso = tienePermiso || this.auth.tienePermisoPara(permiso);
        });
        return tienePermiso;
    }
}