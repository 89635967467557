export class ContenidoFEIC {
    constructor(
        // Paso 1
        public actuaNombrePropio:           boolean = true,
        public calidadActuacion:            string = null,
        public tipoNegociacion:             string = null,
        // Paso 2
        public pais:                        string = null,
        public departamento:                string = null,
        public municipio:                   string = null,
        public agencia:                     string = null,
        public nombreAgencia:               string = null,
        public codigoSucursal:              string = null,
        // Paso 3
        public actualizarDatosCliente:      boolean = false,
        public numeroCodigo:                string = null,
        public primerApellido:              string = null,
        public segundoApellido:             string = null,
        public apellidoCasada:              string = null,
        public primerNombre:                string = null,
        public segundoNombre:               string = null,
        public otrosNombres:                string = null,
        public otros:                       string = null,
        public fechaNacimiento:             string = null,
        public paisNacimiento:              string = null,
        public departamentoNacimiento:      string = null,
        public municipioNacimiento:         string = null,
        public condicionMigratoria:         number = null,
        public otraCondicionMigratoria:     string = null,
        public edad:                        number = null,
        public nacionalidad:                string = null,
        public otraNacionalidad:            string = null,
        public sexo:                        number = null,
        public estadoCivil:                 number = null,
        public profesion:                   string = null,
        public documentoIdentificacion:     number = null,
        public numeroIdentificacion:        string = null,
        public emisionPasaporte:            string = null,
        public nit:                         string = null,
        public telefono1:                   string = null,
        public telefono2:                   string = null,
        public correoElectronico:           string = null,
        public direccionResidencia:         string = null,
        public paisResidencia:              string = null,
        public departamentoResidencia:      string = null,
        public municipioResidencia:         string = null,
        public existeRiesgo:                boolean = false,
        // Paso 3(Jurídico)
        public nombreComercialJuridico:                 string = null,
        public fechaNacimientoJuridico:                 string = null,
        public nacionalidadJuridico:                    string = null,
        public otraNacionalidadJuridico:                string = null,
        public nitJuridico:                             string = null,
        public telefonoJuridico:                        string = null,
        public celularJuridico:                         string = null,
        public emailJuridico:                           string = null,
        public direccionJuridico:                       string = null,
        public zonaJuridico:                            number = null,
        public departamentoJuridico:                    string = null,
        public municipioJuridico:                       string = null,
        public paisJuridico:                            string = null,
        public nombreJuridico:                          string = null,
        public perteneceOrganizacionGubernamental:      boolean = false,
        public esFundacion:                             boolean = false,
        public esOrganizacionSinLucro:                  boolean = false,
        public esEmbajada:                              boolean = false,
        public empresasRelacionadas:                    string = null,
        public tipoOrganizacion:                        string = null,
        public numeroEscrituraPublica:                  string = null,
        public notarioEscrituraPublica:                 string = null,
        public numeroPatenteComercio:                   string = null,
        public folioPatenteComercio:                    string = null,
        public libroPatenteComercio:                    string = null,
        // Representante legal
        public plazoNombramientoRepLegal:               string = null,
        public fechaInicialRepLegal:                    string = null,
        public fechaFinalRepLegal:                      string = null,
        public nombreAutorizaRepLegal:                  string = null,
        public cargoNombradoRepLegal:                   string = null,
        public numeroRegistroRepLegal:                  string = null,
        public folioRegistroRepLegal:                   string = null,
        public libroRegistroRepLegal:                   string = null,
        public nombreRegistranteRepLegal:               string = null,
        public datosPersonalesRepresentante:            DatosPersonales = null,
        // Paso 4
        public clienteCPE:                              boolean = null,
        public clientePEPOParentesco:                   boolean = null,
        public origenRiqueza:                           string = null,
        public especifiqueOrigenRiqueza:                string = null,
        public cargosPEP:                               CargoPEP[] = [],
        public familiaresAsociadosPEP:                  FamiliarAsociadoPEP[] = [],
        // Paso 5
        public trabajaParaEstado:                       boolean = null,
        public esContratistaEstado:                     boolean = null,
        public pertenecePartidoPolitico:                boolean = null,
        public trabajaParaEstadoEspecificacion:         string = null,
        public esContratistaEstadoEspecificacion:       string = null,
        public pertenecePartidoPoliticoEspecificacion:  string = null,
        // Paso 6 individual
        public establecimientoInformacion:              string = 'I',
        public tipoModificacion:                        number = null,
        public tipoMonedaIngresos:                      string = 'GTQ',
        public montoMensual:                            number = null,
        public propositoRelacionComercial:              string = null,
        public actividadEconomicaRTU:                   string = null,
        public fuentesIngresos:                         FuenteIngreso[] = [],
        public origenRiquezaCliente:                    string = null,
        public tipoFuenteIngreso:                       number = null,
        public otraFuenteIngreso:                       string = null,
        // Paso 6 jurídico:
        public egresosMensual:                          number = null,
        public subsidiarias:                            number = null,
        public empleados:                               number = null,
        public nombreProveedor:                         string = null,
        public paisProveedor:                           string = null,
        public nombreCliente:                           string = null,
        public paisCliente:                             string = null,
        // Paso 7
        public productos:                               ProductoServicio[] = [],
        public realizaTransferencia:                    boolean = false,
        public transferencia:                           string = null,
        public propositoProducto:                       string = null,
        public empresaPropiedad:                        number = null,
        public empresaSolicitante:                      DatosPersonaJuridica = null,
        // Paso 8
        public perfilesTransaccionales:                 PerfilTransaccional[] = [],
        public fondosPropios:                           number = null,
        public fondosTercero:                           string = null,
        public personasIndividualesProveenFondos:       DatosPersonales[] = [],
        public personasJuridicasProveenFondos:          DatosPersonaJuridica[] = [],
        public participacionAccionaria:                 string = null,
        public estructuraAccionaria:                    DatosEstructuraAccionaria[] = [],
        public organoAdministrativo:                    DatosOrganoAdministrativo[] = [],
        // Paso 9
        public tipoVenta:                               string = null,
        public cajasPropiasEmpresa:                     boolean = false,
        public entidadBancaria:                         boolean = false,
        public cajasRuralesTiendas:                     boolean = false,
        public dondePagoOtros:                          boolean = false,
        public formaPagoEfectivo:                       boolean = false,
        public formaPagoCheque:                         boolean = false,
        public formaPagoTransferencia:                  boolean = false,
        public formaPagoTarjeta:                        boolean = false,
        public formaPagoDepositos:                      boolean = false,
        public formaPagoUnidadUsada:                    boolean = false,
        public formaPagoOtros:                          boolean = false,
        public formaPagoEspecifique:                    string = null,
        public tipoMonedaPagoEfectivo:                  string = 'GTQ',
        public montoPagoEfectivo:                       number = null,
        // Paso 10
        public referencias:                             Referencia[] = [],
        // Paso 11
        public factoresProducto:            FactoresProducto = new FactoresProducto(),
        public factoresClienteIndividual:   FactoresClienteIndividual = new FactoresClienteIndividual(),
        public factoresClienteJuridico:     FactoresClienteJuridico = new FactoresClienteJuridico(),
        public factoresTransaccion:         FactoresTransaccion = new FactoresTransaccion(),
        public factoresEstructurales:       FactoresEstructurales = new FactoresEstructurales(),
    ){}

    fechaInicialRepLegalDate: Date;
    fechaInicialRepLegalFormato: string;
    fechaFinalRepLegalDate: Date;
    fechaFinalRepLegalFormato: string;
    
    fechaNacimientoDate: Date;
    fechaNacimientoFormato: string;

    fechaNacimientoJuridicoDate: Date;
    fechaNacimientoJuridicoFormato: string;
}

export class FactoresEstructurales {
    constructor(
        public riesgoVentasAnuales:     number = null,
        public riesgoNumeroEmpleados:   number = null,
        public riesgoENR:               number = null,
    ){}
}

export class FactoresTransaccion {
    constructor(
        public riesgoTipoVenta:         number = null,
        public riesgoDondePago:         number = null,
        public riesgoFormaPago:         number = null,
    ){}
}

export class FactoresProducto {
    constructor(
        public riesgoTipoNegociacion:   number = null,
        public riesgoPropositoDestino:  number = null,
        public riesgoMarca:             number = null,
        public riesgoRangoTiempo:       number = null,
        public riesgoLinea:             number = null,
    ){}
}

export class FactoresClienteIndividual {
    constructor(
        public riesgoNacionalidad:          number = null,
        public riesgoEdad:                  number = null,
        public riesgoClientePEP:            number = null,
        public riesgoFuentesIngreso:        number = null,
        public riesgoListaNegra:            number = null,
        public riesgoTercerasPersonas:      number = null,
        public riesgoProveedor:             number = null,
        public riesgoContratistaEstado:     number = null,
        public riesgoPartidosPoliticos:     number = null,
    ){}
}

export class FactoresClienteJuridico {
    constructor(
        public riesgoNacionalidad:                          number = null,
        public riesgoListaNegra:                            number = null,
        public riesgoAnioConstitucion:                      number = null,
        public riesgoPerteneceOrganizacionGubernamental:    number = null,
        public riesgoEsFundacion:                           number = null,
        public riesgoEsOrganizacionSinLucro:                number = null,
        public riesgoEsEmbajada:                            number = null,
        public riesgoEmpresasRelacionadas:                  number = null,
        public riesgoTercerasPersonas:                      number = null,
        public riesgoTipoOrganizacion:                      number = null,
        public riesgoPartidosPoliticos:                     number = null,
        public riesgoClientePEP:                            number = null,
        public riesgoEdad:                                  number = null,
    ){}
}

export class CargoPEP {
    constructor(
        public entidad:                     string = null,
        public puestoQueDesempena:          string = null,
        public paisEntidad:                 string = null,
    ){}
}

export class FamiliarAsociadoPEP {
    constructor(
        public tipo:                        string = null,
        public parentesco:                  string = null,
        public otroParentesco:              string = null,
        public motivoAsociacion:            string = null,
        public otroMotivoAsociacion:        string = null,
        public sexo:                        number = null,
        public condicion:                   string = null,
        public primerApellido:              string = null,
        public segundoApellido:             string = null,
        public apellidoCasada:              string = null,
        public primerNombre:                string = null,
        public segundoNombre:               string = null,
        public otrosNombres:                string = null,
        public entidadFamiliar:             string = null,
        public puestoQueDesempenaFamiliar:  string = null,
        public paisEntidadFamiliar:         string = null,
    ){}
}

export class FuenteIngreso {
    constructor(
        public tipo:                            number = 1,
        // Negocio propio
        public nombreComercial:                 string = null,
        public principalActividadEconomica:     string = null,
        public fechaInscripcionNegocio:         string = null,
        public numeroRegistroPatenteComercio:   string = null,
        public folioPatenteComercio:            string = null,
        public libroPatenteComercio:            string = null,
        public direccion:                       string = null,
        public telefono:                        number = null,
        public zona:                            number = null,
        public pais:                            string = null,
        public departamento:                    string = null,
        public municipio:                       string = null,
        public tipoMoneda:                      string = null,
        public montoIngresosMensuales:          number = null,
        public actividadEconomica:              string = null,
        public numeroSubsidiarias:              string = null,
        public numeroEmpleados:                 string = null,
        // Relación de dependencia
        public sector:                          string = null,
        public colaboradorEmpleado:             boolean = false,
        public puesto:                          string = null,
        // Otros ingresos
        public tipoIngreso:                     string = null,
        public descripcionIngresos:             string = null,
        // Otros Datos
        public proveedores:                     Proveedor[] = [],
    ){}
}

export class Proveedor {
    constructor(
        public nombreProveedor:                 string = null,
        public paisProveedor:                   string = null,
        public nombreCliente:                   string = null,
        public paisCliente:                     string = null,
    ){}
}

export class ProductoServicio {
    constructor(
        public fecha:                           string = null,
        public tipo:                            string = null,
        public nombre:                          string = null,
        public descripcion:                     string = null,
        public cantidad:                        number = null,
        public tipoMoneda:                      string = null,
        public valorUnitario:                   number = null,
        public pais:                            string = null,
        public departamento:                    string = null,
        public municipio:                       string = null,
        public identificadorProducto:           string = null,
        public nombreDeQuienContrata:           string = null,
        public claseVehiculo:                   string = null,
        public valorMonetario:                  number = null,
        public montoInicial:                    number = null,
        public montoMensual:                    number = null,
        public marca:                           string = null,
        public linea:                           string = null,
        public modelo:                          number = null,
        public numeroInventario:                string = null,
        public completado:                      boolean = false,
    ){}

    
}

export class PerfilTransaccional {
    constructor(
        public fechaElaboracion:                string = null,
        public productoServicio:                string = null,
        public tipoMoneda:                      string = null,
        public montoPromedioMensual:            number = null,
        public pais:                            string = null,
        public departamento:                    string = null,
        public municipio:                       string = null,
        public procedenciaFondos:               string = null,
    ){}
}

export class Referencia {
    constructor(
        public tipo:        string = null, // 1 personal, 2 laboral
        public nombre:      string = null,
        public telefono:    number = null,
        public celular:     number = null,
    ){}
}

export class DatosPersonales {
    constructor(
        // Datos personales
        public primerApellido:              string = null,
        public segundoApellido:             string = null,
        public apellidoCasada:              string = null,
        public primerNombre:                string = null,
        public segundoNombre:               string = null,
        public otrosNombres:                string = null,
        public otros:                       string = null,
        public fechaNacimiento:             string = null,
        public paisNacimiento:              string = null,
        public departamentoNacimiento:      string = null,
        public municipioNacimiento:         string = null,
        public condicionMigratoria:         number = null,
        public otraCondicionMigratoria:     string = null,
        public edad:                        number = null,
        public nacionalidad:                string = null,
        public otraNacionalidad:            string = null,
        public sexo:                        number = null,
        public estadoCivil:                 number = null,
        public profesion:                   string = null,
        public documentoIdentificacion:     number = null,
        public numeroIdentificacion:        string = null,
        public emisionPasaporte:            string = null,
        public nit:                         string = null,
        public telefono1:                   string = null,
        public telefono2:                   string = null,
        public correoElectronico:           string = null,
        public direccionResidencia:         string = null,
        public paisResidencia:              string = null,
        public departamentoResidencia:      string = null,
        public municipioResidencia:         string = null,
        public zona:                        string = null,
        // PEP
        public clienteCPE:                              boolean = null,
        public clientePEPOParentesco:                   boolean = null,
        public origenRiqueza:                           string = null,
        public especifiqueOrigenRiqueza:                string = null,
        public cargosPEP:                               CargoPEP[] = [],
        public familiaresAsociadosPEP:                  FamiliarAsociadoPEP[] = [],
    ){}

    fechaNacimientoDate: Date;
    fechaNacimientoFormato: string;
}

export class DatosPersonaJuridica {
    constructor(
        // Datos personales
        public nombreRazonSocial:               string = null,
        public nombreComercial:                 string = null,
        public paisJurisdiccionConstitucion:    string = null,
        public nit:                             string = null,
        public direccionResidencia:             string = null,
        public paisResidencia:                  string = null,
        public departamentoResidencia:          string = null,
        public municipioResidencia:             string = null,
        public zona:                            string = null,
        public numeroPatente:                   string = null,
        public folioPatente:                    string = null,
        public libroPatente:                    string = null,
    ){}
}

export class DatosEstructuraAccionaria {
    constructor(
        // Datos personales
        public tipoPersona:                     string = null,
        public primerNombre:                    string = null,
        public segundoNombre:                   string = null,
        public otrosNombres:                    string = null,
        public primerApellido:                  string = null,
        public segundoApellido:                 string = null,
        public apellidoCasada:                  string = null,
        public razonSocial:                     string = null,
        public participacion:                   number = null,
        public extranjero:                      string = null,
    ){}
}

export class DatosOrganoAdministrativo {
    constructor(
        // Datos personales
        public primerNombre:                    string = null,
        public segundoNombre:                   string = null,
        public otrosNombres:                    string = null,
        public primerApellido:                  string = null,
        public segundoApellido:                 string = null,
        public apellidoCasada:                  string = null,
        public cargo:                           string = null,
        public extranjero:                      string = null,
        public tipoDocumentoIdentificacion:     number = null,
        public numeroDocumentoIdentificacion:   string = null,
        public emisionPasaporte:                string = null,
    ){}
}