import { CampaniaPreventiva } from "./CampaniaPreventiva";
import { VehiculoCampania } from "./VehiculoCampania";

export class ReservacionCampania{
    constructor(
        public id: number,
        public nombre: string,
        public email: string,
        public fecha: string,
        public fechaPropuesta: string,
        public horaPropuesta: string,
        public telefono: string,
        //Relaiones
        public CampaniaPreventivaId: number,
        public VehiculoCampaniaId: number,
    ){}

    fechaFormato: string;
    fechaDate: Date;
    fechaFormatoPropuesta: string;
    fechaDatePropuesta: Date;

    CampaniaPreventiva: CampaniaPreventiva;
    VehiculoCampania: VehiculoCampania;

}