import { Injectable } from '@angular/core';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { JSONConverters } from '../models/JSONConverters';
import { Repuesto } from '../models/repuesto';

@Injectable({
  providedIn: 'root'
})
export class RepuestosService {

    apiUrls: APIUrls = new  APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
    ) { }

    // * * * Repuestos * * * 
    async obtenerRepuestoPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.repuestosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.repuestoDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearRepuesto(registro: Repuesto) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.repuestosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarRepuesto(registro: Repuesto) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.repuestosURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarRepuesto(RepuestoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.repuestosURL + '/id/' + RepuestoId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Repuesto eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Todas
    async obtenerTodas() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.repuestosURL;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.repuestoDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las repuestos.');
        }
    }

    // * * * * * * Archivos * * * * * *
    async guardarArchivoEnServidorRepuestos(archivo: File){
        try {//
            var url = this.apiUrls.uploadURL + this.apiUrls.usuariosURL + '/uploadArchivo';
            const res = await this.connection.uploadFile(url, archivo, true);
            if(!res.error) {
                return { error: null, data: { url: res.url } };
            }
            else throw new Error();
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al cargar el archivo.');
        }
    }
    async obtenerRepuestos(params: string = null) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.repuestosURL + '/search';
            if(params) url += '?' + params;
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let element of json.records) {
                registros.push(this.jsonConverters.repuestoDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las líneas.');
        }
    }
}
