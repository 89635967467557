import { Injectable } from '@angular/core';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { ClienteAhorrent } from '../models/clienteAhorrent';
import { JSONConverters } from '../models/JSONConverters';
import { Unidad } from '../models/unidad';
import { DocumentoCliente } from '../models/documentoCliente';
import { SeguimientoCliente } from '../models/seguimientoCliente1';
import { CambioEstadoUnidad } from '../models/cambioEstadoUnidad';
import { ServicioUnidad } from '../models/servicioUnidad';
import { GastoServicio } from '../models/gastoServicio';
import { ContratoAhorrent } from '../models/ContratoAhorrent';
import * as FileSaver from 'file-saver';
import * as Papa from 'papaparse';
import * as languageEncoding from 'detect-file-encoding-and-language';
import { CambioEstadoContratoAhorrent } from '../models/cambioEstadoContratoAhorrent';
import { ServicioPendienteUnidad } from '../models/ServicioPendienteUnidad';
import { MarcaAhorrent } from '../models/marcaAhorrent';
import { LineaAhorrent } from '../models/lineaAhorrent';
import { ClientePresupuesto } from '../models/ClientePresupuesto';
import { Presupuesto } from '../models/presupuesto';
import { PuntoPresupuesto } from '../models/PuntoPresupuesto';
import { ItemPuntoPresupuesto } from '../models/ItemPuntoPresupuesto';
import { CambioEstadoPresupuesto } from '../models/cambioEstadoPresupuesto';

@Injectable()
export class PresupuestoService { 
    apiUrls: APIUrls = new  APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
    ) {
        //Papa Promise
        Papa.parsePromise = async function (file) {
            let infoFile = function (file: File) {
                return new Promise((resolve, reject) => {
                    languageEncoding(file).then((fileInfo) => resolve(fileInfo));
                });
            } 

            // Obtener resultados del CSV
            let info: any = await infoFile(file);

            return new Promise(function (complete, error) {
                Papa.parse(file, {
                    header: true,
                    skipEmptyLines: true,
                    encoding: info.encoding,
                    complete,
                    error
                });
            });
        };
     }

    // * * * Unidades * * * 
    async obtenerTodasUnidades() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.unidadesURL;
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                var element = json[i];
                var registro = this.jsonConverters.unidadDeJSON(element);
                registros.push(registro);
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerUnidadPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.unidadesURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.unidadDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearUnidad(registro: Unidad) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.unidadesURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Unidad creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarUnidad(registro: Unidad) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.unidadesURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Unidad actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarUnidad(UnidadId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.unidadesURL + '/id/' + UnidadId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Unidad eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }


    // * * * Clientes presupuestos * * * 
    async obtenerTodasClientes() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.clientesPresupuestoURL;
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                var element = json[i];
                var registro = this.jsonConverters.clientePresupuestoDeJSON(element);
                registros.push(registro);
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerClientePresupuestoPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.clientesPresupuestoURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.clientePresupuestoDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearClientePresupuesto(registro: ClientePresupuesto) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.clientesPresupuestoURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Cliente Presupuesto creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarClientePresupuesto(registro: ClientePresupuesto) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.clientesPresupuestoURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Cliente Presupuesto actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarClientePresupuesto(ClientePresupuestoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.clientesPresupuestoURL + '/id/' + ClientePresupuestoId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Cliente Presupuesto eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }


        //Configuracion

        public async obtenerConfiguracion() {
            try {
                var url = this.apiUrls.baseURL + this.apiUrls.configuracionPresupuestoURL;
                const res = await this.connection.getRequest(url);
                var registro = this.jsonConverters.configuracionPresupuestoDeJSON(res.result);
                return { error: null, data: { result: registro } };
            } catch (error) {
                return this.connection.obtenerMensajeError(error.status, null);
            }
        }
    
        public async actualizarConfiguracion(registro) {
            try {
                var url = this.apiUrls.baseURL + this.apiUrls.configuracionPresupuestoURL;
                const res = await this.connection.putRequest(url, registro);
                return { error: null, data: { mensaje: 'Configuracion Presupuestos actualizado con éxito', result: res } };
            } catch (error) {
                return this.connection.obtenerMensajeError(error.status, error.error.message);
            }
        }


    // * * * Presupuestos * * *
    async obtenerTodosPresupuestos() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.presupuestosURL;
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                var element = json[i];
                var registro = this.jsonConverters.presupuestoDeJSON(element);
                registros.push(registro);
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerPresupuestoPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.presupuestosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.presupuestoDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearPresupuesto(registro: Presupuesto) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.presupuestosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Presupuesto creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarPresupuesto(registro: Presupuesto) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.presupuestosURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Presupuesto actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarPresupuesto(presupuestoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.presupuestosURL + '/id/' + presupuestoId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Presupuesto eliminado con éxito', result: res}};
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    //* Puntos Presupuestos* */
    async obtenerTodosPuntosPresupuestos(PresupuestoId = null) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.puntosPresupuestoURL;
            if (PresupuestoId != null) {
                url += '?&PresupuestoId=' + PresupuestoId;
            }
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                var element = json[i];
                var registro = this.jsonConverters.puntoPresupuestoDeJSON(element);
                registros.push(registro);
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerPuntoPresupuestoPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.puntosPresupuestoURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.puntoPresupuestoDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearPuntoPresupuesto(registro: PuntoPresupuesto) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.puntosPresupuestoURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Punto Presupuesto creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarPuntoPresupuesto(registro: PuntoPresupuesto) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.puntosPresupuestoURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Punto Presupuesto actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarPuntoPresupuesto(puntoPresupuestoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.puntosPresupuestoURL + '/id/' + puntoPresupuestoId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Punto Presupuesto eliminado con éxito', result: res}};
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    //Correo presupuesto nuevo
    async enviarCorreoPresupuestoNuevo(PresupuestoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.presupuestosURL + '/enviar-correo-presupuesto-nuevo';
            url+= `?&PresupuestoId=${PresupuestoId}`;
            var json = await this.connection.getRequest(url);
            return { error: null, data: { mensaje: 'Correo enviado' } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Obtener items punto presupuesto
    async obtenerItemsPuntoPresupuesto(PuntoPresupuestoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.itemsPuntoPresupuestoURL;
            url+= `?&PuntoPresupuestoId=${PuntoPresupuestoId}`;
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                var element = json[i];
                var registro = this.jsonConverters.itemPuntoPresupuestoDeJSON(element);
                registros.push(registro);
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }    
    }

        // Obtener items punto presupuesto
    async obtenerItemsPuntoPresupuesto3(PresupuestoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.itemsPuntoPresupuestoURL;
            url+= `?&PresupuestoId=${PresupuestoId}`;
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                var element = json[i];
                var registro = this.jsonConverters.itemPuntoPresupuestoDeJSON(element);
                registros.push(registro);
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }    
    }


    // Obtener repuestos de items punto presupuesto
    async obtenerItemsPuntoPresupuesto2(PresupuestoId = null, PuntoPresupuestoId = null, ItemPuntoPresupuestoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.repuestosItemsPuntoPresupuestoURL;
            url+= `?&ItemPuntoPresupuestoId=${ItemPuntoPresupuestoId}`;
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                var element = json[i];
                var registro = this.jsonConverters.repuestoItemPuntoPresupuestoDeJSON(element);
                registros.push(registro);
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }    
    }

        // Obtener items punto presupuesto
        async obtenerRepuestosItemsPuntoPresupuesto(PresupuestoId = null, PuntoPresupuestoId = null, ItemPuntoPresupuestoId: number) {
            try {
                var url = this.apiUrls.baseURL + this.apiUrls.repuestosItemsPuntoPresupuestoURL;
                url+= `?&PuntoPresupuestoId=${PuntoPresupuestoId}`;
                var json = await this.connection.getRequest(url);
                var registros = [];
                for (let i = 0; i < json.length; i++) {
                    var element = json[i];
                    var registro = this.jsonConverters.repuestoItemPuntoPresupuestoDeJSON(element);
                    registros.push(registro);
                }
                return { error: null, data: { registros: registros } };
            } catch (error) {
                return this.connection.obtenerMensajeError(error.status, null);
            }    
        }

    async obtenerItemPuntoPresupuestoPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.itemsPuntoPresupuestoURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.itemPuntoPresupuestoDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear item punto presupuesto
    public async crearItemPuntoPresupuesto(registro: ItemPuntoPresupuesto) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.itemsPuntoPresupuestoURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Item Punto Presupuesto creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    
    // Actualizar item punto presupuesto
    public async actualizarItemPuntoPresupuesto(registro: ItemPuntoPresupuesto) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.itemsPuntoPresupuestoURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Item Punto Presupuesto actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar item punto presupuesto
    public async eliminarItemPuntoPresupuesto(ItemPuntoPresupuestoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.itemsPuntoPresupuestoURL + '/id/' + ItemPuntoPresupuestoId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Item Punto Presupuesto eliminado con éxito', result: res}};
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

        //* * Cambiar Estado Contrato * * * *
    // Crear
    public async crearCambioEstadoPresupuesto(registro: CambioEstadoPresupuesto) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.cambioEstadoPresupuestosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Cambio de estado creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }


    async descargarPresupuesto(PresupuestoId: number, fileName: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.presupuestosURL + `/descargar/${PresupuestoId}`;
            var res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var fileName = `${fileName}.pdf`;
            var mediaType = 'application/pdf';
            var blob = new Blob([res], {
                type: mediaType
            });
            FileSaver.saveAs(blob, fileName);

            return {
                error: null,
                data: {
                    mensaje: 'Presupuesto descargado con éxito.'
                }
            };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }    

    async obtenerTodosPresupuestosPorPlaca(numeroPlaca){
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.presupuestosURL + `/placa/${numeroPlaca}`;
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                var element = json[i];
                var registro = this.jsonConverters.citaDeJSON(element);
                registros.push(registro);
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }    
    } 

    
    async obtenerTodosCitasPorPlaca(numeroPlaca){
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.citasURL + `/placa/${numeroPlaca}`;
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                var element = json[i];
                var registro = this.jsonConverters.presupuestoDeJSON(element);
                registros.push(registro);
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }    
    } 


    async enviarCorreoManoObra(PresupuestoId: number){
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.presupuestosURL + `/enviarCorreoManoObra/${PresupuestoId}`;
            var json = await this.connection.getRequest(url);
            return { error: null, data: { mensaje: 'Correo enviado con éxito' } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }    
    }

    async enviarCorreoRepuestos(PresupuestoId: number){
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.presupuestosURL + `/enviarCorreoRepuestos/${PresupuestoId}`;
            var json = await this.connection.getRequest(url);
            return { error: null, data: { mensaje: 'Correo enviado con éxito' } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }    
    }

    
    public async descargarExcelPresupuestos(nombre: string, params:string) {
        try {
            // Construir ruta
            var url = this.apiUrls.baseURL + this.apiUrls.presupuestosURL + '/descargar-excel';
            if(params) url+= '?'+ params;
            var response = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `${nombre}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([ response ], { type: mediaType });
            FileSaver.saveAs(blob, filename);
            return response;
        } catch (error) {
            return null;
        }
    }

    async visualizarDocumentoPDF(idDocumentoGenerado: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.presupuestosURL + `/descargar/${idDocumentoGenerado}`;
            var res = await this.connection.getDownloadRequest(url);
            let pdfSrc: any = null;
  
            // Descargar archivo
            var mediaType = 'application/pdf';
            var blob = new Blob([res], {
                type: mediaType
            });
  
            return {
                  error: null,
                  data: {
                      blob: blob,
                  },
                  mensaje: 'documento',
            };
        } catch (error) {
          return { error: true, mensaje: error.error.message, result: null };
        }
      }


      async   enviarCorreoPresupuestoCliente(PresupuestoId: number, correos){
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.presupuestosURL + `/enviarCorreoCliente/${PresupuestoId}`;
            var data = {
                registros: correos,
            }
            var json = await this.connection.postRequest(url, data);
            return { error: null, data: { mensaje: 'Correo enviado con éxito' } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
      }

    public async descargarExcelPresupuestosTiempos(nombre: string, params: string) {
        try {
            // Construir ruta
            var url = this.apiUrls.baseURL + this.apiUrls.presupuestosURL + '/descargar-excel-tiempos';
            if (params) url += '?' + params;
            var response = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `${nombre}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([response], { type: mediaType });
            FileSaver.saveAs(blob, filename);
            return response;
        } catch (error) {
            return null;
        }

    }

    //descargarExcelPresupuestoTiemposHistorico
    public async descargarExcelPresupuestoTiemposHistorico(nombre: string, params: string) {
        try {
            // Construir ruta
            var url = this.apiUrls.baseURL + this.apiUrls.presupuestosURL + '/descargar-excel-tiempos-historico';
            if (params) url += '?' + params;
            var response = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `${nombre}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([response], { type: mediaType });
            FileSaver.saveAs(blob, filename);
            return response;
        } catch (error) {
            return null;
        }

    }

    public async historicoNotificaciones(PresupuestoId){
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.presupuestosURL + `/historico-notificaciones/${PresupuestoId}`;
            var json = await this.connection.getRequest(url);
            return { error: null, data: { json: json } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }
}