import { SolicitudPruebaManejo } from "./SolicitudPruebaManejo";
import { Usuario } from "./usuario";

export class CambioEstadoSolicitud {
    constructor(
        public id:number,
        public fecha: string,
        public estadoAnterior: string,
        public estadoPosterior: string,
        public observaciones: string,
        public kilometrajeActual: number,
        //Relaciones
        public UsuarioId: number,
        public SolicitudPruebaManejoId: number,
    ){}

    fechaFormato:string;
    fechaDate:Date;
    Usuario: Usuario;
    SolicitudPruebaManejo: SolicitudPruebaManejo;

    //auxiliares
    horaSalida: string;
    horaIngreso: string;
    kmSalida: number;
    kmIngreso: number;
    autonomiaSalida: number;
    autonomiaIngreso: number;
    combustibleSalida: string;
    combustibleIngreso: string;
    citaCumplida: boolean;
 
}