import { EstructuraDescuentos, InspeccionGrafica, InspeccionVisual, OtrasInspecciones } from "./avaluo";
import { ClienteBoleta } from "./ClienteBoleta";
import { Empresa } from "./empresa";
import { Usuario } from "./usuario";
import { VehiculoDemo } from "./VehiculoDemo";

export class SolicitudPruebaManejo {
    constructor(
        public id: number,
        public fecha: string,
        public estado: string,
        public horaSalida: string,
        public horaIngreso: string,
        public kmSalida: number,
        public kmIngreso: number,
        public autonomiaSalida: number,
        public autonomiaIngreso: number,
        public sede: string,
        public tipo: string,
        public asesor: string,
        public nombreCliente: string,
        public dpiCliente: number,
        public diasPrestamo: string,
        public inspeccionEntrada: string,
        public combustibleSalida: string,
        public combustibleIngreso: string,
        public fechaDevolucion: string,
        public horaEstimadaIngreso: string,
        public citaProgramada: boolean,
        public citaCumplida: boolean,
        //Relaciones
        public UsuarioSolicitadorId: number,
        public UsuarioAprobadorId: number,
        public VehiculoDemoId: number,
        public ClienteBoletaId:number,
        public EmpresaId: number,
    ) {}

    objetoId: any = {};

    fechaFormato: string;
    fechaDate: Date;

    fechaDevolucionFormato: string;
    fechaDevolucionDate: Date;

    UsuarioSolicitador: Usuario;
    UsuarioAprobador: Usuario;
    ClienteBoleta: ClienteBoleta;
    Empresa: Empresa;
    VehiculoDemo: VehiculoDemo;
    kmUtilizado: number;
    autonomiaUtilizada: number;

    tipoFormato:string;
    estadoFormato:string;

    inspeccionEntradaJson: InspeccionEntradaSalidaDigitalizacion = new InspeccionEntradaSalidaDigitalizacion();


}

export class InspeccionEntradaSalidaDigitalizacion {

    constructor(
        public inspeccionGrafica: string = null,
        public listadoDescuentos: string = null,
        public inspeccionesVisuales: string = null,
        public otrasInspecciones: string = null,
        public descuentoInspeccionMecanicaVisual: number = 0,
        public otrosDescuentos: number = 0,
        public descuentoInspeccionGrafica: number = 0,
    ) {}

    public listadoInspeccionesVisualesJson: InspeccionVisual = new InspeccionVisual();
    public DescuentosJson: EstructuraDescuentos = new EstructuraDescuentos();
    public otrasInspeccionesJson: OtrasInspecciones = new OtrasInspecciones();
    public listadoInspeccionesGraficasJson: InspeccionGrafica = new InspeccionGrafica();
    
}