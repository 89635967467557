import { EncuestaAhorrent } from './EncuestaAhorrent';
import { Empresa } from './empresa';

export class ConfiguracionAhorrent {
    constructor(
        public id: number,
        public talleres: string,
        public tiposDocumentos: string,
        public rubrosGastos: string,
        public modosPago:string,
        public categoriasCliente:string,
        public serviciosKilometraje: string,
        public lavadores: string,
        public mecanicos: string,
        public cambioDolares: number,
        public agencias: string,
        public precioDiesel: number,
        public precioGasolina: number,
        public tarifaNormalProteccionChoques: number,
        public tarifaReduccionDeducibleProteccionChoques: number,
        public agentesYPilotos: string,
        public porcentajesEficiencia: string,
        public porcentajesAniosEficiencia: string,
        public marcasTarjetas: string,
        public devolucionVehiculo: string,
        public correosAvisosContratos: string,
        public grupos: string,
        public correosPendientesUnidad: string,
        public correosAgentesPendientesListoUnidad: string,
        public correosAlertasLavado: string,
        public correosVehiculosVenta: string,
        public aniosVehiculoEnVenta: number,
        public tiposTransmision: string,
        public restriccionesContratos: string,
        public origenRenta: string,
        public porcentajesDescuento: string,
        public usuariosAgencia: string,
        public usuariosDashboards: string,
        public referidos: string,
        public tipoDanios: string,
        public multas: string,
        public monedasMultas: string,
        public paisesMultas: string,
        public establecimientos: string,

                                /**
                         *             seguroCuotaConductorMenor25: { type: DataTypes.DECIMAL(19,2), allowNull: true },
            seguroProteccionLlantasParabrisas: { type: DataTypes.DECIMAL(19,2), allowNull: true },
            servicioCartaPoderFrontera: { type: DataTypes.DECIMAL(19,2), allowNull: true },
            servicioNocheLibre: { type: DataTypes.DECIMAL(19,2), allowNull: true },
            servicioSillaBebe: { type: DataTypes.DECIMAL(19,2), allowNull: true },
            servicioAcoplarRemolque: { type: DataTypes.DECIMAL(19,2), allowNull: true },
            servicioCamperPickup: { type: DataTypes.DECIMAL(19,2), allowNull: true },
            servicioPiloto: { type: DataTypes.DECIMAL(19,2), allowNull: true },
            pap: { type: DataTypes.DECIMAL(19,2), allowNull: true },
                         */
                        
        public seguroCuotaConductorMenor25: number,
        public seguroProteccionLlantasParabrisas: number,
        public servicioCartaPoderFrontera: number,
        public servicioNocheLibre: number,
        public servicioSillaBebe: number,
        public servicioAcoplarRemolque: number,
        public servicioCamperPickup: number,
        public servicioPiloto: number,
        public pap: number,

        public encuestaIndividual: string,
        public encuestaCorporativa: string,
        public textosEncuestaFinalizada: string,
        
        public horasInicioVerdeOperaciones: number,
        public horasFinVerdeOperaciones: number,
        public horasInicioAmarilloOperaciones: number,
        public horasFinAmarilloOperaciones: number,
        public horasInicioRojoOperaciones: number,
        public horasFinRojoOperaciones: number,
        
        public horasInicioVerdePendientesTaller: number,
        public horasFinVerdePendientesTaller: number,
        public horasInicioAmarilloPendientesTaller: number,
        public horasFinAmarilloPendientesTaller: number,
        public horasInicioRojoPendientesTaller: number,
        public horasFinRojoPendientesTaller: number,
    ){this.cargarArrays()}

    talleresArray: any[];
    tiposDocumentosArray: any[];
    rubrosGastosArray: any[];
    modosPagoArray:any[];
    categoriasClienteArray:any[];
    serviciosKilometrajeArray:any[];
    lavadoresArray:any[];
    mecanicosArray:any[];
    agenciasArray:any[];
    agentesYPilotosArray:any[];
    porcentajesEficienciaArray: any[];
    porcentajesAniosEficienciaArray: any;
    marcasTarjetasArray: any[];
    devolucionVehiculoArray: any[];
    correosAvisosContratosArray: any[];
    gruposArray: any[];
    correosPendientesUnidadArray: any[];
    correosAgentesPendientesListoUnidadArray: any[];
    correosAlertasLavadoArray: any[];
    correosVehiculosVentaArray: any[];
    tiposTransmisionArray: any[];
    restriccionesContratosArray: any[];
    origenRentaArray: any[];
    porcentajesDescuentoArray: any[];
    usuariosAgenciaArray: any[];
    usuariosDashboardsArray: any[];
    referidosArray: any[];
    tipoDaniosArray: any[];
    multasArray: any[];
    monedasMultasArray: any[];
    paisesMultasArray: any[];
    establecimientosArray: any[];

    encuestaIndividualObject: EncuestaAhorrent;
    encuestaCorporativaObject: EncuestaAhorrent;
    textosEncuestaFinalizadaObject: any;


    cargarArrays() {
        
        if (this.talleres) {
            this.talleresArray =  JSON.parse(this.talleres)
        }
        if (this.tiposDocumentos) {
            this.tiposDocumentosArray=  JSON.parse(this.tiposDocumentos)
        }
        if (this.rubrosGastos) {
            this.rubrosGastosArray =  JSON.parse(this.rubrosGastos)
        }
        this.modosPagoArray = this.modosPago? JSON.parse(this.modosPago) : [];
        this.categoriasClienteArray = this.categoriasCliente? JSON.parse(this.categoriasCliente) : [];
        this.serviciosKilometrajeArray = this.serviciosKilometraje? JSON.parse(this.serviciosKilometraje) : [];
        this.lavadoresArray = this.lavadores ? JSON.parse(this.lavadores) : [];
        this.mecanicosArray = this.mecanicos ? JSON.parse(this.mecanicos) : [];
        this.agenciasArray = this.agencias ? JSON.parse(this.agencias) : [];
        this.agentesYPilotosArray = this.agentesYPilotos ? JSON.parse(this.agentesYPilotos) : [];
        this.marcasTarjetasArray = this.marcasTarjetas ? JSON.parse(this.marcasTarjetas) : [];
        this.devolucionVehiculoArray = this.devolucionVehiculo ? JSON.parse(this.devolucionVehiculo) : [];
        this.correosAvisosContratosArray = this.correosAvisosContratos ? JSON.parse(this.correosAvisosContratos) : [];
        this.gruposArray = this.grupos ? JSON.parse(this.grupos) : [];
        this.correosVehiculosVentaArray = this.correosVehiculosVenta ? JSON.parse(this.correosVehiculosVenta) : [];
        this.correosPendientesUnidadArray = this.correosPendientesUnidad ? JSON.parse(this.correosPendientesUnidad) : [];
        this.correosAgentesPendientesListoUnidadArray = this.correosAgentesPendientesListoUnidad ? JSON.parse(this.correosAgentesPendientesListoUnidad) : [];
        this.correosAlertasLavadoArray = this.correosAlertasLavado ? JSON.parse(this.correosAlertasLavado) : [];
        this.tiposTransmisionArray = this.tiposTransmision ? JSON.parse(this.tiposTransmision) : [];
        this.restriccionesContratosArray = this.restriccionesContratos ? JSON.parse(this.restriccionesContratos) : [];
        this.origenRentaArray = this.origenRenta ? JSON.parse(this.origenRenta) : [];
        this.porcentajesDescuentoArray = this.porcentajesDescuento ? JSON.parse(this.porcentajesDescuento) : [];
        this.usuariosAgenciaArray = this.usuariosAgencia ? JSON.parse(this.usuariosAgencia) : [];
        this.usuariosDashboardsArray = this.usuariosDashboards ? JSON.parse(this.usuariosDashboards) : [];
        this.referidosArray = this.referidos ? JSON.parse(this.referidos) : [];
        this.tipoDaniosArray = this.tipoDanios ? JSON.parse(this.tipoDanios) : [];
        this.multasArray = this.multas ? JSON.parse(this.multas) : [];
        this.monedasMultasArray = this.monedasMultas? JSON.parse(this.monedasMultas) : [];
        this.paisesMultasArray = this.paisesMultas? JSON.parse(this.paisesMultas) : [];
        this.establecimientosArray = this.establecimientos ? JSON.parse(this.establecimientos) : []

        this.encuestaIndividualObject = this.encuestaIndividual ? JSON.parse(this.encuestaIndividual) : new EncuestaAhorrent('', '');
        this.encuestaCorporativaObject = this.encuestaCorporativa ? JSON.parse(this.encuestaCorporativa) : new EncuestaAhorrent('', '');
        this.textosEncuestaFinalizadaObject = this.textosEncuestaFinalizada ? JSON.parse(this.textosEncuestaFinalizada) : {};
        this.inicializarPorcentajesMeses();

    }

    inicializarPorcentajesMeses(){
        if(!this.porcentajesEficiencia || this.porcentajesEficiencia == '[]'){
            this.porcentajesEficienciaArray = [
                { mes: 1, porcentaje: 0 },
                { mes: 2, porcentaje: 0 },
                { mes: 3, porcentaje: 0 },
                { mes: 4, porcentaje: 0 },
                { mes: 5, porcentaje: 0 },
                { mes: 6, porcentaje: 0 },
                { mes: 7, porcentaje: 0 },
                { mes: 8, porcentaje: 0 },
                { mes: 9, porcentaje: 0 },
                { mes: 10, porcentaje: 0 },
                { mes: 11, porcentaje: 0 },
                { mes: 12, porcentaje: 0 },
            ]
        }
        else this.porcentajesEficienciaArray = JSON.parse(this.porcentajesEficiencia);

        if(!this.porcentajesAniosEficiencia || this.porcentajesAniosEficiencia == '{}'){
            this.porcentajesAniosEficienciaArray = {
                null: [
                    { mes: 1, porcentaje: 0 },
                    { mes: 2, porcentaje: 0 },
                    { mes: 3, porcentaje: 0 },
                    { mes: 4, porcentaje: 0 },
                    { mes: 5, porcentaje: 0 },
                    { mes: 6, porcentaje: 0 },
                    { mes: 7, porcentaje: 0 },
                    { mes: 8, porcentaje: 0 },
                    { mes: 9, porcentaje: 0 },
                    { mes: 10, porcentaje: 0 },
                    { mes: 11, porcentaje: 0 },
                    { mes: 12, porcentaje: 0 },
                ]
            }
        }
        else this.porcentajesAniosEficienciaArray = JSON.parse(this.porcentajesAniosEficiencia);
    }

    guardar() {
        this.encuestaIndividual = JSON.stringify(this.encuestaIndividualObject);
        this.encuestaCorporativa = JSON.stringify(this.encuestaCorporativaObject);
        this.textosEncuestaFinalizada = JSON.stringify(this.textosEncuestaFinalizadaObject);
    }

}