import { Component, Inject } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { APIUrls } from 'src/app/api/apiUrls';
import { LibraryService } from 'src/app/library/library.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GenericDialogConfirmacion } from 'src/app/library/generic-dialog-confirmacion/generic-dialog-confirmacion.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AhorrentService } from '../ahorrent.service';
import { UsuariosService } from 'src/app/usuarios/usuarios.service';
import { Unidad } from 'src/app/models/unidad';
import { GastoServicio } from 'src/app/models/gastoServicio';

export interface DialogData {
    modoDetalle: boolean,
    modoCreacion: boolean,
    modoEdicion: boolean,
    registroId?:number,
    ServicioUnidadId?:number,
    UsuarioId:number
    
}

@Component({
    selector: 'dialog-crud-gastos',
    templateUrl: './dialog-crud-gastos.component.html',
    styleUrls: ['./dialog-crud-gastos.component.scss'],
})
export class DialogGastos {

    cargando:  boolean;
    registro: GastoServicio;
    apiURLs: APIUrls = new APIUrls();
    modoDetalle: boolean;
    modoCreacion: boolean;
    modoEdicion: boolean;
    titulo:string = "Gasto";
    archivoCargado:  boolean;
    estados: any;
    configuracion: any;

    constructor(
        public dialogRef: MatDialogRef<DialogGastos>,
        public authService: AuthService,
        public auth: AuthService,
        public libraryService: LibraryService,
        public dialog: MatDialog,
        public service: AhorrentService,
        private router: Router,
        private route: ActivatedRoute,
        public usuarioService: UsuariosService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {
        this.modoCreacion = data.modoCreacion;
        this.modoEdicion = data.modoEdicion;
        this.modoDetalle = data.modoDetalle;
        if(data.registroId) this.obtenerRegistro()
        else this.registro = new GastoServicio(-1,null,null,0,data.ServicioUnidadId,data.UsuarioId);
        this.obtenerTipos();        
    }

    ngOnInit() {
        var dialog = document.getElementById("dialogOverflow");
        var dialogContainer = null;
        if (dialog) dialogContainer = dialog.parentElement;
        if (dialogContainer) dialogContainer = dialogContainer.parentElement;
        if (dialogContainer) dialogContainer = dialogContainer.parentElement;
        if (dialogContainer) dialogContainer.className += ' dialogOverflow';
    }

    async obtenerRegistro(){
        var res = await this.service.obtenerGastoServicioPorId(this.data.registroId)
        if(!res.error) this.registro = res.data.registro;
        else this.cerrar()

    }

    async obtenerTipos() {
        this.cargando = true;
        var res = await this.service.obtenerConfiguracion();
        if(!res.error) {
            this.configuracion = res.data.result;
        }
        else {
            this.router.navigate(['/404']);
        }
        this.cargando = false;
    }

    async guardar(){
        // Enviar el registro para guardar
        this.cargando = true;
        var res;

        if(this.modoCreacion){
            res = await this.service.crearGastoServicio(this.registro);
        }
        else if(this.modoEdicion){
            res = await this.service.actualizarGastoServicio(this.registro);
        }

        if (!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.confirmar();
        } else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    // Eliminar datos
    abrirEliminar() {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Eliminar gasto',
                mensaje: '¿Está seguro de que desea eliminar este gasto?',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.eliminar();
            }
        });
    }

    // Eliminar
    async eliminar(){
        this.cargando = true;

        // Enviar el registro para guardar
        var res = await this.service.eliminarGastoServicio(this.registro.id);
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.confirmar();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    cerrar() {
        this.dialogRef.close(null);
    }

    confirmar() {
        this.dialogRef.close("confirmar");
    }

    obtenerNombre(id){
        let index = this.libraryService.indexOf(this.apiURLs.estadoUnidad,'id',id);
        return index == -1 ? '' : this.apiURLs.estadoUnidad[index].nombre;
    }

    // * * * * * Validaciones * * * * * 
    formularioValido() {
        if(!this.registro) return false;
        if(!(this.registro.total && this.registro.rubro)) return false;
        return true;
    }

    registroValido():  boolean {
        return true;
    }

}
