import { Contratacion } from "./Contratacion";
import { ItemPlanDeMantenimiento } from "./ItemPlanDeMantenimiento";

export class OrdenTrabajoPlanMantenimiento{
    constructor(
        public id: number,
        public titulo: string,
        public fechaFacturacion: string,
        public observaciones: string,
        public cargoCliente: boolean,
        public definicionCargoCliente: string,
        public fechaSugeridaProximoServicio: string,
        public valorConIVA: number,
        public tipoServicio: string,
        public costoRepuesto: number,
        public ventaRepuesto: number,
        public ventaManoObra: number,
        public numeroFactura: string,
        public fechaPago: string,
        public pagosEnCajaConIVA: number,
        public pagosEnCajaSinIVA: number,
        public comentarios: string,
        //Relaciones
        public ContratacionId: number,
        public ItemPlanMantenimientoId: number,

    ){}

    enviarRecordatorio: boolean = true;

    Contratacion: Contratacion;
    ItemPlanMantenimiento: ItemPlanDeMantenimiento;

    fechaFacturacionFormato: string;
    fechaFacturacionDate: Date;

    fechaSugeridaProximoServicioFormato: string;
    fechaSugeridaProximoServicioDate: Date;

    fechaPagoFormato: string;
    fechaPagoDate: Date;
}