<ng-container *ngIf="registro && !mostrarResultadoGuardado">
    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Nombres</mat-label>
                <input matInput name="nombres" [(ngModel)]="registro.nombres"
                [disabled]="modoDetalle" required>
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field>
                <mat-label>Apellidos</mat-label>
                <input matInput name="apellidos" [(ngModel)]="registro.apellidos"
                [disabled]="modoDetalle" required>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Correo electrónico</mat-label>
                <input matInput name="correo" [(ngModel)]="registro.correo"
                [disabled]="modoDetalle" required>
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field>
                <mat-label>Teléfono</mat-label>
                <input matInput name="telefono" [(ngModel)]="registro.telefono"
                [disabled]="modoDetalle" required>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>{{(apiURLs.AMBIENTE == 'GT' ? 'NIT' : (apiURLs.AMBIENTE == 'SV' ? 'NIT' : 'RTN'))}}</mat-label>
                <input matInput name="nit" [(ngModel)]="registro.nit"
                [disabled]="modoDetalle">
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field>
                <mat-label>Línea del vehículo</mat-label>
                <mat-select name="lineaVehiculo" [(ngModel)]="registro.lineaVehiculo" [disabled]="modoDetalle">
                    <mat-option *ngFor="let linea of lineas" [value]="linea.nombre">{{linea.nombre}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <!--
            <div class="col" *ngIf="registro.servicio == 'servicio-mantenimiento'">
                <mat-form-field>
                    <mat-label>Rango de servicio</mat-label>
                    <mat-select name="servicio" [(ngModel)]="registro.rangoServicio" [disabled]="modoDetalle">
                        <mat-option *ngFor="let tipo of apiURLs.rangosServicioCitas" [value]="tipo.id">{{tipo.nombre}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        -->
    </div>
    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Tipo de servicio</mat-label>
                <mat-select name="servicio" [(ngModel)]="registro.servicio" required [disabled]="modoDetalle">
                    <mat-option *ngFor="let tipo of apiURLs.tipoServicioCitas" [value]="tipo.id">{{tipo.nombre}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field>
                <mat-label>Concesionario</mat-label>
                <mat-select name="ConcesionarioId" [(ngModel)]="registro.ConcesionarioId" [disabled]="modoDetalle"
                (selectionChange)="actualizarConcesionario($event.value)" required>
                    <mat-option *ngFor="let concesionario of concesionarios" [value]="concesionario.id">{{concesionario.nombre}} - {{concesionario.direccion}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <!-- * Fecha * -->
        <div class="col">
            <mat-form-field>
                <mat-label>Fecha</mat-label>
                <input matInput name="fechaDate" [matDatepicker]="pickerFecha" placeholder="Fecha" [disabled]="modoDetalle"
                    (dateChange)="actualizarFecha($event)" [min]="minDate" [(ngModel)]="registro.fechaDate" required>
                <mat-datepicker-toggle matSuffix [for]="pickerFecha" required></mat-datepicker-toggle>
                <mat-datepicker #pickerFecha required></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col" *ngIf="agentes">
            <mat-form-field>
                <mat-label>Asesor</mat-label>
                <mat-select name="AgenteId" [(ngModel)]="registro.AgenteId" [disabled]="modoDetalle"
                (selectionChange)="actualizarAgente($event)" required>
                    <mat-option *ngFor="let agente of agentes" [value]="agente.id">{{agente.nombreCompleto}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col" *ngIf="agenteSeleccionado">
            <mat-form-field>
                <mat-label>Horario</mat-label>
                <mat-select name="AgenteId" [(ngModel)]="horarioSeleccionado" [disabled]="modoDetalle"
                (selectionChange)="actualizarHorarioSeleccionado()" required>
                    <mat-option *ngFor="let horario of agenteSeleccionado.horariosDisponibles" [value]="horario">{{horario.horaInicio}} a {{horario.horaFin}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row" *ngIf="!cargando && registro.ConcesionarioId && registro.fecha && ((!agentes || agentes.length == 0) || (agenteSeleccionado && (!agenteSeleccionado.horariosDisponibles || agenteSeleccionado.horariosDisponibles.length == 0)))">
        <div class="col">
            <div class="card" style="padding: 10px;margin:5px; background-color: #f56e64; margin-bottom: 20px;">
                <p><mat-icon class="iconoBoton">warning</mat-icon> No hay un horario disponible para este agente en esta fecha. Por favor, seleccione otro agente o seleccione otra fecha.</p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Kilometraje</mat-label>
                <input matInput name="kilometraje" [(ngModel)]="registro.kilometraje" [disabled]="modoDetalle">
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field>
                <mat-label>Placa</mat-label>
                <input matInput name="placa" [(ngModel)]="registro.placa" [disabled]="modoDetalle" required>
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field>
                <mat-label>Chasis</mat-label>
                <input matInput name="chasis" [(ngModel)]="registro.chasis" [disabled]="modoDetalle">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Observaciones</mat-label>
                <textarea matInput name="observaciones" [disabled]="modoDetalle" [(ngModel)]="registro.observaciones"></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <!-- Subir documento -->
            <input id="file-input-archivo-multiple" name="file-input-archivo" type="file"
            (change)="seleccionarDocumentoResultado($event)" accept=".png, .jpg, .jpeg" style="display: none;" multiple="multiple">
            <button color="primary" mat-raised-button type="button" (click)="activarSeleccionDocumentoResultado()" [disabled]="cargando || modoDetalle">
                <span>Subir imágenes</span>
                <mat-icon class="iconoBoton">upgrade</mat-icon>
            </button>
        </div>
    </div>
    <!-- Imágenes -->
    <div class="row">
        <div class="col" style="display: inline-flex;">
            <ng-container *ngFor="let imagen of registro.imagenesArray">
                <img class="imagenCita" src="{{imagen}}" (click)="openModal(imagen)" />
            </ng-container>
        </div>
    </div>
    <br/>
    <div class="row">
        <div class="col">
            <!-- Guardar -->
            <button (click)="select()" *ngIf="!modoDetalle"
            color="primary" mat-raised-button type="button" [disabled]="cargando || cargandoExterior || !formularioValido()">
                {{tituloBoton}}
                <mat-icon class="iconoBoton">event_available</mat-icon>
            </button>
        </div>
    </div>
</ng-container>