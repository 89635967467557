<div class="container" id="dialogOverflow" style="width: 1000px; height: 600px">
  <h3>{{data.titulo}}</h3>
  <br>
  <div class="row" style="border: 1px solid rgb(175, 175, 175);">
      <!-- Nombre -->
      <div class="col">
          <pdf-viewer
            [src]="data.pdfSrc"
            [render-text]="true"
            [original-size]="false"
            [autoresize]="true"
          ></pdf-viewer>
      </div>
  </div>
  <br/>
  <div class="row">
      <div class="col" style="text-align: right;">
          <button (click)="cerrar()" color="secundary" mat-raised-button type="button" class="margenBoton">
              <span>Cerrar</span>
          </button>
          <!--<button (click)="descargar()" color="primary" mat-raised-button type="button" class="margenBoton" *ngIf="!data.documentoTemporal">
              <span>Descargar</span>
          </button>-->
      </div>
  </div>
  <br>
  <br>
</div>
