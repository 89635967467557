import { CampaniaPreventiva } from "./CampaniaPreventiva";

export class VehiculoCampania{
    constructor(
        public id: number,
        public chasis: string,
        public tipo: string,
        public marca: string,
        public modelo: string,
        public numeroMotor: string,
        public color: string,
        public placa: string,
        public vehiculoCliente: string,
        public email: string,
        public telefono: string,
        public aplicado: boolean,
        //Relaciones
        public CampaniaPreventivaId: number,
    ){}

    CampaniaPreventiva: CampaniaPreventiva;
    codigoCampania: string;

}