export default [
    { "departamento": "ATLANTIDA", "id": "0-0", "nombre": "LA CEIBA" },
    { "departamento": "ATLANTIDA", "id": "0-1", "nombre": "EL PORVENIR" },
    { "departamento": "ATLANTIDA", "id": "0-2", "nombre": "ESPARTA" },
    { "departamento": "ATLANTIDA", "id": "0-3", "nombre": "JUTIAPA" },
    { "departamento": "ATLANTIDA", "id": "0-4", "nombre": "LA MASICA" },
    { "departamento": "ATLANTIDA", "id": "0-5", "nombre": "SAN FRANCISCO" },
    { "departamento": "ATLANTIDA", "id": "0-6", "nombre": "TELA" },
    { "departamento": "ATLANTIDA", "id": "0-7", "nombre": "ARIZONA" },
    { "departamento": "COLON", "id": "1-0", "nombre": "TRUJILLO" },
    { "departamento": "COLON", "id": "1-1", "nombre": "BALFATE" },
    { "departamento": "COLON", "id": "1-2", "nombre": "IRIONA" },
    { "departamento": "COLON", "id": "1-3", "nombre": "LIMON" },
    { "departamento": "COLON", "id": "1-4", "nombre": "SABA" },
    { "departamento": "COLON", "id": "1-5", "nombre": "SANTA FE" },
    { "departamento": "COLON", "id": "1-6", "nombre": "SANTA ROSA AGUAN" },
    { "departamento": "COLON", "id": "1-7", "nombre": "SONAGUERA" },
    { "departamento": "COLON", "id": "1-8", "nombre": "TOCOA" },
    { "departamento": "COLON", "id": "1-9", "nombre": "BONITO ORIENTAL" },
    { "departamento": "COMAYAGUA", "id": "2-0", "nombre": "COMAYAGUA" },
    { "departamento": "COMAYAGUA", "id": "2-1", "nombre": "AJUTERIQUE" },
    { "departamento": "COMAYAGUA", "id": "2-2", "nombre": "EL ROSARIO" },
    { "departamento": "COMAYAGUA", "id": "2-3", "nombre": "ESQUIAS" },
    { "departamento": "COMAYAGUA", "id": "2-4", "nombre": "HUMUYA" },
    { "departamento": "COMAYAGUA", "id": "2-5", "nombre": "LA LIBERTAD" },
    { "departamento": "COMAYAGUA", "id": "2-6", "nombre": "LAMANI" },
    { "departamento": "COMAYAGUA", "id": "2-7", "nombre": "LA TRINIDAD" },
    { "departamento": "COMAYAGUA", "id": "2-8", "nombre": "LEJAMANI" },
    { "departamento": "COMAYAGUA", "id": "2-9", "nombre": "MEAMBAR" },
    { "departamento": "COMAYAGUA", "id": "2-10", "nombre": "MINAS DE ORO" },
    { "departamento": "COMAYAGUA", "id": "2-11", "nombre": "OJOS DE AGUA" },
    { "departamento": "COMAYAGUA", "id": "2-12", "nombre": "SAN JERONIMO" },
    { "departamento": "COMAYAGUA", "id": "2-13", "nombre": "SAN JOSE DE COMAYAGUA" },
    { "departamento": "COMAYAGUA", "id": "2-14", "nombre": "SAN JOSE DEL POTRERO" },
    { "departamento": "COMAYAGUA", "id": "2-15", "nombre": "SAN LUIS" },
    { "departamento": "COMAYAGUA", "id": "2-16", "nombre": "SAN SEBASTIAN" },
    { "departamento": "COMAYAGUA", "id": "2-17", "nombre": "SIGUATEPEQUE" },
    { "departamento": "COMAYAGUA", "id": "2-18", "nombre": "VILLA DE SAN ANTONIO" },
    { "departamento": "COMAYAGUA", "id": "2-19", "nombre": "LAS LAJAS" },
    { "departamento": "COMAYAGUA", "id": "2-20", "nombre": "TAULABE" },
    { "departamento": "COPAN", "id": "3-0", "nombre": "SANTA ROSA DE COPAN" },
    { "departamento": "COPAN", "id": "3-1", "nombre": "CABAÑAS" },
    { "departamento": "COPAN", "id": "3-2", "nombre": "CONCEPCION" },
    { "departamento": "COPAN", "id": "3-3", "nombre": "COPAN RUINAS" },
    { "departamento": "COPAN", "id": "3-4", "nombre": "CORQUIN" },
    { "departamento": "COPAN", "id": "3-5", "nombre": "CUCUYAGUA" },
    { "departamento": "COPAN", "id": "3-6", "nombre": "DOLORES" },
    { "departamento": "COPAN", "id": "3-7", "nombre": "DULCE NOMBRE" },
    { "departamento": "COPAN", "id": "3-8", "nombre": "EL PARAISO" },
    { "departamento": "COPAN", "id": "3-9", "nombre": "FLORIDA" },
    { "departamento": "COPAN", "id": "3-10", "nombre": "LA JIGUA" },
    { "departamento": "COPAN", "id": "3-11", "nombre": "LA UNION" },
    { "departamento": "COPAN", "id": "3-12", "nombre": "NUEVA ARCADIA" },
    { "departamento": "COPAN", "id": "3-13", "nombre": "SAN AGUSTIN" },
    { "departamento": "COPAN", "id": "3-14", "nombre": "SAN ANTONIO" },
    { "departamento": "COPAN", "id": "3-15", "nombre": "SAN JERONIMO" },
    { "departamento": "COPAN", "id": "3-16", "nombre": "SAN JOSE" },
    { "departamento": "COPAN", "id": "3-17", "nombre": "SAN JUAN DE OPOA" },
    { "departamento": "COPAN", "id": "3-18", "nombre": "SAN NICOLAS" },
    { "departamento": "COPAN", "id": "3-19", "nombre": "SAN PEDRO" },
    { "departamento": "COPAN", "id": "3-20", "nombre": "SANTA RITA" },
    { "departamento": "COPAN", "id": "3-21", "nombre": "TRINIDAD" },
    { "departamento": "COPAN", "id": "3-22", "nombre": "VERACRUZ" },
    { "departamento": "CORTES", "id": "4-0", "nombre": "SAN PEDRO SULA" },
    { "departamento": "CORTES", "id": "4-1", "nombre": "CHOLOMA" },
    { "departamento": "CORTES", "id": "4-2", "nombre": "OMOA" },
    { "departamento": "CORTES", "id": "4-3", "nombre": "PIMIENTA" },
    { "departamento": "CORTES", "id": "4-4", "nombre": "POTRERILLOS" },
    { "departamento": "CORTES", "id": "4-5", "nombre": "PUERTO CORTES" },
    { "departamento": "CORTES", "id": "4-6", "nombre": "SAN ANTONIO DE CORTES" },
    { "departamento": "CORTES", "id": "4-7", "nombre": "SAN FRANCISCO DE YOJOA" },
    { "departamento": "CORTES", "id": "4-8", "nombre": "SAN MANUEL" },
    { "departamento": "CORTES", "id": "4-9", "nombre": "SANTA CRUZ DE YOJOA" },
    { "departamento": "CORTES", "id": "4-10", "nombre": "VILLANUEVA" },
    { "departamento": "CORTES", "id": "4-11", "nombre": "LA LIMA" },
    { "departamento": "CHOLUTECA", "id": "5-0", "nombre": "CHOLUTECA" },
    { "departamento": "CHOLUTECA", "id": "5-1", "nombre": "APACILAGUA" },
    { "departamento": "CHOLUTECA", "id": "5-2", "nombre": "CONCEPCION DE MARIA" },
    { "departamento": "CHOLUTECA", "id": "5-3", "nombre": "DUYURE" },
    { "departamento": "CHOLUTECA", "id": "5-4", "nombre": "EL CORPUS" },
    { "departamento": "CHOLUTECA", "id": "5-5", "nombre": "EL TRIUNFO" },
    { "departamento": "CHOLUTECA", "id": "5-6", "nombre": "MARCOVIA" },
    { "departamento": "CHOLUTECA", "id": "5-7", "nombre": "MOROLICA" },
    { "departamento": "CHOLUTECA", "id": "5-8", "nombre": "NAMASIGUE" },
    { "departamento": "CHOLUTECA", "id": "5-9", "nombre": "OROCUINA" },
    { "departamento": "CHOLUTECA", "id": "5-10", "nombre": "PESPIRE" },
    { "departamento": "CHOLUTECA", "id": "5-11", "nombre": "SAN ANTONIO DE FLORES" },
    { "departamento": "CHOLUTECA", "id": "5-12", "nombre": "SAN ISIDRO" },
    { "departamento": "CHOLUTECA", "id": "5-13", "nombre": "SAN JOSE" },
    { "departamento": "CHOLUTECA", "id": "5-14", "nombre": "SAN MARCOS DE COLON" },
    { "departamento": "CHOLUTECA", "id": "5-15", "nombre": "SANTA ANA DE YUSGUARE" },
    { "departamento": "EL PARAISO", "id": "6-0", "nombre": "YUSCARAN" },
    { "departamento": "EL PARAISO", "id": "6-1", "nombre": "ALAUCA" },
    { "departamento": "EL PARAISO", "id": "6-2", "nombre": "DANLI" },
    { "departamento": "EL PARAISO", "id": "6-3", "nombre": "EL PARAISO" },
    { "departamento": "EL PARAISO", "id": "6-4", "nombre": "GUINOPE" },
    { "departamento": "EL PARAISO", "id": "6-5", "nombre": "JACALEAPA" },
    { "departamento": "EL PARAISO", "id": "6-6", "nombre": "LIURE" },
    { "departamento": "EL PARAISO", "id": "6-7", "nombre": "MOROCELI" },
    { "departamento": "EL PARAISO", "id": "6-8", "nombre": "OROPOLI" },
    { "departamento": "EL PARAISO", "id": "6-9", "nombre": "POTRERILLOS" },
    { "departamento": "EL PARAISO", "id": "6-10", "nombre": "SAN ANTONIO DE FLORES" },
    { "departamento": "EL PARAISO", "id": "6-11", "nombre": "SAN LUCAS" },
    { "departamento": "EL PARAISO", "id": "6-12", "nombre": "SAN MATIAS" },
    { "departamento": "EL PARAISO", "id": "6-13", "nombre": "SOLEDAD" },
    { "departamento": "EL PARAISO", "id": "6-14", "nombre": "TEUPASENTI" },
    { "departamento": "EL PARAISO", "id": "6-15", "nombre": "TEXIGUAT" },
    { "departamento": "EL PARAISO", "id": "6-16", "nombre": "VADO ANCHO" },
    { "departamento": "EL PARAISO", "id": "6-17", "nombre": "YAUYUPE" },
    { "departamento": "EL PARAISO", "id": "6-18", "nombre": "TROJES" },
    { "departamento": "FRANCISCO MORAZAN", "id": "7-0", "nombre": "DISTRITO CENTRAL" },
    { "departamento": "FRANCISCO MORAZAN", "id": "7-1", "nombre": "ALUBAREN" },
    { "departamento": "FRANCISCO MORAZAN", "id": "7-2", "nombre": "CEDROS" },
    { "departamento": "FRANCISCO MORAZAN", "id": "7-3", "nombre": "CURAREN" },
    { "departamento": "FRANCISCO MORAZAN", "id": "7-4", "nombre": "EL PORVENIR" },
    { "departamento": "FRANCISCO MORAZAN", "id": "7-5", "nombre": "GUAIMACA" },
    { "departamento": "FRANCISCO MORAZAN", "id": "7-6", "nombre": "LA LIBERTAD" },
    { "departamento": "FRANCISCO MORAZAN", "id": "7-7", "nombre": "LA VENTA" },
    { "departamento": "FRANCISCO MORAZAN", "id": "7-8", "nombre": "LEPATERIQUE" },
    { "departamento": "FRANCISCO MORAZAN", "id": "7-9", "nombre": "MARAITA" },
    { "departamento": "FRANCISCO MORAZAN", "id": "7-10", "nombre": "MARALE" },
    { "departamento": "FRANCISCO MORAZAN", "id": "7-11", "nombre": "NUEVA ARMENIA" },
    { "departamento": "FRANCISCO MORAZAN", "id": "7-12", "nombre": "OJOJONA" },
    { "departamento": "FRANCISCO MORAZAN", "id": "7-13", "nombre": "ORICA" },
    { "departamento": "FRANCISCO MORAZAN", "id": "7-14", "nombre": "REITOCA" },
    { "departamento": "FRANCISCO MORAZAN", "id": "7-15", "nombre": "SABANAGRANDE" },
    { "departamento": "FRANCISCO MORAZAN", "id": "7-16", "nombre": "SAN ANTONIO DE ORIENTE" },
    { "departamento": "FRANCISCO MORAZAN", "id": "7-17", "nombre": "SAN BUENAVENTURA" },
    { "departamento": "FRANCISCO MORAZAN", "id": "7-18", "nombre": "SAN IGNACIO" },
    { "departamento": "FRANCISCO MORAZAN", "id": "7-19", "nombre": "CANTARRANAS" },
    { "departamento": "FRANCISCO MORAZAN", "id": "7-20", "nombre": "SAN MIGUELITO" },
    { "departamento": "FRANCISCO MORAZAN", "id": "7-21", "nombre": "SANTA ANA" },
    { "departamento": "FRANCISCO MORAZAN", "id": "7-22", "nombre": "SANTA LUCIA" },
    { "departamento": "FRANCISCO MORAZAN", "id": "7-23", "nombre": "TALANGA" },
    { "departamento": "FRANCISCO MORAZAN", "id": "7-24", "nombre": "TATUMBLA" },
    { "departamento": "FRANCISCO MORAZAN", "id": "7-25", "nombre": "VALLE DE ANGELES" },
    { "departamento": "FRANCISCO MORAZAN", "id": "7-26", "nombre": "VILLA SAN FRANCISCO" },
    { "departamento": "FRANCISCO MORAZAN", "id": "7-27", "nombre": "VALLECILLOS" },
    { "departamento": "GRACIAS A DIOS", "id": "8-0", "nombre": "PUERTO LEMPIRA" },
    { "departamento": "GRACIAS A DIOS", "id": "8-1", "nombre": "BRUS LAGUNA" },
    { "departamento": "GRACIAS A DIOS", "id": "8-2", "nombre": "AHUAS" },
    { "departamento": "GRACIAS A DIOS", "id": "8-3", "nombre": "JUAN FRANCISCO BULNES" },
    { "departamento": "GRACIAS A DIOS", "id": "8-4", "nombre": "VILLEDA MORALES" },
    { "departamento": "GRACIAS A DIOS", "id": "8-5", "nombre": "WAMPUSIRPI" },
    { "departamento": "INTIBUCA", "id": "9-0", "nombre": "LA ESPERANZA" },
    { "departamento": "INTIBUCA", "id": "9-1", "nombre": "CAMASCA" },
    { "departamento": "INTIBUCA", "id": "9-2", "nombre": "COLOMONCAGUA" },
    { "departamento": "INTIBUCA", "id": "9-3", "nombre": "CONCEPCION" },
    { "departamento": "INTIBUCA", "id": "9-4", "nombre": "DOLORES" },
    { "departamento": "INTIBUCA", "id": "9-5", "nombre": "INTIBUCA" },
    { "departamento": "INTIBUCA", "id": "9-6", "nombre": "JESUS DE OTORO" },
    { "departamento": "INTIBUCA", "id": "9-7", "nombre": "MAGDALENA" },
    { "departamento": "INTIBUCA", "id": "9-8", "nombre": "MASAGUARA" },
    { "departamento": "INTIBUCA", "id": "9-9", "nombre": "SAN ANTONIO" },
    { "departamento": "INTIBUCA", "id": "9-10", "nombre": "SAN ISIDRO" },
    { "departamento": "INTIBUCA", "id": "9-11", "nombre": "SAN JUAN" },
    { "departamento": "INTIBUCA", "id": "9-12", "nombre": "SAN MARCOS SIERRA" },
    { "departamento": "INTIBUCA", "id": "9-13", "nombre": "SAN MIGUELITO" },
    { "departamento": "INTIBUCA", "id": "9-14", "nombre": "SANTA LUCIA" },
    { "departamento": "INTIBUCA", "id": "9-15", "nombre": "YAMARANGUILA" },
    { "departamento": "INTIBUCA", "id": "9-16", "nombre": "SAN FRANCISCO DE OPALACA" },
    { "departamento": "ISLAS DE LA BAHIA", "id": "10-0", "nombre": "ROATAN" },
    { "departamento": "ISLAS DE LA BAHIA", "id": "10-1", "nombre": "GUANAJA" },
    { "departamento": "ISLAS DE LA BAHIA", "id": "10-2", "nombre": "JOSE SANTOS GUARDIOLA" },
    { "departamento": "ISLAS DE LA BAHIA", "id": "10-3", "nombre": "UTILA" },
    { "departamento": "LA PAZ", "id": "11-0", "nombre": "LA PAZ" },
    { "departamento": "LA PAZ", "id": "11-1", "nombre": "AGUANQUETERIQUE" },
    { "departamento": "LA PAZ", "id": "11-2", "nombre": "CABAÑAS" },
    { "departamento": "LA PAZ", "id": "11-3", "nombre": "CANE" },
    { "departamento": "LA PAZ", "id": "11-4", "nombre": "CHINACLA" },
    { "departamento": "LA PAZ", "id": "11-5", "nombre": "GUAJIQUIRO" },
    { "departamento": "LA PAZ", "id": "11-6", "nombre": "LAUTERIQUE" },
    { "departamento": "LA PAZ", "id": "11-7", "nombre": "MARCALA" },
    { "departamento": "LA PAZ", "id": "11-8", "nombre": "MERCEDES DE ORIENTE" },
    { "departamento": "LA PAZ", "id": "11-9", "nombre": "OPATORO" },
    { "departamento": "LA PAZ", "id": "11-10", "nombre": "SAN ANTONIO DEL NORTE" },
    { "departamento": "LA PAZ", "id": "11-11", "nombre": "SAN JOSE" },
    { "departamento": "LA PAZ", "id": "11-12", "nombre": "SAN JUAN" },
    { "departamento": "LA PAZ", "id": "11-13", "nombre": "SAN PEDRO DE TUTULE" },
    { "departamento": "LA PAZ", "id": "11-14", "nombre": "SANTA ANA" },
    { "departamento": "LA PAZ", "id": "11-15", "nombre": "SANTA ELENA" },
    { "departamento": "LA PAZ", "id": "11-16", "nombre": "SANTA MARIA" },
    { "departamento": "LA PAZ", "id": "11-17", "nombre": "SANTIAGO DE PURINGLA" },
    { "departamento": "LA PAZ", "id": "11-18", "nombre": "YARULA" },
    { "departamento": "LEMPIRA", "id": "12-0", "nombre": "GRACIAS" },
    { "departamento": "LEMPIRA", "id": "12-1", "nombre": "BELEN" },
    { "departamento": "LEMPIRA", "id": "12-2", "nombre": "CANDELARIA" },
    { "departamento": "LEMPIRA", "id": "12-3", "nombre": "COLOLACA" },
    { "departamento": "LEMPIRA", "id": "12-4", "nombre": "ERANDIQUE" },
    { "departamento": "LEMPIRA", "id": "12-5", "nombre": "GUALCINCE" },
    { "departamento": "LEMPIRA", "id": "12-6", "nombre": "GUARITA" },
    { "departamento": "LEMPIRA", "id": "12-7", "nombre": "LA CAMPA" },
    { "departamento": "LEMPIRA", "id": "12-8", "nombre": "LA IGUALA" },
    { "departamento": "LEMPIRA", "id": "12-9", "nombre": "LAS FLORES" },
    { "departamento": "LEMPIRA", "id": "12-10", "nombre": "LA UNION" },
    { "departamento": "LEMPIRA", "id": "12-11", "nombre": "LA VIRTUD" },
    { "departamento": "LEMPIRA", "id": "12-12", "nombre": "LEPAERA" },
    { "departamento": "LEMPIRA", "id": "12-13", "nombre": "MAPULACA" },
    { "departamento": "LEMPIRA", "id": "12-14", "nombre": "PIRAERA" },
    { "departamento": "LEMPIRA", "id": "12-15", "nombre": "SAN ANDRES" },
    { "departamento": "LEMPIRA", "id": "12-16", "nombre": "SAN FRANCISCO" },
    { "departamento": "LEMPIRA", "id": "12-17", "nombre": "SAN JUAN GUARITA" },
    { "departamento": "LEMPIRA", "id": "12-18", "nombre": "SAN MANUEL COLOHETE" },
    { "departamento": "LEMPIRA", "id": "12-19", "nombre": "SAN RAFAEL" },
    { "departamento": "LEMPIRA", "id": "12-20", "nombre": "SAN SEBASTIAN" },
    { "departamento": "LEMPIRA", "id": "12-21", "nombre": "SANTA CRUZ" },
    { "departamento": "LEMPIRA", "id": "12-22", "nombre": "TALGUA" },
    { "departamento": "LEMPIRA", "id": "12-23", "nombre": "TAMBLA" },
    { "departamento": "LEMPIRA", "id": "12-24", "nombre": "TOMALA" },
    { "departamento": "LEMPIRA", "id": "12-25", "nombre": "VALLADOLID" },
    { "departamento": "LEMPIRA", "id": "12-26", "nombre": "VIRGINIA" },
    { "departamento": "LEMPIRA", "id": "12-27", "nombre": "SAN MARCOS DE CAIQUIN" },
    { "departamento": "OCOTEPEQUE", "id": "13-0", "nombre": "OCOTEPEQUE" },
    { "departamento": "OCOTEPEQUE", "id": "13-1", "nombre": "BELEN GUALCHO" },
    { "departamento": "OCOTEPEQUE", "id": "13-2", "nombre": "CONCEPCION" },
    { "departamento": "OCOTEPEQUE", "id": "13-3", "nombre": "DOLORES MERENDON" },
    { "departamento": "OCOTEPEQUE", "id": "13-4", "nombre": "FRATERNIDAD" },
    { "departamento": "OCOTEPEQUE", "id": "13-5", "nombre": "LA ENCARNACION" },
    { "departamento": "OCOTEPEQUE", "id": "13-6", "nombre": "LA LABOR" },
    { "departamento": "OCOTEPEQUE", "id": "13-7", "nombre": "LUCERNA" },
    { "departamento": "OCOTEPEQUE", "id": "13-8", "nombre": "MERCEDES" },
    { "departamento": "OCOTEPEQUE", "id": "13-9", "nombre": "SAN FERNANDO" },
    { "departamento": "OCOTEPEQUE", "id": "13-10", "nombre": "SAN FRANCISCO DEL VALLE" },
    { "departamento": "OCOTEPEQUE", "id": "13-11", "nombre": "SAN JORGE" },
    { "departamento": "OCOTEPEQUE", "id": "13-12", "nombre": "SAN MARCOS" },
    { "departamento": "OCOTEPEQUE", "id": "13-13", "nombre": "SANTA FE" },
    { "departamento": "OCOTEPEQUE", "id": "13-14", "nombre": "SENSENTI" },
    { "departamento": "OCOTEPEQUE", "id": "13-15", "nombre": "SINUAPA" },
    { "departamento": "OLANCHO", "id": "14-0", "nombre": "JUTICALPA" },
    { "departamento": "OLANCHO", "id": "14-1", "nombre": "CAMPAMENTO" },
    { "departamento": "OLANCHO", "id": "14-2", "nombre": "CATACAMAS" },
    { "departamento": "OLANCHO", "id": "14-3", "nombre": "CONCORDIA" },
    { "departamento": "OLANCHO", "id": "14-4", "nombre": "DULCE NOMBRE DE CULMI" },
    { "departamento": "OLANCHO", "id": "14-5", "nombre": "EL ROSARIO" },
    { "departamento": "OLANCHO", "id": "14-6", "nombre": "ESQUIPULAS DEL NORTE" },
    { "departamento": "OLANCHO", "id": "14-7", "nombre": "GUALACO" },
    { "departamento": "OLANCHO", "id": "14-8", "nombre": "GUARIZAMA" },
    { "departamento": "OLANCHO", "id": "14-9", "nombre": "GUATA" },
    { "departamento": "OLANCHO", "id": "14-10", "nombre": "GUAYAPE" },
    { "departamento": "OLANCHO", "id": "14-11", "nombre": "JANO" },
    { "departamento": "OLANCHO", "id": "14-12", "nombre": "LA UNION" },
    { "departamento": "OLANCHO", "id": "14-13", "nombre": "MANGULILE" },
    { "departamento": "OLANCHO", "id": "14-14", "nombre": "MANTO" },
    { "departamento": "OLANCHO", "id": "14-15", "nombre": "SALAMA" },
    { "departamento": "OLANCHO", "id": "14-16", "nombre": "SAN ESTEBAN" },
    { "departamento": "OLANCHO", "id": "14-17", "nombre": "SAN FRANCISCO DE BECERRA" },
    { "departamento": "OLANCHO", "id": "14-18", "nombre": "SAN FRANCISCO DE LA PAZ" },
    { "departamento": "OLANCHO", "id": "14-19", "nombre": "SANTA MARIA DEL REAL" },
    { "departamento": "OLANCHO", "id": "14-20", "nombre": "SILCA" },
    { "departamento": "OLANCHO", "id": "14-21", "nombre": "YOCON" },
    { "departamento": "OLANCHO", "id": "14-22", "nombre": "PATUCA" },
    { "departamento": "SANTA BARBARA", "id": "15-0", "nombre": "SANTA BARBARA" },
    { "departamento": "SANTA BARBARA", "id": "15-1", "nombre": "ARADA" },
    { "departamento": "SANTA BARBARA", "id": "15-2", "nombre": "ATIMA" },
    { "departamento": "SANTA BARBARA", "id": "15-3", "nombre": "AZACUALPA" },
    { "departamento": "SANTA BARBARA", "id": "15-4", "nombre": "CEGUACA" },
    { "departamento": "SANTA BARBARA", "id": "15-5", "nombre": "SAN JOSE DE COLINAS" },
    { "departamento": "SANTA BARBARA", "id": "15-6", "nombre": "CONCEPCION DEL NORTE" },
    { "departamento": "SANTA BARBARA", "id": "15-7", "nombre": "CONCEPCION DEL SUR" },
    { "departamento": "SANTA BARBARA", "id": "15-8", "nombre": "CHINDA" },
    { "departamento": "SANTA BARBARA", "id": "15-9", "nombre": "EL NISPERO" },
    { "departamento": "SANTA BARBARA", "id": "15-10", "nombre": "GUALALA" },
    { "departamento": "SANTA BARBARA", "id": "15-11", "nombre": "ILAMA" },
    { "departamento": "SANTA BARBARA", "id": "15-12", "nombre": "MACUELIZO" },
    { "departamento": "SANTA BARBARA", "id": "15-13", "nombre": "NARANJITO" },
    { "departamento": "SANTA BARBARA", "id": "15-14", "nombre": "NUEVO CELILAC" },
    { "departamento": "SANTA BARBARA", "id": "15-15", "nombre": "PETOA" },
    { "departamento": "SANTA BARBARA", "id": "15-16", "nombre": "PROTECCION" },
    { "departamento": "SANTA BARBARA", "id": "15-17", "nombre": "QUIMISTAN" },
    { "departamento": "SANTA BARBARA", "id": "15-18", "nombre": "SAN FRANCISCO DE OJUERA" },
    { "departamento": "SANTA BARBARA", "id": "15-19", "nombre": "SAN LUIS" },
    { "departamento": "SANTA BARBARA", "id": "15-20", "nombre": "SAN MARCOS" },
    { "departamento": "SANTA BARBARA", "id": "15-21", "nombre": "SAN NICOLAS" },
    { "departamento": "SANTA BARBARA", "id": "15-22", "nombre": "SAN PEDRO DE ZACAPA" },
    { "departamento": "SANTA BARBARA", "id": "15-23", "nombre": "SANTA RITA" },
    { "departamento": "SANTA BARBARA", "id": "15-24", "nombre": "SAN VICENTE CENTENARIO" },
    { "departamento": "SANTA BARBARA", "id": "15-25", "nombre": "TRINIDAD" },
    { "departamento": "SANTA BARBARA", "id": "15-26", "nombre": "LAS VEGAS" },
    { "departamento": "SANTA BARBARA", "id": "15-27", "nombre": "NUEVA FRONTERA" },
    { "departamento": "VALLE", "id": "16-0", "nombre": "NACAOME" },
    { "departamento": "VALLE", "id": "16-1", "nombre": "ALIANZA" },
    { "departamento": "VALLE", "id": "16-2", "nombre": "AMAPALA" },
    { "departamento": "VALLE", "id": "16-3", "nombre": "ARAMECINA" },
    { "departamento": "VALLE", "id": "16-4", "nombre": "CARIDAD" },
    { "departamento": "VALLE", "id": "16-5", "nombre": "GOASCORAN" },
    { "departamento": "VALLE", "id": "16-6", "nombre": "LANGUE" },
    { "departamento": "VALLE", "id": "16-7", "nombre": "SAN FRANCISCO DE CORAY" },
    { "departamento": "VALLE", "id": "16-8", "nombre": "SAN LORENZO" },
    { "departamento": "YORO", "id": "17-0", "nombre": "YORO" },
    { "departamento": "YORO", "id": "17-1", "nombre": "ARENAL" },
    { "departamento": "YORO", "id": "17-2", "nombre": "EL NEGRITO" },
    { "departamento": "YORO", "id": "17-3", "nombre": "EL PROGRESO" },
    { "departamento": "YORO", "id": "17-4", "nombre": "JOCON" },
    { "departamento": "YORO", "id": "17-5", "nombre": "MORAZAN" },
    { "departamento": "YORO", "id": "17-6", "nombre": "OLANCHITO" },
    { "departamento": "YORO", "id": "17-7", "nombre": "SANTA RITA" },
    { "departamento": "YORO", "id": "17-8", "nombre": "SULACO" },
    { "departamento": "YORO", "id": "17-9", "nombre": "VICTORIA" },
    { "departamento": "YORO", "id": "17-10", "nombre": "YORITO" }
]