import { Unidad } from "./unidad";
import { Usuario } from "./usuario";

export class CambioEstadoUnidad {
    constructor(
        public id:number,
        public fecha: string,
        public estadoAnterior: string,
        public estadoPosterior: string,
        public observaciones: string,
        public kilometrajeActual: number,
        public fechaInicial: string,
        public horaInicial: string,
        //Relaciones
        public UsuarioId: number,
        public UnidadId: number,
        public ServicioUnidadId: number,
    ){}

    fechaFormato:string;
    fechaDate:Date;
    fechaInicialFormato:string;
    fechaInicialDate:Date;
    usuario: Usuario;
    unidad: Unidad;
    //servicioUnidad : ServicioUnidad;

}