import { Injectable } from '@angular/core';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { JSONConverters } from '../models/JSONConverters';
import { Rol } from '../models/rol';

@Injectable()
export class RolesService { 
    apiUrls: APIUrls = new  APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
    ) { }

    // * * * Roles * * * 
    async obtenerRolPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.rolesURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.rolDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearRol(registro: Rol) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.rolesURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarRol(registro: Rol) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.rolesURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarRol(RolId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.rolesURL + '/id/' + RolId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Rol eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

}