import { Empresa } from './empresa';

export class ConfiguracionPlanMantenimiento {
    constructor(
        public id: number,
        public correosRecordatorios: string,
        public plantillaCorreoContratacion: string,
        public asesoresContrataciones: string,
        //Relaciones
        public EmpresaId: number,
    ){this.cargarArrays()}

    correosRecordatoriosArray: any[];
    asesoresContratacionesArray: any[];

    Empresa: Empresa;

    cargarArrays() {
        
        this.correosRecordatoriosArray = this.correosRecordatorios ? JSON.parse(this.correosRecordatorios) : [];
        this.asesoresContratacionesArray = this.asesoresContrataciones ? JSON.parse(this.asesoresContrataciones) : [];

    }

}