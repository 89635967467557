import { Empresa } from './empresa';
import { Usuario } from './usuario';
import { MarcaVehiculo } from './marcaVehiculo';
import { LineaVehiculo } from './lineaVehiculo';

export class SolicitudTraspaso {
    constructor(
        public id:                              number,
        public numeroPlaca:                     string,
        public nombreCliente:                   string,
        public tipoRecepcionUsado:              string,
        public aplicaPagoDeuda:                 boolean,
        public llevaCheque:                     boolean,
        public clienteFactura:                  boolean,
        public persona:                         string,
        public fechaSolicitud:                  string,
        public sucursalVendedor:                string,
        public sucursalTraspaso:                string,
        public eliminado:                       boolean,
        public estado:                          string,
        public avaluo:                          string,
        public rechazadoAvaluo:                 boolean,
        public dpiPasaporteVigente:             string,
        public rechazadoDpiPasaporteVigente:    boolean,
        public rtu:                             string,
        public rechazadoRTU:                    boolean,
        public impuestoCirculacionAnioCurso:    string,
        public rechazadoImpuestoCirculacionAnioCurso: boolean,
        public omiso:                           string,
        public rechazadoOmiso:                  boolean,
        public solvenciaMulta:                  string,
        public rechazadoSolvenciaMulta:         boolean,
        public tarjetaTitulo:                   string,
        public rechazadoTarjetaTitulo:          boolean,
        public tarjetaCirculacion:              string,
        public rechazadoTarjetaCirculacion:     boolean,
        public representacionLegal:             string,
        public rechazadoRepresentacionLegal:    boolean,
        public ordenCompra:                     string,
        public ModeloId:                        number,
        public rechazoPreOrden:                 boolean,
        public motivoRechazoPreOrden:           string,
        public facturaVehiculo:                 string,
        public rechazadoFacturaVehiculo:        boolean,
        public solvenciaPNC:                    string,
        public rechazadoSolvenciaPNC:           boolean,
        public garantiaMobiliariaSAT:           string,
        public rechazadoGarantiaMobiliariaSAT:  boolean,
        public registroGarantiaMobiliariaGob:   string,
        public rechazadoRegistroGarantiaMobiliariaGob: boolean,
        public cheque:                          string,
        public rechazadoCheque:                 boolean,
        public memoCompraDirecta:               string,
        public rechazadoMemoCompraDirecta:      boolean,
        public promesaDeuda:                    string,
        public rechazadoPromesaDeuda:           boolean,
        public cartaAutorizacionPagoDeuda:      string,
        public rechazadoCartaAutorizacionPagoDeuda: boolean,
        public correoBancoValidarSaldoPago:     string,
        public facturaDelCliente:               string,
        public rechazadoFacturaDelCliente:      boolean,
        public aplicaFiniquitoLiberacionPrenda: boolean,
        public finiquito:                       string,
        public rechazadoFiniquito:              boolean,
        public liberacionPrenda:                string,
        public rechazadoLiberacionPrenda:       boolean,
        public patenteComercio:                 string,
        public rechazadoPatenteComercio:        boolean,
        public patenteSociedad:                 string,
        public rechazadoPatenteSociedad:        boolean,
        public facturaEmpresaVendeValorAvaluo:  string,
        public rechazadoFacturaEmpresaVendeValorAvaluo: boolean,
        public copiaCheque:                     string,
        public rechazadoCopiaCheque:            boolean,
        public rechazoDocumentacionCompleta:    boolean,
        public motivoRechazoDocumentacionCompleta: string,
        public fechaTentativaFirmaPromesa:      string,
        public fechaTentativaFirmaTraspaso:     string,
        public horaTentativaFirmaPromesa:       string,
        public horaTentativaFirmaTraspaso:      string,
        public observacionesDocumentacionFinal: string,
        public abogadoFirmaPromesa:             string,
        public abogadoFirmaTraspaso:            string,
        public fechaFirmaPromesa:               string,
        public horaFirmaPromesa:                string,
        public fechaFirmaTraspaso:              string,
        public horaFirmaTraspaso:               string,
        public documentoMemo:                   string,
        public rechazadoDocumentoMemo:          boolean,

        // Relaciones
        public EmpresaId: number,
        public VendedorId: number,
        public MarcaId: number,
        public LineaId: number,
    ){}

    empresa: Empresa;
    usuario: Usuario;
    marca: MarcaVehiculo;
    linea: LineaVehiculo;
    fechaSolicitudFormato: string;
    fechaSolicitudDate: Date;

    fechaTentativaFirmaPromesaFormato: string;
    fechaTentativaFirmaPromesaDate: Date;

    fechaTentativaFirmaTraspasoFormato: string;
    fechaTentativaFirmaTraspasoDate: Date;

    fechaFirmaPromesaFormato: string;
    fechaFirmaPromesaDate: Date;

    fechaFirmaTraspasoFormato: string;
    fechaFirmaTraspasoDate: Date;
}