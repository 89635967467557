
import { Empresa } from './empresa';
import { MembreteEmpresa } from './membreteEmpresa';
import { Usuario } from './usuario';

export class HojaMembretada {
    constructor(
        public id:                          number,
        public fechaCreacion:               string,
        public fechaActualizacion:          string,
        public titulo:                      string,
        public descripcion:                 string,
        public estado:                      string,
        public contenido:                   string,
        public descargas:                   number,
        public eliminado:                   boolean,
        public variables:                   string,
        public casosDeUso:                  string,
        public observacionesSolicitud:      string,
        public fechaCarta:                  string,

        // Relaciones
        public UsuarioId:                   number,
        public MembreteEmpresaId:           number,
        public EmpresaId:                   number,
    ){}

    empresa:            Empresa;
    membrete:           MembreteEmpresa;
    usuario:            Usuario;
    variablesModel:     Variable [];
    casosDeUsoModel:         any [];
    
    // Fechas
    fechaCreacionFormato:           string;
    fechaCreacionDate:              Date;
    
    fechaActualizacionFormato:      string;
    fechaActualizacionDate:         Date;

    estadoFormat:                   string;
}
export class Variable {
    constructor(
        public tag:                 string = null,
        public nombre:              string = null,
    ){}
}