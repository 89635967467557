export class CajeroFacturaSV {
    constructor(
        public id: number,
        public numero: string,
        public dui: string,
        public nombreCompleto: string,
        public sucursal: string,
        public EmpresaId: number,
    ) {
    }
}