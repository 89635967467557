import { APIUrls } from '../api/apiUrls';
import { Empresa } from './empresa';
import { JSONConverters } from './JSONConverters';

export class ConfiguracionPDI {
    constructor(
        public id: number,
        public notificacionesCreacionSolicitud: string,
        public notificacionesAutorizacionSolicitud: string,
        public notificacionesPasoKanboard: string,
        public notificacionesAccesoriosFueraAcuerdo: string,
        public tiposVenta: string,
        public notificarDocumentosCompletos: boolean,
        public notificacionesCreacionExhibicion: string,
        public sucursales: string,
        public tiposPases: string,
        public usuariosNotificacionesKanboard: string,
        public emailsReporteria: string,
        public horarioReportes: string,
        public ultimaActualizacionDatosVentas: string,
        public historialActualizacionesDatosVentas: string,
        public horasBloqueoInspeccionSalida: number,
        public horasBloqueoInspeccionEntrada: number,
        public estadoLlantas: string,
        public nivelGasolina: string,
        public entregadoPor: string,
        public sucursalesInspeccionSalida: string,
        public recibidoPor: string,
        public sucursalesInspeccionEntrada: string,
        public tiposDanios: string,
        public calificacionPDI: string,
        public horasBloqueoInspeccionEntradaYSalida: number,
        //Relaciones
        public EmpresaId: number,
    ){
        this.cargarArrays();
        this.conversiones();
    }

    jsonConverts: JSONConverters = new JSONConverters();

    notificacionesCreacionSolicitudArray: any[];
    notificacionesAutorizacionSolicitudArray: any[];
    notificacionesPasoKanboardArray: any[];
    notificacionesAccesoriosFueraAcuerdoArray:any[];
    notificacionesCreacionExhibicionArray:any[];
    tiposVentaArray: any[];
    sucursalesArray: any[];
    tiposPasesArray: any[];
    usuariosNotificacionesKanboardArray: any[];
    emailsReporteriaArray: any[];
    historialActualizacionesDatosVentasArray: string[];
    horarioReportesObjeto: any;
    horariosAnteriores: any;
    ultimaActualizacionDatosVentasFormato: string;
    estadoLlantasArray: any[];
    nivelGasolinaArray: any[];
    entregadoPorArray: any[];
    sucursalesInspeccionSalidaArray: any[];
    recibidoPorArray: any[];
    sucursalesInspeccionEntradaArray: any[];
    tiposDaniosArray: any[];
    calificacionPDIArray: any[];

    Empresa: Empresa;

    cargarArrays() {
        
        this.notificacionesCreacionSolicitudArray = this.notificacionesCreacionSolicitud? JSON.parse(this.notificacionesCreacionSolicitud) : [];
        this.notificacionesAutorizacionSolicitudArray = this.notificacionesAutorizacionSolicitud ? JSON.parse(this.notificacionesAutorizacionSolicitud) : [];
        this.notificacionesPasoKanboardArray = this.notificacionesPasoKanboard ? JSON.parse(this.notificacionesPasoKanboard) : [];
        this.notificacionesAccesoriosFueraAcuerdoArray = this.notificacionesAccesoriosFueraAcuerdo ? JSON.parse(this.notificacionesAccesoriosFueraAcuerdo) : [];
        this.tiposVentaArray = this.tiposVenta ? JSON.parse(this.tiposVenta) : [];
        this.notificacionesCreacionExhibicionArray = this.notificacionesCreacionExhibicion ? JSON.parse(this.notificacionesCreacionExhibicion) : [];
        this.sucursalesArray = this.sucursales ? JSON.parse(this.sucursales) : [];
        this.tiposPasesArray = this.tiposPases ? JSON.parse(this.tiposPases) : [];
        this.usuariosNotificacionesKanboardArray = this.usuariosNotificacionesKanboard ? JSON.parse(this.usuariosNotificacionesKanboard) : [];
        this.emailsReporteriaArray = this.emailsReporteria ? JSON.parse(this.emailsReporteria) : [];
        this.historialActualizacionesDatosVentasArray = this.historialActualizacionesDatosVentas ? JSON.parse(this.historialActualizacionesDatosVentas) : [];
        this.estadoLlantasArray = this.estadoLlantas ? JSON.parse(this.estadoLlantas) : [];
        this.nivelGasolinaArray = this.nivelGasolina ? JSON.parse(this.nivelGasolina) : [];
        this.entregadoPorArray = this.entregadoPor ? JSON.parse(this.entregadoPor) : [];
        this.sucursalesInspeccionSalidaArray = this.sucursalesInspeccionSalida ? JSON.parse(this.sucursalesInspeccionSalida) : [];
        this.recibidoPorArray = this.recibidoPor ? JSON.parse(this.recibidoPor) : [];
        this.sucursalesInspeccionEntradaArray = this.sucursalesInspeccionEntrada ? JSON.parse(this.sucursalesInspeccionEntrada) : [];
        this.tiposDaniosArray = this.tiposDanios ? JSON.parse(this.tiposDanios) : [];
        this.calificacionPDIArray = this.calificacionPDI ? JSON.parse(this.calificacionPDI) : [];
        this.cargarHorarios();

    }

    cargarHorarios(){
        this.horarioReportesObjeto = this.horarioReportes ? JSON.parse(this.horarioReportes) : {
            diario: [{ check:false},{ check:false},{ check:false},{ check:false},{ check:false},{ check:false},{ check:false}], //Domingo a Sabado,
            semanal: [{ check:false},{ check:false},{ check:false},{ check:false},{ check:false},{ check:false},{ check:false}], //Domingo a Sabado,
            horario: [{horario: null},{horario: null},{horario: null},{horario: null},{horario: null},{horario: null},{horario: null}],
        };
        this.horariosAnteriores = this.horarioReportes+'';
    }

    conversiones(){
        let aux:NotificacionAutorizacionSolicitud[] = [];
        for(let notif of this.notificacionesAutorizacionSolicitudArray){

            let notificacion = this.jsonConverts.notificacionAutorizacionSolicitudDeJSON(notif);
            if(notificacion) aux.push(notificacion);

        }
        this.notificacionesAutorizacionSolicitudArray = aux;
    }

}

export class NotificacionAutorizacionSolicitud{

    constructor(
        //public nombre: string,
        public correo: string,
        //public codigo: string,
        //public notificar: boolean,
        //public sede: string,
        public nombreCoach: string,
        public nombreVendedor: string,
        public correoVendedor: string,
    ){}

}