export class ContenidoFormularioAhorrent {
    constructor(
        // Paso 2
        public nombreCliente: string = null,
        public nitCliente: string = null,
        public dpiPasaporte: string = null,
        public noLicenciaConducir: string = null,
        public fechaEmision: string = null,
        public fechaExpiracion: string = null,
        public lugarEmision: string = null,
        public telefono: string = null,
        public fechaNacimiento: string = null,
        public edad: number = null,
        public direccionLocal: string = null,
        public nombreCompania: string = null,
        public nombreConductorAdicional: string = null,
        public agenciaViajesReferidoPor: string = null,
        public establecimiento: string = null,
        public contacto: string = null,
        public observacionesCliente: string = null,
        public correoElectronico: string = null,
        public pilotoAdicional: string = null,
        public DPIpilotoAdicional: string = null,
        public NITpilotoAdicional: string = null,
        public fechaExpiracionLicencia: string = null,
        public nacionalidad: string = null, 
        public noLicenciaConducirPilotoAdicional: string = null,
        public fechaExpiracionLicenciaPilotoAdicional: string = null,
        public direccionCompania: string = null,
        public firmaCliente: string = null,
        public inicialesCliente: string = null,
        public firmaClienteEntrada: string = null,
        public inicialesClienteEntrada: string = null,
        // Paso 3
        public arrendatarioAutorizaCobro: boolean = false,
        public marcaTarjetaCredito: string = null,
        public numeroTarjetaCredito: string = null,
        public fechaVencimientoTarjetaCredito: string = null,
        public montoTarjetaCredito: number = null,
        public noAutorizacionTarjetaCredito: string = null,
        public reservacion: boolean = false,
        public numeroReservacion: string = null,
        public prePagado: boolean = false,
        public modoPago: string = null,
        // Paso 4
        public numeroVehiculo: string = null,
        public placa: string = null,
        public marca: string = null,
        public linea: string = null,
        public modelo: string = null,
        public color: string = null,
        public motor: string = null,
        public chasis: string = null,
        public observacionesVehiculo: string = null,
        // Paso 5
        public aplicaDeducible: boolean = false,
        public arrendatarioAceptaCuotaProteccionChoques: boolean = false,
        public diasArrendatarioAceptaCuotaProteccionChoques: number = null,
        public montoParticipacion: number = null,
        public arrendatarioAceptaCuotaProteccionAccidentesPersonales: boolean = false,
        public diasArrendatarioAceptaCuotaProteccionAccidentesPersonales: number = null,
        public gasolinaSalida: string = null,
        public gasolinaEntrada: string = null,
        public montoDeposito: number = null,
        public reciboDeposito: string = null,
        public cantidadPagadaDolares: number = null,
        public cantidadPagadaMonedaLocal: number = null,
        public devolucionEfectivoDolares: number = null,
        public devolucionEfectivoMonedaLocal: number = null,
        public lugarDevolucionVehiculo: string = null,
        public direccionDevolucionVehiculo: string = null,
        public fechaDevolucionVehiculo: string = null,
        public horaDevolucionVehiculo: string = null,
        public direccionEntregaVehiculo: string = null,
        // Paso 6
        public fechaEntregaVehiculo: string = null,
        public fechaSalida: string = null,
        public horaSalida: string = null,
        public fechaEntrada: string = null,
        public horaEntrada: string = null,
        public nocheLibre: boolean = false,
        public diasAlquiler: number = null,
        public horasAlquiler: number = null,
        public unidadOdometro: string = null,
        public distanciaSalida: number = null,
        public distanciaEntrada: number = null,
        public distanciaRecorrida: number = null,
        public costoUnitarioKilometros: number = null,
        public montoKilometrosRecorridos: number = null,
        public horasExtras: number = null,
        public costoUnitarioHorasExtras: number = null,
        public montoHorasExtras: number = null,
        public dias: number = null,
        public costoUnitarioDias: number = null,
        public costoUnitarioDias1a6: number = null,
        public costoUnitarioDias7a25: number = null,
        public costoUnitarioDias25: number = null,
        public montoDias: number = null,
        public semanas: number = null,
        public costoUnitarioSemanas: number = null,
        public montoSemanas: number = null,
        public otrosCostos: number = null,
        public recargo:number = null,
        public descuento:number = null,
        public observacionCambioSubtotal:string = null,
        public subtotal: number = null,
        public subtotalSistema: number = null,
        public otrosCostosLuegoSubtotal: number = null,
        public otrosCargos: number = null,
        public cobroCombustible: number = null,
        public danosVehiculo: number = null,
        // P.A.P.
        public pap: number = null,
        public papDias: number = null,
        public papUnitario: number = null,
        // Seguros y servicios
        public seguroCuotaConductorMenor25Check: boolean = null,
        public seguroCuotaConductorMenor25Unitario: number = null,
        public seguroCuotaConductorMenor25Dias: number = null,
        public seguroCuotaConductorMenor25: number = null,
        public seguroProteccionLlantasParabrisasCheck: boolean = null,
        public seguroProteccionLlantasParabrisasUnitario: number = null,
        public seguroProteccionLlantasParabrisasDias: number = null,
        public seguroProteccionLlantasParabrisas: number = null,
        public servicioCartaPoderFronteraCheck: boolean = null,
        public servicioCartaPoderFrontera: number = null,
        public servicioNocheLibreCheck: boolean = null,
        public servicioNocheLibre: number = null,
        public servicioSillaBebeCheck: boolean = null,
        public servicioSillaBebeUnitario: number = null,
        public servicioSillaBebeDias: number = null,
        public servicioSillaBebe: number = null,
        public servicioAcoplarRemolqueCheck: boolean = null,
        public servicioAcoplarRemolqueUnitario: number = null,
        public servicioAcoplarRemolqueDias: number = null,
        public servicioAcoplarRemolque: number = null,
        public servicioCamperPickupCheck: boolean = null,
        public servicioCamperPickupUnitario: number = null,
        public servicioCamperPickupDias: number = null,
        public servicioCamperPickup: number = null,
        public servicioPilotoCheck: boolean = null,
        public servicioPilotoUnitario: number = null,
        public servicioPilotoDias: number = null,
        public servicioPiloto: number = null,
        public totalSegurosServicios: number = null,
        // Descuento
        public codigoDescuento: string = null,
        public porcentajeDescuento: number = null,
        public montoPorcentajeDescuento: number = null,
        public proteccionChoquesDias: number = null,
        public proteccionChoquesUnitario: number = null,
        public montoProteccionChoques: number = null,
        public cargoEntreCiudades: number = null,
        public porcentajeIVA: number = null,
        public montoIVA: number = null,
        public cargosGasolina: number = null,
        public cargosTotales: number = null,
        public depositoPagado: number = null,
        public netoAPagar: number = null,
        public netoAPagarDolares: number = null,
        public asignacionPersonalizadaCostoDias: boolean = false,


        //Referidos
        public nombreContactoReferido: string = null,
        public telefonoReferido: string = null,
        public direccionReferido: string = null,
        public montoComision: string = null, 

        //Información de aeropuerto entrada
        public numeroVueloEntrada: string = null,
        public aerolineaEntrada: string = null,
        public numeroPasajerosEntrada: string = null,
        public nombreAeropuertoEntrada: string = null,
        public ciudadEntrada: string = null,
        public fechaArriboEntrada: string = null,

        //Información de aeropuerto salida
        public numeroVueloSalida: string = null,
        public aerolineaSalida: string = null,
        public numeroPasajerosSalida: string = null,
        public nombreAeropuertoSalida: string = null,
        public ciudadSalida: string = null,
        public fechaPartidaSalida: string = null,

        //Información de aeropuerto
        public infoAeropuertoEntrada: any[] = [],
        public infoAeropuertoSalida: any[] = [],

        //notas
        public notas: any[] = [] ,

        // Logs
        public logsImagenes: string[] = [],
    ){
    }
    
    fechaEmisionDate: Date;
    fechaEmisionFormato: string;
    fechaExpiracionDate: Date;
    fechaExpiracionFormato: string;
    fechaNacimientoDate: Date;
    fechaNacimientoFormato: string;
    fechaVencimientoTarjetaCreditoDate: Date;
    fechaVencimientoTarjetaCreditoFormato: string;
    fechaDevolucionVehiculoDate: Date;
    fechaDevolucionVehiculoFormato: string;
    fechaSalidaDate: Date;
    fechaSalidaFormato: string;
    fechaEntradaDate: Date;
    fechaEntradaFormato: string;
    fechaExpiracionLicenciaDate: Date;
    fechaExpiracionLicenciaFormato: string;
    fechaExpiracionLicenciaPilotoAdicionalDate: Date;
    fechaExpiracionLicenciaPilotoAdicionalFormato: string;
    fechaEntregaVehiculoDate: Date;
    fechaEntregaVehiculoFormato: string;
    fechaArriboEntradaDate: Date;
    fechaArriboEntradaFormato: string;
    fechaPartidaSalidaDate: Date;
    fechaPartidaSalidaFormato: string;




    //auxiliares
    conduce: boolean = true;
}