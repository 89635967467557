
import { Empresa } from './empresa';
import { SolicitudPruebaManejo } from './SolicitudPruebaManejo';

export class DocumentoSolicitud {
    constructor(
        public id: number,         
        public descripcion: string,         
        public url: string,
        // Relaciones
        public SolicitudPruebaManejoId: number,
        public EmpresaId: number,
    ){}

    SolicitudPruebaManejo: SolicitudPruebaManejo;
    Empresa: Empresa;

}