import { ClienteAhorrent } from './clienteAhorrent';

export class DocumentoCliente {
    constructor(
        public id: number,
        public tipo: string,          
        public descripcion: string,         
        public url: string,
        public fechaExpiracion:Date,
        // Relaciones
        public ClienteId: number,
    ){}

    cliente: ClienteAhorrent;

    fechaExpiracionFormato: string;
    fechaExpiracionDate: Date;


}