import { SolicitudTraspaso } from './solicitudTraspaso';
import { Usuario } from './usuario';

export class CambioEstadoSolicitudTraspaso {
    constructor(
        public id:                      number,
        public fecha:                   string,
        public estadoAnterior:          string,
        public estadoPosterior:         string,
        public observaciones:           string,

        // Relaciones
        public UsuarioId: number,
        public SolicitudTraspasoId: number,
    ){}

    solicitudTraspaso: SolicitudTraspaso;
    usuario: Usuario;
    fechaFormato: string;
    fechaDate: Date;
}