export class DetalleFacturaSV {
    constructor(
        public id: number,
        public numeroFactura: string,
        public codigoMarca: string,
        public descripcionMarca: string,
        public inventario: string,
        public chasis: string,
        public facturadoValorNeto: number,
        public facturadoIVA: number,
        public facturadoTotal: number,
        public descuento: number,
        public retencion: number,
        public textoRetencion: string,
        public tipoVehiculo: string,
        public noReferencia: string,
        public codigoNuevoUsado: string,
        public descripcionNuevoUsado: string,
        public codigoSerie: string,
        public serieMadre: string,
        public serieDescripcion: string,
        public modelo: string,
        public motor: string,
        public color: string,
        public combustible: string,
        public transmision: string,
        public tonelaje: string,
        public observaciones: string,
        public unidades: string,
        public codigoProducto: string,
        public descripcionProducto: string,
        public desplazamiento: string,
        public FacturaSVId: number,
    ) {
    }
}