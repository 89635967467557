import { Version } from "./version";

export class VersionLineaSOB {
    constructor(
        public id: number,
        public anio: number,
        public codigoLinea: string,
        public nombreLinea: string,
        public nombreVersion: string,
        public tipoMotor: string,
        public descontinuado: boolean,

        //Relaciones
        public VersionId: number,
    ){}

    Version: Version
}