import { Injectable } from '@angular/core';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { JSONConverters } from '../models/JSONConverters';
import * as FileSaver from 'file-saver';
import { ValorFacturaEspecial } from '../models/ValorFacturaEspecial';
import { FacturaSV } from '../models/FacturaSV';
import { CajeroFacturaSV } from '../models/CajeroFacturaSV';
@Injectable()
export class FacturasSVService { 
    apiUrls: APIUrls = new  APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
    ) { }

    //Configuracion
    public async obtenerConfiguracion(EmpresaId:number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.configuracionFacturasSVURL+`?&EmpresaId=${EmpresaId}`;
            const res = await this.connection.getRequest(url);
            var registro = this.jsonConverters.configuracionFacturaSVDeJSON(res.result);
            return { error: null, data: { result: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async actualizarConfiguracion(registro) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.configuracionFacturasSVURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Configuracion facturación SV actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * Facturas SV * * * 
    async obtenerFacturaSVPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.facturasSVURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.facturaSVDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async descargarExcelFacturasSV(nombre: string, params:string) {
        try {
            // Construir ruta
            var url = this.apiUrls.baseURL + this.apiUrls.facturasSVURL + '/descargar-excel';
            if(params) url+= '?'+ params;
            var response = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `${nombre}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([ response ], { type: mediaType });
            FileSaver.saveAs(blob, filename);
            return response;
        } catch (error) {
            return null;
        }
    }

    public async anularFacturaSV(id: number, motivo: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.facturasSVURL + '/anular';
            const res = await this.connection.postRequest(url, { id: id, motivo: motivo });
            return { error: null, data: { mensaje: 'Factura anulada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async emitirNotaCreditoFacturaSV(id: number, nrc: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.facturasSVURL + '/emitir-nota-credito';
            const res = await this.connection.postRequest(url, { id: id, nrc: nrc });
            return { error: null, data: { mensaje: 'Nota de crédito emitida con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async emitirComprobanteCreditoFiscalSV(id: number, nrc: string, contingencia: boolean = false) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.facturasSVURL + '/emitir-comprobante-credito-fiscal';
            const res = await this.connection.postRequest(url, { id: id, nrc: nrc, contingencia: contingencia });
            return { error: null, data: { mensaje: 'Comprobante de crédito fiscal emitido con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async emitirFacturaSV(id: number, contingencia: boolean = false) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.facturasSVURL + '/emitir-factura';
            const res = await this.connection.postRequest(url, { id: id, contingencia: contingencia });
            return { error: null, data: { mensaje: 'Factura emitida con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * Valores especiales facturas * * *
    async obtenerValorFacturaEspecialPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.valoresFacturasEspecialesURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.valorFacturaEspecialDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearValorFacturaEspecial(registro: ValorFacturaEspecial) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.valoresFacturasEspecialesURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarValorFacturaEspecial(registro: ValorFacturaEspecial) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.valoresFacturasEspecialesURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarValorFacturaEspecial(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.valoresFacturasEspecialesURL + '/id/' + id;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Registro eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * Cajeros facturas * * *
    async obtenerCajeroFacturaSVPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.cajerosFacturasSVURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.cajeroFacturaSVDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearCajeroFacturaSV(registro: CajeroFacturaSV) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.cajerosFacturasSVURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarCajeroFacturaSV(registro: CajeroFacturaSV) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.cajerosFacturasSVURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarCajeroFacturaSV(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.cajerosFacturasSVURL + '/id/' + id;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Registro eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async pruebasLecturas() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.facturasSVURL + '/probar-lecturas';
            this.connection.getRequest(url);
            return { error: null, data: { mensaje: 'Se ejecutarán las lecturas de archivo en segundo plano.' } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async pruebasEmisiones() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.facturasSVURL + '/probar-emisiones';
            this.connection.getRequest(url);
            return { error: null, data: { mensaje: 'Se ejecutarán las emisiones de facturas en segundo plano.' } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async actualizarFacturaSV(registro: FacturaSV) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.facturasSVURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
}