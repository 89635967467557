import { Presupuesto} from "./presupuesto";
import { Usuario } from "./usuario";

export class CambioEstadoPresupuesto {
    constructor(
        public id:number,
        public fecha: string,
        public estadoAnterior: string,
        public estadoPosterior: string,
        public observaciones: string,
        public tiempoTranscurrido: number,

        //Relaciones
        public UsuarioId: number,
        public PresupuestoId: number,
    ){}

    fechaFormato:string;
    fechaDate:Date;
    Usuario: Usuario;
    Presupuesto: Presupuesto
    ;

}