import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, SimpleChange, ChangeDetectorRef } from '@angular/core';
import iconClose from '@iconify/icons-ic/twotone-close';

@Component({
  selector: 'mat-select-search',
  templateUrl: './mat-select-search.component.html',
  styleUrls: ['./mat-select-search.component.scss']
})
export class MatSelectSearchComponent implements OnInit {
    @Input() placeholder: string;
    @Input() searchPlaceholder: string = 'Search';
    @Input() idProp: string = 'id';
    @Input() titleProp: string = 'title';
    @Input() subtitleProp: string;
    @Input() records: any[];
    @Input() disabled: boolean;
    @Input() id: any;
    @Output() idChange: EventEmitter<any> = new EventEmitter();
    
    recordsQuery: any[];
    query: string;
    iconClose = iconClose;

    constructor(private changeDetector: ChangeDetectorRef) { }

    ngOnInit() {
        this.recordsQuery = this.records.slice();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.changeDetector.detectChanges();
        if(changes.records && this.records){
            this.recordsQuery = this.records.slice();
            this.query = null;
            this.search();
        }
    }

    search() {
        this.recordsQuery = [];
        if(this.query) {
            var queryCompare = this.query.toLowerCase();
            this.records.forEach(record => {
                var titleCompare = record[this.titleProp].toLowerCase().indexOf(queryCompare) != -1;
                var subtitleCompare = false;
                if(this.subtitleProp){
                    subtitleCompare = record[this.subtitleProp].toLowerCase().indexOf(queryCompare) != -1;
                }
                if(titleCompare || subtitleCompare) {
                    this.recordsQuery.push(record);
                }
            });
        }
        else {
            this.recordsQuery = this.records.slice();
        }
    }

    deleteQuery() {
        this.query = null;
        this.search();
    }

    itemSelected(item) {
        this.id = item.value;
        this.idChange.emit(this.id);
    }

    _handleKeydown(event: KeyboardEvent) {
        if (event.keyCode === 32) {
            // Don't propagate spaces
            event.stopPropagation();
        }
    }
}
