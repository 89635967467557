import { Injectable } from '@angular/core';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { FormularioFEIC } from '../models/formularioFEIC';
import { JSONConverters } from '../models/JSONConverters';
import * as FileSaver from 'file-saver';
import { VentasService } from '../ventas/ventas.service';

@Injectable()
export class FormulariosFEICService { 
    apiUrls: APIUrls = new  APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
        private serviceVentas: VentasService,
    ) { }

    // * * * FormulariosFEIC * * * 
    async obtenerFormularioFEICPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.formulariosFEICURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.formularioFEICDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearFormularioFEIC(registro: FormularioFEIC) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.formulariosFEICURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'FormularioFEIC creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarFormularioFEIC(registro: FormularioFEIC) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.formulariosFEICURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'FormularioFEIC actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarFormularioFEIC(FormularioFEICId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.formulariosFEICURL + '/id/' + FormularioFEICId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'FormularioFEIC eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * Formulario FEIC * * * 
    async descargarFormulariosFEIC(idsFormularios: number[], nombreFinal: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.formulariosFEICURL + `/exportar?idsFormularios=${idsFormularios.toString()}`;
            var res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var fileName = nombreFinal + '.zip';
            var mediaType = 'octet/stream';
            var blob = new Blob([ res ], { type: mediaType });
            FileSaver.saveAs(blob, fileName);

            return { error: null, data: { mensaje: 'Archivos descargado con éxito.' } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async descargarExcelRiesgoFormularios(idsFormularios: number[], nombre: string, fechaInicioParam, fechaFinParam) {
        try {
            // Construir ruta
            var url = this.apiUrls.baseURL + this.apiUrls.formulariosFEICURL + `/exportarExcelRiesgoFormularios?&fechaInicioParam=${fechaInicioParam}&fechaFinParam=${fechaFinParam}`;
            var response = await this.connection.postDownloadRequest(url, { idsFormularios: idsFormularios });

            // Descargar archivo
            var filename = `${nombre}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([ response ], { type: mediaType });
            FileSaver.saveAs(blob, filename);
            return response;
        } catch (error) {
            return null;
        }
    }

}