import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { GenericDialogConfirmacion } from 'src/app/library/generic-dialog-confirmacion/generic-dialog-confirmacion.component';
import { LibraryService } from 'src/app/library/library.service';
import iconSave from '@iconify/icons-ic/twotone-save';
import iconBack from '@iconify/icons-ic/twotone-arrow-back';
import iconDownload from '@iconify/icons-ic/twotone-cloud-download';
import iconUpload from '@iconify/icons-ic/twotone-file-upload';
import iconDelete from '@iconify/icons-ic/twotone-delete';
import iconClose from '@iconify/icons-ic/twotone-close';
import iconCheck from '@iconify/icons-ic/twotone-check';
import iconPlus from '@iconify/icons-ic/twotone-plus';
import iconEdit from '@iconify/icons-ic/twotone-edit';

export interface DialogData {
    urlDocumento: string,
    titulo: string,
}

@Component({
    selector: 'vex-dialog-preview-documento',
    templateUrl: './dialog-preview-documento.component.html',
    styleUrls: ['./dialog-preview-documento.component.scss']
})
export class DialogPreviewDocumentoComponent implements OnInit {
    iconSave = iconSave;
    iconBack = iconBack;
    iconDownload = iconDownload;
    iconUpload = iconUpload;
    iconDelete = iconDelete;
    iconClose = iconClose;
    iconCheck = iconCheck;
    iconPlus = iconPlus;
    iconEdit = iconEdit;
    archivoCargado:boolean = false;
    cargando: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<DialogPreviewDocumentoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private libraryService: LibraryService,
        private router: Router,
        public dialog: MatDialog,
        private auth: AuthService
    ) { }

    ngOnInit(): void {
        // Arreglar estilo
        var dialog = document.getElementById("dialogOverflow");
        var dialogContainer = null;
        if(dialog) dialogContainer = dialog.parentElement;
        if(dialogContainer) dialogContainer = dialogContainer.parentElement;
        if(dialogContainer) dialogContainer = dialogContainer.parentElement;
        if(dialogContainer) dialogContainer.className += ' dialogOverflow';
    }

    cerrar() {
        this.dialogRef.close(null);
    }

    descargar() {
        if (this.data.urlDocumento) {
            window.open(this.data.urlDocumento, "_blank");
        }
    }

}
