export class VehiculoSOB {
    constructor(
        public id: number,
        public chasis: string,
        public marca: string,
        public anio: number,
        public motor: string,
        public tipoMotor: string,
        public codigoLinea: string,
        public nombreLinea: string,
        public codigoVersion: string,
        public nombreVersion: string,
        public codigoColor: string,
        public placas: string,
        public fechaVenta: string,
    ){}

    fechaVentaDate: Date;
    fechaVentaFormato: string;
}