import * as FileSaver from 'file-saver';
import { Injectable } from '@angular/core';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { JSONConverters } from '../models/JSONConverters';
import { SolicitudTraspaso } from '../models/solicitudTraspaso';

@Injectable()
export class solicitudTraspasoService { 
    apiUrls: APIUrls = new  APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
    ) { }

    // * * * Configuraciones * * *
    public async obtenerConfiguracion(EmpresaId:number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.configuracionDigitalizacionTraspasosURL + `?&EmpresaId=${EmpresaId}`;
            const res = await this.connection.getRequest(url);
            var registro = this.jsonConverters.configuracionDigitalizacionTraspasosDeJSON(res.result);
            return { error: null, data: { result: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);   
        }
    }

    public async actualizarConfiguracion(registro) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.configuracionDigitalizacionTraspasosURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Configuración actualizada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * SolicitudTraspasos * * * 
    public async obtenerTodosSolicitudTraspasos() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudTraspasosURL + '?';
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                var element = json[i];
                var registro = this.jsonConverters.solicitudTraspasoDeJSON(element);
                registros.push(registro);
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async obtenerTodosSolicitudTraspasosConParametros(params: string = null) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudTraspasosURL + '?';
            if (params != null) {
                url += params;
            }
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                var element = json[i];
                var registro = this.jsonConverters.solicitudTraspasoDeJSON(element);
                registros.push(registro);
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async obtenerSolicitudTraspasoPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudTraspasosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.solicitudTraspasoDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearSolicitudTraspaso(registro: SolicitudTraspaso) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudTraspasosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Solicitud de Traspaso creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarSolicitudTraspaso(registro: SolicitudTraspaso) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudTraspasosURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: res.message, result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar Estado Manualmente
    public async actualizarEstadoSolicitudTraspaso(registro: SolicitudTraspaso) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudTraspasosURL + '/cambioEstado';
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: res.message, result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar documentos
    public async actualizarDocumentosSolicitudTraspaso(registro: SolicitudTraspaso) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudTraspasosURL + '/documentos';
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: res.message, result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar orden de compra avalúo
    public async actualizarOrdenCompraAvaluoSolicitudTraspaso(registro: SolicitudTraspaso) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudTraspasosURL + '/ordenCompraAvaluo';
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: res.message, result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Regresar cualquier solicitud avanzada de estado devuelta a Documentación Inicial
    public async regresarSolicitudTraspasoDocumentacionInicial(registro: SolicitudTraspaso) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudTraspasosURL + '/regresarDocumentacionInicial';
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: res.message, result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Regresar cualquier solicitud avanzada de estado devuelta a Documentación Final
    public async regresarSolicitudTraspasoDocumentacionFinal(registro: SolicitudTraspaso) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudTraspasosURL + '/regresarDocumentacionFinal';
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: res.message, result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar documentos de Documentacion Final
    public async actualizarDocumentosDocumentacionFinalSolicitudTraspaso(registro: SolicitudTraspaso) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudTraspasosURL + '/documentacionFinal';
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: res.message, result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar documentos de Documentacion Completa
    public async actualizarDocumentosDocumentacionCompletaSolicitudTraspaso(registro: SolicitudTraspaso) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudTraspasosURL + '/documentacionCompleta';
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: res.message, result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar elementos de Cita en Solicitud Traspaso
    public async actualizarCita(registro: SolicitudTraspaso) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudTraspasosURL + '/cita';
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: res.message, result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Forzar elementos de Cita en Solicitud Traspaso
    public async forzarCita(registro: SolicitudTraspaso) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudTraspasosURL + '/forzarCita';
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: res.message, result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    //Actualizar estado a Finalizada
    public async actualizarEstadoFinalizada(registro: SolicitudTraspaso) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudTraspasosURL + '/finalizada';
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: res.message, result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarSolicitudTraspaso(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudTraspasosURL + '/id/' + id;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Solicitud de Traspaso eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * Cambios de Estado * * *
    public async obtenerTodosCambioEstadoSolicitudTraspaso() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudTraspasosURL + '/estado/?';
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.cambioEstadoSolicitudTraspasoDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los estados.');
        }
    }

    // Obtener Estado de Solicitud de Traspaso mediante el ID dentro del mismo componente de solicitud de traspasos
    async obtenerEstadoSolicitudTraspasoPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.cambioEstadoSolicitudTraspasoURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.cambioEstadoSolicitudTraspasoDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Descargar Excel de Solicitud de Traspaso
    public async descargarExcelSolicitudTraspaso(nombre: string, params:string) {
        try {
            // Construir ruta
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudTraspasosURL + '/descargarExcel';
            if(params) url+= '?'+ params;
            var response = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `${nombre}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([ response ], { type: mediaType });
            FileSaver.saveAs(blob, filename);
            return response;
        } catch (error) {
            return null;
        }
    }

    // Reporte de Solicitud de Traspaso
    async obtenerReporte(fechaInicioParam: any, fechaFinParam: any, params = '') {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudTraspasosURL + '/reporte?';
            if (fechaInicioParam) url += '&start=' + fechaInicioParam;
                if (fechaFinParam) url += '&finish=' + fechaFinParam;
            if (params) url += params;
            var json = await this.connection.getRequest(url);
            var registro = json;
            return { error: null, data: { registro: registro.data } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Cancelar Solicitud
    async cancelarSolicitudTraspaso(registro: SolicitudTraspaso) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudTraspasosURL + '/cancelarSolicitud';
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Solicitud cancelada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Enviar documentos por correo
    async enviarDocumentosPorCorreo(solicitudId, email, arrayDocumentos, observaciones) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudTraspasosURL + '/enviarDocumentosPorCorreo';

            let enviar = {
                solicitudId: solicitudId,
                email: email,
                arrayDocumentos: arrayDocumentos,
                observaciones: observaciones
            };

            const res = await this.connection.postRequest(url, enviar);
            return { error: null, data: { mensaje: 'Documentos Enviados con Éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Buscar Avaluos
    async buscarDocumentosAvaluos(numeroPlaca: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudTraspasosURL + '/buscarDocumentoAvaluos/' + numeroPlaca;
            var json = await this.connection.getRequest(url);
            var registro = json;
            return { error: null, data: { registro: registro.data } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Obtener filtrado de abogados
    async obtenerAbogadosFiltrados(diaTentativo, sucursalTraspaso, fechaTentativa, esCita) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudTraspasosURL + '/filtrado-abogados/' + diaTentativo + '/' + sucursalTraspaso + '/' + fechaTentativa + '/' + esCita;

            const res = await this.connection.getRequest(url);
            return { error: null, data: { mensaje: 'Filtrado realizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
}