import { Injectable } from '@angular/core';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { JSONConverters } from '../models/JSONConverters';
import { Concesionario } from '../models/concesionario';
import { Cita } from '../models/cita';
import * as FileSaver from 'file-saver';
import { VersionLineaSOB } from '../models/versionLineaSOB';
import { VehiculoSOB } from '../models/vehiculoSOB';
import * as Papa from 'papaparse';
import * as languageEncoding from 'detect-file-encoding-and-language';
import moment from 'moment';

@Injectable()
export class CitasService { 
    apiUrls: APIUrls = new  APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
    ) 
    {
        //Papa Promise
        Papa.parsePromise = async function (file) {
            let infoFile = function (file: File) {
                return new Promise((resolve, reject) => {
                    languageEncoding(file).then((fileInfo) => resolve(fileInfo));
                });
            } 

            // Obtener resultados del CSV
            let info: any = await infoFile(file);

            return new Promise(function (complete, error) {
                Papa.parse(file, {
                    header: true,
                    skipEmptyLines: true,
                    encoding: info.encoding,
                    complete,
                    error
                });
            });
        };
    }

    // * * * Concesionarios * * * 
    async obtenerTodosConcesionarios() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.concesionariosURL;
            var json = await this.connection.getRequest(url, false);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                var element = json[i];
                var registro = this.jsonConverters.concesionarioDeJSON(element);
                registros.push(registro);
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerConcesionarioPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.concesionariosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.concesionarioDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearConcesionario(registro: Concesionario) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.concesionariosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Concesionario creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarConcesionario(registro: Concesionario) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.concesionariosURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Concesionario actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarConcesionario(ConcesionarioId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.concesionariosURL + '/id/' + ConcesionarioId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Concesionario eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * Citas * * *
    async obtenerCitaPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.citasURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.citaDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerCitaPorUUID(uuid: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.citasURL + '/uuid/' + uuid;
            var json = await this.connection.getRequest(url, false);
            var registro = this.jsonConverters.citaDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearCita(registro: Cita) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.citasURL;
            const res = await this.connection.postRequest(url, registro, false);
            return { error: null, data: { mensaje: 'Cita creada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarCita(registro: Cita) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.citasURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Cita actualizada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async marcarCitaConfirmada(CitaId: number, confirmada: boolean) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.citasURL + '/marcarConfirmada';
            let data = {
                id: CitaId,
                confirmada: confirmada,
            }
            const res = await this.connection.putRequest(url, data);
            return { error: null, data: { mensaje: 'Cita actualizada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async marcarCitaConfirmadaCliente(CitaId: number, confirmada: boolean) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.citasURL + '/marcarConfirmadCliente';
            let data = {
                id: CitaId,
                confirmadaConCliente: confirmada,
            }
            const res = await this.connection.putRequest(url, data);
            return { error: null, data: { mensaje: 'Cita actualizada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async notificarCita(CitaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.citasURL + '/notificarCitaConfirmada';
            let data = {
                id: CitaId,
            }
            const res = await this.connection.putRequest(url, data);
            return { error: null, data: { mensaje: 'Notificaciones enviadas con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarCita(CitaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.citasURL + '/id/' + CitaId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Cita eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerHorariosDisponibles(ConcesionarioId: number, fecha: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.citasURL + '/horariosDisponibles?';
            if(ConcesionarioId) url += '&ConcesionarioId=' + ConcesionarioId;
            if(fecha) url += '&fecha=' + fecha;

            var json = await this.connection.getRequest(url, false);
            var registros = [];
            for (let i = 0; i < json.horariosAgentes.length; i++) {
                var element = json.horariosAgentes[i];
                let agente = this.jsonConverters.usuarioDeJSON(element.agente);
                let horarios = [];
                for (const elementHorario of element.horariosDisponibles) {
                    let horario = this.jsonConverters.horarioCitaDeJSON(elementHorario);
                    horarios.push(horario);
                }
                agente.horariosDisponibles = horarios;
                registros.push(agente);
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async obtenerCalendarioCitas(fecha: string, AgenteId: number, ConcesionarioId: number) {
        try {
            /*URL para obtener todos los movimientos de cuenta relacionados
            a un proyecto y a una unidad habitacional , que no estén pagados y que no sean abonos*/
            var url = this.apiUrls.baseURL + this.apiUrls.citasURL + '/calendario' + '?fecha=' + fecha;
            if(AgenteId) url += '&AgenteId=' + AgenteId;
            if(ConcesionarioId) url += '&ConcesionarioId=' + ConcesionarioId;

            var json = await this.connection.getRequest(url);

            var registros = [];
            var amenidad: any;
            if(json.registros && Array.isArray(json.registros) && json.registros){
                for (let registro of json.registros) {
                    registros.push(this.jsonConverters.citaDeJSON(registro));
                }
            }
            
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los movimientos.');
        }
    }

    async descargarListadoCitasExcel(params: string, nombre: string) {
        try {
            // Construir ruta
            var url = this.apiUrls.baseURL + this.apiUrls.citasURL + '/listado-excel';
            if(params) url += params;
            var response = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `${nombre}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([response], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: response };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);;
        }
    }

    public async marcarFechaInicioAtencion(CitaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.citasURL + '/marcarInicioAtencion';
            let data = {
                id: CitaId,
            }
            const res = await this.connection.putRequest(url, data);
            return { error: null, data: { mensaje: 'Cita actualizada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async marcarFechaFinAtencion(CitaId: number, fechaInicioAtencion: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.citasURL + '/marcarFinAtencion';
            let data = {
                id: CitaId,
                fechaInicioAtencion: fechaInicioAtencion
            }
            const res = await this.connection.putRequest(url, data);
            return { error: null, data: { mensaje: 'Cita actualizada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async marcarNoAtendida(registro) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.citasURL + '/marcarNoAtendida';
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Cita actualizada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async obtenerReporte(fechaInicioParam: any, fechaFinParam: any) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.citasURL + '/reporte?';
            if (fechaInicioParam) url += '&start=' + fechaInicioParam;
            if (fechaFinParam) url += '&finish=' + fechaFinParam;
            var json = await this.connection.getRequest(url);
            var registro = json.data;
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * Versiones lineas * * * 
    async obtenerTodosVersionLineaSOB() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.versioneslineasSOBURL;
            var json = await this.connection.getRequest(url, false);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                var element = json[i];
                var registro = this.jsonConverters.versionLineaSOBDeJSON(element);
                registros.push(registro);
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerVersionLineaSOBPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.versioneslineasSOBURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.versionLineaSOBDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearVersionLineaSOB(registro: VersionLineaSOB) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.versioneslineasSOBURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Version Linea SOB creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarVersionLineaSOB(registro: VersionLineaSOB) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.versioneslineasSOBURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Version Linea SOB actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarVersionLineaSOB(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.versioneslineasSOBURL + '/id/' + id;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Version Linea SOB eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * Vehiculos * * * 
    async obtenerTodosVehiculoSOB() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.vehiculosSOBURL;
            var json = await this.connection.getRequest(url, false);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                var element = json[i];
                var registro = this.jsonConverters.vehiculoSOBDeJSON(element);
                registros.push(registro);
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerVehiculoSOBPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.vehiculosSOBURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.vehiculoSOBDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearVehiculoSOB(registro: VehiculoSOB) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.vehiculosSOBURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Vehiculo SOB creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarVehiculoSOB(registro: VehiculoSOB) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.vehiculosSOBURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Vehiculo SOB actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarVehiculoSOB(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.vehiculosSOBURL + '/id/' + id;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Vehiculo SOB eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    //// * * * Carga Masiva * * * 

    // * * * * * Generar plantilla * * * * *
    public async generarPlantillaVersiones(nombreArchivo: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.versioneslineasSOBURL + '/generacion-plantilla';
            var res = await this.connection.getDownloadRequest(url);
            
            // Descargar archivo
            var filename = `${nombreArchivo}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Plantilla descargada con éxito' } };

        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * * * Lectura del archivo Excel * * * * *
    async lecturaArchivoVersiones(archivo, columnas ) {
        try {
             // Obtener resultados del Excel
             var resArchivo = await Papa.parsePromise(archivo);
             if(!resArchivo || !resArchivo.data) throw new Error('Ha ocurrido un error al leer los datos del archivo');
            var resNormalizacion = this.libraryService.limpiarColumnasResultadosExcel(resArchivo.data, columnas);
            if(!resNormalizacion) throw new Error('Ha ocurrido un error al preparar los datos del archivo Excel');
            var errores = resNormalizacion.errores;
            var resultados = resNormalizacion.resultados;
            // Preparar resultados según modelo
            var trim = this.libraryService.trimString;

            var resultadosFinales:any[] = [];
            resultados.forEach(element => {
                let usuario = new VersionLineaSOB (
                    -1,
                    Number(trim(element['modelo'])),
                    trim(element['serie codigo']),
                    trim(element['serie madre']),
                    trim(element['serie descripcion']),
                    trim(element['combustible']),
                    false,
                    null,
                );

                resultadosFinales.push(usuario);
            })
            
            return {
                error: false,
                errores: errores,
                resultados: resultadosFinales,
                mensaje: 'Carga de archivo exitosa',
            };
        } catch (error) {
            return { error: true, mensaje: error.message };
        }

    }

    async analizarRegistrosVersionesLineasSOB(registros: VersionLineaSOB[]) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.versioneslineasSOBURL + '/analisis-registros';
            var data = {
                registros: registros,
            }
            const res = await this.connection.postRequest(url, data);
            var registrosNuevos = res.registrosNuevos;
            var registrosEditados = res.registrosEditados;
            var registrosAIgnorar = res.registrosIgnorados;
            var errores = res.errores;
            return { error: null, data: {
                mensaje: 'Análisis de importación exitosa.',
                registrosNuevos: registrosNuevos,
                registrosEditados: registrosEditados,
                registrosAIgnorar: registrosAIgnorar,
                errores: errores,
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    subirRegistrosVersionesLineasSOB(registros: VersionLineaSOB[], correo: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.versioneslineasSOBURL + '/carga-versiones';

            var data = {
                registros: registros,
                correo: correo
            }
            this.connection.postRequest(url, data);

            return { error: null, data: {
                mensaje: 'Se le notificará cuando los registros hayan sido subidos. Por favor cierre esta ventana.',
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * * * Generar plantilla * * * * *
    public async generarPlantillaVehiculos(nombreArchivo: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.vehiculosSOBURL + '/generacion-plantilla';
            var res = await this.connection.getDownloadRequest(url);
            
            // Descargar archivo
            var filename = `${nombreArchivo}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Plantilla descargada con éxito' } };

        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * * * Lectura del archivo Excel * * * * *
    async lecturaArchivoVehiculos(archivo, columnas ) {
        try {
             // Obtener resultados del Excel
             var resArchivo = await Papa.parsePromise(archivo);
             if(!resArchivo || !resArchivo.data) throw new Error('Ha ocurrido un error al leer los datos del archivo');
            var resNormalizacion = this.libraryService.limpiarColumnasResultadosExcel(resArchivo.data, columnas);
            if(!resNormalizacion) throw new Error('Ha ocurrido un error al preparar los datos del archivo Excel');
            var errores = resNormalizacion.errores;
            var resultados = resNormalizacion.resultados;
            // Preparar resultados según modelo
            var trim = this.libraryService.trimString;

            var resultadosFinales:any[] = [];
            resultados.forEach(element => {
                let usuario = new VehiculoSOB(
                    -1,
                    element['chasis'].trim(),
                    element['marca descripcion'].trim(),
                    Number(element['modelo'].trim()),
                    element['motor'].trim(),
                    element['combustible'].trim(),
                    element['serie codigo'].trim(),
                    element['serie descripcion'].trim(),
                    element['paquete codigo'].trim(),
                    element['paquete descripcion'].trim(),
                    element['color codigo'].trim(),
                    element['placas'].trim(),
                    moment(new Date(  Number('20' + trim(element['facturacion año'])), Number(trim(element['facturacion mes'])), Number(trim(element['facturacion dia'])))).format('YYYY-MM-DD')
                );

                resultadosFinales.push(usuario);
            })
            
            return {
                error: false,
                errores: errores,
                resultados: resultadosFinales,
                mensaje: 'Carga de archivo exitosa',
            };
        } catch (error) {
            return { error: true, mensaje: error.message };
        }

    }

    async analizarRegistrosVehiculosSOB(registros: VehiculoSOB[]) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.vehiculosSOBURL + '/analisis-registros';
            var data = {
                registros: registros,
            }
            const res = await this.connection.postRequest(url, data);
            var registrosNuevos = res.registrosNuevos;
            var registrosEditados = res.registrosEditados;
            var registrosAIgnorar = res.registrosIgnorados;
            var errores = res.errores;
            return { error: null, data: {
                mensaje: 'Análisis de importación exitosa.',
                registrosNuevos: registrosNuevos,
                registrosEditados: registrosEditados,
                registrosAIgnorar: registrosAIgnorar,
                errores: errores,
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    subirRegistrosVehiculosSOB(registros: VehiculoSOB[], correo: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.vehiculosSOBURL + '/carga-vehiculos';
            
            var data = {
                registros: registros,
                correo: correo
            }
            this.connection.postRequest(url, data);

            return { error: null, data: {
                mensaje: 'Se le notificará cuando los registros hayan sido subidos. Por favor cierre esta ventana.',
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    cargaRemotaVersionesSOB(archivo: string, correo: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.versioneslineasSOBURL + '/carga-remota';
            
            var data = {
                archivo: archivo,
                correo: correo
            }
            this.connection.postRequest(url, { data: data });

            return { error: null, data: {
                mensaje: 'Se le notificará cuando los registros hayan sido subidos. Por favor cierre esta ventana.',
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    cargaRemotaVehiculosSOB(archivo: string, correo: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.vehiculosSOBURL + '/carga-remota';
            
            var data = {
                archivo: archivo,
                correo: correo
            }
            this.connection.postRequest(url, { data: data });

            return { error: null, data: {
                mensaje: 'Se le notificará cuando los registros hayan sido subidos. Por favor cierre esta ventana.',
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async descargarExcelVersionesLineas() {
        try {
            // Construir ruta
            var url = this.apiUrls.baseURL + this.apiUrls.versioneslineasSOBURL + '/excel-versiones-lineas'
            var response = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `Versiones_Lineas.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([response], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: response };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);;
        }
    }

    async descargarExcelVehiculos() {
        try {
            // Construir ruta
            var url = this.apiUrls.baseURL + this.apiUrls.vehiculosSOBURL + '/excel-vehiculos'
            var response = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `Vehiculos.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([response], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: response };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);;
        }
    }
}