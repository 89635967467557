import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { InicioComponent } from './inicio/inicio.component';
import { Error404Component } from './errors/error-404/error-404.component';

const routes: Routes = [
    {
        path: 'login',
        loadChildren: './login/login.module#LoginModule'
    },
    {
        path: 'encuesta-individual',
        loadChildren: './publico/publico.module#PublicoModule'
    },
    {
        path: 'encuesta-corporativa',
        loadChildren: './publico/publico.module#PublicoModule'
    },
    {
        path: 'kiosko',
        loadChildren: './publico/publico.module#PublicoModule'
    },
    {
        path: 'reservacion-citas',
        loadChildren: './reservacion-citas/reservacion-citas.module#ReservacionCitasModule'
    },
    // Buscar vehiculo
    {
        path: 'vw',
        loadChildren: './laning-page/laning-page.module#LaningPageModule',
    },
    {
        path: '',
        component: CustomLayoutComponent,
        children: [
            // Dashboard
            {
                path: '',
                component: InicioComponent,
                pathMatch: 'full',
            },
            // Empresas
            {
                path: 'empresas',
                loadChildren: './empresas/empresas.module#EmpresasModule',
            },
            // Usuarios
            {
                path: 'usuarios',
                loadChildren: './usuarios/usuarios.module#UsuariosModule',
            },
            // Roles
            {
                path: 'roles',
                loadChildren: './roles/roles.module#RolesModule',
            },
            // Clientes
            {
                path: 'clientes',
                loadChildren: './clientes/clientes.module#ClientesModule',
            },
            // Productos
            {
                path: 'productos',
                loadChildren: './productos/productos.module#ProductosModule',
            },
            // Ventas
            {
                path: 'ventas',
                loadChildren: './ventas/ventas.module#VentasModule',
            },
            // Formularios FEIC
            {
                path: 'formularios-feic',
                loadChildren: './formularios-feic/formularios-feic.module#FormulariosFEICModule',
            },
            // Matriz de riesgo
            {
                path: 'matriz-riesgo',
                loadChildren: './matriz-riesgo/matriz-riesgo.module#MatrizRiesgoModule',
            },
            // Avaluos
            {
                path: 'avaluos',
                loadChildren: './avaluos/avaluos.module#AvaluosModule',
            },
            // Hojas membretadas
            {
                path: 'hojas-membretadas',
                loadChildren: './hojas-membretadas/hojas-membretadas.module#HojasMembretadasModule',
            },
            // Manual
            {
                path: 'manual',
                loadChildren: './manual/manual.module#ManualModule',
            },
            // Unidades
            {
                path: 'ahorrent',
                loadChildren: './ahorrent/ahorrent.module#AhorrentModule',
            },
            // Campaña preventiva
            {
                path: 'campanias-preventivas',
                loadChildren: './campanias-preventivas/campania-preventiva.module#CampaniasPreventivasModule',
            },
            // Citas
            {
                path: 'citas',
                loadChildren: './citas/citas.module#CitasModule',
            },
            // Servicios
            {
                path: 'servicios',
                loadChildren: './servicios/servicios.module#ServiciosModule',
            },
            // Requisiciones
            {
                path: 'requisiciones',
                loadChildren: './requisiciones/requisiciones.module#RequisicionesModule',
            },
            // Repuestos
            {
                path: 'repuestos',
                loadChildren: './repuestos/repuestos.module#RepuestosModule',
            },
            // Versiones
            {
                path: 'versiones',
                loadChildren: './versiones/versiones.module#VersionesModule',
            },
            // Configuraciones
            {
                path: 'configuraciones-landing',
                loadChildren: './configuraciones-landing/configuraciones-landing.module#ConfiguracionesLandingModule',
            },
            // Digitalizacion de boletas
            {
                path: 'digitalizacion-boleta',
                loadChildren: './digitalizacion-boleta/digitalizacion-boleta.module#DigitalizacionBoletaModule',
            },
            // Planes mantenimiento
            {
                path: 'planes-mantenimientos',
                loadChildren: './plan-mantenimiento/plan-mantenimiento.module#PlanMantenimientoModule',
            },
            // Prospectos y Leads
            {
                path: 'prospectos-leads',
                loadChildren: './prospecto-lead/prospecto-lead.module#ProspectoLeadModule',
            },
            {
                path: 'pdi',
                loadChildren: './pdi/pdi.module#PdiModule',
            },
            {
                path: 'presupuesto',
                loadChildren: './presupuestos/presupuestos.module#PresupuestoModule',
            },
            {
                path: 'facturas-sv',
                loadChildren: './facturas-sv/facturas-sv.module#FacturasSVModule',
            },
            {
                path: 'solicitudTraspaso',
                loadChildren: './solicitudTraspaso/solicitudTraspaso.module#solicitudTraspasoModule',
            },
            {
                path: 'bestune',
                loadChildren: './bestune/bestune.module#BestuneModule',
            },
        ]
    },
    { 
        path: '**',
        redirectTo: '/404'
    },
    {
        path: '404',
        component: CustomLayoutComponent,
        children: [
            {
                path: '',
                component: Error404Component,
            }
        ]
    },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		// preloadingStrategy: PreloadAllModules,
		scrollPositionRestoration: 'enabled',
		relativeLinkResolution: 'corrected',
		anchorScrolling: 'enabled'
	})],
	exports: [RouterModule]
})
export class AppRoutingModule {}
