
export class Empresa {
    constructor(
        public id:              number,
        public nombre:          string,
        public logo:            string,
        public descripcion:     string,
        public nit:             string,
        public nombreLegal:     string,
        public activo:          boolean,
        public isDeleted:       boolean,
        public prefijo:         string,
        public moduloAhorrent:  boolean,
    ){}
}