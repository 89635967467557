import { Injectable } from '@angular/core';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { JSONConverters } from '../models/JSONConverters';
import { Version } from '../models/version';

@Injectable({
  providedIn: 'root'
})
export class VersionesService {

    apiUrls: APIUrls = new  APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
    ) { }

    // * * * Versiones * * * 
    async obtenerVersionPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.versionesURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.versionDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearVersion(registro: Version) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.versionesURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarVersion(registro: Version) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.versionesURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarVersion(VersionId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.versionesURL + '/id/' + VersionId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Version eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Todas
    async obtenerTodas() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.versionesURL;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.versionDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las versiones.');
        }
    }

    async obtenerVersiones(params: string = null, auth: boolean = true) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.versionesURL;
            if(params) url += '?' + params;
            var json = await this.connection.getRequest(url, auth);
    
            var registros = [];
            for (let element of json) {
                registros.push(this.jsonConverters.versionDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las líneas.');
        }
    }

    async obtenerVersionPorIdPublic(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.versionesURL + '/idPublic/' + id;
            var json = await this.connection.getRequest(url, false);
            var registro = this.jsonConverters.versionDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }
}
