import { ClientePlanMantenimiento } from "./ClientePlanMantenimiento";
import { PlanDeMantenimiento } from "./PlanDeMantenimiento";
import { VehiculoPlanMantenimiento } from "./vehiculoPlanMantenimiento";

export class Contratacion{
    constructor(
        public id: number,
        public pendienteCancelar: number,
        public uuid: string,
        public fechaContratacion: string,
        public Asesor: string,
        public totalPagosEnCajaConIVA: number,
        public totalPagosEnCajaSinIVA: number,
        public codigoClienteIntegra: string,
        public kilometraje: string,

        public ClientePlanMantenimientoId: number,
        public VehiculoPlanMantenimientoId: number,
        public PlanDeMantenimientoId: number,

    ){}

    ClientePlanMantenimiento: ClientePlanMantenimiento;
    VehiculoPlanMantenimiento: VehiculoPlanMantenimiento;
    PlanDeMantenimiento: PlanDeMantenimiento;

    fechaContratacionFormato: string;
    fechaContratacionDate: Date;

}