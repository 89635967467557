<div class="container" id="dialogOverflow">
    <h3>{{data.titulo}}</h3>
    <br>
    <div class="row">
        <!-- Nombre -->
        <div class="col">
            <ngx-doc-viewer [url]="data.urlDocumento" viewer="google" style="width:100%;height:50vh;"></ngx-doc-viewer>
        </div>
    </div>
    <br/>
    <div class="row">
        <div class="col" style="text-align: right;">
            <button (click)="cerrar()" color="secundary" mat-raised-button type="button" class="margenBoton">
                <ic-icon [icon]="iconClose" inline="true" class="iconBoton"></ic-icon>
                <span>Cerrar</span>
            </button>
            <button (click)="descargar()" color="primary" mat-raised-button type="button" class="margenBoton">
                <ic-icon [icon]="iconDownload" inline="true" class="iconBoton"></ic-icon>
                <span>Descargar</span>
            </button>
        </div>
    </div>
</div>