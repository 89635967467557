import { Empresa } from "./empresa";

export class VehiculoPlanMantenimiento{
    constructor(
        public id: number,
        public chasis: String,
        public tipo: String,
        public marca: String,
        public modelo: String,
        public numeroMotor: String,
        public color: String,
        public placa: String,
        public kilometraje: number,
        public linea: string,
        public eliminado: boolean,
        //Relaciones
        public EmpresaId: number,
    ){}

    Empresa: Empresa;

    //auxiliares
    nombreVehiculo: string;

}