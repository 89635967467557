import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate } from '@angular/router';
// Import our authentication service
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardLogueado implements CanActivate {

    constructor(
        public auth: AuthService,
        private router: Router
    ) {}

    canActivate() {
        if(!this.auth.authGuardLoggedIn()) return false;
        return true;
    }
}