import { ItemPuntoPresupuesto } from "./ItemPuntoPresupuesto";
import { Presupuesto } from "./presupuesto";

export class PuntoPresupuesto {
    constructor(
        public id: number,
        public prioridad: string,
        public porcentajeDescuento: number,
        public totalManoObra: number,
        public totalRepuestos: number,
        public total: number,
        public totalDescuentoManoObra: number,
        public totalDescuentoRepuestos: number,
        //Relaciones
        public PresupuestoId: number,
    ) { }

    fechaFormato: string;
    fechaDate: Date;
    Presupuesto: Presupuesto;

    ItemsPuntoPresupuesto: ItemPuntoPresupuesto[] = [];

}