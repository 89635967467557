import { SolicitudPDI } from "./solicitudPDI";
import { Usuario } from "./usuario";

export class DanioSolicitudPDI {
    constructor(
        public id: number,
        public tipo: string,
        public parteVehiculo: string,
        public fecha: string,
        public tipoDanio: string,
        public descripcion: string,
        public imagenes: string,

        // Relaciones
        public SolicitudPDIId: number,
        public UsuarioId: number,
    ) { 
        this.imagenes = this.imagenes ? JSON.parse(this.imagenes) : [];
    }

    Usuario: Usuario;
    SolicitudPDI: SolicitudPDI;
    imagenesArray: string[];

    //Fechas
    fechaFormato: string;
    fechaDate: Date;
}