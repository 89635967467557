import { ContratoAhorrent } from './ContratoAhorrent';
import { Unidad } from './unidad';
export class MultaAhorrent{
    constructor(
        public id: number,
        public numeroTicket: string,
        public fecha: string,
        public motivo: string,
        public direccion: string,
        public pais: string,
        public ciudad: string,
        public moneda: string,
        public monto: number,

        // Relaciones
        public ContratoAhorrentId: number,
        public UnidadId: number,
    ){}

    //Fecha
    fechaFormato: string;
    fechaDate: Date;

    ContratoAhorrent: ContratoAhorrent;
    Unidad: Unidad;
}