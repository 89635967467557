import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { APIUrls } from 'src/app/api/apiUrls';
import { LibraryService } from 'src/app/library/library.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuariosService } from 'src/app/usuarios/usuarios.service';


import * as moment from 'moment';

import { JSONConverters } from 'src/app/models/JSONConverters';
import { PreguntaEncuestaAhorrent } from 'src/app/models/PreguntaEncuestaAhorrent';
export interface DialogData {
    registro: PreguntaEncuestaAhorrent,
    modoDetalle: boolean,
    modoCreacion: boolean,
    modoEdicion: boolean
}

@Component({
    selector: 'vex-dialog-seleccion-multiple',
    templateUrl: './dialog-seleccion-multiple.component.html',
    styleUrls: ['./dialog-seleccion-multiple.component.scss']
})
export class DialogSeleccionMultipleComponent implements OnInit {

    cargando: boolean;
    registro: PreguntaEncuestaAhorrent;
    apiURLs: APIUrls = new APIUrls();
    modoDetalle: boolean;
    modoCreacion: boolean;
    modoEdicion: boolean;
    titulo: string = "Opciones multiples";
    jsonConverters: JSONConverters = new JSONConverters();

    constructor(
        public dialogRef: MatDialogRef<DialogSeleccionMultipleComponent>,
        public authService: AuthService,
        // public service: EncuestaService,
        public auth: AuthService,
        public libraryService: LibraryService,
        public dialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute,
        public usuarioService: UsuariosService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,) { }

    ngOnInit(): void {
        this.modoCreacion = this.data.modoCreacion;
        this.modoEdicion = this.data.modoEdicion;
        this.modoDetalle = this.data.modoDetalle;
        this.inicializarDatos();
    }

    async inicializarDatos() {
        this.obtenerRegistro();
    }

    async obtenerRegistro() {
        if (this.modoEdicion && this.data.registro) {
            this.registro = this.data.registro;
        }
    }

    async guardar() {
        this.cargando = true;
        // Enviar el registro para guardar
        var res;

        if (this.modoCreacion) {
        } else if (this.modoEdicion) {
            this.data.registro = this.registro;
        }

        if (!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.confirmar();
        } else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    cerrar() {
        this.dialogRef.close(null);
    }

    confirmar() {
        this.dialogRef.close("confirmar");
    }

    eliminarFila(index) {
        if (this.registro && this.registro.opcionesArray) {
            this.registro.opcionesArray.splice(index, 1);
        }
    }

    agregarFila() {
        if (this.registro && this.registro.opcionesArray) {
            let nuevo: any = { opcion: null, imagen: null };
            this.registro.opcionesArray.push(nuevo);
        }
    }

}
