
export class DatoVenta {
    constructor(
        public id: number,
        public vendedor: string,
        public inventario: string,
        public chasis: string,
        public color: string,
        public linea: string,
        public cliente: string,
        public ubicacionAlFacturar: string,
        public polarizado: string,
        public cuero: string,
        public tipoNegocio: string,
        public accesorios: string,
        public numeroFactura: string,
        public fechaFactura: string,
        public observaciones: string,
        public numeroAcuerdo: string,
        public ventaValidada: boolean,
        public tipo: string,
        public numeroFacturaImportacion: string,
        public estadoCodigo: string,
        public categoria: string,
        public modelo: string,
        public numeroPedido: string,
        public serieCodigo: string,
        public fechaEmbarque: string,
        public compania: string,
        
    ) {
        this.cargarAccesorios();
    }

    fechaFacturaFormato: string;
    fechaFacturaDate: Date;

    fechaEmbarqueFormato: string;
    fechaEmbarqueDate: Date;

    //Auxiliares
    accesoriosArray: string[] = [];

    cargarAccesorios() {
        try {
            this.accesoriosArray = JSON.parse(this.accesorios) || [];
            
        } catch (error) {
            console.error('Error al cargar accesorios: '+ error);
        }
    }
}