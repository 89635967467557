import { Injectable } from '@angular/core';
import { Usuario } from '../models/usuario';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { JSONConverters } from '../models/JSONConverters';
import { UsuarioEmpresa } from '../models/usuarioEmpresa';

@Injectable()
export class UsuariosService {
    apiUrls: APIUrls = new APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
    ) { }

    // * * * * * Perfil * * * * *
    // Obtener usuario logueado con token
    async obtenerUsuarioPerfil() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/perfil';
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.usuarioDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Actualizar el perfil del usuario logueado con token
    public async actualizarPerfil(registro: Usuario) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/perfil';
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Perfil actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async reiniciarPassword(password: string, UsuarioId: number, modoPerfil: boolean = false) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL;
            if (modoPerfil) url += '/passwordPerfil';
            else url += '/password';

            var data = {
                password: password,
                UsuarioId: UsuarioId
            }
            const res = await this.connection.putRequest(url, data);
            return { error: null, data: { mensaje: 'Contraseña actualizada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async cambiarPermisos(permisos: string, bloqueos: string, UsuarioId: number, modoPerfil: boolean = false) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/permisos';

            var data = {
                permisos: permisos,
                bloqueos: bloqueos,
                UsuarioId: UsuarioId
            }
            const res = await this.connection.putRequest(url, data);
            return { error: null, data: { mensaje: 'Permisos actualizados con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerUsuarioPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.usuarioDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear usuario
    public async crearUsuario(registro: Usuario) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL;
            const res = await this.connection.postRequest(url, { data: registro });
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Crear usuario
    public async asignarUsuario(registro: Usuario) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/asignar';
            const res = await this.connection.postRequest(url, { data: registro });
            return { error: null, data: { mensaje: 'Registro asignado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar usuario
    public async actualizarUsuario(registro: Usuario) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL;
            const res = await this.connection.putRequest(url, { data: registro });
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async eliminarUsuario(UsuarioId: number, modo: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/id/' + UsuarioId + '?modo=' + modo;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Registro eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async generarTokenSeguridad(UsuarioId: number, modoPerfil: boolean = false) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/tokenSeguridad';

            var data = {
                UsuarioId: UsuarioId,
                modoPerfil: modoPerfil,
            }
            const res = await this.connection.putRequest(url, data);
            return { error: null, data: { mensaje: 'Código de seguridad generado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Actualiza bloqueos y permisos
    public async actualizarPermisosBloqueos(registro: UsuarioEmpresa) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/actualizarPermisos';
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Permisos actualizados con éxito.', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * * * Registro * * * * *
    public async registroUsuario(usuario: Usuario, password: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/registro';
            const res = await this.connection.postRequest(url, { data: usuario, password: password }, false);
            return { error: null, data: { mensaje: 'Su cuenta se ha creado con éxito.', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * * * Obtener datos * * * * *
    async obtenerTodosUsuarios() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.usuarioDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener usuario.');
        }
    }

    async obtenerTodosUsuariosRol(tipo: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/rol/' + tipo;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.usuarioDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener usuario.');
        }
    }

    async obtenerTodosRoles() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.rolesURL;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.rolDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los roles.');
        }
    }

    async obtenerUsuariosAsignadosSolicituImpresion() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/usuariosAsignadosSolicitudImpresion';
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.usuarioDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los usuarios asignados para solicitud de impresión.');
        }
    }

    async obtenerTodosMembretesEmpresa() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.membretesEmpresasURL + '/empresa';
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.membreteEmpresaDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los membretes.');
        }
    }

    // * * * * * * Archivos * * * * * *
    async guardarArchivoEnServidorUsuarios(archivo: File, basicAuth: boolean = false) {
        try {
            var url = this.apiUrls.uploadURL + this.apiUrls.usuariosURL + '/uploadArchivo';
            if(basicAuth) url += 'Abierto';
            const res = await this.connection.uploadFile(url, archivo, !basicAuth);
            if (!res.error) {
                return { error: null, data: { url: res.url } };
            }
            else throw new Error();
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al cargar el archivo.');
        }
    }

    async guardarArchivoEnServidor(archivo: File, basicAuth: boolean = false) {
        try {
            var url = this.apiUrls.uploadURL + this.apiUrls.usuariosURL + '/uploadArchivo';
            if(basicAuth) url += 'Abierto';
            const res = await this.connection.uploadFile(url, archivo, !basicAuth);
            if (!res.error) {
                return { error: null, data: { url: res.url } };
            }
            else throw new Error();
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al cargar el archivo.');
        }
    }


    // Verificar correo
    async verificarCorreo(correo: any) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/email/' + correo;
            const res = await this.connection.getRequest(url, false);
            return { error: res.error, data: { mensaje: 'Verificacion existosa.', result: res.result } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear usuario
    public async registrarUsuario(data: any) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/registro';
            const res = await this.connection.postRequest(url, { data: data }, false);
            return { error: null, data: { mensaje: 'Usuario registrado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * * * Reset contraseña * * * * *
    public async enviarCorreoRestaurarContrasena(correo: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/solicitarReinicioPassword';
            const res = await this.connection.postRequest(url, { correo: correo }, false);
            return { error: null, data: { mensaje: 'Por favor revise su correo para obtener el código de seguridad.', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async recuperarPasswordCodigoSeguridad(correo: string, codigoSeguridad: string, password: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/recuperarPasswordCodigoSeguridad';
            var data = {
                correo: correo,
                codigoSeguridad: codigoSeguridad,
                password: password,
            };
            const res = await this.connection.postRequest(url, data, false);
            return { error: null, data: { mensaje: 'Contraseña recuperada con éxito.', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.message);
        }
    }

    // * * * Usuario Proyecto * * * //

    async obtenerRelacionUsuarioEmpresaId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.empresasURL + '/UsuarioEmpresa/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.usuarioEmpresaDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async crearRelacion(relacion: UsuarioEmpresa) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.empresasURL + '/UsuarioEmpresa';
            const res = await this.connection.postRequest(url, { data: relacion });
            return { error: null, data: { mensaje: 'Permisos creados con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async actualizarRelacion(relacion: UsuarioEmpresa) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.empresasURL + '/UsuarioEmpresa';
            const res = await this.connection.putRequest(url, relacion);
            return { error: null, data: { mensaje: 'Permisos actualizados con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async eliminarRelacion(RelacionId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.empresasURL + '/UsuarioEmpresa' + '/id/' + RelacionId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Relación eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerUsuariosVendedores() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/vendedores';
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.usuarioDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener vendedores.');
        }
    }

    async obtenerUsuariosValuadores() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/valuadores';
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.usuarioDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener valuadores.');
        }
    }

    async obtenerUsuariosAgentesCitas() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/agentesCitas';
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.usuarioDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener vendedores.');
        }
    }

}
